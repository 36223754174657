export const nxerConfig = {
    previewLines: {
        mainColor: "red",
        lineWidth: 10,
        opacity: 1,
        dashed: false,
    },
    perpendicularLine: {
        color: "red",
        lineWidth: 10,
        opacity: 1,
        dashed: false,
    },
    mainLines: {
        lineWidth: 10,
        opacity: 1,
        dashed: true,
    },
}

export type NxerConfig = typeof nxerConfig