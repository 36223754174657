/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
/* eslint-disable max-statements */
import { Icosahedron, Text, } from "@react-three/drei"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { ThreeEvent, useFrame, useThree } from "@react-three/fiber"
import { useRecoilValue, useSetRecoilState } from "recoil"
import {
    BackSide,
    Box3,
    Color,
    Group,
    Mesh,
    Object3D,
    Raycaster,
    Vector3
} from "three"
import { ConnectionTypeAPI } from "../../../../../../../../common/api/Types"
import { addPartModal } from "../../../../../../../state/atoms"
import { connectionTypesSelector } from "../../../../../../../state/initialDataSelectors"
import { allConnections } from "../../../../../../../state/scene/selectors"
import { innerToOuter, outerToInner } from "../../../../../../../utils/MarkerUtil"
import { MeshUtils } from "../../../../../../../utils/MeshUtils"
import { ConnectorPart, MarkerType, PartTypeEnum } from "../../../../../../../utils/Types"
import { ObjDictionary } from "../../../../../../../../common/utils/utils"
import { roundNumber } from "../../../../../../../utils/utils"
import { metersToInch } from "../../../../utils/utilsThree"
import {
    isExpandReduceApplicable
} from "../../../../../../main/DesignScreen/scene/part/parts/tube/utils/ReduceExpandToFit"
import { ConnectorInternalsType, ConnectorMarkerType } from "./types/types"
import { breadcrumb } from "../../../../../../../../common/utils/sentrySetup"
import { EnvHelper } from "../../../../../../../../common/utils/EnvHelper"
import { debounce as debounceLodash } from "lodash"
import { SceneRef } from "../../../../../../../state/types"

type NewPartButtonProps = {
    name: string,
    id: string,
    markersMapName: string,
    connectorInternalsRef: React.MutableRefObject<ConnectorInternalsType>,
    connectorMeshRef: React.MutableRefObject<Group | undefined>,
    connector: ConnectorPart,
    connectionTypes: ObjDictionary<ConnectionTypeAPI>,
    sizeMarker: number,
    setIsSelected: (selected: boolean) => void,
    sceneRefs: SceneRef,
}


const NewPartButtonConnector = (props: NewPartButtonProps) => {
    const { scene, camera, } = useThree()
    const [buttonSize, setButtonSize,] = useState(0.02)
    const [hovered, hover,] = useState(false)
    const setNewConnectionData = useSetRecoilState(addPartModal)
    const icoRef = useRef<Mesh>(null)
    const cameraVector = new Vector3()



    useFrame((state) => {
        if (icoRef.current) {
            camera.getWorldPosition(cameraVector)
            icoRef.current.lookAt(cameraVector)
        }
    })


    const connections = useRecoilValue(allConnections)
    const connectionTypes = useRecoilValue(connectionTypesSelector)

    const trackerName = innerToOuter(props.name)


    // eslint-disable-next-line max-statements
    const getMarkerPosAndRot = (markerName: string) => {
        const markersRef = props.connectorInternalsRef.current.markers
        let innerMarker
        let outerMarker
        if (markerName.includes(ConnectorMarkerType.inner)) {
            innerMarker = markersRef[markerName]
            outerMarker = markersRef[innerToOuter(markerName)]
        } else {
            outerMarker = markersRef[markerName]
            innerMarker = markersRef[outerToInner(markerName)]
        }

        const innerPos = MeshUtils.copyWorldPosition(innerMarker)
        const outerPos = MeshUtils.copyWorldPosition(outerMarker)

        const innerMarkerRotation = MeshUtils.copyWorldQuaternion(innerMarker)
        const outerMarkerRotation = MeshUtils.copyWorldQuaternion(outerMarker)

        return {
            inner: {
                pos: innerPos,
                rot: innerMarkerRotation,
            },
            outer: {
                pos: outerPos,
                rot: outerMarkerRotation,
            },
        }
    }

    const connectorBox = useMemo(() => {
        const box = new Box3()
        // Get world position from the mesh
        const worldPosition = new Vector3()
        const posAndRot = getMarkerPosAndRot(props.name)
        worldPosition.copy(posAndRot.outer.pos)

        // Create a small box around that position
        const size = 0.02 // Small default size
        box.min.set(
            worldPosition.x - size,
            worldPosition.y - size,
            worldPosition.z - size
        )
        box.max.set(
            worldPosition.x + size,
            worldPosition.y + size,
            worldPosition.z + size
        )

        return box
    }, [props.name,])

    useEffect(() => {
        if (!props.sceneRefs?.current?.cameraControls || !props.sceneRefs?.current?.calculateSizeOfBoxFactor) {
            return
        }
        const cam = props.sceneRefs.current.cameraControls.current
        if (!cam) {
            return
        }

        //updating of 2d position of markers on the screen for snapping logic
        //you need a slight timeout bc the camera still moved for a little right after it says
        //controlend

        const debouncedUpdate = debounceLodash(() => {
            if (connectorBox && props.sceneRefs.current.calculateSizeOfBoxFactor) {
                const size = props.sceneRefs.current.calculateSizeOfBoxFactor(connectorBox, false)
                if (size) {
                    if (size.result === buttonSize) {
                    } else {
                        setButtonSize(size.result)
                    }
                }
            }
        }, 50,)


        const update = (() => {
            //console.log("update")
            debouncedUpdate()
        })

        update()


        // we need both
        cam.addEventListener("update", update)
        cam.addEventListener("transitionstart", update)

        // Clean up
        return () => {
            cam.removeEventListener("update", update)
            cam.removeEventListener("transitionstart", update)
        }
    }, [connectorBox,])


    const onHover = () => {
        hover(true)
    }

    const onHoverOut = () => {
        hover(false)
    }

    useEffect(() => {
        if (hovered) {
            document.body.style.cursor = "pointer"
        } else {
            document.body.style.cursor = "default"
        }
    }, [hovered,])

    const handleNewPart = (
        trackerName: string,
        e: ThreeEvent<MouseEvent>
    ) => {
        breadcrumb({
            message: "Click on new Part button",
            level: "info",
            data: {
                partToConnectId: props.connector.apiTypeId,
                partToConnectName: props.connector.name,
            },
        })
        props.setIsSelected(false)
        e.stopPropagation()

        const markersRef = props.connectorInternalsRef.current.markers
        const tracker = markersRef[trackerName]
        if (tracker && props.connectorMeshRef.current) {

            const ray = new Raycaster()
            const isExpand = isExpandReduceApplicable(
                tracker,
                ray,
                scene,
                connections,
                connectionTypes
            )

            breadcrumb({
                message: `is expand or reduce: ${isExpand.isApplicable}`,
                level: "info",
            })
            const posAndRot = getMarkerPosAndRot(trackerName)

            const source = {
                partId: props.connector.id,
                markerId: tracker.userData.id,
                markerName: tracker.name,
                posAndRot,
                connectionLength: tracker.userData.iELength,
                sizeId: tracker.userData.sizeId,
                expandReduceToFitInfo: isExpand,
                type: PartTypeEnum.connector,
            }

            breadcrumb({
                message: "Set data to show parts modal",
                level: "info",
                data: {
                    partId: source.partId,
                },
            })

            setNewConnectionData({ step1: { source, }, })
        }
    }

    const materialColor = useMemo(() => {
        return new Color(hovered ? "#43a7ff" : "#0088ff").convertSRGBToLinear()
    }, [hovered,])

    const isSpecialRotationMarker = props.connector.specialRotationMarker

    if (trackerName.includes(ConnectorMarkerType.inner)) {
        return null
    } else {
        return (
            <Icosahedron
                userData={{
                    type: MarkerType.PLUS_BUTTON,
                }}
                ref={icoRef}
                args={[buttonSize / 2, 3,]}
                position={[0, 0, buttonSize,]}
                //use PointerDown instead of onClick bc on mobile, sometimes it does not get recognized
                onPointerDown={(e) => {
                    e.stopPropagation()
                    //handleNewPart(trackerName, e)
                }}
                onPointerLeave={() => onHoverOut()}
                onPointerEnter={() => onHover()}
                onPointerUp={(e) => {
                    e.stopPropagation()
                    handleNewPart(trackerName, e)
                }}
                onClick={(e) => {
                    e.stopPropagation()
                }}>
                <meshBasicMaterial
                    color={materialColor}
                    side={BackSide}
                    depthTest={!isSpecialRotationMarker}
                    depthWrite={!isSpecialRotationMarker}
                />
                <Text fontSize={buttonSize} color={"#fff"}>
                    <meshBasicMaterial color="#ffffff" depthTest={!isSpecialRotationMarker} depthWrite={!isSpecialRotationMarker} />
                    +
                </Text>
            </Icosahedron >
        )
    }
}

export default NewPartButtonConnector