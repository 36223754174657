/* eslint-disable max-statements */
/* eslint-disable no-console */
/* eslint-disable max-len */
import React, { useState } from "react"
import { Typography } from "antd"
import styled from "styled-components"
import { useRecoilState, useRecoilValue } from "recoil"
import {
  GenericPartState,
  PartTypeEnum,
  SegmentedTubePart,
  SegmentedTubeValues,
  TubePart
} from "../../../../utils/Types"
import { roundLength } from "../../../../utils/utils"
import UnitToggle from "./UnitToggle"
import useUnitConversion from "../utils/UnitUtils"
import { boundingBoxAtom, BoundingBoxState, combinedBoxAtomUserSelection, partsIdsSelector, rulerAtom, RulerState } from "../../../../state/scene/atoms"
import { ReactComponent as SvgButton }
  from "../../../../../../src/common/assets/icons/Dimensions.svg"
import isMobile from "ismobilejs"
import { ReactComponent as RulerIcon } from "../../../../../../src/common/assets/icons/Ruler.svg"
import { useCursor } from "@react-three/drei"
import { SceneRef } from "../../../../state/types"
const { Text, } = Typography

const SvgButtonContainer = styled.span<{ $isShown: boolean, }>`
    padding: 4px;
    padding-right: 4px;
    cursor: pointer;
    display: flex;

    svg path {
        fill: ${({ $isShown, }) => ($isShown ? "blue" : "black")}
    }

    @media (hover: hover) {
        &:hover svg path,
        &:active svg path {
            fill: blue;
        }
    }

    @media (hover: none) {
        &:active svg path {
            fill: blue;
        }
    }
`

const SizeContainer = styled.div<{ $isMobile: boolean, }>`
  display: flex;
  align-items: center;
  max-width: 100%;
`

const SelectedPartNameText = styled(Text) <{ $isMobile: boolean, }>`
    color: grey;
    display: inline-block;
    vertical-align: middle;
    min-width: 75px;

    ${({ $isMobile, }) => $isMobile && `
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `}

    &&& {
      @media screen and (max-width: 390px) {
        font-size: 11px;
      }
    }
`

const getLength = (selectedPart: TubePart | SegmentedTubePart) => {
  if (selectedPart.type === PartTypeEnum.tube) {
    return selectedPart.unitRealValue || selectedPart.length
  }

  if (selectedPart.unitRealValue) {
    return selectedPart.unitRealValue
  }

  let adjustedStartLength = selectedPart.startSegmentLength
  let adjustedEndLength = selectedPart.endSegmentLength

  if (adjustedStartLength <= 0.01) {
    adjustedStartLength = 0
  }
  if (adjustedEndLength <= 0.01) {
    adjustedEndLength = 0
  }

  return (selectedPart.length + selectedPart.lengthNegativeSide)
    * selectedPart.segmentLength
    + adjustedStartLength
    + adjustedEndLength
}

const getUnitSymbol = (selectedPart: TubePart | SegmentedTubePart, unit: string) => {
  if ((selectedPart as SegmentedTubeValues)?.partUnits) {
    return (selectedPart as SegmentedTubeValues).partUnits
  }
  if (!selectedPart.partUnits) {
    return "in"
  }
  return unit === "cm" ? " cm" : "\""
}

interface PartSizeProps {
  selectedPart?: GenericPartState;
  sceneRefs: SceneRef;
}

const PartSize: React.FC<PartSizeProps> = ({ selectedPart, sceneRefs, }) => {
  const [boundingBox, setBoundingBox,] = useRecoilState(boundingBoxAtom)
  const [ruler, setRuler,] = useRecoilState(rulerAtom)
  const [hoveredText, setHoveredText,] = useState(false)
  useCursor(hoveredText, "pointer")

  const partsIdsList = useRecoilValue(partsIdsSelector)
  const { unit, } = useUnitConversion()
  const combinedBox = useRecoilValue(combinedBoxAtomUserSelection)
  const isMobilePhone = isMobile().phone
  const handleClickSize = () => {
    setBoundingBox((prevState: BoundingBoxState) => ({
      ...prevState,
      show: !prevState.show,
    }))
  }

  const handleClickRuler = () => {
    setRuler((prevState: RulerState) => ({
      ...prevState,
      show: !prevState.show,
    }))
  }

  //we're relying completely on the combinedBox state to get the dimensions

  const cmSizeInfo = combinedBox?.userData.getDimensions("cm")
  const inSizeInfo = combinedBox?.userData.getDimensions("in")

  const inSizeString = inSizeInfo ? `${inSizeInfo?.width?.toFixed(2)}" x ${inSizeInfo?.height?.toFixed(2)}" x ${inSizeInfo?.depth?.toFixed(2)}"` : ""
  let cmSizeString = cmSizeInfo ? `${cmSizeInfo?.width?.toFixed(2)} x ${cmSizeInfo?.height?.toFixed(2)} x ${cmSizeInfo?.depth?.toFixed(2)}` : ""

  if (!isMobilePhone) {
    cmSizeString = cmSizeString.concat(" cm")
  }

  //account for when there is nothing on the scene
  if (!partsIdsList || partsIdsList.length === 0) {
    return null
  }

  if (!selectedPart) {
    return (
      <SizeContainer $isMobile={isMobilePhone}>
        <SvgButtonContainer $isShown={ruler.show} onClick={handleClickRuler}>
          <RulerIcon />
        </SvgButtonContainer>
        {(cmSizeString || inSizeString) && (
          <SvgButtonContainer
            $isShown={boundingBox?.show}
            onClick={handleClickSize}
          >
            <SvgButton />
          </SvgButtonContainer>
        )}
        <SelectedPartNameText
          onMouseEnter={() => setHoveredText(true)}
          onMouseLeave={() => setHoveredText(false)}
          onClick={handleClickSize}
          $isMobile={isMobilePhone}
        >
          {unit === "in" ? inSizeString : cmSizeString}
        </SelectedPartNameText>
        {(cmSizeString || inSizeString) && (
          <UnitToggle compact={isMobilePhone} dropdown={true} />
        )}
      </SizeContainer>
    )
  }

  const isConnector = selectedPart.type === PartTypeEnum.connector
  if (isConnector) { return null }

  const partLength = `${roundLength(getLength(selectedPart))}`
  const unitSymbol = getUnitSymbol(selectedPart, unit)

  return <span>{` - ${partLength}${unitSymbol} Length`}</span>
}

export default PartSize