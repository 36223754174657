/* eslint-disable max-len */
import { captureException } from "@sentry/react"
import pako from "pako"
import { breadcrumb } from "../utils/sentrySetup"

const COMPRESS_URL = "https://compressdesign-pe2dtkz4ra-uc.a.run.app"
const DECOMPRESS_URL = "https://decompressdesign-pe2dtkz4ra-uc.a.run.app"

const compressInCloud = async (designAsString: string): Promise<string> => {
    // console.log("compressInCloud", designAsString)
    try {
        const response = await fetch(COMPRESS_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ designAsString: designAsString, }),
        })

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }

        const data = await response.json()
        return data.compressedDesign
    } catch (error) {
        // console.error("Error compressing in cloud:", error)
        captureException(error, {
            extra: { message: "Error compressing in cloud", },
        })
        return designAsString
    }
}

const decompressInCloud = async (encodedDesign: string): Promise<string> => {
    // console.log("decompressInCloud", encodedDesign)
    try {
        const response = await fetch(DECOMPRESS_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ encodedDesign: encodedDesign, }),
        })

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }

        const data = await response.json()
        return data.decompressedDesign
    } catch (error) {
        console.error("Error decompressing in cloud:", error)
        captureException(error, {
            extra: { message: "Error decompressing in cloud", },
        })
        return encodedDesign
    }
}

const compressLocally = (designAsString: string): string => {
    const encoder = new TextEncoder()
    const data = encoder.encode(designAsString)
    const compressed = pako.deflate(data)
    return btoa(String.fromCharCode.apply(null, Array.from(compressed)))
}

const decompressLocally = (encodedDesign: string): string => {
    const base64Decoded = atob(encodedDesign)
    const charData = Uint8Array.from(base64Decoded.split("").map(c => c.charCodeAt(0)))
    const decompressedData = pako.inflate(charData)
    const decoder = new TextDecoder()
    return decoder.decode(decompressedData)
}

export const compressDesign = async (designAsString: string, shouldLog = true): Promise<string> => {
    if (shouldLog) {
        // console.time("compressDesign")
    }

    breadcrumb({
        level: "info",
        message: "compressDesign",
    })

    try {
        // First test if client-side compression works
        const canUseClientSide = testCompressionAndDecompression()

        if (canUseClientSide) {
            try {
                // Try local compression
                return compressLocally(designAsString)
            } catch (localError) {
                console.error("Local compression failed, falling back to cloud:", localError)
                // Fallback to cloud compression if local fails
                return await compressInCloud(designAsString)
            }
        } else {
            // Environment doesn't support local compression, use cloud
            return await compressInCloud(designAsString)
        }
    } catch (error) {
        console.error("Error compressing design:", error)
        captureException(error, {
            extra: { message: "Error compressing design", },
        })
        return designAsString
    } finally {
        if (shouldLog) {
            // console.timeEnd("compressDesign")
        }
    }
}

export const decompressDesign = async (encodedDesign: string, shouldLog = true): Promise<string> => {
    if (shouldLog) {
        // console.time("decompressDesign")
    }

    breadcrumb({
        level: "info",
        message: "decompressDesign",
    })

    try {
        // First test if client-side decompression works
        const canUseClientSide = testCompressionAndDecompression()

        if (canUseClientSide) {
            return decompressLocally(encodedDesign)
        } else {
            // Fallback to cloud decompression
            return await decompressInCloud(encodedDesign)
        }
    } catch (error) {
        console.error("Error decompressing design:", error)
        captureException(error, {
            extra: { message: "Error decompressing design", },
        })
        return encodedDesign
    } finally {
        if (shouldLog) {
            // console.timeEnd("decompressDesign")
        }
    }
}

// If you need the test functions, they can be exported separately
export const testCompression = () => {
    const testString = JSON.stringify({
        parts: {
            "d6307303-67c6-4a5e-9a0c-9835d02e7959": {
                loaded: false,
                id: "d6307303-67c6-4a5e-9a0c-9835d02e7959",
                name: '1" x 3" Lumber',
            },
        },
        name: "test",
    })
    const expectedCompressed = "eJyrVipILCopVrKqVkoxMzYwNzYw1jUzTzbTNUk0TdW1TDRI1rW0MDZNMTBKNbc0tQSpy8lPTElNUbJKS8wpTtVRygQyidOro5SXmJsKVG0Yo6RQoWAMJH1Kc5NSi5Rqa+FyJanFJUq1ADWpKb0="

    const compressed = compressLocally(testString)

    return compressed === expectedCompressed
}

export const testDecompression = () => {
    const testString = JSON.stringify({
        parts: {
            "d6307303-67c6-4a5e-9a0c-9835d02e7959": {
                loaded: false,
                id: "d6307303-67c6-4a5e-9a0c-9835d02e7959",
                name: '1" x 3" Lumber',
            },
        },
        name: "test",
    })
    const fixedInputCompressed = "eJyrVipILCopVrKqVkoxMzYwNzYw1jUzTzbTNUk0TdW1TDRI1rW0MDZNMTBKNbc0tQSpy8lPTElNUbJKS8wpTtVRygQyidOro5SXmJsKVG0Yo6RQoWAMJH1Kc5NSi5Rqa+FyJanFJUq1ADWpKb0="

    const decompressedResult = decompressLocally(fixedInputCompressed)
    return decompressedResult === testString
}

export const testCompressionAndDecompression = (): boolean => {
    const compressionTest = testCompression()
    const decompressionTest = testDecompression()
    if (!compressionTest) {
        captureException("compressionTest failed")
    }
    if (!decompressionTest) {
        captureException("decompressionTest failed")
    }

    return compressionTest && decompressionTest
}