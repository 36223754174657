/* eslint-disable max-len */
import React, { useEffect, useRef } from "react"
import ActionButton from "../../../../../../../../../common/components/ActionButton"
import { ActionButtonContainer } from "../../segmentedTube/ui/ActionPanelStyles"
import { TUBE_UI } from "../types/types"
import { ReactComponent as TrashIcon }
    from "../../../../../../../../../common/assets/icons/Trash.svg"

interface Props {
    tubeUI: TUBE_UI;
    setTubeUI: (ui: TUBE_UI) => void;
    onRemove: () => void;
    onSwap: () => void;
    onDuplicate: (duplicateEverything: boolean, duplicateSpecificPartsIds?: string[]) => void;
    partId: string;
    setIdsAsHighlightedAndTurnOnControl: (ids: string[], mode: "translate" | "rotate" | "nx") => void;
}

const ActionsUI = (props: Props) => {
    const { onSwap, } = props
    const transformModeRef = useRef<string>("off")

    useEffect(() => {
        transformModeRef.current = "off"

        return () => {
            transformModeRef.current = "off"
        }
    }, [])

    const handleRemoveModal = () => {
        props.setTubeUI(TUBE_UI.NONE)
    }

    const handleSwap = () => {
        props.setTubeUI(TUBE_UI.NONE)
        onSwap()
    }

    const handleDuplicate = () => {
        props.onDuplicate(false, [props.partId,])
    }

    const handleShowSlider = () => {
        props.setTubeUI(TUBE_UI.SLIDER)
    }

    const handleSingleItemMove = () => {
        if (props.partId) {
            //props.setIsSelected(false)
            transformModeRef.current = "translate"
            props.setIdsAsHighlightedAndTurnOnControl([props.partId!,], "translate")
            props.setTubeUI(TUBE_UI.NONE)
        }
    }

    const handleNX = () => {
        if (props.partId) {
            //props.setIsSelected(false)
            transformModeRef.current = "nx"
            props.setIdsAsHighlightedAndTurnOnControl([props.partId!,], "nx")
            props.setTubeUI(TUBE_UI.NONE)
        }
    }


    return (
        <ActionButtonContainer>
            <ActionButton
                onClick={handleSwap}>
                Swap</ActionButton>
            <ActionButton
                selected={props.tubeUI === TUBE_UI.SLIDER}
                onClick={handleShowSlider}
            >Edit Length
            </ActionButton>
            {props.partId && (
                <ActionButton
                    selected={transformModeRef.current === "translate"}
                    onClick={handleSingleItemMove}
                >
                    Move
                </ActionButton>
            )}
            <ActionButton
                onClick={handleDuplicate}
            >2x
            </ActionButton>
            {props.partId && (
                <ActionButton
                    selected={transformModeRef.current === "nx"}
                    onClick={handleNX}
                >
                    Nx
                </ActionButton>
            )}
            <ActionButton
                onClick={props.onRemove}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingLeft: 0,
                    paddingRight: 0,
                }}>
                <TrashIcon />
            </ActionButton>
        </ActionButtonContainer>
    )
}

export default ActionsUI