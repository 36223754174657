/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
/* eslint-disable max-len */
import React, { useState, useEffect, } from "react"
import { Html } from "@react-three/drei"
import ReactDOM from "react-dom"
import { AnimatePresence } from "framer-motion"
import { ActionsContainer, HeaderContainer, HeaderContainerCompact, LeftContent, MultipleCheckBoxContainer, MultipleCheckBoxItem, SimpleCheckBoxContainer, SliderContainer }
  from "../../components/main/DesignScreen/scene/part/parts/ActionPanelStyles"
import ActionsUI from "./ActionsUI"
import { ROTATION_AXES_ENUM } from
  "../../components/main/DesignScreen/scene/part/parts/segmentedTube/types/types"
import MultiSelectionRotation from "./MultiSelectionRotation"
import { viewForScalerUI } from "../../components/ScalerUILines"
import { messageUtils } from "../../components/main/DesignScreen/scene/LowerRightMessages"
import { BoxMeshWithUserData, createCombinedOrientedBoundingBox, drawDimensionLine, drawVector3Point, } from "../../utils/PartUtils"
import { Mesh, Scene, Line as LineThree, Vector3, LineBasicMaterial, BufferGeometry, ArrowHelper } from "three"
import { Group } from "three"
import NumberSlider from "../../components/main/DesignScreen/scene/part/parts/sliders/Slider"
import { LeftRightContainer, LeftRightContainerCompact, containerForCenteredHeading } from "../../components/main/DesignScreen/scene/part/parts/segmentedTube/ui/ActionPanelStyles"
import { Label } from "../../components/main/DesignScreen/scene/part/parts/segmentedTube/ui/SegmentedSliderStyles"
import { set } from "immer/dist/internal"
import isMobile from "ismobilejs"


interface Props {
  onDelete: () => void;
  changeTransformMode: (mode: "translate" | "rotate" | "scale" | "off") => void;
  changeSelectionMode: (selectionMode: boolean) => void;
  resetSelection: () => void;
  transformMode: "translate" | "rotate" | "scale" | "off";
  selectionMode: boolean;
  NXmode: boolean;
  setNXmode: (NXmode: boolean) => void;
  duplicateSelectedParts: (duplicateEverything?: boolean,
    duplicateSpecificPartsIds?: string[]) => void;
  hideMultiUIControls: boolean;
  createGroup: (name: string) => void;
  isAdmin: boolean;
  onNxClick: () => { boundingBoxGroup: Group, boundingBoxes: Mesh[], };
  setHideMultiUIControls: (hide: boolean) => void;
  onRotationSliderChange: (rotationAxis: ROTATION_AXES_ENUM, value: number) => void;
  setShowGroupNamingModal: (show: boolean) => void;
  scene: Scene;
  resizeMode: boolean;
  setResizeMode: (enable: boolean) => void;
  viewsForScalerUI: viewForScalerUI[] | null;
  scalerMoveConnectedItems: boolean;
  setScalerMoveConnectedItems: (enable: boolean) => void;
  NXmodeOffset: number;
  setNXmodeOffset: (offset: number) => void;
  NXmodeUnit: string;
  setNXmodeUnit: (unit: string) => void;
  checkForSnap: boolean;
  setCheckForSnap: (checkForSnap: boolean) => void;
  checkForAlign: boolean;
  setCheckForAlign: (checkForAlign: boolean) => void;
}

const MultiSelectUI: React.FC<Props> = ({ onDelete, changeTransformMode,
  duplicateSelectedParts, changeSelectionMode,
  transformMode, selectionMode, resetSelection, onRotationSliderChange, isAdmin, setShowGroupNamingModal, resizeMode, setResizeMode, viewsForScalerUI, onNxClick, scene, setNXmode, NXmode, NXmodeOffset, setNXmodeOffset, NXmodeUnit, setNXmodeUnit, scalerMoveConnectedItems, setScalerMoveConnectedItems, checkForSnap, setCheckForSnap, checkForAlign, setCheckForAlign, }) => {
  const [activeTab, setActiveTab,]
    = useState<"move" | "rotate" | "duplicate" | "delete" | "resize" | "nx" | null>(null)
  const isMobileResult = isMobile()
  const isMobilePhone = isMobileResult.phone

  useEffect(() => {
    if (transformMode === "translate" && selectionMode === false) {
      setActiveTab("move")
    }
    else if (transformMode === "rotate" && selectionMode === false) {
      setActiveTab("rotate")
    }
    else if (NXmode) {
      setActiveTab("nx")
    }
    else if (transformMode === "off" && selectionMode === false && NXmode === false) {
      setActiveTab(null)
    }
  }, [selectionMode, transformMode, NXmode,])


  const handleMoveClick = () => {
    setActiveTab(activeTab === "move" ? null : "move")
    changeTransformMode(activeTab === "move" ? "off" : "translate")
    changeSelectionMode(false)
  }

  const handleRotateClick = () => {
    setActiveTab(activeTab === "rotate" ? null : "rotate")
    changeTransformMode(activeTab === "rotate" ? "off" : "rotate")
    changeSelectionMode(false)
  }

  const handleDuplicateClick = () => {
    duplicateSelectedParts()
  }

  const handleNxClick = () => {
    if (NXmode) {
      setActiveTab(null)
      setNXmode(false)
    } else {

      changeTransformMode("off")
      changeSelectionMode(false)
      setResizeMode(false)
      setTimeout(() => {
        setActiveTab("nx")
        setNXmode(true)
      }, 100)
    }

  }

  const handleUnitChange = (unit: string) => {
    setNXmodeUnit(unit)
  }

  const handleDeleteClick = () => {
    onDelete()
  }
  const handleClearClick = () => {
    resetSelection()
  }

  const handleClickOnMoveConnectedItems = () => {
    setScalerMoveConnectedItems(!scalerMoveConnectedItems)
    localStorage.setItem("scalerMoveConnectedItems", String(!scalerMoveConnectedItems))
  }

  const handleResizeClick = () => {
    if ((viewsForScalerUI && viewsForScalerUI.length === 0) || !viewsForScalerUI) {
      messageUtils.custom("There are no parts that are resizable in your selection!", {
        forceShow: true,
        duration: 6,
      })
      return
    }
    if (resizeMode) {
      setResizeMode(false)
      setActiveTab(null)
    } else {
      changeTransformMode("off")
      setNXmode(false)
      changeSelectionMode(false)
      setTimeout(() => {
        setActiveTab("resize")
        setResizeMode(true)
      }, 100)
    }
  }


  return (
    <Html wrapperClass="neutralHTML">
      {ReactDOM.createPortal(
        <AnimatePresence>
          <ActionsContainer
            $partUI="multiselect"
            $isMobilePhone={isMobilePhone}
            transition={{ duration: 0.2, }}
            variants={{
              visible: { y: "0%", },
              hidden: { y: "130%", },
            }}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <ActionsUI
              activeTab={activeTab}
              onMoveClick={handleMoveClick}
              onRotateClick={handleRotateClick}
              onDuplicateClick={handleDuplicateClick}
              onNxClick={handleNxClick}
              onDeleteClick={handleDeleteClick}
              onClearClick={handleClearClick}
              onResizeClick={handleResizeClick}
              isAdmin={isAdmin}
              setShowGroupNamingModal={setShowGroupNamingModal}
            />
            {activeTab === "move" && (
              <MultipleCheckBoxContainer>
                <MultipleCheckBoxItem>
                  <input
                    type="radio"
                    id="align-radio"
                    name="alignment-mode"
                    checked={checkForAlign}
                    onChange={() => {
                      setCheckForAlign(true)
                      setCheckForSnap(false)
                    }}
                  />
                  <label htmlFor="align-radio">Align</label>
                </MultipleCheckBoxItem>
                <MultipleCheckBoxItem>
                  <input
                    type="radio"
                    id="snap-radio"
                    name="alignment-mode"
                    checked={checkForSnap}
                    onChange={() => {
                      setCheckForSnap(true)
                      setCheckForAlign(false)
                    }}
                  />
                  <label htmlFor="snap-radio">Snap When Near</label>
                </MultipleCheckBoxItem>
                <MultipleCheckBoxItem>
                  <input
                    type="radio"
                    id="free-radio"
                    name="alignment-mode"
                    checked={!checkForSnap && !checkForAlign}
                    onChange={() => {
                      setCheckForSnap(false)
                      setCheckForAlign(false)
                    }}
                  />
                  <label htmlFor="free-radio">Free</label>
                </MultipleCheckBoxItem>
              </MultipleCheckBoxContainer>
            )}
            {activeTab === "rotate"
              && <MultiSelectionRotation onRotationSliderChange={onRotationSliderChange} />}
            {activeTab === "resize" && (
              <SimpleCheckBoxContainer $isMobile={isMobilePhone}>
                <label style={{ display: "flex", alignItems: "center", cursor: "pointer", fontSize: "13px", fontWeight: "500", color: "grey", }}>
                  <input
                    type="checkbox"
                    checked={scalerMoveConnectedItems}
                    onChange={handleClickOnMoveConnectedItems}
                    style={{ paddingTop: "8px", paddingBottom: "8px", marginRight: "10px", }}
                  />
                  Reposition Parts Connected to Resized Parts
                </label>

              </SimpleCheckBoxContainer>
            )}
            {activeTab === "nx"
              && <SliderContainer $isMobile={isMobilePhone}>
                <HeaderContainerCompact $isMobile={isMobilePhone}>
                  Space Between Each Copy
                </HeaderContainerCompact>
                <LeftRightContainerCompact>

                  <NumberSlider
                    min={0}
                    updateUnit={handleUnitChange}
                    max={100}
                    step={0.1}
                    handleMouseUp={(value) => setNXmodeOffset(value)}
                    onChange={(value) => setNXmodeOffset(value)}
                    getValue={() => NXmodeOffset}
                    inputNumber={true}
                    unit={NXmodeUnit}
                  />
                </LeftRightContainerCompact>

              </SliderContainer>
            }
          </ActionsContainer>
        </AnimatePresence>,
        document.getElementById("actions-container")!
      )}
    </Html>
  )
}

export default MultiSelectUI