import React from "react"
import isMobile from "ismobilejs"
import styled from "styled-components"
import Header from "../header/Header"
import WorkArea from "./WorkArea"
import { MotionConfig } from "framer-motion"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { addPartModal } from "../../../../state/atoms"
import StartButton from "./StartButton"
import { partsIdsSelector } from "../../../../state/scene/atoms"
import useViewOnly from "../../../../providers/viewerProvider/useViewOnly"
import {
    BottomSheetPortal, TooltipPortal
} from "../../../../../common/components/BottomSheet/BottomSheet"
import { SceneRef } from "../../../../state/types"

interface Props {
    scene: JSX.Element;
    sceneRefs: SceneRef;
}

const transition = {
    duration: 0.5,
    ease: [0.54, 0.01, 0.61, 1,],
}

const MainLayout = (props: Props) => {
    const setPartModal = useSetRecoilState(addPartModal)
    const partIdsList = useRecoilValue(partsIdsSelector)
    const isViewOnly = useViewOnly()

    const handleTapToStart = () => {
        setPartModal({ step1: { source: undefined, }, })
    }

    return (
        <Container>
            <TooltipPortal />
            <BottomSheetPortal />
            {!isMobile(window.navigator).phone && !isViewOnly && <Header />}
            <MotionConfig transition={transition}>
                <WorkArea
                    partCount={partIdsList.length}
                    scene={props.scene}
                    sceneRefs={props.sceneRefs}
                />
            </MotionConfig>
            {partIdsList?.length === 0 && <StartContainer>
                <StartButton onClick={handleTapToStart} />
            </StartContainer>}
        </Container>
    )
}

const Container = styled.div`
    height: 100vh;
    height: 100svh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100vw;
`



const StartContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export default MainLayout