/* eslint-disable complexity */
/* eslint-disable max-len */
import React from "react"
import { boundingBoxAtom, unitSelector, combinedBoxAtomUserSelection } from "../../../../state/scene/atoms"
import {
    Vector3,
    Matrix4
} from "three"

import { Text, Line, Billboard, Html } from "@react-three/drei"
import { useRecoilValue } from "recoil"

// Type for dimension configuration
type DimensionConfig = {
    value: number,
    direction: Vector3,
    perpDirection: Vector3,
    size: number,
    color: string,
}

// eslint-disable-next-line max-lines-per-function
const DimensionLabels: React.FC = () => {
    const boundingBox = useRecoilValue(boundingBoxAtom)
    const combinedBox = useRecoilValue(combinedBoxAtomUserSelection)
    const unit = useRecoilValue(unitSelector)

    if (!combinedBox || !boundingBox.show) {
        return null
    }

    const mSizeInfo = combinedBox?.userData.getDimensions("m")
    const cmSizeInfo = combinedBox?.userData.getDimensions("cm")
    const inSizeInfo = combinedBox?.userData.getDimensions("in")

    const width = mSizeInfo?.width || combinedBox?.userData.worldDimensions.width
    const height = mSizeInfo?.height || combinedBox?.userData.worldDimensions.height
    const depth = mSizeInfo?.depth || combinedBox?.userData.worldDimensions.depth

    // Get center position
    const center = new Vector3()
    if (combinedBox?.matrixWorld) {
        center.copy(combinedBox.userData.center || new Vector3())
        center.applyMatrix4(combinedBox.matrixWorld)
    } else if (combinedBox?.position) {
        center.copy(combinedBox.position)
    }

    // Get directional vectors from userData.worldDimensions
    const heightDirection = combinedBox.userData.worldDimensions.heightDirection || new Vector3(0, 1, 0)
    const widthDirection = combinedBox.userData.worldDimensions.widthDirection || new Vector3(1, 0, 0)
    const depthDirection = combinedBox.userData.worldDimensions.depthDirection || new Vector3(0, 0, 1)

    // Get perpendicular directions
    const heightPerpendicularDirection = combinedBox.userData.worldDimensions.heightPerpendicularDirection || new Vector3(1, 0, 0)
    const widthPerpendicularDirection = combinedBox.userData.worldDimensions.widthPerpendicularDirection || new Vector3(0, 1, 0)
    const depthPerpendicularDirection = combinedBox.userData.worldDimensions.depthPerpendicularDirection || new Vector3(1, 0, 0)

    const heightToUse = unit === "cm" ? cmSizeInfo?.height : inSizeInfo?.height
    const widthToUse = unit === "cm" ? cmSizeInfo?.width : inSizeInfo?.width
    const depthToUse = unit === "cm" ? cmSizeInfo?.depth : inSizeInfo?.depth

    const fontSize = Math.min(width, height, depth) * 0.15
    const offsetValue = Math.min(width, height, depth) * 0.35
    const unitLabel = unit === "in" ? '"' : "cm"

    // Arrow size for dimension lines
    const arrowSize = Math.min(width, height, depth) * 0.1
    const arrowWidth = arrowSize * 0.7

    // Create dimension configurations
    const dimensions: DimensionConfig[] = [
        // Height dimension
        heightToUse && heightToUse > 0 ? {
            value: heightToUse,
            direction: heightDirection,
            perpDirection: heightPerpendicularDirection,
            size: height,
            color: "red",
        } : null,

        // Width dimension
        widthToUse && widthToUse > 0 ? {
            value: widthToUse,
            direction: widthDirection,
            perpDirection: widthPerpendicularDirection,
            size: width,
            color: "green",
        } : null,

        // Depth dimension
        depthToUse && depthToUse > 0 ? {
            value: depthToUse,
            direction: depthDirection,
            perpDirection: depthPerpendicularDirection,
            size: depth,
            color: "blue",
        } : null,
    ].filter(Boolean) as DimensionConfig[]

    // Add responsive sizing calculations
    const getResponsiveFontSize = () => {
        const baseSize = Math.min(width, height, depth) * 0.15
        return `clamp(12px, ${baseSize * 1.5}px, 24px)`
    }

    const getResponsivePadding = () => {
        const baseSize = Math.min(width, height, depth) * 0.15
        return `clamp(2px, ${baseSize * 0.3}px, 8px) clamp(4px, ${baseSize * 0.5}px, 16px)`
    }

    // Function to create a dimension line with arrows and label
    const renderDimension = (config: DimensionConfig) => {
        const { direction, perpDirection, size, color, value, } = config
        const start = new Vector3().copy(center)
            .add(new Vector3().copy(direction)
                .multiplyScalar(-size / 2))
        const end = new Vector3().copy(center)
            .add(new Vector3().copy(direction)
                .multiplyScalar(size / 2))

        return (
            <React.Fragment key={color}>
                {/* Main dimension line */}
                <Line
                    points={[start.toArray(), end.toArray(),]}
                    color={color}
                    depthTest={false}
                    depthWrite={false}
                    lineWidth={2}
                    userData={{ ignoreRaycast: true, }}
                    raycast={() => { return false }}
                />

                {/* Arrow at start */}
                <Line
                    points={[
                        start.toArray(),
                        new Vector3().copy(start)
                            .add(new Vector3().copy(direction)
                                .multiplyScalar(arrowSize))
                            .add(new Vector3().copy(perpDirection)
                                .multiplyScalar(arrowWidth))
                            .toArray(),
                    ]}
                    color={color}
                    depthTest={false}
                    depthWrite={false}
                    lineWidth={2}
                    userData={{ ignoreRaycast: true, }}
                    raycast={() => { return false }}
                />
                <Line
                    points={[
                        start.toArray(),
                        new Vector3().copy(start)
                            .add(new Vector3().copy(direction)
                                .multiplyScalar(arrowSize))
                            .add(new Vector3().copy(perpDirection)
                                .multiplyScalar(-arrowWidth))
                            .toArray(),
                    ]}
                    color={color}
                    depthTest={false}
                    depthWrite={false}
                    lineWidth={2}
                    userData={{ ignoreRaycast: true, }}
                    raycast={() => { return false }}
                />

                {/* Arrow at end */}
                <Line
                    points={[
                        end.toArray(),
                        new Vector3().copy(end)
                            .add(new Vector3().copy(direction)
                                .multiplyScalar(-arrowSize))
                            .add(new Vector3().copy(perpDirection)
                                .multiplyScalar(arrowWidth))
                            .toArray(),
                    ]}
                    color={color}
                    depthTest={false}
                    depthWrite={false}
                    lineWidth={2}
                    userData={{ ignoreRaycast: true, }}
                    raycast={() => { return false }}
                />
                <Line
                    points={[
                        end.toArray(),
                        new Vector3().copy(end)
                            .add(new Vector3().copy(direction)
                                .multiplyScalar(-arrowSize))
                            .add(new Vector3().copy(perpDirection)
                                .multiplyScalar(-arrowWidth))
                            .toArray(),
                    ]}
                    color={color}
                    depthTest={false}
                    depthWrite={false}
                    lineWidth={2}
                    userData={{ ignoreRaycast: true, }}
                    raycast={() => { return false }}
                />

                {/* Dimension label */}
                <Html
                    position={new Vector3().copy(end)
                        .add(new Vector3().copy(direction)
                            .multiplyScalar(offsetValue))
                        .toArray()}
                    center
                    style={{
                        background: "white",
                        color: color,
                        padding: getResponsivePadding(),
                        borderRadius: "3px",
                        fontSize: getResponsiveFontSize(),
                        fontWeight: "bold",
                        userSelect: "none",
                        pointerEvents: "none",
                        border: `1px solid ${color}`,
                        whiteSpace: "nowrap",
                        transform: "scale(var(--camera-scale, 1))",
                    }}
                    userData={{ ignoreRaycast: true, }}
                    raycast={() => false}
                    occlude={false}
                    zIndexRange={[1, 10,]}
                >
                    {`${value.toFixed(2)}${unitLabel}`}
                </Html>
            </React.Fragment>
        )
    }

    return (
        <>
            {dimensions.map(renderDimension)}
        </>
    )
}

export default DimensionLabels