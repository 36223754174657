/* eslint-disable no-continue */
/* eslint-disable no-negated-condition */
/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable max-statements */
import { BoxGeometry, Box3, Intersection, MeshBasicMaterial, Object3D, Quaternion, Raycaster, Scene, Vector3, Mesh, LineSegments, LineBasicMaterial, BufferGeometry, Float32BufferAttribute, BoxHelper, ArrowHelper, Box3Helper, Color, Triangle, Line3, Line, PointsMaterial, Group, SphereGeometry, Points, BufferAttribute } from "three"
import { isParallel, isSameDirection, metersToInch } from "../components/main/DesignScreen/utils/utilsThree"
import { MarkerType, PartTypeEnum } from "./Types"

const APROXIMATELLY_COLLINEAR_MARGIN = 0.5 / metersToInch
const INTERSECTION_TOLERANCE = 0.001 // Adjust this value as needed

const copyWorldPosition = (mesh: Object3D | null | undefined) => {
    if (!mesh || typeof mesh.getWorldPosition !== "function") {
        return new Vector3()
    }
    const position = new Vector3()
    mesh.getWorldPosition(position)
    return position
}


export const verifyPartsInScene = (scene: Scene, partIds: string[]): boolean => {
    const foundParts = new Set()
    scene.traverse((object: any) => {
        if (object.userData?.partId && partIds.includes(object.userData.partId)) {
            foundParts.add(object.userData.partId)
        }
    })
    return foundParts.size === partIds.length
}

export const tryVerifyPartsInScene = (
    scene: Scene,
    partIds: string[],
    options: {
        maxAttempts?: number,
        intervalMs?: number,
        onFound?: (foundPartIds: string[]) => void,
        onMaxAttemptsReached?: () => void,
        onAttempt?: (attemptCount: number, found: boolean, foundPartIds: string[]) => void,
    } = {}
): () => void => {
    const {
        maxAttempts = 10,
        intervalMs = 250,
        onFound,
        onMaxAttemptsReached,
        onAttempt,
    } = options

    let attemptCount = 0
    let timeoutId: number | null = null

    // Set to track found part IDs
    const foundPartIds = new Set<string>()

    const checkForParts = () => {
        attemptCount++

        // Clear foundPartIds set for fresh check
        foundPartIds.clear()

        // Find all matching parts in the scene
        scene.traverse((object: any) => {
            if (object.userData?.partId && partIds.includes(object.userData.partId)) {
                foundPartIds.add(object.userData.partId)
            }
        })

        const allFound = foundPartIds.size === partIds.length

        // Call onAttempt callback if provided
        if (onAttempt) {
            onAttempt(attemptCount, allFound, Array.from(foundPartIds))
        }

        if (allFound) {
            // All parts found - success case
            if (onFound) {
                console.log("found all of them!")
                onFound(Array.from(foundPartIds))
            }
            return
        }

        if (attemptCount >= maxAttempts) {
            // Max attempts reached - failure case
            if (onMaxAttemptsReached) {
                onMaxAttemptsReached()
            }
            return
        }

        // Schedule next attempt
        timeoutId = window.setTimeout(checkForParts, intervalMs)
    }

    // Start the first check
    timeoutId = window.setTimeout(checkForParts, 0)

    // Return cleanup function
    return () => {
        if (timeoutId !== null) {
            window.clearTimeout(timeoutId)
            timeoutId = null
        }
    }
}

export const removeRedundantTriangles = (geometry: BufferGeometry, debug = true) => {
    if (!geometry.index) {
        console.warn("Geometry has no index buffer")
        return geometry
    }

    const newGeometry = geometry.clone()
    const positions = newGeometry.attributes.position
    const indices = newGeometry.index?.array

    // Create a set to track unique triangles
    const uniqueTriangles = new Set()
    const newIndices = []
    let redundantCount = 0

    if (!indices) {
        console.warn("Geometry has no index buffer")
        return geometry
    }

    // Process each triangle
    for (let i = 0; i < indices.length; i += 3) {
        const v1 = indices[i]
        const v2 = indices[i + 1]
        const v3 = indices[i + 2]

        // Skip degenerate triangles (where vertices are the same)
        if (v1 === v2 || v2 === v3 || v3 === v1) {
            redundantCount++
            continue
        }

        // Get the actual vertex positions
        const p1 = new Vector3(
            positions.getX(v1),
            positions.getY(v1),
            positions.getZ(v1)
        )
        const p2 = new Vector3(
            positions.getX(v2),
            positions.getY(v2),
            positions.getZ(v2)
        )
        const p3 = new Vector3(
            positions.getX(v3),
            positions.getY(v3),
            positions.getZ(v3)
        )

        // Calculate triangle area
        const triangleArea = getTriangleArea(p1, p2, p3)

        // Skip triangles with very small area - using a smaller threshold
        if (triangleArea < 0.0000001) { // reduced threshold
            redundantCount++
            continue
        }

        // Calculate a normal vector for the triangle
        const edge1 = new Vector3().subVectors(p2, p1)
        const edge2 = new Vector3().subVectors(p3, p1)
        const normal = new Vector3().crossVectors(edge1, edge2)
            .normalize()

        // Instead of sorting vertices, maintain winding order and use the normal
        // This helps distinguish between triangles on opposite faces
        const key = `${p1.x.toFixed(6)},${p1.y.toFixed(6)},${p1.z.toFixed(6)}_`
            + `${p2.x.toFixed(6)},${p2.y.toFixed(6)},${p2.z.toFixed(6)}_`
            + `${p3.x.toFixed(6)},${p3.y.toFixed(6)},${p3.z.toFixed(6)}_`
            + `${normal.x.toFixed(4)},${normal.y.toFixed(4)},${normal.z.toFixed(4)}`

        // Check if we've seen this triangle before
        if (!uniqueTriangles.has(key)) {
            uniqueTriangles.add(key)
            newIndices.push(v1, v2, v3)
        } else {
            redundantCount++
        }
    }

    // Set the new indices
    newGeometry.setIndex(newIndices)

    if (debug) {
        console.log(`removeRedundantTriangles Removed ${redundantCount} redundant triangles`)
        console.log(`removeRedundantTriangles Original: ${indices.length / 3} triangles, New: ${newIndices.length / 3} triangles`)
    }

    return newGeometry
}

// Helper function to calculate triangle area
const getTriangleArea = (p1: Vector3, p2: Vector3, p3: Vector3) => {
    // Calculate vectors from p1 to p2 and p1 to p3
    const v1 = new Vector3().subVectors(p2, p1)
    const v2 = new Vector3().subVectors(p3, p1)

    // Calculate the cross product
    const cross = new Vector3().crossVectors(v1, v2)

    // Half the magnitude of the cross product is the area
    return cross.length() / 2
}

export const removeTooCloseVertices = (geometry: BufferGeometry, threshold = 0.0001, debug = true) => {
    // Clone the geometry to avoid modifying the original
    const newGeometry = geometry.clone()
    const positions = newGeometry.attributes.position
    const count = positions.count

    if (debug) {
        console.log(`Starting removeTooCloseVertices with ${count} vertices`)
    }

    // Create a map of unique positions and a mapping for vertices
    const uniquePositions = []
    const vertexMapping = new Map()

    // First pass - identify unique positions
    for (let i = 0; i < count; i++) {
        const x = positions.getX(i)
        const y = positions.getY(i)
        const z = positions.getZ(i)
        const pos = new Vector3(x, y, z)

        // Check if this position is close to any existing unique position
        let foundMatch = false
        for (let j = 0; j < uniquePositions.length; j++) {
            const existingPos = uniquePositions[j]
            const dx = Math.abs(existingPos.x - x)
            const dy = Math.abs(existingPos.y - y)
            const dz = Math.abs(existingPos.z - z)

            if (dx < threshold && dy < threshold && dz < threshold) {
                // They're effectively the same position within our threshold
                foundMatch = true
                vertexMapping.set(i, j)

                if (debug && (dx > 0 || dy > 0 || dz > 0)) {
                    console.log(`Near match: Vertex ${i} (${x.toFixed(6)},${y.toFixed(6)},${z.toFixed(6)}) mapped to unique vertex ${j} (${existingPos.x.toFixed(6)},${existingPos.y.toFixed(6)},${existingPos.z.toFixed(6)})`)
                    console.log(`  Difference: (${dx.toExponential(5)}, ${dy.toExponential(5)}, ${dz.toExponential(5)})`)
                }
                break
            }
        }

        if (!foundMatch) {
            vertexMapping.set(i, uniquePositions.length)
            uniquePositions.push(pos)
        }
    }

    // If all vertices are already unique, just return the original
    if (uniquePositions.length === count) {
        if (debug) {
            console.log(`All vertices are already unique (${count} vertices)`)
        }
        return newGeometry
    }

    if (debug) {
        console.log(`Found ${count - uniquePositions.length} vertices that can be merged`)
        console.log(`Original: ${count} vertices, Unique: ${uniquePositions.length} vertices`)
    }

    // Create new geometry with only unique positions
    const newPositions = new Float32Array(uniquePositions.length * 3)
    for (let i = 0; i < uniquePositions.length; i++) {
        const pos = uniquePositions[i]
        newPositions[i * 3] = pos.x
        newPositions[i * 3 + 1] = pos.y
        newPositions[i * 3 + 2] = pos.z
    }

    // Create a new geometry with the unique positions
    const mergedGeometry = new BufferGeometry()
    mergedGeometry.setAttribute("position", new BufferAttribute(newPositions, 3))

    // Handle indices if they exist
    if (newGeometry.index) {
        const indices = newGeometry.index.array
        const newIndices = []

        for (let i = 0; i < indices.length; i++) {
            const oldIndex = indices[i]
            const newIndex = vertexMapping.get(oldIndex)
            newIndices.push(newIndex)
        }

        mergedGeometry.setIndex(newIndices)
    }

    // Copy over other attributes (like normals, uvs) - this is important!
    // We need to use our mapping to reindex these attributes
    const attributes = newGeometry.attributes
    for (const name in attributes) {
        if (name === "position") { continue } // Already handled above

        const attribute = attributes[name]
        const itemSize = attribute.itemSize
        const newArray = new Float32Array(uniquePositions.length * itemSize)

        // Initialize with zeros
        for (let i = 0; i < newArray.length; i++) {
            newArray[i] = 0
        }

        // Use a counter to track how many vertices contribute to each merged position
        const contributionCount = new Array(uniquePositions.length).fill(0)

        // Sum up all attribute values for each unique position
        for (let i = 0; i < count; i++) {
            const newIndex = vertexMapping.get(i)
            contributionCount[newIndex]++

            for (let j = 0; j < itemSize; j++) {
                newArray[newIndex * itemSize + j] += attribute.array[i * itemSize + j]
            }
        }

        // Average the values
        for (let i = 0; i < uniquePositions.length; i++) {
            if (contributionCount[i] > 1) {
                for (let j = 0; j < itemSize; j++) {
                    newArray[i * itemSize + j] /= contributionCount[i]
                }
            }
        }

        mergedGeometry.setAttribute(name, new BufferAttribute(newArray, itemSize))
    }

    if (debug) {
        console.log(`Created new geometry with ${uniquePositions.length} vertices`)
    }

    return mergedGeometry
}

export const analyzeVertexPositions = (geometry: BufferGeometry, threshold = 0.0001) => {
    // First, get the raw positions
    const positions = geometry.attributes.position
    const count = positions.count

    console.log(`Total position entries: ${count}`)

    // Track unique positions within the threshold
    const uniquePositions: { x: number, y: number, z: number, }[] = []
    const uniqueIndices = new Map()

    for (let i = 0; i < count; i++) {
        const x = positions.getX(i)
        const y = positions.getY(i)
        const z = positions.getZ(i)

        // Check if this position is close to any existing unique position
        let foundMatch = false
        for (let j = 0; j < uniquePositions.length; j++) {
            const pos = uniquePositions[j]
            const dx = Math.abs(pos.x - x)
            const dy = Math.abs(pos.y - y)
            const dz = Math.abs(pos.z - z)

            if (dx < threshold && dy < threshold && dz < threshold) {
                // They're effectively the same position within our threshold
                foundMatch = true

                // Keep track of which vertices map to this unique position
                const indices = uniqueIndices.get(j) || []
                indices.push(i)
                uniqueIndices.set(j, indices)

                // Log very close but not exact matches
                if (dx > 0 || dy > 0 || dz > 0) {
                    console.log(`Near-duplicate found: Vertex ${i} is very close to unique vertex ${j}`)
                    console.log(`  Difference: (${dx.toExponential(5)}, ${dy.toExponential(5)}, ${dz.toExponential(5)})`)
                }
                break
            }
        }

        if (!foundMatch) {
            uniquePositions.push({ x, y, z, })
            uniqueIndices.set(uniquePositions.length - 1, [i,])
        }
    }

    console.log(`Truly unique positions (within ${threshold} units): ${uniquePositions.length}`)

    // Find clusters with multiple vertices
    const clusters = Array.from(uniqueIndices.entries())
        .filter(([_, indices,]) => indices.length > 1)
        .map(([uniqueIdx, indices,]) => ({
            position: uniquePositions[uniqueIdx],
            count: indices.length,
            vertices: indices,
        }))

    if (clusters.length > 0) {
        console.log("Found vertex clusters (very close positions):")
        clusters.forEach((cluster, i) => {
            console.log(`Cluster ${i + 1}: ${cluster.count} vertices at approximately (${cluster.position.x.toFixed(5)}, ${cluster.position.y.toFixed(5)}, ${cluster.position.z.toFixed(5)})`)
        })
    }

    return {
        rawCount: count,
        uniqueCount: uniquePositions.length,
        uniquePositions,
        clusters,
    }
}

export const visualizeVertexClusters = (scene: Scene, geometry: BufferGeometry, threshold = 0.0001) => {
    const analysis = analyzeVertexPositions(geometry, threshold)

    // Create a group to hold our visualization objects
    const group = new Group()
    group.name = "VertexClusterVisualization"

    // Add points for all unique positions
    const pointsMaterial = new PointsMaterial({
        color: 0x00ff00,
        size: 0.02,
        sizeAttenuation: true,
    })

    const pointsGeometry = new BufferGeometry()
    const positions = new Float32Array(analysis.uniqueCount * 3)

    analysis.uniquePositions.forEach((pos, i) => {
        positions[i * 3] = pos.x
        positions[i * 3 + 1] = pos.y
        positions[i * 3 + 2] = pos.z
    })

    pointsGeometry.setAttribute("position", new BufferAttribute(positions, 3))
    const points = new Points(pointsGeometry, pointsMaterial)
    group.add(points)

    // Add spheres for clusters
    analysis.clusters.forEach(cluster => {
        const sphereGeometry = new SphereGeometry(threshold * 2, 8, 8)
        const sphereMaterial = new MeshBasicMaterial({
            color: 0xff0000,
            transparent: true,
            opacity: 0.3,
            wireframe: true,
        })

        const sphere = new Mesh(sphereGeometry, sphereMaterial)
        sphere.position.set(cluster.position.x, cluster.position.y, cluster.position.z)
        group.add(sphere)

        // Add a text label showing how many vertices are in this cluster
        const textPos = new Vector3(cluster.position.x, cluster.position.y, cluster.position.z)
        textPos.y += threshold * 3

        // You'd need a text implementation here - depends on your project
        // For example, if using troika-three-text or similar
    })

    scene.add(group)
    setTimeout(() => {
        group.removeFromParent()
    }, 5000)

    console.log(`Added visualization with ${analysis.uniqueCount} points and ${analysis.clusters.length} clusters`)

    return group
}

export const logGeometryInfo = (geometry: BufferGeometry, sayBefore = "") => {
    console.log(sayBefore, "Geometry info:")
    console.log(sayBefore, "  Vertex count:", geometry.attributes.position.count)
    console.log(sayBefore, "  Index count:", geometry.index ? geometry.index.count : "No indices")

    // Log ALL available attributes without filtering
    const allAttributes = Object.keys(geometry.attributes)
    console.log(sayBefore, "  Available attributes:", allAttributes)

    // Standard THREE.js attributes for reference
    const standardAttrs = ["position", "normal", "uv", "uv2", "tangent", "color",]
    console.log(sayBefore, "  Standard attributes:", standardAttrs.filter(name => allAttributes.includes(name)))

    // Everything else is a custom attribute - log them all without filtering
    const customAttributes = allAttributes.filter(name => !standardAttrs.includes(name))

    if (customAttributes.length > 0) {
        console.log(sayBefore, "\n  ALL CUSTOM ATTRIBUTES:", customAttributes)

        // Log details for each custom attribute
        customAttributes.forEach(attrName => {
            const attribute = geometry.attributes[attrName]
            //console.log(sayBefore, `    - ${attrName}: ${attribute.itemSize} components per vertex, ${attribute.count} total`)

            // Sample first few values
            if (attribute.count > 0) {
                // Get more sample values to better understand the data
                const numSamples = Math.min(10, attribute.count)
                const sampleValues = []

                for (let i = 0; i < numSamples; i++) {
                    if (attribute.itemSize === 1) {
                        // For single values like weights
                        sampleValues.push(attribute.array[i].toFixed(4))
                    } else {
                        // For vector values
                        const itemValues = []
                        for (let j = 0; j < attribute.itemSize; j++) {
                            itemValues.push(attribute.array[i * attribute.itemSize + j].toFixed(4))
                        }
                        sampleValues.push(`[${itemValues.join(", ")}]`)
                    }
                }

                //console.log(sayBefore, `      Sample values (${numSamples} of ${attribute.count}): ${sampleValues.join(" | ")}`)
            }
        })
    } else {
        console.log(sayBefore, "\n  NO CUSTOM ATTRIBUTES FOUND - Only standard Three.js attributes exist")
    }

    // Also check for userData which might contain additional information
    if (geometry.userData && Object.keys(geometry.userData).length > 0) {
        console.log(sayBefore, "\n  Geometry userData:", geometry.userData)
    }

    // Check for morphAttributes (shape keys) which might contain vertex group data
    if (geometry.morphAttributes && Object.keys(geometry.morphAttributes).length > 0) {
        console.log(sayBefore, "\n  Morph attributes:", Object.keys(geometry.morphAttributes))
    }
}

const copyWorldQuaternion = (mesh: Object3D) => {
    const quaternion = new Quaternion()
    if (!mesh || typeof mesh.getWorldQuaternion !== "function") {
        return new Quaternion()
    }
    mesh.getWorldQuaternion(quaternion)
    return quaternion
}

export const returnMeshesThatFitWithinExtrudedPlane = (
    sourcePlane: Mesh,
    extrusionDistance: number,
    potentialIntersectingMeshes: Mesh[],
    debug?: boolean,
    scene?: Scene,
): Mesh[] => {
    const normal = MeshUtils.copyWorldDirection(sourcePlane).normalize()

    const meshesWithCompatibleDirection = potentialIntersectingMeshes.filter(mesh => {
        const meshNormal = MeshUtils.copyWorldDirection(mesh).normalize()
        return isSameDirection(normal, meshNormal.negate()) || mesh.userData.specialRotationMarker
    })

    debug && console.log(meshesWithCompatibleDirection, "meshesWithCompatibleDirection", sourcePlane.name)

    // Get world corners of source plane
    const geometry = sourcePlane.geometry
    const posAttr = geometry.attributes.position
    const worldScale = new Vector3()
    sourcePlane.getWorldScale(worldScale)

    const worldCorners: Vector3[] = []
    for (let i = 0; i < posAttr.count; i++) {
        const vertex = new Vector3()
        vertex.fromBufferAttribute(posAttr, i)
        vertex.multiply(worldScale)
        sourcePlane.localToWorld(vertex)
        worldCorners.push(vertex)
    }

    // Create extruded corners
    const extrudedCorners = worldCorners.map(corner =>
        corner.clone().add(normal.clone().multiplyScalar(extrusionDistance))
    )

    if (debug && scene) {
        // Visualize the extruded shape
        const extrudedMaterial = new LineBasicMaterial({ color: 0x00ff00, })
        const sourceMaterial = new LineBasicMaterial({ color: 0xff0000, })

        // Draw source plane outline
        const sourceGeometry = new BufferGeometry()
        const sourcePoints = [...worldCorners, worldCorners[0],]
        sourceGeometry.setFromPoints(sourcePoints)
        scene.add(new Line(sourceGeometry, sourceMaterial))

        // Draw extruded plane outline
        const extrudedGeometry = new BufferGeometry()
        const extrudedPoints = [...extrudedCorners, extrudedCorners[0],]
        extrudedGeometry.setFromPoints(extrudedPoints)
        scene.add(new Line(extrudedGeometry, extrudedMaterial))

        // Draw connecting lines between source and extruded plane
        worldCorners.forEach((corner, i) => {
            const connectionGeometry = new BufferGeometry().setFromPoints([corner, extrudedCorners[i],])
            scene.add(new Line(connectionGeometry, new LineBasicMaterial({ color: 0x0000ff, })))
        })

        // Add box helper for each potential mesh
        meshesWithCompatibleDirection.forEach(mesh => {
            if (mesh.children.length > 0) {
                // Create temporary clone only if mesh has children
                const clonedMesh = new Mesh(mesh.geometry, mesh.material)
                clonedMesh.position.copy(mesh.getWorldPosition(new Vector3()))
                clonedMesh.quaternion.copy(mesh.getWorldQuaternion(new Quaternion()))
                clonedMesh.scale.copy(mesh.getWorldScale(new Vector3()))

                const boxHelper = new BoxHelper(clonedMesh, 0xffff00)
                scene.add(boxHelper)

                // Clean up the temporary mesh
                clonedMesh.geometry.dispose()
                if (Array.isArray(clonedMesh.material)) {
                    clonedMesh.material.forEach(m => m.dispose())
                } else {
                    clonedMesh.material.dispose()
                }
            } else {
                // Use original mesh if no children
                const boxHelper = new BoxHelper(mesh, 0xffff00)
                scene.add(boxHelper)
            }
        })

        // Visualize normal direction
        const normalArrow = new ArrowHelper(
            normal,
            sourcePlane.position,
            extrusionDistance,
            0xff00ff
        )
        scene.add(normalArrow)
    }

    return meshesWithCompatibleDirection.filter(mesh => {
        let meshToUse = mesh
        let needsCleanup = false

        if (mesh.children.length > 0 && mesh.userData.partType === "connector_part_type") {
            debug && console.log(mesh, "special connector case -> mesh with children that we need to cleanup")
            // Create temporary clone only if mesh has children
            meshToUse = new Mesh(mesh.geometry, mesh.material)
            meshToUse.position.copy(mesh.getWorldPosition(new Vector3()))
            meshToUse.quaternion.copy(mesh.getWorldQuaternion(new Quaternion()))
            meshToUse.scale.copy(mesh.getWorldScale(new Vector3()))
            needsCleanup = true
        }

        const meshGeometry = meshToUse.geometry
        const meshPosAttr = meshGeometry.attributes.position
        const meshWorldCorners: Vector3[] = []
        for (let i = 0; i < meshPosAttr.count; i++) {
            const vertex = new Vector3()
            vertex.fromBufferAttribute(meshPosAttr, i)
            meshToUse.localToWorld(vertex)
            meshWorldCorners.push(vertex)
        }

        if (needsCleanup) {
            // Clean up the temporary mesh
            meshToUse.geometry.dispose()
            if (Array.isArray(meshToUse.material)) {
                meshToUse.material.forEach(m => m.dispose())
            } else {
                meshToUse.material.dispose()
            }
            // Don't set meshToUse to undefined as we still need it
        }

        // Get edges for both polygons
        const sourceEdges = getEdges2(worldCorners)
        const meshEdges = getEdges2(meshWorldCorners)

        // Get all potential separating axes
        const axes = [
            ...sourceEdges.map(edge => edge.normalize()),
            ...meshEdges.map(edge => edge.normalize()),
            normal,
        ]

        // Check for separation along each axis
        for (const axis of axes) {
            const sourceProj = projectPolygonOntoAxis(worldCorners, axis)
            const meshProj = projectPolygonOntoAxis(meshWorldCorners, axis)
            const extrudedProj = projectPolygonOntoAxis(extrudedCorners, axis)

            // Combine source and extruded projections
            const combinedSourceProj = {
                min: Math.min(sourceProj.min, extrudedProj.min),
                max: Math.max(sourceProj.max, extrudedProj.max),
            }

            // Check for separation with tolerance
            if (combinedSourceProj.min > meshProj.max + INTERSECTION_TOLERANCE
                || meshProj.min > combinedSourceProj.max + INTERSECTION_TOLERANCE) {
                if (debug) {
                    console.log("Separation found on axis:", axis)
                    console.log("Combined projection:", combinedSourceProj)
                    console.log("Mesh projection:", meshProj)
                    console.log("Gap size:", Math.min(
                        Math.abs(combinedSourceProj.min - meshProj.max),
                        Math.abs(meshProj.min - combinedSourceProj.max)
                    ))
                }
                return false // Found a separating axis
            }
        }

        return true // No separating axis found = intersection exists
    })
}

// Helper function to get edges from corners
const getEdges2 = (corners: Vector3[]): Vector3[] => {
    const edges: Vector3[] = []
    for (let i = 0; i < corners.length; i++) {
        const start = corners[i]
        const end = corners[(i + 1) % corners.length]
        edges.push(end.clone().sub(start)) // Edge vector
    }
    return edges
}

// Helper function to project polygon onto axis
const projectPolygonOntoAxis = (corners: Vector3[], axis: Vector3) => {
    let min = Infinity
    let max = -Infinity

    corners.forEach(corner => {
        const proj = corner.dot(axis)
        min = Math.min(min, proj)
        max = Math.max(max, proj)
    })

    return { min, max, }
}

const copyWorldDirection = (mesh: Object3D | null) => {
    if (!mesh || typeof mesh.getWorldDirection !== "function") {
        return new Vector3()
    }
    const direction = new Vector3()
    mesh.getWorldDirection(direction)
    return direction
}

const getMarkerMeshesWithoutMiddleSegments = (scene: Scene) => {
    const meshes: Object3D[] = []
    scene.traverse(object => {
        if (object.userData.type === MarkerType.COLLISION && !object.userData.middleSection) {
            meshes.push(object)
        }
    })
    return meshes
}

const checkDistanceToCenter = (intersection: Intersection, center: Vector3) => {
    const distanceToCenter = intersection.point.distanceTo(center)
    return distanceToCenter <= APROXIMATELLY_COLLINEAR_MARGIN
}

const getOriginPositionMoved = (origin: Vector3, direction: Vector3, distanceToMove: number) => {
    const newPos = new Vector3()
    const auxDir = new Vector3(direction.x, direction.y, direction.z)
    newPos.addVectors(
        origin,
        auxDir.normalize().multiplyScalar(distanceToMove / metersToInch)
    )
    return newPos
}


export function box3ToMesh(box: Box3, material?: MeshBasicMaterial): Mesh {
    // Create geometry (default unit cube 1x1x1)
    const geometry = new BoxGeometry(1, 1, 1)

    // Create default material if none provided
    const boxMaterial = material || new MeshBasicMaterial({
        color: 0xff0000,
        transparent: true,
        opacity: 0.2,
        wireframe: true,
    })

    // Create mesh
    const mesh = new Mesh(geometry, boxMaterial)

    // Get box center and size
    const center = new Vector3()
    const size = new Vector3()
    box.getCenter(center)
    box.getSize(size)

    // Apply transformations
    mesh.position.copy(center)
    mesh.scale.copy(size)

    // Update matrices
    mesh.updateMatrix()
    mesh.updateMatrixWorld(true)

    return mesh
}

const checkAproxCollinearToDirection = (
    aPos: Vector3,
    aDir: Vector3,
    meshB: Object3D,
    backward: boolean,
) => {
    const movedOrigin = getOriginPositionMoved(aPos, aDir, backward ? 0.2 : -0.2)
    const dir = backward ? aDir.multiplyScalar(-1) : aDir
    const ray = new Raycaster(movedOrigin, dir)
    const intersection = ray.intersectObject(meshB)
    return intersection[0]
}

const areApproximatelyCollinear = (
    meshA: Object3D,
    meshB: Object3D,
) => {
    const aDir = copyWorldDirection(meshA)
    const bDir = copyWorldDirection(meshB)
    if (isParallel(aDir, bDir)) {
        const aPos = copyWorldPosition(meshA)
        const bPos = copyWorldPosition(meshB)

        const intersectionForward = checkAproxCollinearToDirection(aPos, aDir, meshB, false)
        if (intersectionForward) {
            return checkDistanceToCenter(intersectionForward, bPos)
        } else {
            const intersectionBackward = checkAproxCollinearToDirection(aPos, aDir, meshB, true)
            if (intersectionBackward) {
                return checkDistanceToCenter(intersectionBackward, bPos)
            }
        }
    }
    return false
}

interface Point {
    x: number;
    y: number;
}

export interface Polygon {
    center: Point;
    vertices: Point[];
    minHeight?: number;
}

const createPolygon = (points: Point[], minHeight = 1, padding = 0.1): Polygon => {
    const center = calculateCenter(points)
    let vertices = organizePoints(points, minHeight)

    // Check if shape is too line-like and adjust if needed
    if (isLineLike(vertices, minHeight)) {
        vertices = convertToRectangle(vertices, minHeight)
    }

    // Add fixed padding to each vertex
    if (padding !== 0) {
        vertices = vertices.map(vertex => {
            const dx = vertex.x - center.x
            const dy = vertex.y - center.y
            // Get the direction from center to vertex
            const length = Math.sqrt(dx * dx + dy * dy)
            if (length === 0) { return vertex }

            // Add fixed padding in the direction of the vertex
            return {
                x: vertex.x + (dx / length) * padding,
                y: vertex.y + (dy / length) * padding,
            }
        })
    }

    return {
        minHeight,
        center,
        vertices,
    }
}

const calculateCenter = (points: Point[]): Point => {
    const sumX = points.reduce((sum, p) => sum + p.x, 0)
    const sumY = points.reduce((sum, p) => sum + p.y, 0)
    return {
        x: sumX / points.length,
        y: sumY / points.length,
    }
}

const pointToLineDistance = (point: Point, line: { x1: number, y1: number, x2: number, y2: number, }): number => {
    const numerator = Math.abs(
        (line.y2 - line.y1) * point.x
        - (line.x2 - line.x1) * point.y
        + line.x2 * line.y1
        - line.y2 * line.x1
    )
    const denominator = Math.sqrt(
        Math.pow(line.y2 - line.y1, 2)
        + Math.pow(line.x2 - line.x1, 2)
    )
    return numerator / denominator
}

const isLineLike = (vertices: Point[], minHeight: number): boolean => {
    let maxHeight = 0
    for (let i = 0; i < vertices.length; i++) {
        for (let j = i + 1; j < vertices.length; j++) {
            const line = {
                x1: vertices[i].x,
                y1: vertices[i].y,
                x2: vertices[j].x,
                y2: vertices[j].y,
            }

            for (let k = 0; k < vertices.length; k++) {
                if (k !== i && k !== j) {
                    const height = pointToLineDistance(vertices[k], line)
                    maxHeight = Math.max(maxHeight, height)
                }
            }
        }
    }
    return maxHeight < minHeight
}

const findFurthestPoints = (vertices: Point[]): [Point, Point] => {
    let maxDist = 0
    let points: [Point, Point] = [vertices[0], vertices[1],]

    for (let i = 0; i < vertices.length; i++) {
        for (let j = i + 1; j < vertices.length; j++) {
            const dist = Math.hypot(
                vertices[i].x - vertices[j].x,
                vertices[i].y - vertices[j].y
            )
            if (dist > maxDist) {
                maxDist = dist
                points = [vertices[i], vertices[j],]
            }
        }
    }
    return points
}

const convertToRectangle = (vertices: Point[], minHeight: number): Point[] => {
    const [p1, p2,] = findFurthestPoints(vertices)
    const angle = Math.atan2(p2.y - p1.y, p2.x - p1.x)

    const halfHeight = minHeight / 2
    const dx = halfHeight * Math.sin(angle)
    const dy = halfHeight * Math.cos(angle)

    return [
        { x: p1.x - dx, y: p1.y + dy, },
        { x: p1.x + dx, y: p1.y - dy, },
        { x: p2.x + dx, y: p2.y - dy, },
        { x: p2.x - dx, y: p2.y + dy, },
    ]
}

const isLeftTurn = (p1: Point, p2: Point, p3: Point): boolean => {
    return (
        (p2.x - p1.x) * (p3.y - p1.y)
        - (p2.y - p1.y) * (p3.x - p1.x)
    ) > 0
}

const organizePoints = (points: Point[], minHeight: number): Point[] => {
    if (points.length < 3) {
        const [p1, p2,] = points.length === 2 ? points : [points[0], points[0],]
        return convertToRectangle([p1, p2,], minHeight)
    }

    let bottomPoint = points[0]
    for (let i = 1; i < points.length; i++) {
        if (points[i].y < bottomPoint.y
            || (points[i].y === bottomPoint.y && points[i].x < bottomPoint.x)) {
            bottomPoint = points[i]
        }
    }

    const sortedPoints = points
        .filter(p => p !== bottomPoint)
        .map(p => ({
            point: p,
            angle: Math.atan2(p.y - bottomPoint.y, p.x - bottomPoint.x),
            distance: Math.sqrt(
                Math.pow(p.x - bottomPoint.x, 2)
                + Math.pow(p.y - bottomPoint.y, 2)
            ),
        }))
        .sort((a, b) => {
            if (a.angle === b.angle) {
                return a.distance - b.distance
            }
            return a.angle - b.angle
        })
        .map(p => p.point)

    sortedPoints.unshift(bottomPoint)

    const hull = [sortedPoints[0], sortedPoints[1],]
    for (let i = 2; i < sortedPoints.length; i++) {
        while (
            hull.length >= 2
            && !isLeftTurn(
                hull[hull.length - 2],
                hull[hull.length - 1],
                sortedPoints[i]
            )
        ) {
            hull.pop()
        }
        hull.push(sortedPoints[i])
    }

    return hull
}

// Helper function to get polygon edges
const getEdges = (polygon: Polygon) => {
    const edges = []
    for (let i = 0; i < polygon.vertices.length; i++) {
        const p1 = polygon.vertices[i]
        const p2 = polygon.vertices[(i + 1) % polygon.vertices.length]
        edges.push({
            x: p2.x - p1.x,
            y: p2.y - p1.y,
        })
    }
    return edges
}

type xy = { x: number, y: number, }
// Project polygon onto axis
const projectPolygon = (polygon: Polygon, axis: xy) => {
    let min = Number.POSITIVE_INFINITY
    let max = Number.NEGATIVE_INFINITY

    polygon.vertices.forEach(vertex => {
        const projection = (vertex.x * axis.x + vertex.y * axis.y)
        min = Math.min(min, projection)
        max = Math.max(max, projection)
    })

    return { min, max, }
}

const simplifyPolygonToRectangle = (points: { x: number, y: number, }[]) => {
    // Find extremes
    const bounds = points.reduce((acc, point) => ({
        minX: Math.min(acc.minX, point.x),
        maxX: Math.max(acc.maxX, point.x),
        minY: Math.min(acc.minY, point.y),
        maxY: Math.max(acc.maxY, point.y),
    }), { minX: Infinity, maxX: -Infinity, minY: Infinity, maxY: -Infinity, })

    // Create rectangle points (clockwise from top-left)
    return [
        { x: bounds.minX, y: bounds.minY, }, // top-left
        { x: bounds.maxX, y: bounds.minY, }, // top-right
        { x: bounds.maxX, y: bounds.maxY, }, // bottom-right
        { x: bounds.minX, y: bounds.maxY, },  // bottom-left
    ]
}

const polygonsOverlap = (polygonA: Polygon, polygonB: Polygon): false | number => {
    // Get bounding boxes
    const boundsA = getBounds(polygonA.vertices)
    const boundsB = getBounds(polygonB.vertices)

    // Quick bounds check
    if (boundsA.maxX < boundsB.minX || boundsA.minX > boundsB.maxX
        || boundsA.maxY < boundsB.minY || boundsA.minY > boundsB.maxY) {
        return false
    }

    // Handle cases where either polygon is very thin
    const isALineLike = isVeryThin(polygonA.vertices)
    const isBLineLike = isVeryThin(polygonB.vertices)

    if (isALineLike || isBLineLike) {
        // For line-like polygons, check if the line intersects the other polygon
        if (isALineLike) {
            return checkLinePolygonOverlapSensitiveToLineLike(polygonA.vertices, polygonB.vertices)
        } else {
            return checkLinePolygonOverlapSensitiveToLineLike(polygonB.vertices, polygonA.vertices)
        }
    }

    // Original SAT algorithm for non-degenerate cases
    const edgesA = getEdges(polygonA)
    const edgesB = getEdges(polygonB)

    // Get all axes we need to check (perpendicular to all edges)
    const axes = [...edgesA, ...edgesB,].map(edge => ({
        x: -edge.y,
        y: edge.x,
    }))

    // Track overlap percentages on each axis
    const overlaps: number[] = []

    // Check projection on each axis
    for (const axis of axes) {
        // Normalize axis
        const length = Math.sqrt(axis.x * axis.x + axis.y * axis.y)
        if (length !== 0) {
            const normalizedAxis = {
                x: axis.x / length,
                y: axis.y / length,
            }

            const projectionA = projectPolygon(polygonA, normalizedAxis)
            const projectionB = projectPolygon(polygonB, normalizedAxis)

            // Check for gap between projections
            if (projectionA.max < projectionB.min || projectionB.max < projectionA.min) {
                return false // Gap found, polygons don't overlap
            }

            // Calculate overlap on this axis
            const overlap = Math.min(projectionA.max, projectionB.max) - Math.max(projectionA.min, projectionB.min)
            const totalLength = Math.max(
                projectionA.max - projectionA.min,
                projectionB.max - projectionB.min
            )
            overlaps.push(overlap / totalLength)
        }
    }

    // If we got here, polygons overlap. Calculate average overlap percentage
    const overlapPercentage = overlaps.reduce((sum, val) => sum + val, 0) / overlaps.length
    return Math.min(1, overlapPercentage)
}

const getBounds = (vertices: Point[]) => {
    return vertices.reduce((acc, vertex) => ({
        minX: Math.min(acc.minX, vertex.x),
        maxX: Math.max(acc.maxX, vertex.x),
        minY: Math.min(acc.minY, vertex.y),
        maxY: Math.max(acc.maxY, vertex.y),
    }), { minX: Infinity, maxX: -Infinity, minY: Infinity, maxY: -Infinity, })
}

const isVeryThin = (vertices: Point[], threshold = 1): boolean => {
    if (vertices.length < 2) { return true }

    // Check if the polygon is thin in either dimension
    const bounds = getBounds(vertices)
    const width = bounds.maxX - bounds.minX
    const height = bounds.maxY - bounds.minY

    return width < threshold || height < threshold
}

const checkLinePolygonOverlapSensitiveToLineLike = (lineVertices: Point[], polyVertices: Point[]): number => {
    // Get the line segment endpoints
    const start = lineVertices[0]
    const end = lineVertices[lineVertices.length - 1]

    // Check if either endpoint is inside the polygon
    if (pointInPolygon(start, polyVertices) || pointInPolygon(end, polyVertices)) {
        return 1
    }

    // Check if the line intersects any of the polygon's edges
    for (let i = 0; i < polyVertices.length; i++) {
        const nextI = (i + 1) % polyVertices.length
        if (lineSegmentsIntersect(
            start, end,
            polyVertices[i], polyVertices[nextI]
        )) {
            return 1
        }
    }

    return 0
}

const lineSegmentsIntersect = (p1: Point, p2: Point, p3: Point, p4: Point): boolean => {
    const denominator = ((p4.y - p3.y) * (p2.x - p1.x)) - ((p4.x - p3.x) * (p2.y - p1.y))

    if (denominator === 0) {
        return false
    }

    const ua = (((p4.x - p3.x) * (p1.y - p3.y)) - ((p4.y - p3.y) * (p1.x - p3.x))) / denominator
    const ub = (((p2.x - p1.x) * (p1.y - p3.y)) - ((p2.y - p1.y) * (p1.x - p3.x))) / denominator

    return (ua >= 0 && ua <= 1) && (ub >= 0 && ub <= 1)
}

export const containsPoint = (polygon: Polygon, point: Point, scale = 1): boolean => {
    if (scale === 1) {
        return pointInPolygon(point, polygon.vertices)
    }

    const scaledVertices = polygon.vertices.map(vertex => ({
        x: polygon.center.x + (vertex.x - polygon.center.x) * scale,
        y: polygon.center.y + (vertex.y - polygon.center.y) * scale,
    }))

    return pointInPolygon(point, scaledVertices)
}

const pointInPolygon = (point: Point, vertices: Point[]): boolean => {
    const { x, y, } = point
    let inside = false

    for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
        const xi = vertices[i].x
        const yi = vertices[i].y
        const xj = vertices[j].x
        const yj = vertices[j].y

        const intersect = ((yi > y) !== (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi)

        if (intersect) { inside = !inside }
    }

    return inside
}

const debugDrawPolygon = (
    ctx: CanvasRenderingContext2D,
    polygon: Polygon,
    options = {
        fillStyle: "rgba(0, 255, 0, 0.2)",
        strokeStyle: "rgba(0, 255, 0, 0.8)",
        centerColor: "red",
        scale: 1,
        offsetX: 0,
        offsetY: 0,
    }
) => {
    const { vertices, center, } = polygon
    const { fillStyle, strokeStyle, centerColor, scale, offsetX, offsetY, } = options

    // Draw the polygon
    ctx.beginPath()
    vertices.forEach((point, index) => {
        const x = point.x * scale + offsetX
        const y = point.y * scale + offsetY

        if (index === 0) {
            ctx.moveTo(x, y)
        } else {
            ctx.lineTo(x, y)
        }
    })
    ctx.closePath()

    // Fill polygon
    ctx.fillStyle = fillStyle
    ctx.fill()

    // Stroke polygon
    ctx.strokeStyle = strokeStyle
    ctx.stroke()

    // Draw center point
    ctx.beginPath()
    ctx.arc(
        center.x * scale + offsetX,
        center.y * scale + offsetY,
        3,
        0,
        2 * Math.PI
    )
    ctx.fillStyle = centerColor
    ctx.fill()

    // Draw vertices as small circles
    ctx.fillStyle = strokeStyle
    vertices.forEach(point => {
        ctx.beginPath()
        ctx.arc(
            point.x * scale + offsetX,
            point.y * scale + offsetY,
            2,
            0,
            2 * Math.PI
        )
        ctx.fill()
    })
}

export const PolygonUtils = {
    createPolygon,
    containsPoint,
    pointInPolygon,
    debugDrawPolygon,
    polygonsOverlap,
    simplifyPolygonToRectangle,
}

export const MeshUtils = {
    copyWorldPosition,
    copyWorldQuaternion,
    copyWorldDirection,
    getMarkerMeshesWithoutMiddleSegments,
    areApproximatelyCollinear,
    checkAproxCollinearToDirection,
    getOriginPositionMoved,
}