/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import React, { useEffect } from "react"
import { Collapse, Dropdown, Menu } from "antd"
import { CustomTooltip as Tooltip } from "../../../../../../ui/CustomTooltip"
import {
  SettingOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  PushpinOutlined,
  PushpinFilled,
} from "@ant-design/icons"
import tinycolor from "tinycolor2"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { usePartsList } from "../usePartsList"
import { PartTypeAPI, ShopifyIdAPI } from "../../../../../../../../common/api/Types"
import { ObjDictionary } from "../../../../../../../../common/utils/utils"
import { SamePartsPanel } from "./SamePartsPanel"
import { GroupType } from "../../../../../../../state/scene/types"
import { styled } from "styled-components"
import { ReactComponent as PencilIcon } from "../../../../../../../../common/assets/icons/Pencil.svg"
import { groupVisibilityAtom, groupOnlyViewAtom, scenePartsSelector, partVisibilityAtom } from "../../../../../../../state/scene/atoms"
import { useComponentRegistry } from "../../../../../../../providers/multiselectProvider/useComponentMethods"
import { layoutState } from "../../../../../../../state/layoutState"
import produce from "immer"
import { multiSelectionSelector } from "../../../../../../../state/scene/selectors"
import { useGroupVisibility } from "../../../../../../../hooks/useGroupVisibility"
import isMobile from "ismobilejs"
import { SceneRef } from "../../../../../../../state/types"

interface GroupPanelProps {
  groupId: string;
  parts: any[];
  allParts: PartTypeAPI[];
  groupsAtomList: GroupType[];
  handleItemHover: (itemIds: string[]) => void;
  handleItemLeave: (itemIds: string[]) => void;
  handleRenameGroup: (groupId: string) => void;
  handleUngroup: (groupId: string) => void;
  handleDeleteGroup: (groupId: string) => void;
  sceneRefs: SceneRef;
  isActive?: boolean;
  onPanelChange?: (isActive: boolean) => void;
  shopifyIds: ObjDictionary<ShopifyIdAPI>;
}

const StyledPencilIcon = styled(PencilIcon) <{ $color: string, }>`
  width: 14px;
  height: 21px;
  cursor: pointer;
  color: ${props => props.$color};
`

export const GroupPanel: React.FC<GroupPanelProps> = ({
  groupId,
  parts,
  allParts,
  groupsAtomList,
  handleRenameGroup,
  handleUngroup,
  handleDeleteGroup,
  handleItemHover,
  handleItemLeave,
  sceneRefs,
  isActive,
  onPanelChange,
  shopifyIds,
}) => {
  const { getRows, } = usePartsList({ parts: allParts, shopifyIds, allowCm: true, })
  const setLayout = useSetRecoilState(layoutState)
  const isMobileResult = isMobile(window.navigator)
  const isMobilePhone = isMobileResult.phone

  const {
    isGroupVisible,
    isGroupOnlyView,
    hasAnyGroupOnlyView,
    showGroup,
    hideGroup,
    setOnlyViewGroup,
    clearOnlyView,
  } = useGroupVisibility()

  const thisGroup = groupsAtomList.find(group => group.id === groupId)
  const thisGroupName = thisGroup?.name || "Unnamed Group"

  // Group parts by type for rendering in panels.
  const groupPartsByType = (parts: any[]) => {
    return parts.reduce((acc: { [key: string]: any[], }, part) => {
      const key = part.apiTypeId
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(part)
      return acc
    }, {})
  }

  // Simplified toggle visibility handler
  const toggleVisibility = (e: React.MouseEvent) => {
    e.stopPropagation()

    if (hasAnyGroupOnlyView() && !isGroupOnlyView(groupId)) {
      clearOnlyView()
      showGroup(groupId)
      return
    }

    if (isGroupVisible(groupId)) {
      hideGroup(groupId)
    } else {
      showGroup(groupId)
    }
  }

  // Simplified toggle only view handler
  const toggleOnlyView = (e: React.MouseEvent) => {
    e.stopPropagation()

    if (isGroupOnlyView(groupId)) {
      clearOnlyView()
    } else {
      setOnlyViewGroup(groupId)
    }
  }

  // Update handleSelectAllGroupParts to use the new hook methods
  const handleSelectAllGroupParts = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (sceneRefs?.current?.resetSelection) {
      sceneRefs.current.resetSelection(true)
    }

    if (hasAnyGroupOnlyView() && !isGroupOnlyView(groupId)) {
      clearOnlyView()
      showGroup(groupId)
    } else if (!isGroupVisible(groupId)) {
      showGroup(groupId)
    }

    if (sceneRefs?.current?.setIdsAsHighlightedAndTurnOnControl) {
      sceneRefs.current.setIdsAsHighlightedAndTurnOnControl(
        parts.map(p => p.id),
        "translate"
      )
    }

    clearOnlyView()
    setLayout(produce((draft) => {
      draft.isDrawerOpen = false
      draft.showPartList = false
      draft.filteredPartIds = undefined
      draft.from = undefined
    }))
  }

  const onRenameGroup = (groupId: string) => {
    handleRenameGroup(groupId)
  }

  // Ungroup: first ensure that this group's parts are visible.
  const onUngroup = (groupId: string) => {
    const groupParts = thisGroup?.partIds || []
    showGroup(groupId)
    handleUngroup(groupId)
  }

  const onDeleteGroup = (groupId: string) => {
    handleDeleteGroup(groupId)
  }

  const getGroupMenu = (groupId: string) => (
    <Menu>
      <Menu.Item key="rename" onClick={() => onRenameGroup(groupId)}>
        Rename
      </Menu.Item>
      <Menu.Item key="ungroup" onClick={() => onUngroup(groupId)}>
        Ungroup
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => onDeleteGroup(groupId)}>
        Delete Group
      </Menu.Item>
    </Menu>
  )

  const groupColor = groupsAtomList.find(g => g.id === groupId)?.color || "#ffffff"
  const textColor = tinycolor(groupColor).isLight() ? "#000000" : "#ffffff"

  return (
    <Collapse
      activeKey={isActive ? [groupId,] : []}
      onChange={(keys) => onPanelChange?.(keys.includes(groupId))}
    >
      <Collapse.Panel
        key={groupId}
        header={
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%", }}>
            <span style={{ color: textColor, }}>{thisGroupName}</span>
            <div style={{ display: "flex", gap: "8px", }}>
              {!isMobilePhone && (
                <>
                  <Tooltip title={isGroupVisible(groupId) ? "Hide Group" : "Show Group"}>
                    {isGroupVisible(groupId) ? (
                      <EyeOutlined style={{ cursor: "pointer", color: textColor, }} onClick={toggleVisibility} />
                    ) : (
                      <EyeInvisibleOutlined style={{ cursor: "pointer", color: textColor, }} onClick={toggleVisibility} />
                    )}
                  </Tooltip>
                  <Tooltip title={isGroupOnlyView(groupId) ? "Show All Groups" : "Only View This Group"}>
                    {isGroupOnlyView(groupId) ? (
                      <PushpinFilled style={{ cursor: "pointer", color: textColor, }} onClick={toggleOnlyView} />
                    ) : (
                      <PushpinOutlined style={{ cursor: "pointer", color: textColor, }} onClick={toggleOnlyView} />
                    )}
                  </Tooltip>
                </>
              )}
              <Tooltip title="Select All Group Parts">
                <StyledPencilIcon $color={textColor} onClick={handleSelectAllGroupParts} />
              </Tooltip>
              <div onClick={e => e.stopPropagation()}>
                <Dropdown overlay={getGroupMenu(groupId)} trigger={["click",]}>
                  <Tooltip title="Settings">
                    <SettingOutlined style={{ cursor: "pointer", color: textColor, }} />
                  </Tooltip>
                </Dropdown>
              </div>
            </div>
          </div>
        }
        style={{
          backgroundColor: groupColor,
          marginBottom: "4px",
          borderRadius: "4px",
        }}
      >
        {Object.entries(groupPartsByType(parts)).map(([typeKey, sameParts,]) => (
          <SamePartsPanel
            key={typeKey}
            typeKey={typeKey}
            sameParts={sameParts}
            handleItemHover={handleItemHover}
            handleItemLeave={handleItemLeave}
            sceneRefs={sceneRefs}
            getRows={getRows}
          />
        ))}
      </Collapse.Panel>
    </Collapse>
  )
}