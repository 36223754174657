/* eslint-disable complexity */
/* eslint-disable no-continue */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-negated-condition */
/* eslint-disable no-useless-return */
/* eslint-disable default-case */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState, useCallback } from "react"
import { Vector3, Line as LineThree, LineBasicMaterial, Box3, Mesh, Scene, ArrowHelper, Object3D, Group } from "three"
import { CameraControls, Html, Line, } from "@react-three/drei"
import { useComponentRegistry } from "../providers/multiselectProvider/useComponentMethods"
import { useThree } from "@react-three/fiber"

import useGetDebugVariables from "./main/DesignScreen/utils/useGetDebugVariables"
import { messageUtils } from "./main/DesignScreen/scene/LowerRightMessages"
import { useLevaControls } from "../providers/debugProvider/useLevaControls"
import isMobile from "ismobilejs"
import { BoxMeshWithUserData, drawVector3Point, createCombinedOrientedBoundingBox, visualizeGrowthDirections, drawLine, } from "../utils/PartUtils"
import { calculateDashParameters, convertDimensionLinesToLinePoints, LineInfo, calculateDistanceAndOffset } from "../utils/ScalerNxerutils"
import { getCursorTypeFrom2DVector } from "./main/DesignScreen/utils/scalerUtils"
import { nxerConfig } from "../config/nxerConfig"
import { getRandomId } from "../../common/utils/utils"
import debounce from "lodash/debounce"
import { tryVerifyPartsInScene } from "../utils/MeshUtils"


interface NxerProps {
    cameraControls: React.MutableRefObject<CameraControls | any>;
    userIsResizingNxing: boolean;
    setUserIsResizingNxing: (value: boolean) => void;
    setAutofocusMode: (value: boolean) => void;
    highlightedPartIds: React.MutableRefObject<string[]>;
    autofocusMode: boolean;
    scene: Scene;
    enabled: boolean;
    selectionBox: Box3;
    dontResetSelection: React.MutableRefObject<boolean>;
    setOnTopOfTransformControls: (value: boolean) => void;
    createBoundingBoxMeshClones: (addToScene?: boolean, makeVisible?: boolean) => {
        boundingBoxGroup: Group,
        boundingBoxes: BoxMeshWithUserData<Mesh>[],
    };
    NXmodeOffset: number;
    setNXmodeOffset: (offset: number) => void;
    NXmodeUnit: string;
    setNXmodeUnit: (unit: string) => void;
    setIdsAsHighlightedAndTurnOnControl: (ids: string[], control: "selection" | "translate" | "rotate" | "nx") => void;
}


export type LineSet = {
    points: [Vector3, Vector3],
    dimension: "width" | "height" | "depth",
    direction: Vector3,
}



const Nxer: React.FC<NxerProps> = ({ cameraControls, enabled, scene, selectionBox, setUserIsResizingNxing, userIsResizingNxing, setAutofocusMode, autofocusMode, highlightedPartIds, setOnTopOfTransformControls, dontResetSelection, createBoundingBoxMeshClones, NXmodeOffset, setNXmodeOffset, NXmodeUnit, setNXmodeUnit, setIdsAsHighlightedAndTurnOnControl, }) => {
    const { getComponent, } = useComponentRegistry()
    const [linePoints, setLinePoints,] = useState<LineInfo[]>([])
    const [isVisible, setIsVisible,] = useState(false)
    const [hoveredLineIndex, setHoveredLineIndex,] = useState<number | null>(null)
    const { gl, } = useThree()
    const originalMouseXYRef = useRef<{ x: number, y: number, } | null>(null)
    const originalCursorRef = useRef<string>("auto")
    const [activeLine, setActiveLine,] = useState<LineInfo | null>(null)
    const NXModeObjectsRef = useRef<Object3D[]>([])
    const NXModeSingleOperationObjectsRef = useRef<Object3D[]>([])
    const { showDimensionAxisArrows, showGrowthDirectionArrows, drawOtherLines, drawCombinedBoxCenter, drawStepPoints, posOperationDrawOriginalCenters, posOperationDrawNewCenters, posOperationDrawNewPositions, multiplierDirectionDebug, canvasSplitDebug, nxLogs, } = useLevaControls()
    const combinedBoundingBoxRef = useRef<BoxMeshWithUserData<Mesh> | null>(null)
    const combinedBoundingBoxGroupRef = useRef<Group | null>(null)
    const largeCombinedBoundingBoxRef = useRef<BoxMeshWithUserData<Mesh> | null>(null)
    const [rowAndColumnsState, setRowAndColumnsState,] = useState<{ rows: number, columns: number, } | null>(null)
    const [labelPosition, setLabelPosition,] = useState<Vector3 | null>(null)
    const autoFocusModeRef = useRef<boolean>(autofocusMode ?? false)

    const [cameraDistance, setCameraDistance,] = useState(cameraControls.current?.distance ?? 0)

    // Debounced camera update function
    const updateCameraDistance = useCallback(
        debounce((camera) => {
            const newDistance = camera.distance
            if (newDistance !== cameraDistance) {
                setCameraDistance(newDistance)
            }
        }, 150), // Adjust debounce delay as needed
        []
    )

    // Watch for camera changes
    useEffect(() => {
        const handleCameraChange = () => {
            updateCameraDistance(cameraControls.current)
        }

        // Add camera change listener
        cameraControls.current.addEventListener("update", handleCameraChange)
        cameraControls.current.addEventListener("transitionstart", handleCameraChange)

        return () => {
            cameraControls.current.removeEventListener("update", handleCameraChange)
            cameraControls.current.removeEventListener("transitionstart", handleCameraChange)
            updateCameraDistance.cancel()
        }
    }, [cameraControls, updateCameraDistance,])

    useEffect(() => {
        nxLogs && console.log("NXmodeUnit", NXmodeUnit)
        nxLogs && console.log("NXmodeOffset", NXmodeOffset)

        if (NXmodeOffset > 0) {
            messageUtils.custom("Great! Now use the dashed lines to create cloned sets of your selected parts in the direction.", {
                duration: 10,
                showUpTo: 3,
                minTimeBetweenShows: 30,
                showCloseIcon: true,
            })
        }

    }, [NXmodeUnit, NXmodeOffset,])

    const createNXModeUI = () => {
        nxLogs && console.log("creating UI")
        const { boundingBoxGroup, boundingBoxes, } = createBoundingBoxMeshClones()
        combinedBoundingBoxGroupRef.current = boundingBoxGroup
        const combinedBoundingBox = createCombinedOrientedBoundingBox(boundingBoxes as BoxMeshWithUserData<Mesh>[])
        const largeCombinedBoundingBox = createCombinedOrientedBoundingBox(boundingBoxes as BoxMeshWithUserData<Mesh>[], 0.01)

        NXModeObjectsRef.current.push(combinedBoundingBox)
        NXModeObjectsRef.current.push(largeCombinedBoundingBox)

        nxLogs && console.log(combinedBoundingBox, "combinedBoundingBox")
        combinedBoundingBox.name = "combinedBoundingBox"

        const center = combinedBoundingBox.userData.getWorldCenter()
        const dimensions = combinedBoundingBox.userData.getDimensions("cm")

        nxLogs && console.log(center, "center")
        nxLogs && console.log(dimensions, "dimensions")

        //const centerDot = drawVector3Point(center, scene, "red", 0.05, 5000, true)
        //NXModeObjectsRef.current.push(centerDot)

        // Draw dimension lines
        const { dimensionLines, } = largeCombinedBoundingBox.userData

        const { widthDirection, heightDirection, depthDirection, widthPerpendicularDirection, heightPerpendicularDirection, depthPerpendicularDirection, widthPerpendicularMatchingDimension, heightPerpendicularMatchingDimension, depthPerpendicularMatchingDimension, } = combinedBoundingBox.userData.worldDimensions

        // Draw center point
        //drawVector3Point(center, scene, "red", 0.01, 1000, true)

        // Draw direction arrows

        if (showDimensionAxisArrows) {
            const arrowLength = 2 // Adjust length as needed
            const arrowHelper1 = new ArrowHelper(widthDirection, center, arrowLength, 0xff0000, arrowLength * 0.2, arrowLength * 0.1)
            const arrowHelper2 = new ArrowHelper(heightDirection, center, arrowLength, 0x00ff00, arrowLength * 0.2, arrowLength * 0.1)
            const arrowHelper3 = new ArrowHelper(depthDirection, center, arrowLength, 0x0000ff, arrowLength * 0.2, arrowLength * 0.1)

            scene.add(arrowHelper1)
            scene.add(arrowHelper2)
            scene.add(arrowHelper3)

            NXModeObjectsRef.current.push(arrowHelper1)
            NXModeObjectsRef.current.push(arrowHelper2)
            NXModeObjectsRef.current.push(arrowHelper3)

        }

        // Convert dimension lines to linePoints with directions
        const newLinePoints = convertDimensionLinesToLinePoints(
            dimensionLines,
            {
                widthDirection,
                heightDirection,
                depthDirection,
                widthPerpendicularDirection,
                heightPerpendicularDirection,
                depthPerpendicularDirection,
                widthPerpendicularMatchingDimension,
                heightPerpendicularMatchingDimension,
                depthPerpendicularMatchingDimension,
            }
        )
        nxLogs && console.log(newLinePoints, "newLinePoints")

        setLinePoints(newLinePoints)

        //if (showGrowthDirectionArrows) {
        const arrows = visualizeGrowthDirections(newLinePoints, scene, { width: gl.domElement.clientWidth, height: gl.domElement.clientHeight, }, { length: 250, thickness: 15, }, 3500, true)
        NXModeObjectsRef.current.push(...arrows)
        //}


        largeCombinedBoundingBox.material = new LineBasicMaterial({
            color: "blue",
            transparent: true,
            opacity: 0.15,
        })
        scene.add(largeCombinedBoundingBox)
        largeCombinedBoundingBox.userData.originalDimensions = dimensions
        largeCombinedBoundingBoxRef.current = largeCombinedBoundingBox


        combinedBoundingBoxRef.current = combinedBoundingBox
        //scene.add(combinedBoundingBox)



        messageUtils.custom("Use the dashed lines to create cloned sets of your selected parts in the direction.", {
            duration: 20,
            showUpTo: 3,
            minTimeBetweenShows: 30,
            showCloseIcon: true,
        })
    }

    useEffect(() => {
        createNXModeUI()

        return () => {
            NXModeObjectsRef.current.forEach(object => {
                scene.remove(object)
            })
            NXModeObjectsRef.current = []
            NXModeSingleOperationObjectsRef.current.forEach(object => {
                scene.remove(object)
            })
            NXModeSingleOperationObjectsRef.current = []
            gl.domElement.style.cursor = "default"
            highlightedPartIds.current.forEach(partId => {
                const component = getComponent(partId)
                if (component) {
                    component.updateColor(0x1b7fe3)
                }
            })
        }
    }, [])


    // Add new state for preview lines
    const [previewLines, setPreviewLines,] = useState<LineInfo[]>([])
    const [movingPreviewLines, setMovingPreviewLines,] = useState<LineInfo[]>([])
    const [perpendicularPreviewLine, setPerpendicularPreviewLine,] = useState<LineInfo | null>(null)
    const [currentSteps, setCurrentSteps,] = useState(0)

    // Add new state for step points
    const [stepPoints, setStepPoints,] = useState<{ step: number, point: Vector3, }[]>([])

    const calculateSteppedOffset = useCallback((offset: Vector3, activeLine: LineInfo, NXmodeUnit: string, NXmodeOffset: number) => {
        if (!combinedBoundingBoxRef.current) { return new Vector3() }

        const dimensions = combinedBoundingBoxRef.current.userData.getDimensions("cm")
        let baseSize = 0

        switch (activeLine.perpendicularMatchingDimension) {
            case "width":
                baseSize = dimensions.width
                break
            case "height":
                baseSize = dimensions.height
                break
            case "depth":
                baseSize = dimensions.depth
                break
        }


        const nxModeOffsetInCM = NXmodeUnit === "cm" ? NXmodeOffset / 100 : NXmodeOffset * 2.54 / 100
        nxLogs && console.log("nxModeOffsetInCM", nxModeOffsetInCM)
        const stepSize = ((baseSize / 100)) + nxModeOffsetInCM
        const offsetInGrowthDirection = offset.dot(activeLine.growthDirection!)
        let steps = Math.round(offsetInGrowthDirection / stepSize)
        steps = Math.max(0, steps)

        if (steps === 0) {
            messageUtils.custom("Keep dragging until you see a clone!", {
                duration: 1,
                showUpTo: 5,
                minTimeBetweenShows: 3,
            })
        }

        // Calculate step points
        const newStepPoints = []
        const lineCenter = new Vector3().addVectors(
            activeLine.points[0],
            activeLine.points[1]
        )
            .multiplyScalar(0.5)
            .add(activeLine.growthDirection!.clone().multiplyScalar(-0.005)) //this is the amount we grew our box by
            .add(activeLine.growthDirection!.clone().multiplyScalar(nxModeOffsetInCM / 2)) //we have to account for half the offset for the first step


        //drawVector3Point(lineCenter, scene, "green", 0.05, 5000, true)

        for (let i = 1; i <= steps; i++) {
            const stepOffset = activeLine.growthDirection!
                .clone()
                .multiplyScalar((i - 0.5) * stepSize)
            const pointPosition = lineCenter.clone().add(stepOffset)
            newStepPoints.push({ step: i, point: pointPosition, })
        }
        setStepPoints(newStepPoints)

        const steppedOffset = activeLine.growthDirection!
            .clone()
            .multiplyScalar(steps * stepSize)

        setCurrentSteps(steps)
        return steppedOffset
    }, [])

    useEffect(() => {
        nxLogs && console.log("stepPoints", stepPoints)
    }, [stepPoints,])

    const handlePointerOver = (index: number, event: any) => {
        if (userIsResizingNxing) { return }

        const line = linePoints[index]
        nxLogs && console.log("line", line)
        // Don't handle hover for lines that are not parallel to normal

        setHoveredLineIndex(index)
        originalCursorRef.current = gl.domElement.style.cursor
        gl.domElement.style.cursor = getCursorTypeFrom2DVector(line.points[0], line.growthDirection ?? new Vector3(), cameraControls.current.camera)
    }



    const handlePointerDown = (index: number, event: any) => {
        setOnTopOfTransformControls(true)
        const line = linePoints[index]
        // Don't handle click for lines that are not parallel to normal

        event.stopPropagation()
        setUserIsResizingNxing(true)
        originalMouseXYRef.current = { x: event.clientX, y: event.clientY, }
        setActiveLine(line)

        //remeber the autofocus mode
        autoFocusModeRef.current = autofocusMode ?? false
        //console.log("autoFocusModeRef.current", autofocusMode)
        setAutofocusMode(false)

        // Create preview lines
        const selectedLine = linePoints[index]

        // Find the matching line with same viewType
        const matchingLine = linePoints.find(line =>
            line.viewType === selectedLine.viewType
            && line !== selectedLine
        )

        let otherLines: LineInfo[] = []

        if (matchingLine) {
            // Create two new lines connecting the endpoints
            otherLines = [
                {
                    ...selectedLine,
                    points: [selectedLine.points[0], matchingLine.points[0],],
                    position: "top",
                },
                {
                    ...selectedLine,
                    points: [selectedLine.points[1], matchingLine.points[1],],
                    position: "bottom",
                },
            ]
        }

        if (drawOtherLines) {
            drawLine(selectedLine.points[0], matchingLine?.points[0] ?? new Vector3(), scene, "purple", false)
            drawLine(selectedLine.points[1], matchingLine?.points[1] ?? new Vector3(), scene, "purple", false)
        }

        //drawVector3Point(selectedLine.points[0], scene, "red", 0.05, 5000, true)
        //drawVector3Point(selectedLine.points[1], scene, "red", 0.05, 5000, true)

        setPreviewLines(otherLines)


    }

    const handlePointerOut = (index: number, event: any) => {
        //console.log("handle pointer out")
        if (userIsResizingNxing) { return }
        setHoveredLineIndex(null)
        setActiveLine(null)
        gl.domElement.style.cursor = originalCursorRef.current
    }


    // Modify the pointer move effect to use stepped offset
    useEffect(() => {
        const handlePointerMove = (event: PointerEvent) => {
            if (!userIsResizingNxing || !activeLine || !originalMouseXYRef.current) { return }

            const { distance, offset, } = calculateDistanceAndOffset(
                event,
                originalMouseXYRef.current,
                activeLine,
                cameraControls.current.camera,
                gl,
                linePoints,
                scene,
                multiplierDirectionDebug,
                canvasSplitDebug
            )

            // Calculate stepped offset
            const steppedOffset = calculateSteppedOffset(offset, activeLine, NXmodeUnit, NXmodeOffset)

            const activeLineCenter = new Vector3().addVectors(
                activeLine.points[0],
                activeLine.points[1]
            )
                .multiplyScalar(0.5)

            const movingPoints: Vector3[] = []

            // Update preview lines using stepped offset
            const updatedPreviewLines = previewLines.map(line => {
                const dist0 = line.points[0].distanceTo(activeLineCenter)
                const dist1 = line.points[1].distanceTo(activeLineCenter)

                if (dist0 < dist1) {
                    const movedPoint = line.points[0].clone().add(steppedOffset)
                    movingPoints.push(movedPoint)
                    return {
                        ...line,
                        points: [movedPoint, line.points[1],],
                    }
                }
                const movedPoint = line.points[1].clone().add(steppedOffset)
                movingPoints.push(movedPoint)
                return {
                    ...line,
                    points: [line.points[0], movedPoint,],
                }
            })

            if (movingPoints.length === 2) {
                setPerpendicularPreviewLine({
                    points: [movingPoints[0], movingPoints[1],],
                    viewType: "width",
                    position: "top",
                    color: "purple",
                })
            }

            setMovingPreviewLines(updatedPreviewLines as LineInfo[])

            // Clear previous objects from scene
            NXModeObjectsRef.current = NXModeObjectsRef.current.filter(obj => {
                if (obj.userData?.isStepPoint || obj.userData?.isStepGroup) {
                    scene.remove(obj)
                    return false
                }
                return true
            })

            // Draw debug point at world center
            if (combinedBoundingBoxRef.current) {
                const worldCenter = combinedBoundingBoxRef.current.userData.getWorldCenter()
                if (drawCombinedBoxCenter) {
                    const centerDebugPoint = drawVector3Point(worldCenter, scene, "red", 0.005, 5000, true, undefined, undefined, undefined, "cone")
                    centerDebugPoint.userData.isStepPoint = true
                    NXModeObjectsRef.current.push(centerDebugPoint)
                }
            }


            //cleanup the previous groups, if they exist
            NXModeSingleOperationObjectsRef.current.forEach(object => {
                object.removeFromParent()
            })
            NXModeSingleOperationObjectsRef.current = []



            // Create clones at each step point
            stepPoints.forEach(({ point, step, }) => {

                // Draw step point for debugging
                if (drawStepPoints) {
                    const stepPointObject = drawVector3Point(point, scene, "purple", 0.009, 5000, true)
                    stepPointObject.userData.isStepPoint = true
                    NXModeObjectsRef.current.push(stepPointObject)
                }

                if (combinedBoundingBoxGroupRef.current) {
                    // Create center anchor point
                    const centerAnchor = new Object3D()
                    centerAnchor.position.copy(combinedBoundingBoxRef.current!.userData.getWorldCenter())
                    centerAnchor.quaternion.copy(combinedBoundingBoxGroupRef.current.quaternion)

                    // Clone the bounding box group
                    const groupClone = combinedBoundingBoxGroupRef.current.clone(true)

                    // Copy userData functions from original to clone for the group
                    groupClone.userData = {
                        ...combinedBoundingBoxGroupRef.current.userData,
                    }

                    // Recursively copy userData functions for all children
                    const copyUserDataFunctions = (original: Object3D, clone: Object3D) => {
                        // Copy userData functions from original to clone
                        clone.userData = { ...original.userData, }

                        // Recursively process children
                        original.children.forEach((originalChild, index) => {
                            const cloneChild = clone.children[index]
                            copyUserDataFunctions(originalChild, cloneChild)
                        })
                    }

                    copyUserDataFunctions(combinedBoundingBoxGroupRef.current, groupClone)

                    // Attach clone to center anchor
                    centerAnchor.attach(groupClone)

                    // Position the center anchor at the step point
                    centerAnchor.position.copy(point)

                    //name it
                    centerAnchor.name = `centerAnchor-${step}`

                    // Add to scene and track for cleanup
                    scene.add(centerAnchor)
                    centerAnchor.userData.isStepGroup = true

                    //Add to this ref so we can operate over it using its center positions
                    NXModeSingleOperationObjectsRef.current.push(centerAnchor)
                }
            })

            const { rows, columns, } = getNXOperationDimensions()
            setRowAndColumnsState({ rows, columns, })
            setLabelPosition(activeLineCenter)
        }

        window.addEventListener("pointermove", handlePointerMove)
        return () => window.removeEventListener("pointermove", handlePointerMove)
    }, [userIsResizingNxing, activeLine, previewLines, calculateSteppedOffset, stepPoints, NXmodeUnit, NXmodeOffset,])


    // Modify the mouse up handler to use stepped offset
    useEffect(() => {
        const handleGlobalMouseUp = (event: MouseEvent | TouchEvent) => {
            if (userIsResizingNxing && activeLine && originalMouseXYRef.current) {
                gl.domElement.style.cursor = originalCursorRef.current
                setHoveredLineIndex(null)

                const clientX = "touches" in event
                    ? (event as TouchEvent).changedTouches[0].clientX
                    : (event as MouseEvent).clientX
                const clientY = "touches" in event
                    ? (event as TouchEvent).changedTouches[0].clientY
                    : (event as MouseEvent).clientY

                const { distance, offset, } = calculateDistanceAndOffset(
                    { clientX, clientY, },
                    originalMouseXYRef.current,
                    activeLine,
                    cameraControls.current.camera,
                    gl,
                    linePoints,
                    scene,
                    multiplierDirectionDebug,
                    canvasSplitDebug
                )

                // Use stepped offset for final position
                const finalSteppedOffset = calculateSteppedOffset(offset, activeLine, NXmodeUnit, NXmodeOffset)

                // Generate history key for the duplication operation
                const historyKey = getRandomId()


                // Duplicate parts at each step point
                if (currentSteps > 0) {
                    duplicatePartsAtStepPoints(historyKey, stepPoints)
                }

                const putBackInAutofocusMode = setTimeout(() => {
                    setAutofocusMode(autoFocusModeRef.current)
                }, 500)
                setOnTopOfTransformControls(false)

                clearTimeout(putBackInAutofocusMode)
                setTimeout(() => {
                    setAutofocusMode(autoFocusModeRef.current)
                }, 500)
            }

            // Clear preview lines
            setUserIsResizingNxing(false)

            setActiveLine(null)
            setPreviewLines([])
            setMovingPreviewLines([])
            setPerpendicularPreviewLine(null)
            setStepPoints([])

        }

        window.addEventListener("mouseup", handleGlobalMouseUp)
        window.addEventListener("touchend", handleGlobalMouseUp)
        return () => {
            window.removeEventListener("mouseup", handleGlobalMouseUp)
            window.removeEventListener("touchend", handleGlobalMouseUp)
        }
    }, [userIsResizingNxing, activeLine, calculateSteppedOffset, currentSteps,])

    useEffect(() => {
        nxLogs && console.log(previewLines, "previewLines")
    }, [previewLines,])


    // Initial check
    useEffect(() => {
        setIsVisible(true)
    }, [])

    const getNXOperationDimensions = () => {
        const rows = NXModeSingleOperationObjectsRef?.current?.length || 0
        const columns = NXModeSingleOperationObjectsRef?.current?.[0]?.children?.[0]?.children?.length || 0

        return { rows, columns, }
    }

    const duplicatePartsAtStepPoints = async (historyKey: string, points: { step: number, point: Vector3, }[], callback?: () => void) => {
        // Get the original bounding box meshes from the first object in NXModeSingleOperationObjectsRef
        const newPartsIds: string[] = []


        // Process all objects in NXModeSingleOperationObjectsRef
        for (const stepObject of NXModeSingleOperationObjectsRef.current) {
            // Get the group containing bounding box meshes (first child)
            const boundingBoxGroup = stepObject.children[0]
            if (!boundingBoxGroup) { continue }

            // Get all bounding box meshes from the group
            const boundingBoxMeshes = boundingBoxGroup.children

            // Process each bounding box mesh
            for (const boundingBoxMesh of boundingBoxMeshes) {
                const component = getComponent(boundingBoxMesh.userData.partId)
                if (!component) { continue }

                // Get the original part's center
                const originalCenter = component.getBoundingBoxMesh().userData.getWorldCenter()

                posOperationDrawOriginalCenters && drawVector3Point(originalCenter, scene, "red", 0.005, 5000, true)

                // Get the part's original position/marker offset
                const partInfo = component.getPartInfo()
                const originalPosition = partInfo.markerOffset || partInfo.position

                // Calculate the offset between center and position
                const centerToPositionOffset = new Vector3()
                    .subVectors(originalPosition, originalCenter)

                // Get the world center of the current bounding box mesh
                const newCenter = (boundingBoxMesh as BoxMeshWithUserData<Mesh>).userData.center.clone()
                boundingBoxMesh.localToWorld(newCenter)

                posOperationDrawNewCenters && drawVector3Point(newCenter, scene, "blue", 0.005, 5000, true)

                // Calculate new position by adding the original offset to the new center
                const newPosition = newCenter.clone().add(centerToPositionOffset)

                posOperationDrawNewPositions && drawVector3Point(newPosition, scene, "green", 0.005, 5000, true)

                // Duplicate the part at the new position
                let newPart
                if (partInfo.markerOffset) {
                    newPart = await component.duplicatePart(historyKey, newPosition, undefined, newPosition)
                } else {
                    newPart = await component.duplicatePart(historyKey, newPosition)
                }
                //console.log(newPart, "newPart")
                newPartsIds.push(newPart)
            }
        }

        if (callback) {
            return callback?.()
        }

        // Use the new tryVerifyPartsInScene function
        tryVerifyPartsInScene(scene, newPartsIds, {
            maxAttempts: 150,
            intervalMs: 250,
            onFound: (foundPartIds) => {
                messageUtils.custom(`Select ${foundPartIds.length} cloned part${foundPartIds.length === 1 ? "" : "s"}?`, {
                    duration: 3,
                    forceShow: true,
                    buttonText: "Select",
                    onButtonClick: () => {
                        setIdsAsHighlightedAndTurnOnControl(newPartsIds.map(part => part), "translate")
                    },
                })
            },
        })

        return newPartsIds
    }

    const isMobileDevice = isMobile(window.navigator).any

    const generateKey = (prefix: string, line: LineInfo, index: number, additionalId = "") => {
        const key = `${prefix}-${line.viewType}-${line.position}-${index}-${additionalId}-${Math.random().toString(36)
            .substr(2, 5)}`
        //console.log(`Generated key: ${key}`)
        return key
    }

    return (
        <>
            {linePoints.map((line, index) => {
                const { dashSize, gapSize, } = calculateDashParameters(
                    line.points,
                    isMobile(window.navigator).any,
                    cameraControls.current?.camera,
                    cameraDistance
                )
                const isHovered = hoveredLineIndex === index
                const lineColor = isHovered ? "#ff9900" : line.color

                return (
                    <React.Fragment key={`line-group-${index}`}>
                        {isMobileDevice && (
                            <Line
                                key={generateKey("grab-helper", line, index)}
                                points={line.points}
                                dashed={false}
                                color={"red"}
                                lineWidth={nxerConfig.mainLines.lineWidth * 3}
                                userData={{ ignoreRaycast: true, }}
                                visible={isVisible}
                                opacity={0}
                                transparent={true}
                                onPointerOver={(e) => handlePointerOver(index, e)}
                                onPointerDown={(e) => handlePointerDown(index, e)}
                                onPointerOut={(e) => handlePointerOut(index, e)}
                            />
                        )}
                        <Line
                            key={generateKey("main", line, index)}
                            points={line.points}
                            dashed={nxerConfig.mainLines.dashed}
                            dashSize={dashSize}
                            gapSize={gapSize}
                            color={lineColor}
                            lineWidth={nxerConfig.mainLines.lineWidth}
                            opacity={nxerConfig.mainLines.opacity}
                            userData={{ ignoreRaycast: true, }}
                            visible={isVisible}
                            {...(!isMobileDevice && {
                                onPointerOver: (e) => handlePointerOver(index, e),
                                onPointerDown: (e) => handlePointerDown(index, e),
                                onPointerOut: (e) => handlePointerOut(index, e),
                            })}
                        />
                    </React.Fragment>
                )
            })}

            {activeLine && movingPreviewLines.map((line, index) => (
                <Line
                    key={generateKey("preview", line, index)}
                    points={line.points}
                    color={activeLine?.color}
                    lineWidth={nxerConfig.previewLines.lineWidth}
                    dashed={nxerConfig.previewLines.dashed}
                    opacity={nxerConfig.previewLines.opacity}
                    visible={isVisible}
                    userData={{ ignoreRaycast: true, }}
                />
            ))}

            {perpendicularPreviewLine && activeLine && (
                <Line
                    key={generateKey("perpendicular", perpendicularPreviewLine, 0)}
                    points={perpendicularPreviewLine.points}
                    color={activeLine.color}
                    lineWidth={nxerConfig.perpendicularLine.lineWidth}
                    dashed={nxerConfig.perpendicularLine.dashed}
                    opacity={nxerConfig.perpendicularLine.opacity}
                    visible={isVisible}
                    userData={{ ignoreRaycast: true, }}
                />
            )}
            {labelPosition && activeLine && rowAndColumnsState && rowAndColumnsState.rows > 0 && rowAndColumnsState.columns > 0 && (
                <Html
                    position={labelPosition}
                    style={{
                        background: "rgba(0, 0, 0, 0.8)",
                        padding: "8px 12px",
                        borderRadius: "4px",
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        pointerEvents: "none",
                        transform: "translate(-50%, -100%)",
                    }}
                >
                    Cloning {rowAndColumnsState.rows} sets of {rowAndColumnsState.columns} ({rowAndColumnsState.rows * rowAndColumnsState.columns} parts)
                </Html>
            )}
        </>
    )
}

export default Nxer