/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import { useGLTF } from "@react-three/drei"
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import {
    Snapshot, useRecoilCallback, useRecoilState,
    useRecoilValue, useSetRecoilState
} from "recoil"
import {
    ArrowHelper,
    AxesHelper,
    Box3,
    BoxHelper,
    Color,
    DoubleSide,
    Euler,
    Group,
    InstancedMesh,
    MathUtils,
    Matrix4,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    PlaneGeometry,
    Quaternion,
    Scene,
    SphereGeometry,
    Vector3
} from "three"
import { assertPartType, ConnectorPart, Marker, MarkerType, PartDataStore, PartTypeEnum } from "../../../../../../../utils/Types"
import { ConnectorInternalsType, ConnectorMarkerType, CONNECTOR_UI } from "./types/types"
import ConnectorUI from "./ui/ConnectorUI"
import useInstancedMesh from "../../../../../../../providers/instancedMesh/useInstancedMesh"
import useCollision from "../../../../../../../providers/collisionProvider/useCollision"
import useSelector from "../../../../../../../providers/mouseProvider/useSelector"
import { addPartModal, isItemSelected, } from "../../../../../../../state/atoms"
import { allConnections, partConnections, } from "../../../../../../../state/scene/selectors"
import {
    useMultipleUpdates, useNewPart,
    useUpdateConnector,
} from "../../../../../../../state/scene/setters"
import { partsIdsSelector, partsSelector, partVisibilityAtom, } from "../../../../../../../state/scene/atoms"
import { initialData } from "../../../../../../../state/atoms"

import {
    attachTo,
    detachFrom,
    getConnectorInternals,
    resetMarkerValues,
    setMarkerDirAndPos,
    setRotation,
    getInitialMarker,
    getRotationMarker,
    saveRotation as saveFinalRotation,
    MAIN_MODEL,
    handleConnectorSwap,
    removeGuidelines,
    canSwapConnector,
    checkExactSnap,
} from "./utils/ConnectorUtils"
import {
    getConnectionMarkerName,
    getOtherPartOfTheConnection,
    isInConnection,
    useGetMarkerData
} from "../../../../../../../state/scene/util"
import {
    connectionTypesSelector,
    sizesSelector
} from "../../../../../../../state/initialDataSelectors"
import { innerToMesh, innerToOuter, isInner, MarkerUserData, visualizeBoundingBoxLabel, visualizeMarkerPositionsWithLabels, } from "../../../../../../../utils/MarkerUtil"
import { message } from "antd"
import { createBoxWithCenterSphere, Faces, getFreeMarkers, getSceneAttachedMarker, handleCollisionOnCreation, visualizeFaces, visualizeOrientedBoundingBox } from "../../../../../../../utils/PartUtils"
import useModal from "../../../../../../../../common/providers/modalProvider/useModal"
import {
    useRegisterMultipleMovement
} from "../../../../../../../providers/multipleMovementProvider/useMultipleMovement"
import NewPartButtonConnector from "./newPartButton"
import { MultipleMovementUtils } from "./utils/MultipleMovementUtils"
import { PartConnectionType } from "../../../../../../../state/scene/types"
import useWoodTexture from "../hooks/useWoodTexture"
import { useThree } from "@react-three/fiber"
import { MeshUtils } from "../../../../../../../utils/MeshUtils"
import { SoundHelper } from "../../../../utils/SoundHelper"
import { breadcrumb } from "../../../../../../../../common/utils/sentrySetup"
import { ObjDictionary, SELECTED_PART_COLOR, getLocalStorage, requestidleCallbackWithFallback } from "../../../../../../../../common/utils/utils"
import useCloseMarkers from "../../../../../../../providers/closeMarkersProvider/useCloseMarkers"
import { useComponentRegistry, useRegisterComponent } from
    "../../../../../../../providers/multiselectProvider/useComponentMethods"
import { useLevaControls } from "../../../../../../../providers/debugProvider/useLevaControls"
import MarkerHelpers from "../utils/markerHelpers/MarkerHelpers"
import { CorrectionsType }
    from "../../../../../../../providers/multipleMovementProvider/MultipleMovementProvider"
import { getRotationMarker as getRotationMarkerUtil }
    from "../../../../../../../state/scene/setters"
import hotkeys from "hotkeys-js"
import { useRegisterSlide } from "../../../../../../../providers/slideProvider/useSlide"
import { SegmentedTubeMarker, SegmentedTubeMarkers } from "../segmentedTube/types/types"
import useGetDebugVariables from "../../../../utils/useGetDebugVariables"
import { Text } from "@react-three/drei"
import { normalizeDirectionalName, getNormalDirection } from "../../../../../../../utils/MarkerUtil"
import VisualDebug, { VisualDebugData } from "../../common/VisualDebug"
import { getPointsArrayForSingleEnd } from "../../../../../../../providers/moveProvider/meshBuilderEnds"
import { createLabelAtPosition } from "../../../../../../../utils/MarkerUtil"
import { Box3Helper } from "three"
import { drawVector3Point } from "../../../../../../../utils/PartUtils"
import { SizeAPI } from "../../../../../../../../common/api/Types"
import { SceneRef } from "../../../../../../../state/types"
import { cameraUtils } from "../../../../utils/cameraUtils"
interface Props {
    id: string;
    handleDeletePart: (id: string) => void;
    partDataRef: React.MutableRefObject<Record<string, PartDataStore>>;
    sceneRefs: SceneRef;
}

const Connector = (props: Props) => {

    const { scene, } = useThree()
    const { showModal, } = useModal()
    const getPartIdsList = useRecoilCallback(({ snapshot, }) => () => {
        return snapshot.getLoadable(partsIdsSelector).contents
    }, [])
    const connector = useRecoilValue(partsSelector({ id: props.id, }))!
    assertPartType(connector, PartTypeEnum.connector)
    const [isSelected, setIsSelected,] = useRecoilState(isItemSelected(connector.id))
    const partConnectionsValue = useRecoilValue(partConnections(connector.id))
    const updateConnectorValues = useUpdateConnector()
    const multipleUpdate = useMultipleUpdates()
    const setNewConnectionData = useSetRecoilState(addPartModal)
    const [connectorUI, setConnectorUI,] = useState<CONNECTOR_UI>(CONNECTOR_UI.NONE)
    const getConnectionTypes = useRecoilCallback(({ snapshot, }) => () => {
        return snapshot.getLoadable(connectionTypesSelector).contents
    }, [])
    const getSizes = useRecoilCallback(({ snapshot, }) => () => {
        return snapshot.getLoadable(sizesSelector).contents
    }, [])
    const getMarkerData = useGetMarkerData()
    const [isColliding, setIsColliding,] = useState(false)
    const [savingRotation, setSavingRotation,] = useState(false)
    const getConnections = useRecoilCallback(({ snapshot, }) => () => {
        return snapshot.getLoadable(allConnections).contents
    }, [])
    const importedGLTF = useGLTF(connector.fileURL, true)
    const { material, woodTexture, } = useWoodTexture(connector.color, 3, 3)
    const rotationMarker = useRef<string | undefined>(undefined)
    const attachedMarker = useRef<Mesh | undefined>(undefined)
    const connectorInternalsRef = useRef<ConnectorInternalsType>(getConnectorInternals())
    const [tempConnectedMarkers, setTempConnectedMarkers,] = useState<string[]>([])
    const closestMarkersHook = useCloseMarkers(connector.id)
    const { viewInnerMarkers, viewOuterMarkers, viewLocalAxis, showDebugText, viewMarkerPositionsWithLabels, showAllPartsIds, seeOBBdebugOnSelect, logRenders, visualizeFacesForOBB, } = useLevaControls()
    const [boundingBox, setBoundingBox,] = useState<Box3 | null>(null)
    const getAllParts = useRecoilCallback(({ snapshot, }) => () => {
        return snapshot.getLoadable(initialData).contents
    }, [])
    const { unregister, } = useComponentRegistry()
    const wasDuplicated = !!connector.duplicatedFrom || !!connector.bulkCreated
    const [updatingPosition, setUpdatingPosition,] = useState(false)
    const [uiVisible, setUiVisible,] = useState(false)
    const { getVariables, } = useGetDebugVariables()
    const isAdmin = getVariables().isAdmin
    const meshRef = useRef<InstancedMesh | null>(null)
    const matrixRef = useRef<Matrix4 | null>(null)
    const mountedRef = useRef(true)
    const [visualDebug, setVisualDebug,] = useState<VisualDebugData>({})
    const [correspondingMarkersNeedUpdate, setCorrespondingMarkersNeedUpdate,] = useState(false)
    const [boundingBoxCenter, setBoundingBoxCenter,] = useState<Vector3 | null>(null)
    const getInvisibleParts = useRecoilCallback(
        ({ snapshot, }) =>
            () => {
                const invisibleParts = snapshot.getLoadable(partVisibilityAtom).contents
                return invisibleParts
            },
        [],
    )
    useEffect(() => {
        return () => {
            mountedRef.current = false
        }
    }, [])
    const [debugNormals, setDebugNormals,] = useState(false)
    const [debugLabels, setDebugLabels,] = useState<{
        position: Vector3,
        text: string,
    }[]>([])

    isAdmin && console.log("core connector", connector.id)


    const { fit, getBoundingBoxForConnector, } = cameraUtils

    const { getInAutofocusMode, setInAutofocusMode, addMeshToSceneBounds, } = props.sceneRefs.current


    const handleConnectorClick = useCallback((mesh: InstancedMesh, matrix: Matrix4) => {
        setIsSelected(true)
        setInAutofocusMode?.(true)
        props.sceneRefs.current.setTransformMode?.("off")
        updateColor(SELECTED_PART_COLOR)
        //console.log(getLatestStatusofDuplication(), "getLatestStatusofDuplication")

        setConnectorUI(CONNECTOR_UI.ACTIONS)
        meshRef.current = mesh
        matrixRef.current = matrix

    }, [getInAutofocusMode, setInAutofocusMode,])

    useEffect(() => {
        if ((getLocalStorage("autofocusMode") ?? getInAutofocusMode?.()) && meshRef.current && matrixRef.current) {
            fit(meshRef.current!, matrixRef.current!, props.sceneRefs.current.cameraControls?.current)
        }
    }, [isSelected,])


    const onCollide = (colliding: boolean) => {
        setIsColliding(colliding)
    }

    const setInternals = (newConnectorInternals: ConnectorInternalsType) => {
        connectorInternalsRef.current = newConnectorInternals
    }

    const updateTempConnectedMarkers = (newConnections: PartConnectionType[]) => {
        if (newConnections.length !== tempConnectedMarkers.length
            || tempConnectedMarkers.some(
                m => !newConnections.some(
                    c => getConnectionMarkerName(c, connector.id) === m))
            || newConnections.some(c =>
                tempConnectedMarkers.some(m => getConnectionMarkerName(c, connector.id) === m))
        ) {
            setTempConnectedMarkers(newConnections.map(
                connection => getConnectionMarkerName(connection, connector.id)!))
        }
    }

    const {
        ref,
        getIndex,
        changeVisibilityOnSpecificIndex,
        getVisibilityOnSpecificIndex,
        createStandAloneMeshFromInstance,
        updateTransforms,
        deleteMesh,
        instancedMesh,
        updateColor,
        getMatrix,
        setVisibilityForInstance,
    } = useInstancedMesh(
        (importedGLTF as any).nodes[MAIN_MODEL],
        connector.id,
        connector.apiTypeId,
        handleConnectorClick,
        material,
        woodTexture
    )

    const {
        updateCollider,
        buildCollider,
        deleteCollider,
    } = useCollision(
        connector.id,
        ref,
        instancedMesh,
        onCollide,
        connector.type,
        (importedGLTF as any).nodes.colliderModel
    )
    const { registerInstancedMesh, } = useSelector(connector.id, undefined)

    const detachFromMarker = MultipleMovementUtils.useDetachFromMarker({
        connector,
        attachedMarker,
        scene,
        buildCollider,
        updateCollider: (connectedParts: string[]) => updateCollider(undefined, connectedParts),
        connectorInternalsRef,
        setInternals,
        compatibilityList: getConnectionTypes(),
    })
    useRegisterMultipleMovement({
        id: props.id,
        attachToMarker: (marker: Mesh) => {
            marker.attach(attachedMarker.current!)
        },
        detachFromMarker: (
            marker: Mesh,
            connections: PartConnectionType[],
            getExactSnaps?: boolean,
            checkCollisions?: boolean,
            resetGuidelines?: boolean
        ) => {
            const connectorConnections = connections
                .filter(c => isInConnection(c, connector.id))
                .map(c => {
                    return {
                        connectedPartId: getOtherPartOfTheConnection(c, connector.id),
                        connectedMarkerName: getConnectionMarkerName(c, connector.id)!,
                    }
                })
            return detachFromMarker(marker,
                connectorConnections.map(c => c.connectedPartId),
                connectorConnections.map(c => c.connectedMarkerName),
                getExactSnaps,
                checkCollisions,
                resetGuidelines
            )
        },
        updateTransforms,
        checksOnLengthMove: (
            elongationDirection: Vector3,
            connections: PartConnectionType[],
            lengthDirection: number
        ) => {
            return {
                ...MultipleMovementUtils.checkSnap({
                    connector,
                    scene,
                    connectorInternalsRef,
                    connections,
                    setInternals,
                    compatibilityList: getConnectionTypes(),
                    elongationDirection,
                }),
                ...MultipleMovementUtils.checkAlignments({
                    connector,
                    scene,
                    connectorInternalsRef,
                    connections,
                    setInternals,
                    elongationDirection,
                    lengthDirection,
                }),
            } as CorrectionsType
        },
        checksOnSegmentedTubeLengthMove: (
            intersectableMeshes: Object3D[],
            connections: PartConnectionType[]
        ) => {
            MultipleMovementUtils.checkAlignments({
                connector,
                scene,
                connectorInternalsRef,
                connections,
                setInternals,
                intersectableMeshes,
            })
        },
        checksOnRotationMove: (connections: PartConnectionType[]) => {
            MultipleMovementUtils.checkAlignments({
                connector,
                scene,
                connectorInternalsRef,
                connections,
                setInternals,
            })

            const connectorConnections = connections.filter(c => isInConnection(c, connector.id))

            if (
                checkExactSnap(
                    scene,
                    connector,
                    connectorInternalsRef.current,
                    getFreeMarkers(
                        connector,
                        connectorConnections.map(c => getConnectionMarkerName(c, connector.id)!)
                    ),
                    [
                        ...connectorConnections.map(
                            c => getOtherPartOfTheConnection(c, connector.id)),
                        connector.id,
                    ],
                    getConnectionTypes()
                ).length > 0
            ) {
                SoundHelper.playUnsnap()
            }
        },
        getMaxLength: (direction: Vector3, connections?: PartConnectionType[]) => {
            return MultipleMovementUtils.getMaxLength({
                connector,
                scene,
                connectorInternalsRef,
                connections,
                setInternals,
                compatibilityList: getConnectionTypes(),
                direction,
            })
        },
        getPosAndRot: () => {
            const markersRef = connectorInternalsRef.current.markers
            const initialMarker = getInitialMarker(connector, markersRef)

            initialMarker.rotateY(MathUtils.degToRad(-180))
            const pos = MeshUtils.copyWorldPosition(initialMarker)
            const rot = MeshUtils.copyWorldQuaternion(initialMarker)
            initialMarker.rotateY(MathUtils.degToRad(180))
            return { pos, rot, }
        },
    })

    const getColor = () => {
        if (isSelected) {
            return SELECTED_PART_COLOR
        } else {
            return material ? "#fff" : connector.color
        }
    }

    const getBoundingBoxFromBoxMesh = () => {

        if (connectorInternalsRef.current.boundingBoxMesh?.userData.calculateBoundingBox) {
            //lets debug it as well
            const boundingBox = connectorInternalsRef.current.boundingBoxMesh.userData.calculateBoundingBox()
            //const boxHelper = new Box3Helper(boundingBox, new Color(0xff0000))
            //scene.add(boxHelper)
            return boundingBox
        } else if (instancedMesh.current && getMatrix()) {
            //had to keep this here because of issues with instanced meshes during initialization
            return getBoundingBoxForConnector(instancedMesh.current, getMatrix(), props.sceneRefs.current.cameraControls?.current)
        } else {
            return new Box3(new Vector3(0.5, 0.5, 0.5), new Vector3(-0.5, -0.5, -0.5))
        }
    }



    useEffect(() => {
        const bounder = getBoundingBoxFromBoxMesh()
        setBoundingBox(bounder)
        updateTransforms()


    }, [isColliding, isSelected,])

    useEffect(() => {
        if (boundingBox) {
            const bounder = getBoundingBoxFromBoxMesh()
            setBoundingBoxCenter(bounder.getCenter(new Vector3()))
        }
    }, [boundingBox,])

    useEffect(() => {
        const scope = connector.id
        if (isSelected) {
            hotkeys.setScope(scope)
            hotkeys("delete,backspace", scope, (event, handler) => {
                event.preventDefault()
                handleConnectorDelete()
            })
        } else {
            hotkeys.deleteScope(scope)
        }

        // Cleanup function
        return () => {
            hotkeys.deleteScope(scope)
        }


    }, [isSelected,])

    const originalColor = () => {
        updateColor(connector.color)
    }


    useEffect(() => {
        if (isSelected) {
            updateColor(SELECTED_PART_COLOR)
            if (isAdmin) {

                if (connectorInternalsRef.current.boundingBoxMesh?.userData.getWorldCorners && seeOBBdebugOnSelect) {
                    visualizeOrientedBoundingBox(connectorInternalsRef.current.boundingBoxMesh, scene)
                }

                if (visualizeFacesForOBB && connectorInternalsRef.current.boundingBoxMesh) {
                    const allFaces: Faces = connectorInternalsRef.current.boundingBoxMesh.userData.getAllFaces()
                    //draw all the face points with a different color per face
                    console.log(allFaces, "allFaces")
                    visualizeFaces(allFaces, scene, 5000, true)
                }
                if (viewMarkerPositionsWithLabels) {
                    const markers = getAllMarkers()
                    visualizeMarkerPositionsWithLabels(scene, markers as Mesh[], { color: "red", timer: 10000, sphereRadius: 0.01, depthTestAndWrite: true, })
                }
                console.log("Selected connector", connector.id, connector, partConnectionsValue, "partConnectionsValue")
            }
        } else {
            updateColor(getColor())
            connectorInternalsRef.current.guidelines.forEach((line) => scene.remove(line))
            setInternals({
                ...connectorInternalsRef.current,
                guidelines: [],
            })
        }
    }, [isSelected,])


    const saveRotation = () => {
        const markersRef = connectorInternalsRef.current.markers
        const marker = getRotationMarker(connector, markersRef) || attachedMarker.current
        breadcrumb({
            message: "After getRotationMarker",
            level: "info",
            data: { marker: marker.userData, },
        })
        isAdmin && console.log("running saveRotation")
        const connections = getConnections()
        if (marker) {
            saveFinalRotation(
                marker,
                scene,
                connectorInternalsRef,
                connector,
                multipleUpdate,
                setSavingRotation,
                partConnectionsValue.map(c => c.partMarkerName),
                getConnectionTypes(),
                buildCollider,
                updateCollider,
                partConnectionsValue.map(connection => connection.destinationPartId),
                connections,
                connector.loaded,
                updateTempConnectedMarkers
            )
            breadcrumb({
                message: "After saveRotation",
                level: "info",
            })
        } else {
            console.warn("Connector marker couldn't be found - can't save rotation")
        }
    }

    const detachEverythingFrom = useCallback((marker: Mesh) => {
        const markersRef = connectorInternalsRef.current.markers
        const meshesToDetach = Object.values(markersRef)
        detachFrom(marker, meshesToDetach, scene, attachedMarker)
        if (ref.current) {
            marker.remove(ref.current)
            scene.add(ref.current)
        }
    }, [attachedMarker, ref, connectorInternalsRef,])

    const attachEverythingTo = useCallback((marker: Mesh) => {
        const markersRef = connectorInternalsRef.current.markers
        if (ref.current) {
            marker.attach(ref.current)
            const meshesToAttach = Object.keys(markersRef).filter(
                name => name !== marker.name
            )
                .map(m => markersRef[m])
            attachTo(marker, meshesToAttach, attachedMarker)
        }
    }, [attachedMarker, ref, connectorInternalsRef,])

    const drawLocalAxisOfMarker = () => {
        const markersRef = connectorInternalsRef.current.markers
        const initialMarker = getInitialMarker(connector, markersRef)
        // Remove any existing axis helper for this marker
        const existingHelper = initialMarker.getObjectByName("localAxisHelper")
        if (existingHelper) {
            initialMarker.remove(existingHelper)
        }

        // Create a new AxesHelper
        const axesHelper = new AxesHelper(0.1) // Size of the helper
        axesHelper.name = "localAxisHelper"

        // Add the helper to the marker
        initialMarker.add(axesHelper)

        // Update the scene
        scene.updateMatrixWorld(true)
    }

    useEffect(() => {
        if (viewLocalAxis) {
            drawLocalAxisOfMarker()
        }
    }, [isSelected, viewLocalAxis,])


    const fixIfQuaternionIsArray = (value: any) => {
        if (Array.isArray(value)) {
            console.warn(`found connector ${connector.id} with array quaternion`, value)
            const quaternion = new Quaternion(value[0], value[1], value[2], value[3])
            const xyzwRotation = {
                x: quaternion.x ?? (quaternion as any)._x,
                y: quaternion.y ?? (quaternion as any)._y,
                z: quaternion.z ?? (quaternion as any)._z,
                w: quaternion.w ?? (quaternion as any)._w,
            }
            console.log(`fixed connector ${connector.id} rotation`, xyzwRotation)
            return xyzwRotation
        }
        return value
    }

    const setupOrientedBoundingBox = (debug?: boolean, useSpecificMarker?: Mesh) => {
        const markersRef = connectorInternalsRef.current.markers
        if (!connectorInternalsRef.current.centerObject) {
            debug && console.log("setupOrientedBoundingBox no center Object", connector.id)
            const boundingBox = getBoundingBoxFromBoxMesh()
            const initialMarker = getInitialMarker(connector, markersRef)

            const boundingBoxCenter = new Vector3()
            boundingBox.getCenter(boundingBoxCenter)

            /*if (debug) {
                const helper = new Box3Helper(boundingBox, new Color("blue"))
                scene.add(helper)
                createLabelAtPosition(scene, boundingBoxCenter, "boundingBoxCenter Of SegTube", {
                    color: "white",
                    fontSize: "15px",
                    timer: 1000,
                })
                setTimeout(() => {
                    scene.remove(helper)
                }, 1000)
            }*/

            //drawVector3Point(boundingBoxCenter, scene, "red", 0.01, undefined, true)

            const realSizes = {
                width: boundingBox.max.x - boundingBox.min.x,
                height: boundingBox.max.y - boundingBox.min.y,
                length: boundingBox.max.z - boundingBox.min.z,
            }

            const { centerSphere, boxMesh, } = createBoxWithCenterSphere({
                width: realSizes.width,
                height: realSizes.height,
                depth: realSizes.length,
                tubeId: connector.id,
                partType: connector.type,
                boxColor: "red",
                boxOpacity: 0.5,
                sphereVisible: !!debug,
                boxVisible: !!debug,
            })
            connectorInternalsRef.current.boundingBoxMesh = boxMesh

            if (!props.partDataRef.current[connector.id]) {
                props.partDataRef.current[connector.id] = {}
            }
            props.partDataRef.current[connector.id].boundingBoxMesh = boxMesh

            connectorInternalsRef.current.centerObject = centerSphere

            //this becomes our reference for the sizes for the future because sometimes the boundingbox is
            //attached to under another marker midway so we cant use the getDimensinos method for this at some points
            connectorInternalsRef.current.boxMeshLastUnScaledDimensions = realSizes

            //always set posiition before attaching
            centerSphere.position.copy(boundingBoxCenter)
            if (useSpecificMarker) {
                getSceneAttachedMarker(useSpecificMarker, scene).attach(centerSphere)
                return
            }

            //i had to write this new getSceneAttachedMarker function because the attachement points were not reliable
            if (attachedMarker.current) {
                debug && console.log("first run attaching to attachedMarker.current", attachedMarker.current.name)
                getSceneAttachedMarker(attachedMarker.current!, scene).attach(centerSphere)
            } else if (initialMarker) {
                debug && console.log("first run attaching to initialMarker", initialMarker.name)
                getSceneAttachedMarker(initialMarker, scene).attach(centerSphere)
            }

            debug && console.log(initialMarker, "initialMarker")
            debug && console.log(attachedMarker.current, "attachedMarker.current")

        } else if (connectorInternalsRef.current.centerObject && connectorInternalsRef.current.boundingBoxMesh
            && connectorInternalsRef.current.boxMeshLastUnScaledDimensions) {

            debug && console.log("setupOrientedBoundingBox centerObject and boundingBoxMesh exist", connector.id)
            //this is now the second time around

            const { centerSphere, boxMesh, } = createBoxWithCenterSphere({
                width: connectorInternalsRef.current.boxMeshLastUnScaledDimensions.width,
                height: connectorInternalsRef.current.boxMeshLastUnScaledDimensions.height,
                depth: connectorInternalsRef.current.boxMeshLastUnScaledDimensions.length,
                tubeId: connector.id,
                partType: connector.type,
                boxColor: "red",
                boxOpacity: 0.5,
                sphereVisible: !!debug,
                boxVisible: !!debug,
            })

            const centerObjectWorldQuaternion = new Quaternion()
            connectorInternalsRef.current.centerObject?.getWorldQuaternion(centerObjectWorldQuaternion)
            centerSphere.quaternion.copy(centerObjectWorldQuaternion)

            const centerObjectWorldPosition = new Vector3()
            connectorInternalsRef.current.centerObject?.getWorldPosition(centerObjectWorldPosition)
            centerSphere.position.copy(centerObjectWorldPosition)

            connectorInternalsRef.current.centerObject?.removeFromParent()
            connectorInternalsRef.current.boundingBoxMesh?.removeFromParent()

            debug && console.log("second run attaching to attachedMarker.current", attachedMarker.current?.name)
            getSceneAttachedMarker(attachedMarker.current!, scene).attach(centerSphere)

            if (!props.partDataRef.current[connector.id]) {
                props.partDataRef.current[connector.id] = {}
            }
            props.partDataRef.current[connector.id].boundingBoxMesh = boxMesh

            connectorInternalsRef.current.boundingBoxMesh = boxMesh
            connectorInternalsRef.current.centerObject = centerSphere
        }
    }
    const setInitialPositionAndRotation = (debug?: boolean) => {


        if (attachedMarker.current) {
            resetMarkerValues(attachedMarker.current)
            detachEverythingFrom(attachedMarker.current)
        }
        const rotation = fixIfQuaternionIsArray(connector.rotation)
        const markersRef = connectorInternalsRef.current.markers
        const initialMarker = getInitialMarker(connector, markersRef)

        attachEverythingTo(initialMarker)

        //new bounding box logic start

        //this is for for the first time before the item is rotated

        setupOrientedBoundingBox(seeOBBdebugOnSelect)

        setMarkerDirAndPos(
            initialMarker,
            rotation,
            connector.position
        )
        initialMarker.rotateY(MathUtils.degToRad(180))
    }

    useEffect(() => {
        if (connector.instanciated) {
            setupOrientedBoundingBox(seeOBBdebugOnSelect)
        }
    }, [connector.rotationMarkerName, connector.rotation, connector.position,])

    const setConnectorPositionBySelectedMarker = () => {
        const markersRef = connectorInternalsRef.current.markers
        const marker = getInitialMarker(connector, markersRef)

        if (marker && ref.current) {
            setInitialPositionAndRotation(seeOBBdebugOnSelect)
            updateTransforms()
            updateColor(material ? "#fff" : connector.color)
        }
        setRotationMarker()
    }

    useEffect(() => {
        if (!isSelected) {
            setConnectorUI(CONNECTOR_UI.CLOSE)
        }
    }, [connector.id, isSelected,])

    const onUserRotationApplied = () => {
        updateConnectorValues(props.id, (c) => {
            c.userRotation = 0
        }, true)
    }

    useEffect(() => {
        if (connectorUI === CONNECTOR_UI.SEGMENTED_SLIDER && props.sceneRefs.current.setTransformMode) {
            props.sceneRefs.current.setTransformMode("off")
        }
    }, [connectorUI,])

    useEffect(() => {
        rotationMarker.current = connector.rotationMarkerName
            ? connector.rotationMarkerName
            : innerToOuter(connector.markers[0].name)
        //console.log(rotationMarker.current, "rotationMarker.current")
        updateConnectorValues(props.id, (c) => { c.instanciated = true }, true)
        setConnectorPositionAndRotation(MAIN_MODEL)
        registerInstancedMesh()
        if (connector.loaded || wasDuplicated) {
            setConnectorUI(CONNECTOR_UI.NONE)
        } else {
            setConnectorUI(CONNECTOR_UI.AFTER_CREATION)
        }
        addMeshToSceneBounds?.()

        return () => {
            updateColor(material ? "#fff" : connector.color)
            deleteMesh()
            deleteCollider()
            if (connectorInternalsRef.current) {
                removeGuidelines(
                    connectorInternalsRef,
                    scene,
                    setInternals
                )
            }
        }
    }, [])



    const setPosAndRotWithNewInitialMarker = (initialMarker: Mesh) => {
        let attachedMarkerAux
        if (attachedMarker.current) {
            attachedMarkerAux = attachedMarker.current
            resetMarkerValues(attachedMarker.current)
            detachEverythingFrom(attachedMarker.current)
        }
        attachEverythingTo(initialMarker)
        initialMarker.rotateY(MathUtils.degToRad(180))
        setMarkerDirAndPos(initialMarker, connector.rotation, connector.position)
        initialMarker.rotateY(MathUtils.degToRad(-180))
        if (attachedMarkerAux) {
            const posAux = MeshUtils.copyWorldPosition(attachedMarkerAux)
            const rotAux = MeshUtils.copyWorldQuaternion(attachedMarkerAux)
            resetMarkerValues(initialMarker)
            detachEverythingFrom(initialMarker)
            attachEverythingTo(attachedMarkerAux)
            setMarkerDirAndPos(attachedMarkerAux, rotAux, posAux)
        }
    }



    const setPositionWithMarker = (marker: Mesh, pos: Vector3) => {
        // Get initial marker and calculate current offset
        const initialMarker = getInitialMarker(connector, connectorInternalsRef.current.markers)
        const markerWorldPos = MeshUtils.copyWorldPosition(marker)
        const initialMarkerWorldPos = MeshUtils.copyWorldPosition(initialMarker)
        const offset = markerWorldPos.clone().sub(initialMarkerWorldPos)

        // Calculate new position by subtracting offset from desired position
        const newPosition = pos.clone().sub(offset)

        // Update connector position
        updateConnectorValues(props.id, (c) => {
            c.position = newPosition
        }, true)

        // Debug visualization if needed
        //drawVector3Point(pos, scene, "red", 0.01, undefined, true)
        //drawVector3Point(newPosition, scene, "black", 0.01, undefined, true)

        updateTransforms()
    }

    useEffect(() => {
        if (connector.instanciated) {
            const markersRef = connectorInternalsRef.current.markers
            const initialMarker = getInitialMarker(connector, markersRef)
            if (savingRotation) {
                setSavingRotation(false)
            }
            if (updatingPosition) {
                setInitialPositionAndRotation(seeOBBdebugOnSelect)
                setUpdatingPosition(false)
                requestidleCallbackWithFallback(() => {
                    updateCollider()
                }, 100)
            } else if (attachedMarker.current
                && initialMarker.name === attachedMarker.current.name) {
                setMarkerDirAndPos(
                    attachedMarker.current!,
                    connector.rotation,
                    connector.position
                )
                initialMarker.rotateY(MathUtils.degToRad(180))
            } else {
                setPosAndRotWithNewInitialMarker(initialMarker)
            }
            //updateMarkerPositionMatrixInfo()
        }

        const bounder = getBoundingBoxFromBoxMesh()
        setBoundingBox(bounder)
        updateTransforms()
        if (getInvisibleParts().includes(connector.id)) {
            setVisibility(false)
        }
    }, [connector.position, connector.rotation,])


    const setConnectorPositionAndRotation = (meshName: string) => {
        const mesh = (importedGLTF as any).nodes[meshName] as Mesh
        const pos = mesh.position
        const rot = mesh.rotation
        ref.current?.position.set(pos.x, pos.y, pos.z)
        ref.current?.rotation.set(rot.x, rot.y, rot.z)
        updateTransforms()
    }


    const getExactSnapOnCreation = () => {
        const connectedMarkers = partConnectionsValue.map(c => c.partMarkerName)
        const connectedToPartIds = partConnectionsValue.map(c => c.destinationPartId)
        const newConnections = checkExactSnap(
            scene,
            connector,
            connectorInternalsRef.current,
            getFreeMarkers(connector, connectedMarkers),
            connectedToPartIds,
            getConnectionTypes()
        )
        return newConnections
    }

    const checkIfMarkerRefsExist = () => {
        if (!connectorInternalsRef.current || !connectorInternalsRef.current.markers) {
            return false
        }

        const markers = connectorInternalsRef.current.markers
        const markerValues = Object.values(markers)
        return markerValues.every(marker => marker instanceof Mesh)
    }

    const canRunSetupMarkers = (markerSetupRetriesCount: number) => {
        const maxRetriesExceeded = markerSetupRetriesCount > 10
        if (maxRetriesExceeded) {
            console.warn("Marker setup retries exceeded")
        }
        if (!checkIfMarkerRefsExist() && !maxRetriesExceeded) {
            console.warn("No marker refs found, scheduling retry...")
            return false
        }
        //console.log("Marker refs found, proceeding with setup...")
        return true
    }

    const initialSetup = () => {
        //migration for messed up rotation values
        if (Array.isArray(connector.rotation)) {
            console.warn(`oops connector ${connector.id} has array quaternion`, connector.rotation)
            const cleanRotation = fixIfQuaternionIsArray(connector.rotation)
            updateConnectorValues(props.id, (c) => {
                c.rotation = cleanRotation
            }, true)
            console.log(`fixed connector ${connector.id} rotation`, cleanRotation)
        }

        setConnectorPositionBySelectedMarker()
        if (!connector.loaded) {
            if (!wasDuplicated) {
                setIsSelected(true)
            }
            if ((getLocalStorage("autofocusMode") ?? getInAutofocusMode?.()) && !wasDuplicated) {
                fit(instancedMesh.current!, getMatrix()!, props.sceneRefs.current.cameraControls?.current)
            }
        }
        if (!wasDuplicated) {
            buildCollider()
        }
        let markerSetupRetriesCount = 0
        const checkMarkersAndSetup = () => {
            markerSetupRetriesCount++
            if (!canRunSetupMarkers(markerSetupRetriesCount)) {
                setTimeout(checkMarkersAndSetup, 100)
                return
            }

            let connectionsOtherParts: string[] = []

            if (!wasDuplicated) {
                if (!connector.loaded) {
                    const exactSnaps = getExactSnapOnCreation()
                    if (exactSnaps.length > 0) {
                        SoundHelper.playUnsnap()
                    }
                    connectionsOtherParts = exactSnaps.map(
                        c => getOtherPartOfTheConnection(c, connector.id))
                    updateTempConnectedMarkers(exactSnaps)

                    if (connector.rotationDisabled) {
                        setConnectorUI(CONNECTOR_UI.ACTIONS)
                    }
                }


                if (partConnectionsValue.length > 0) {
                    partConnectionsValue.forEach(connection => {
                        connectionsOtherParts.push(connection.destinationPartId)
                    })
                }
                handleCollisionOnCreation({
                    buildCollider,
                    updateCollider: (removePart?: () => void) => updateCollider(
                        removePart, connectionsOtherParts),
                    deleteCollider,
                    partType: PartTypeEnum.connector,
                    onRemove: handleConnectorDelete,
                    showModal,
                })
                setInAutofocusMode?.(true)
            }
            closestMarkersHook.updateMarkers(
                Object.values(connectorInternalsRef.current.markers)
            )
        }

        setTimeout(checkMarkersAndSetup, 100)

        setTimeout(() => {
            setRotationMarker()
            //if (!connector.markers[0].positionXYZ) {
            //    updateMarkerPositionMatrixInfo()
            //}
        }, 250)
    }

    useEffect(() => {
        if (connector.instanciated) {
            initialSetup()
        }
    }, [
        connector.id,
        connector.instanciated,
    ])

    const partConnectionsFunc = useRecoilCallback(
        ({ snapshot, }) =>
            () => {
                return snapshot.getLoadable(partConnections(connector.id)).contents
            },
        [],
    )

    const partConnectionsValueFresh = partConnectionsFunc()

    const getRotationMeshName = (meshName: string): string => {
        const numberMatch = meshName.match(/\d+/)
        if (numberMatch) {
            const number = numberMatch[0]
            return `rotation${number}`
        }
        return meshName
    }


    const generateRotationMarker = (markersMap: Marker) => {

        if (connector.specialRotationMarker) {
            //console.log("internal markers", connectorInternalsRef.current.markers)

            const rotationMarkerName = getRotationMeshName(markersMap.name)

            const models = importedGLTF.nodes.Scene.children as Mesh[]

            const rotationMarker = models.filter((m) => m.name === rotationMarkerName)[0]

            if (!rotationMarker) {
                return
            }


            const name = rotationMarker.name

            //console.log(rotationMarker, "rotationMarker")
            //console.log(connector.markers)

            const userData = {
                userDataType: "MarkerUserData",
                id: markersMap.id,
                partId: connector.id,
                partApiId: connector.apiTypeId,
                partType: PartTypeEnum.connector,
                sizeId: markersMap.sizeId,
                innerOuter: "rotation",
                iELength: markersMap.iELenght,
                specialRotationMarker: connector.specialRotationMarker,
            } as const


            if (rotationMarker) {
                return (
                    <mesh
                        userData={{ ...userData, markerName: name, }}
                        key={name}
                        name={name}
                        ref={(ref) => {
                            connectorInternalsRef.current.markers[name] = ref as Mesh
                        }}
                        scale={rotationMarker.scale}
                        position={rotationMarker.position}
                        rotation={rotationMarker.rotation}
                        geometry={rotationMarker.geometry}
                        dispose={null}
                    >
                        <meshBasicMaterial
                            alphaTest={0}
                            visible={false}
                            attach="material"
                            color={"#ff0808"}
                            side={DoubleSide}
                        />
                    </mesh>
                )
            }
        }


    }


    const generateMarker = (
        markersMap: Marker,
        userData: Omit<MarkerUserData, "markerName">,
        inner: boolean
    ) => {
        const models = importedGLTF.nodes.Scene.children as Mesh[]
        const name = inner ? markersMap.name : innerToOuter(markersMap.name)

        const sizes = getSizes()


        userData.innerOuter = isInner(name)
            ? ConnectorMarkerType.inner : ConnectorMarkerType.outer

        const markerMesh = models.filter((m) => m.name === name)[0]
        const markerIsAvailable = !partConnectionsValueFresh.some(
            ({ partMarkerName, }: any) =>
                innerToOuter(partMarkerName) === innerToOuter(markersMap.name)
        )
            && !tempConnectedMarkers.some(
                markerName => innerToOuter(markerName) === innerToOuter(markersMap.name))
        const tooltipSize = Object.values(sizes as ObjDictionary<SizeAPI>).find(s => s.id === markersMap.sizeId)?.size!
        if (markerMesh) {
            return (
                <mesh
                    userData={{ ...userData, markerName: name, }}
                    key={name}
                    name={name}
                    ref={(ref) => {
                        connectorInternalsRef.current.markers[name] = ref as Mesh
                    }}
                    scale={markerMesh.scale}
                    position={markerMesh.position}
                    rotation={markerMesh.rotation}
                    geometry={markerMesh.geometry}
                    dispose={null}
                >
                    <meshBasicMaterial
                        alphaTest={0}
                        visible={!!isAdmin}
                        wireframe={!!isAdmin}
                        attach="material"
                        color={"#ff0808"}
                        side={DoubleSide}
                    />
                    {(!inner && isSelected && markerIsAvailable)

                        && <NewPartButtonConnector
                            name={name}
                            id={connector.id}
                            markersMapName={markersMap.name}
                            connectionTypes={getConnectionTypes()}
                            connector={connector}
                            connectorInternalsRef={connectorInternalsRef}
                            connectorMeshRef={ref}
                            sizeMarker={tooltipSize}
                            setIsSelected={setIsSelected}
                            sceneRefs={props.sceneRefs}
                        />
                    }

                </mesh>
            )
        }
    }

    const generateMeshMarker = (
        markersMap: Marker,
        userData: Omit<MarkerUserData, "markerName">,
    ) => {
        const models = importedGLTF.nodes.Scene.children as Mesh[]
        const name = innerToMesh(markersMap.name)

        userData.innerOuter = ConnectorMarkerType.mesh

        const markerMesh = models.filter((m) => m.name === name)[0]
        if (markerMesh) {
            return (
                <mesh
                    userData={{ ...userData, markerName: name, }}
                    key={name}
                    name={name}
                    ref={(ref) => {
                        connectorInternalsRef.current.markers[name] = ref as Mesh
                    }}
                    scale={markerMesh.scale}
                    position={markerMesh.position}
                    // quaternion={markerMesh.quaternion}
                    rotation={markerMesh.rotation}
                    geometry={markerMesh.geometry}
                    dispose={null}
                >
                    <meshBasicMaterial
                        wireframe={false}
                        alphaTest={0}
                        opacity={0}
                        visible={true}
                        attach="material"
                        color={"#ff0808"}
                        transparent={true}
                        side={DoubleSide}
                    />
                </mesh>
            )
        }
    }


    useEffect(() => {
        if (correspondingMarkersNeedUpdate) {
            updateCorrespondigMeshMarkers()
            setCorrespondingMarkersNeedUpdate(false)
        }
    }, [correspondingMarkersNeedUpdate,])

    const updateCorrespondigMeshMarkers = () => {
        const markers = Object.values(connectorInternalsRef.current.markers)
        markers.forEach(marker => {
            if (!marker?.name) { return }

            if (marker.name.includes("mesh")) {
                // Store IDs of corresponding inner/outer markers
                const innerMarker = markers.find(m => m?.name === marker.name.replace("mesh", "inner"))
                const outerMarker = markers.find(m => m?.name === marker.name.replace("mesh", "outer"))

                if (innerMarker || outerMarker) {
                    // Calculate distances if markers exist
                    const meshPosition = new Vector3()
                    marker.getWorldPosition(meshPosition)

                    const distances = {
                        inner: innerMarker ? meshPosition.distanceTo(MeshUtils.copyWorldPosition(innerMarker)) : Infinity,
                        outer: outerMarker ? meshPosition.distanceTo(MeshUtils.copyWorldPosition(outerMarker)) : Infinity,
                    }

                    // Store the closest marker to use when something intersects with the mesh
                    if (distances.inner < distances.outer) {
                        marker.userData.closestInnerOrOuterMarkerMeshId = innerMarker?.id
                    } else if (distances.outer < distances.inner) {
                        marker.userData.closestInnerOrOuterMarkerMeshId = outerMarker?.id
                    }
                }

                // Generate slide points on mesh
                const { freePositions, } = getPointsArrayForSingleEnd(marker, 0.5)
                marker.userData.localSlidePoints = freePositions.map(point => ({
                    position: marker.worldToLocal(new Vector3().copy(point.position)),
                }))
            } else if (marker.name.includes("inner") || marker.name.includes("outer")) {
                // Store corresponding mesh ID on inner/outer markers
                const mesh = markers.find(m => m?.name === marker.name.replace(/^(inner|outer)/, "mesh"))
                if (mesh) { marker.userData.correspondingMeshMarkerId = mesh.id }
            }
        })
    }


    const getMarkers = useMemo(() => {
        setTimeout(() => {
            setCorrespondingMarkersNeedUpdate(true)
        }, 150)
        return connector.markers
            .map((markersMap) => {
                //console.log(markersMap.name, "markerMap")
                const userData = {
                    userDataType: "MarkerUserData",
                    id: markersMap.id,
                    partId: connector.id,
                    partApiId: connector.apiTypeId,
                    partType: PartTypeEnum.connector,
                    sizeId: markersMap.sizeId,
                    innerOuter: isInner(markersMap.name)
                        ? ConnectorMarkerType.inner : ConnectorMarkerType.outer,
                    iELength: markersMap.iELenght,
                    specialRotationMarker: connector.specialRotationMarker,
                } as const
                return (
                    <Fragment key={markersMap.name}>
                        {generateMarker(markersMap,
                            { ...userData, type: MarkerType.COLLISION, }, true)
                        }
                        {generateMarker(markersMap,
                            { ...userData, type: MarkerType.COLLISION, }, false)
                        }
                        {generateMeshMarker(markersMap,
                            { ...userData, type: MarkerType.COLLISION, },
                        )}
                        {generateRotationMarker(markersMap)}
                    </Fragment>
                )
            })
    }, [
        connector.markers,
        isSelected,
        connector.id,
        connector.rotationMarkerName,
        connector.rotation,
        connector.position,
        connector.apiTypeId,
        partConnectionsValue,
    ])



    const handleConnectorDelete = () => {
        props.handleDeletePart(connector.id)
        unregister(connector.id)
        delete props.partDataRef.current[connector.id]
        if (connectorInternalsRef.current.boundingBoxMesh) {
            connectorInternalsRef.current.boundingBoxMesh.removeFromParent()
        }
        if (connectorInternalsRef.current.centerObject) {
            connectorInternalsRef.current.centerObject.removeFromParent()
        }
    }

    const getBoundingBox = () => {
        const bounder = getBoundingBoxFromBoxMesh()
        if (showAllPartsIds) {
            visualizeBoundingBoxLabel(scene, bounder, connector.id, { color: "green", })
        }
        return bounder
    }

    const getBoundingBoxMesh = () => {
        return connectorInternalsRef.current.boundingBoxMesh
    }


    const findPartById = (apiTypeId: string) => {
        return getAllParts()?.parts.find((part: any) => part.id === apiTypeId)
    }

    const createPart = useNewPart()

    const duplicatePart = async (historyKey: string, position: Vector3, initialMarkerName?: string,
        markerOffset?: Vector3, rotation?: Quaternion) => {
        //console.log("duplicatePart", position, tube.id)
        const matchingPart = findPartById(connector.apiTypeId)
        if (!matchingPart) {
            console.warn("part no longer exists and cannot be duplicated", connector.apiTypeId)
            return
        }

        let rotationToUse = fixIfQuaternionIsArray(connector.rotation)
        if (rotation) {
            rotationToUse = fixIfQuaternionIsArray(rotation)
        }
        let newPart: any
        if (matchingPart && position) {

            newPart = {
                part: {
                    ...matchingPart,
                },
                posAndRot: {
                    inner: {
                        pos: position,
                        rot: rotationToUse || connector.rotation,
                    },
                    outer: {
                        pos: position,
                        rot: rotationToUse || connector.rotation,
                    },
                },
            }
        }
        if (matchingPart && !position) {
            newPart = {
                part: {
                    ...matchingPart,
                },
                posAndRot: {
                    inner: {
                        pos: new Vector3(0, 0, 0),
                        rot: rotationToUse || connector.rotation,
                    },
                    outer: {
                        pos: new Vector3(0, 0, 0),
                        rot: rotationToUse || connector.rotation,
                    },
                },
            }
        }
        newPart.duplicatedFrom = connector.id
        newPart.initialMarkerName = initialMarkerName || connector.initialMarkerName
        const { newPartId, } = await createPart(newPart, historyKey)
        return newPartId
    }

    const getPartInfo = () => {
        return connector
    }

    const updateInitialMarkerName = (initialMarkerName: string) => {
        updateConnectorValues(connector.id, (c) => {
            c.initialMarkerName = initialMarkerName
        },)
        setIsSelected(false)
    }

    const setUItoNone = () => {
        setConnectorUI(CONNECTOR_UI.NONE)
        setIsSelected(false)
    }

    const getMesh = (color?: string) => {
        const mesh = createStandAloneMeshFromInstance()
        if (mesh) {
            if (color) {
                (mesh.material as MeshBasicMaterial).color.set(color)
            }
            mesh.name = `delete_after_use_${connector.id}`
            //console.log(mesh, "mesh")
            return mesh
        }
    }


    const updatePositionAndRotation = async (position: Vector3, rotation: Quaternion, markerOffset?: Vector3, historyKey?: string): Promise<void> => {
        await new Promise((resolve, reject) => {
            // Check if already unmounted
            if (!mountedRef.current) {
                reject(new Error("Component unmounted"))
                return
            }

            requestAnimationFrame(() => {
                // Check again after frame
                if (!mountedRef.current) {
                    reject(new Error("Component unmounted"))
                    return
                }

                updateConnectorValues(connector.id, (c) => {
                    c.position = position
                    c.rotation = rotation
                }, false, historyKey)
                setUpdatingPosition(true)

                resolve(undefined)
            })
        }).catch(err => {
            // Handle cancellation - you can either:
            // 1. Silently ignore: do nothing
            // 2. Log for debugging
            console.debug("Connector position update cancelled:", err.message)
            // 3. Rethrow if you want upstream handlers to know
            // throw err
        })
    }


    const getAllMarkers = () => {
        return Object.values(connectorInternalsRef.current.markers)
    }

    const getFacingDirectionOfMarkers = (
        specificRotation?: { x: number, y: number, z: number, },
        debug?: boolean,
        addCloneToScene?: boolean,
        customLabelPrepend?: string,
    ) => {
        if (!attachedMarker.current) {
            return [{ originalName: "", friendlyName: "", },]
        }

        //console.log("friendlyNamesForMarkers", specificRotation, connector.id)

        const processMarkersWithRotation = (rotation?: { x: number, y: number, z: number, }, useRandomPosition?: boolean) => {
            const clone = attachedMarker.current!.clone(true)
            clone.userData.clone = true

            // Apply rotation
            if (rotation) {
                clone.rotation.set(
                    MathUtils.degToRad(rotation.x),
                    MathUtils.degToRad(rotation.y),
                    MathUtils.degToRad(rotation.z)
                )
                clone.updateMatrixWorld(true)
            }




            // Position clone slightly offset for visibility if debugging
            if (useRandomPosition) {
                const randomPosition = new Vector3(
                    debug ? 0.5 + Math.random() * 0.5 : 0,
                    debug ? 0.5 + Math.random() * 0.5 : 0,
                    debug ? 0.5 + Math.random() * 0.5 : 0
                )
                clone.position.copy(randomPosition)
            }

            if (addCloneToScene) {
                scene.add(clone)
            }

            const initialMarkerWorldQuat = new Quaternion()

            if (clone) {
                clone.rotateY(MathUtils.degToRad(180))
                clone.getWorldQuaternion(initialMarkerWorldQuat)
                clone.rotateY(MathUtils.degToRad(-180))
            }


            const markers = getAllMarkers()
            const markersInfo = connector.markers

            //console.log(markers, "markers in friendlyNamesForMarkers")
            const markerDirections = markers.map(marker => {
                if (!marker) { return null }

                const matchingCloneMarker = clone.getObjectByName(marker.name)
                if (!matchingCloneMarker) { return null }

                const markerInfo = markersInfo.find(m => m.name === marker.name)

                return {
                    originalName: marker.name,
                    sizeId: markerInfo?.sizeId,
                    typeId: markerInfo?.id,
                    facing: getNormalDirection(matchingCloneMarker, `c-${customLabelPrepend}-${connector.id.slice(-4)}`, scene, debug, setDebugNormals, setDebugLabels),
                    initialMarkerWorldQuat,
                }
            }).filter((item): item is { originalName: string, facing: string, initialMarkerWorldQuat: Quaternion, sizeId: string | undefined, typeId: string | undefined, } => item !== null)

            // Cleanup clone if not needed for debug
            if (!addCloneToScene) {
                clone.traverse((obj: Object3D) => {
                    if (obj instanceof Mesh) {
                        obj.geometry?.dispose()
                        if (Array.isArray(obj.material)) {
                            obj.material.forEach(mat => mat.dispose())
                        } else {
                            obj.material?.dispose()
                        }
                    }
                })
            }

            return markerDirections
        }
        const result = processMarkersWithRotation(specificRotation, !!specificRotation)
        return result
    }

    const updateMarkerPositionMatrixInfo = () => {
        const updatedMarkers = connector.markers.map(marker => {
            // Modify the marker name if it's a middle marker
            const markerName = marker.name

            // Find the corresponding object in the scene
            let sceneObject: Object3D | undefined
            scene.traverse((object) => {
                if (object.name === markerName && object.userData.partId === connector.id) {
                    sceneObject = object
                }
            })

            if (sceneObject && sceneObject.userData.partId === connector.id) {
                const worldPosition = new Vector3()
                sceneObject.getWorldPosition(worldPosition)
                return {
                    ...marker,
                    positionXYZ: {
                        x: worldPosition.x,
                        y: worldPosition.y,
                        z: worldPosition.z,
                    },
                }
            } else {
                console.warn(`Marker not found in scene: ${markerName}`)
                return marker
            }
        })
        updateConnectorValues(connector.id, (t) => {
            t.markers = updatedMarkers
        })
    }

    const updateMatrix = () => {
        const initialMarker = getInitialMarker(connector, connectorInternalsRef.current.markers)
        if (initialMarker) {
            initialMarker.updateWorldMatrix(true, true)
        }
        attachedMarker.current?.updateWorldMatrix(true, true)
        updateTransforms()
    }


    const visualDebugSetter = useCallback((
        content: string | string[],
        scope = "default",
        clear = false,
        objects?: Object3D[]
    ) => {
        setVisualDebug(prev => {
            const newEntry = {
                scope,
                contents: Array.isArray(content) ? content : [content,],
                objects: objects || [],
            }

            if (clear) { return { [scope]: newEntry, } }

            return {
                ...prev,
                [scope]: {
                    ...prev[scope],
                    contents: [...(prev[scope]?.contents || []), ...newEntry.contents,].slice(-20),
                    objects: [...(prev[scope]?.objects || []), ...newEntry.objects,],
                },
            }
        })
    }, [])

    const setVisibility = (visible: boolean) => {
        if (instancedMesh.current) {
            setVisibilityForInstance(visible)
        }
    }

    useRegisterComponent(props.id, {
        updateColor, originalColor, getColor,
        deletePart: handleConnectorDelete, getBoundingBox, getBoundingBoxMesh, duplicatePart,
        getPartInfo, updateInitialMarkerName, setUItoNone,
        getMesh, updatePositionAndRotation, setVisibility, setPositionWithMarker,
        getAllMarkers, changeVisibilityOnSpecificIndex, getVisibility: getVisibilityOnSpecificIndex,
        getFacingDirectionOfMarkers,
        updateMatrix, getEveryMarker: getAllMarkers,
        visualDebugSetter,
    })


    const handleSwap = () => {
        const partIdsList = getPartIdsList()
        handleConnectorSwap({
            connectorValues: connector,
            partConnectionsValue,
            connectionTypes: getConnectionTypes(),
            sizes: getSizes(),
            getMarkerData,
            setNewConnectionData,
            partIdsList,
            scene,
            connectorRef: instancedMesh.current!,
        })
    }



    const findRotationMarkerWithinRef = () => {
        const markers = connectorInternalsRef.current.markers
        //console.log("looking for this rotation marker name", connector.rotationMarkerName)
        const marker = Object.values(markers).find(marker => marker.name.toLowerCase().includes(connector.rotationMarkerName))
        //console.log(marker, "marker in findRotationMarkerWithinRef")
        return marker
    }

    const setRotationMarker = () => {
        const markersRef = connectorInternalsRef.current.markers
        let newRotationMarker

        if (connector.specialRotationMarker) {
            newRotationMarker = findRotationMarkerWithinRef()?.name
        } else {
            newRotationMarker = innerToOuter(connector.rotationMarkerName)
        }

        //console.log(newRotationMarker, "newRotationMarker rotationMarkerName")
        //console.log(connector.rotationMarkerName, "rotation marker before changing")

        if (connector.rotationMarkerName && connector.instanciated) {
            const markerMesh = markersRef[newRotationMarker!]
            if (markerMesh) {
                const pos = new Vector3()
                markerMesh.getWorldPosition(pos)
                const dir = MeshUtils.copyWorldQuaternion(markerMesh)
                let marker = markersRef[rotationMarker.current!]
                resetMarkerValues(attachedMarker.current!)
                detachEverythingFrom(attachedMarker.current!)
                rotationMarker.current = newRotationMarker
                marker = getRotationMarker(connector, markersRef)

                //console.log(marker, "marker in setRotationMarker")

                if (marker) {
                    attachEverythingTo(marker)
                    setMarkerDirAndPos(marker, dir, pos)

                    //have to use this because the marker is not the initial marker and the ref is not properly updated
                    setupOrientedBoundingBox(seeOBBdebugOnSelect, marker)
                }
            } else {
                console.warn(`Rotation marker "${newRotationMarker}" not found in markersRef`)
            }
        }
    }

    useEffect(() => {
        setRotationMarker()
        updateTransforms()
    }, [connector.rotationMarkerName,])

    //useEffect(() => {
    //console.log(connector.rotationMarkerName, "connector.rotationMarkerName")
    //}, [connector.rotationMarkerName,])


    const handleRotation = (value: number, rotationMarker?: Mesh) => {
        const markersRef = connectorInternalsRef.current.markers
        const marker = rotationMarker ? rotationMarker : getRotationMarker(connector, markersRef)
        const connections = getConnections()
        if (marker) {
            setRotation(
                value,
                marker,
                scene,
                connectorInternalsRef,
                connector,
                partConnectionsValue,
                setInternals,
                connections
            )
            const connectedMarkers = partConnectionsValue.map(c => c.partMarkerName)
            const connectedToPartIds = partConnectionsValue.map(c => c.destinationPartId)
            const newConnections = checkExactSnap(
                scene,
                connector,
                connectorInternalsRef.current,
                getFreeMarkers(connector, connectedMarkers),
                connectedToPartIds,
                getConnectionTypes()
            )

            updateTempConnectedMarkers(newConnections)

            if (newConnections.length > 0) {
                SoundHelper.playUnsnap()
            }
            updateTransforms()
        } else {
            message.warning("This part can't be rotated")
        }
    }

    const handleRotationStart = () => { }

    const rotationEnd = () => {
        const newConnections = checkExactSnap(
            scene,
            connector,
            connectorInternalsRef.current,
            getFreeMarkers(
                connector,
                partConnectionsValue.map(c => c.partMarkerName)
            ),
            partConnectionsValue.map(c => c.destinationPartId),
            getConnectionTypes()
        )
        updateCollider(
            undefined,
            newConnections.map(c => getOtherPartOfTheConnection(c, connector.id))
        )
    }

    const handleCheckExactSnap = () => {
        return checkExactSnap(
            scene,
            connector,
            connectorInternalsRef.current,
            getFreeMarkers(
                connector,
                partConnectionsValue.map(c => c.partMarkerName)
            ),
            partConnectionsValue.map(c => c.destinationPartId),
            getConnectionTypes()
        )
    }

    const transformMarkersToSides = (markers: { [key: string]: Mesh, }) => {
        const newMarkers: { [key: string]: { inner: Mesh, outer: Mesh, }, } = {}

        Object.keys(markers).forEach(key => {
            const index = key.match(/\d+/)?.[0]
            const type = key.includes("inner") ? "inner" : "outer"

            if (index) {
                if (!newMarkers[index]) {
                    newMarkers[index] = { inner: undefined as any, outer: undefined as any, }
                }
                newMarkers[index][type] = markers[key]
            }
        })

        return newMarkers
    }


    const transformedMarkers = transformMarkersToSides(connectorInternalsRef.current.markers)

    const transformMarkersForSlide = (markers: { [side: string]: Mesh, }) => {
        const transformedMarkers: { [side: string]: SegmentedTubeMarkers, } = {}

        Object.keys(markers).forEach(key => {
            const index = key.match(/\d+/)?.[0] // Extract the number from the key
            let type: keyof SegmentedTubeMarker = "outer" // Explicitly define type

            if (key.includes("inner")) {
                type = "inner"
            } else if (key.includes("mesh")) {
                type = "mesh"
            }

            if (index) {
                if (!transformedMarkers[index]) {
                    transformedMarkers[index] = { "0": { inner: undefined as any, outer: undefined as any, mesh: undefined as any, }, }
                }

                if (key in markers) {
                    transformedMarkers[index]["0"][type] = markers[key]
                }
            }
        })

        return transformedMarkers
    }

    const [markersForSlide, setMarkersForSlide,] = useState(() => {
        return transformMarkersForSlide(connectorInternalsRef.current.markers)
    })

    const markerCount = Object.keys(connectorInternalsRef.current.markers).length


    useEffect(() => {
        const markers = connectorInternalsRef.current.markers
        setMarkersForSlide(transformMarkersForSlide(markers))
    }, [markerCount,])

    useEffect(() => {
        if (markersForSlide && Object.keys(markersForSlide).length > 0) {
            slideRegister.updateSlide(markersForSlide, instancedMesh.current!, getIndex())
        }
    }, [markersForSlide,])

    const slideRegister = useRegisterSlide({
        id: props.id,
        sides: markersForSlide,
        startLength: 0,
        endLength: 0,
        segmentLength: 0,
        mergedMesh: instancedMesh.current!,
        type: PartTypeEnum.connector,
    })

    //there are some saves that happens after UI close
    //so we need to wait for these process to finish before hiding the UI
    // since the logic sits there

    //we also need a slight to change UI actions because
    //there are some useEffects that depend on that change to work


    const drawVector3onInitialMarkerPos = () => {
        const markers = getAllMarkers()
        const initialMarker = markers.find(marker => marker?.name === innerToOuter(connector.initialMarkerName))

        if (initialMarker) {
            const worldPosition = new Vector3()
            initialMarker.getWorldPosition(worldPosition)
            console.log("initalMakerPos world pos was", worldPosition, "connector.position was", connector.position, "using", initialMarker.name)
            const connectorPosition = new Vector3(connector.position.x, connector.position.y, connector.position.z)
            drawVector3Point(connectorPosition, scene, "red", 0.002, undefined, true)
        }
    }

    useEffect(() => {
        if (isSelected) {
            setUiVisible(true)
            if (isAdmin) {
                console.log(connector, "connector", connector.id)
                console.log(partConnectionsValue, "partConnectionsValue")
                console.log(getAllMarkers(), "getAllMarkers")
                drawVector3onInitialMarkerPos()
            }
            setConnectorUI(CONNECTOR_UI.NONE)
            const timer = setTimeout(() => {
                setConnectorUI(CONNECTOR_UI.ACTIONS)
            }, 150)
            return () => clearTimeout(timer)
        } else {
            // Delay hiding UI to allow for any final updates
            const timer = setTimeout(() => {
                setUiVisible(false)
            }, 300)
            return () => clearTimeout(timer)
        }
    }, [isSelected,])

    const updateRotationMarkerWhenConnectionsChange = () => {
        const newRotationMarker = getRotationMarkerUtil({
            snapshot: {
                getLoadable: (selector: any) => {
                    if (selector === partConnections(connector.id)) {
                        return {
                            state: "hasValue",
                            contents: partConnectionsValue,
                            getValue: () => partConnectionsValue,
                        }
                    } else {
                        return {
                            state: "hasValue",
                            contents: connector,
                            getValue: () => connector,
                        }
                    }
                },
            } as Snapshot,
            partId: connector.id,
        })

        /* if (connector.rotationMarkerName !== newRotationMarker && newRotationMarker !== "") {
            updateConnectorValues(connector.id, (c) => {
                c.rotationMarkerName = newRotationMarker
            }, true)
        }*/
    }

    useEffect(() => {
        updateRotationMarkerWhenConnectionsChange()
    }, [partConnectionsValue,])


    useEffect(() => {
        if (!debugNormals) {
            setDebugLabels([])
            // Remove arrow helpers
            /*if (scene.traverse) {
                scene.traverse((object) => {
                    if (object instanceof ArrowHelper) {
                        scene.remove(object)
                        object.dispose()
                    }
                })
            }*/
        }
    }, [debugNormals,])


    return React.useMemo(() => {
        logRenders && console.log(`Connector render ${props.id}`)
        return (
            <React.Fragment>
                {(viewInnerMarkers || viewOuterMarkers) && <MarkerHelpers
                    slideSide={transformedMarkers}
                    attachmentPoint={attachedMarker.current}
                    onDotClick={() => { }}
                    viewInnerMarkers={viewInnerMarkers}
                    viewOuterMarkers={viewOuterMarkers}
                />}
                <group ref={(r) => { ref.current = r as Group }}
                    userData={{ type: "COLLISION_CONNECTOR", }} />
                {getMarkers}
                {uiVisible && <ConnectorUI
                    instancedMesh={instancedMesh.current}
                    connector={connector}
                    connectorInternalsRef={connectorInternalsRef}
                    attachedMarker={attachedMarker}
                    attachEverythingTo={attachEverythingTo}
                    detachEverythingFrom={detachEverythingFrom}
                    handleMouseUp={rotationEnd}
                    handleRotationStart={handleRotationStart}
                    connectorUI={connectorUI}
                    setConnectorUI={setConnectorUI}
                    handleConnectorDelete={handleConnectorDelete}
                    handleConnectorSwap={handleSwap}
                    handleRotation={handleRotation}
                    handleConnectorState={saveRotation}
                    showSwap={canSwapConnector(connector, partConnectionsValue)}
                    buildCollider={buildCollider}
                    updateCollider={updateCollider}
                    checkExactSnap={handleCheckExactSnap}
                    updateTransforms={updateTransforms}
                    onUserRotationApplied={onUserRotationApplied}
                    boundingBox={boundingBox}
                    onDuplicate={props.sceneRefs.current.duplicateSelectedParts ?? (() => { })}
                    setIdsAsHighlightedAndTurnOnControl={props.sceneRefs.current.setIdsAsHighlightedAndTurnOnControl ?? (() => { })}
                    setIsSelected={setIsSelected}
                    setRotationMarker={setRotationMarker}
                    sceneRefs={props.sceneRefs}
                />}
                {debugNormals && debugLabels.map((label, index) => (
                    <Text
                        key={index}
                        material-depthTest={false}
                        material-depthWrite={false}
                        position={label.position}
                        fontSize={0.02}
                        color="black"
                        anchorX="center"
                        anchorY="middle"
                        userData={{ ignoreRaycast: true, }}
                    >
                        {label.text}
                    </Text>
                ))}
                {showDebugText && boundingBoxCenter && isSelected && (
                    <VisualDebug
                        partId={connector.id}
                        contents={visualDebug}
                        position={boundingBoxCenter}
                        visible={showDebugText}
                        isSelected={isSelected}
                    />
                )}
            </React.Fragment>
        )
    }, [
        connector,
        connectorUI,
        uiVisible,
        isSelected,
        partConnectionsValue,
    ])
}

export default Connector