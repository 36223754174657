/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines */


import { matchPath } from "react-router-dom"
import { Object3D, Group, MeshPhongMaterial, Mesh, DoubleSide, ArrowHelper, Vector3, Scene, Camera, WebGLRenderer, Box3, MeshBasicMaterial, BoxGeometry } from "three"
import CameraControls from "camera-controls"
import { projectTo2D } from "../../builder/providers/multiselectProvider/MultiSelectUtils"
import { PolygonUtils } from "../../builder/utils/MeshUtils"
export type ObjDictionary<T> = {
  [a: string]: T,
}


export const requestidleCallbackWithFallback = (callback: () => void, timeout: number) => {
  if (typeof requestIdleCallback === "undefined") {
    setTimeout(callback, timeout)
  } else {
    requestIdleCallback(callback)
  }
}
export const getLocalStorage = (key: string) => {
  const savedAutofocus = localStorage.getItem(key)
  // Only use savedAutofocus if it's explicitly "true" or "false"
  const parsedAutofocus = savedAutofocus === "true" || savedAutofocus === "false"
    ? JSON.parse(savedAutofocus)
    : null
  return parsedAutofocus
}

export const calcWorldSizeWithTargetPixelSize = (
  camera: CameraControls,
  gl: WebGLRenderer,
  scene: Scene,
  isMobile: boolean,
  box: Box3,  // Use the actual Box3 instead of creating one
  ctxRef: React.RefObject<CanvasRenderingContext2D>,
  options: {
    targetPixelSize: number,
    debug?: {
      enabled: boolean,
      timeout?: number,
    },
  }
) => {
  const minResult = 0.02
  const defaultTargetSize = isMobile ? 25 : 40
  const targetPixelSize = options?.targetPixelSize ?? defaultTargetSize
  const boxSize = new Vector3()
  box.getSize(boxSize)
  const originalSize = Math.max(boxSize.x, boxSize.y, boxSize.z)  // Use largest dimension

  if (!camera) { return { result: originalSize, scaleFactor: 1, targetPixelSize, } }

  // Create debug mesh using the actual box
  if (options.debug?.enabled) {
    const geometry = new BoxGeometry(boxSize.x, boxSize.y, boxSize.z)
    const material = new MeshBasicMaterial({ color: "purple", opacity: 0.5, transparent: true, })
    const boxMesh = new Mesh(geometry, material)
    boxMesh.position.copy(box.getCenter(new Vector3()))
    scene.attach(boxMesh)

    setTimeout(() => {
      scene.remove(boxMesh)
    }, options.debug.timeout || 5000)
  }

  // Get the box corners from the actual box
  const corners = [
    new Vector3(box.min.x, box.min.y, box.min.z),
    new Vector3(box.max.x, box.min.y, box.min.z),
    new Vector3(box.min.x, box.max.y, box.min.z),
    new Vector3(box.max.x, box.max.y, box.min.z),
    new Vector3(box.min.x, box.min.y, box.max.z),
    new Vector3(box.max.x, box.min.y, box.max.z),
    new Vector3(box.min.x, box.max.y, box.max.z),
    new Vector3(box.max.x, box.max.y, box.max.z),
  ]

  // Project all corners to 2D
  const points2D = corners.map(corner => projectTo2D(corner, camera.camera, gl))

  // Convert to rectangle using existing polygon utils
  const polygon = PolygonUtils.createPolygon(points2D)
  const rect = PolygonUtils.simplifyPolygonToRectangle(points2D)

  // Debug drawing with cleanup
  if (options?.debug?.enabled && ctxRef.current) {
    PolygonUtils.debugDrawPolygon(ctxRef.current, polygon, {
      fillStyle: "red",
      strokeStyle: "red",
      centerColor: "red",
      scale: 1,
      offsetX: 0,
      offsetY: 0,
    })

    // Clear debug drawing after timeout
    const timeout = options.debug.timeout || 2000
    setTimeout(() => {
      if (ctxRef.current) {
        const canvas = ctxRef.current.canvas
        ctxRef.current.clearRect(0, 0, canvas.width, canvas.height)
      }
    }, timeout)
  }

  // Find the longer side of the 2D rectangle
  const width = Math.abs(rect[1].x - rect[0].x)
  const height = Math.abs(rect[2].y - rect[1].y)

  if (options?.debug?.enabled) {
    console.log(width, height, "width, height")
  }

  //need to divide by two because we need the radius of the circle
  const currentSize = Math.max(width, height, 1) / 2

  // Calculate scale factor needed to reach target size
  const scaleFactor = targetPixelSize / currentSize

  if (options?.debug?.enabled) {
    console.log(scaleFactor, "scaleFactor")
  }

  // Apply scale factor to original size
  const result = Math.max(originalSize, originalSize * scaleFactor)

  //if infinity or 0 return original size
  if (result === Infinity || result === 0 || isNaN(result)) {
    return { result: minResult, scaleFactor: 1, targetPixelSize: targetPixelSize, }
  }

  return { result, rect, scaleFactor, targetPixelSize, }
}

export const getRandomId = () => {
  return crypto.randomUUID?.() || crypto.getRandomValues(new Uint32Array(4)).join("-")
}

export const toDictionary = <T>(data: T[], getKey: (o: T) => string) => {
  return data.reduce<ObjDictionary<T>>((acc, curr) => {
    const key = getKey(curr)
    if (key) {
      acc[key] = curr
    }
    return acc
  }, {})
}

export type GroupedBy<T> = {
  key: any,
  values: T[],
};

type ObjGroup<T> = Record<string, T[]>;

export const GroupByList = <T>(data: T[], getKey: (o: T) => string | string[]) => {
  const order: string[] = []
  const dict = data.reduce<ObjGroup<T>>((acc, curr) => {
    const setData = (key: string) => {
      if (acc[key]) {
        acc[key].push(curr)
      } else {
        acc[key] = [curr,]
        order.push(key)
      }
    }
    const key = getKey(curr)
    if (key) {
      if (typeof key === "object") {
        key.forEach(k => setData(k))
      } else {
        setData(key)
      }
    }
    return acc
  }, {})

  return order.map((i) => ({
    key: i,
    values: dict[i],
  }))
}

export type GroupedData<T> = {
  key: string,
  values: T[] | GroupedData<T>[],
}

export const recursiveGroupByList = <T>(
  data: T[], getKeys: ((o: T) => string | string[])[]
) => {
  const [firstGetKey, ...restOfGetKeys] = getKeys
  let groupedData: GroupedData<T>[] = GroupByList(data, firstGetKey)
  if (restOfGetKeys.length > 0) {
    groupedData = groupedData.map(gd => {
      return { key: gd.key, values: recursiveGroupByList(gd.values as T[], restOfGetKeys), }
    })
  }
  return groupedData
}

export const filterWithValue = <T>(arr: (T | null | undefined)[]): T[] => {
  return arr.filter(t => t !== null && t !== undefined) as T[]
}

export const compare = <T>(getKey: (a: T) => string | number) => (a: T, b: T) => {
  if (getKey(a) < getKey(b)) {
    return -1
  }
  if (getKey(a) > getKey(b)) {
    return 1
  }
  return 0
}

export const extractNumberFromString = (value: string | number): number => {
  // If already a number, return it
  if (typeof value === "number") { return value }

  // Remove any quotes and trim whitespace
  const cleaned = value.toString().replace(/["']/g, "")
    .trim()

  // Extract number pattern (handles decimals and fractions)
  const match = cleaned.match(/([\d.\/]+)/)
  if (!match) { return 0 }

  const numberStr = match[1]

  // Handle fractions (e.g., "3/4")
  if (numberStr.includes("/")) {
    const [num, denom,] = numberStr.split("/")
    return Number(num) / Number(denom)
  }

  // Return plain number
  return Number(numberStr)
}

export const mapAsync = async <T, R>(items: T[], f: (p: T) => Promise<R>): Promise<R[]> => {
  const ret: R[] = []
  for (let index = 0; index < items.length; index++) {
    const element = items[index]
    ret.push(await f(element))
  }

  return ret
}

export const flatten = <T>(i: T[][]) => {
  return i.reduce((ar, val) => {
    return ar.concat(val)
  }, [])
}

interface CloneOptions {
  wireframe?: boolean;
  transparent?: boolean;
  opacity?: number;
  color?: number | string;
}



const applyMaterialToMesh = (mesh: Mesh, options: CloneOptions) => {

  const {
    wireframe = true,
    transparent = false,
    opacity = 0.5,
    color = 0xe6f3ff,
  } = options

  const newMaterial = new MeshPhongMaterial({
    color: color,
    wireframe: wireframe,
    transparent: transparent || wireframe,
    opacity: wireframe ? 0.8 : opacity,
    side: DoubleSide,
    depthWrite: false,
    depthTest: false,
    polygonOffset: wireframe,
    polygonOffsetFactor: 1,
    polygonOffsetUnits: 1,
    visible: true,
    emissive: color,
    emissiveIntensity: 0.5,
    specular: color,
    shininess: 30,
  })

  // Handle arrays of materials
  if (Array.isArray(mesh.material)) {
    mesh.material = mesh.material.map(() => newMaterial.clone())
  } else {
    mesh.material = newMaterial
  }

  mesh.visible = true
  mesh.frustumCulled = false
}

const processObject = (obj: Object3D, options: CloneOptions) => {
  if (obj instanceof Mesh && obj.name.toLowerCase().includes("boundingbox")) {
    // Set visibility explicitly for boundingBox meshes
    obj.visible = true
    applyMaterialToMesh(obj, {
      ...options,
      wireframe: true,
      transparent: true,
      opacity: 0.8,
    })
  } else {
    // Hide non-boundingBox objects
    obj.visible = false
  }

  // Recursively process all children
  obj.children.forEach(child => {
    processObject(child, options)
  })
}

export const createDeepAnchorClone = (
  sourceAnchor: Object3D,
  options: CloneOptions = {}
): Object3D => {
  // Create new root object
  const clonedAnchor = new Object3D()
  clonedAnchor.name = sourceAnchor.name

  // Clone only the first level children (Groups)
  sourceAnchor.children.forEach((group) => {
    if (group instanceof Group) {
      const clonedGroup = group.clone()

      // Process materials and visibility for the group's children
      clonedGroup.traverse((obj) => {
        if (obj instanceof Mesh) {
          processObject(obj, options)
        }
      })

      clonedAnchor.add(clonedGroup)
    }
  })

  // Copy transform
  clonedAnchor.position.copy(sourceAnchor.position)
  clonedAnchor.quaternion.copy(sourceAnchor.quaternion)
  clonedAnchor.scale.copy(sourceAnchor.scale)

  return clonedAnchor
}
const URL_QUERIES_TO_PRESERVE = ["storeIsOpen",]

export const getUrlWithQueriesToPreserve = (url: string) => {
  const urlObject = new URL(window.location.href)
  const newUrlObject = new URL(url, window.location.origin)
  const queries = urlObject.searchParams
  URL_QUERIES_TO_PRESERVE.forEach(q => {
    const queryValue = queries.get(q)
    if (queryValue) {
      newUrlObject.searchParams.set(q, queryValue)
    }
  })
  return newUrlObject.pathname + newUrlObject.search
}

export const getUserDesignIdsFromUrl = () => {
  const match = matchPath("/design/:userId/:designId", window.location.pathname)

  if (match) {
    return {
      userId: match.params.userId,
      designId: match.params.designId,
    }
  }

  return undefined
}

export const arrayRange = (start: number, stop: number, step: number) => Array.from(
  { length: (stop - start) / step + 1, },
  (value, index) => start + index * step
)

export const SELECTED_PART_COLOR = "#00a2ff"

export const createAndUpdateArrow = (
  scene: Scene,
  sourceObject: Object3D,
  targetObject: Object3D,
  arrowRef: { current: ArrowHelper | null, },
  animationFrameRef: { current: number | null, },
  color = 0x0000ff,
  headLength = 0.03,
  headWidth = 0.01,
  lineWidth = 0.01
) => {
  // Create arrow if it doesn't exist
  if (!arrowRef.current) {
    arrowRef.current = new ArrowHelper(
      new Vector3(),
      new Vector3(),
      1,
      color,
      headLength,
      headWidth
    )

    // Set depth properties for line
    if (arrowRef.current.line && arrowRef.current.line.material) {
      const material = Array.isArray(arrowRef.current.line.material)
        ? arrowRef.current.line.material[0]
        : arrowRef.current.line.material
      material.depthTest = false
      material.depthWrite = false
    }

    // Set depth properties for cone
    if (arrowRef.current.cone && arrowRef.current.cone.material) {
      const material = Array.isArray(arrowRef.current.cone.material)
        ? arrowRef.current.cone.material[0]
        : arrowRef.current.cone.material
      material.depthTest = false
      material.depthWrite = false
    }

    scene.add(arrowRef.current)
  }

  const updatePosition = () => {
    if (!arrowRef.current) { return }

    const startPos = new Vector3()
    const endPos = new Vector3()

    sourceObject.getWorldPosition(startPos)
    targetObject.getWorldPosition(endPos)

    const direction = endPos.clone().sub(startPos)
      .normalize()
    const length = endPos.distanceTo(startPos)

    arrowRef.current.position.copy(startPos)
    arrowRef.current.setDirection(direction)
    arrowRef.current.setLength(length, headLength, headWidth)

    animationFrameRef.current = requestAnimationFrame(updatePosition)
  }

  animationFrameRef.current = requestAnimationFrame(updatePosition)
}

export const createAndUpdateCanvasArrow = (
  scene: Scene,
  source2DPosition: { x: number, y: number, },
  target2DPosition: { x: number, y: number, },
  ctx: CanvasRenderingContext2D,
  animationFrameRef: { current: number | null, },
  color = "#0000ff",
  headLength = 10,
  headWidth = 8,
  lineWidth = 1,
  outlineColor = "blue",
  outlineWidth = 2
) => {
  // Cancel any existing animation frame first
  if (animationFrameRef.current !== null) {
    cancelAnimationFrame(animationFrameRef.current)
    animationFrameRef.current = null
  }

  const updatePosition = () => {
    const width = ctx.canvas.width
    const height = ctx.canvas.height

    // Clear previous frame
    ctx.clearRect(0, 0, width, height)

    // Set global alpha to ensure full opacity
    ctx.globalAlpha = 1.0

    // Draw the line outline
    ctx.beginPath()
    ctx.moveTo(source2DPosition.x, source2DPosition.y)
    ctx.lineTo(target2DPosition.x, target2DPosition.y)
    ctx.strokeStyle = outlineColor
    ctx.lineWidth = lineWidth + (outlineWidth * 2)
    ctx.stroke()

    // Draw the main line
    ctx.beginPath()
    ctx.moveTo(source2DPosition.x, source2DPosition.y)
    ctx.lineTo(target2DPosition.x, target2DPosition.y)
    ctx.strokeStyle = color
    ctx.lineWidth = lineWidth
    ctx.stroke()

    // Calculate arrow head points
    const angle = Math.atan2(target2DPosition.y - source2DPosition.y, target2DPosition.x - source2DPosition.x)
    const point1 = {
      x: target2DPosition.x - headLength * Math.cos(angle - Math.PI / 6),
      y: target2DPosition.y - headLength * Math.sin(angle - Math.PI / 6),
    }
    const point2 = {
      x: target2DPosition.x - headLength * Math.cos(angle + Math.PI / 6),
      y: target2DPosition.y - headLength * Math.sin(angle + Math.PI / 6),
    }

    // Draw arrow head fill first
    ctx.beginPath()
    ctx.moveTo(target2DPosition.x, target2DPosition.y)
    ctx.lineTo(point1.x, point1.y)
    ctx.lineTo(point2.x, point2.y)
    ctx.closePath()
    ctx.fillStyle = color
    ctx.fill()

    // Draw arrow head outline last
    ctx.strokeStyle = outlineColor
    ctx.lineWidth = outlineWidth
    ctx.stroke()

    // Store the animation frame ID
    animationFrameRef.current = requestAnimationFrame(updatePosition)
  }

  // Start the new animation
  updatePosition()
}

export const cleanupCanvasArrow = (
  ctx: CanvasRenderingContext2D,
  animationFrameRef: { current: number | null, }
) => {
  // Make sure we cancel the animation frame first
  if (animationFrameRef.current !== null) {
    cancelAnimationFrame(animationFrameRef.current)
    animationFrameRef.current = null
  }

  // Then clear the canvas
  const canvas = ctx.canvas
  ctx.clearRect(0, 0, canvas.width, canvas.height)
}

export const cleanupArrow = (
  scene: Scene,
  arrowRef: { current: ArrowHelper | null, },
  animationFrameRef: { current: number | null, }
) => {
  if (animationFrameRef.current) {
    cancelAnimationFrame(animationFrameRef.current)
    animationFrameRef.current = null
  }

  if (arrowRef.current) {
    arrowRef.current.removeFromParent()
    arrowRef.current = null
  }
}

// This would be a completely different implementation using 2D canvas
export const create2DArrow = (
  sourceObject: Object3D,
  targetObject: Object3D,
  camera: Camera,
  canvas: HTMLCanvasElement,
  color = "#0000ff",
  headLength = 10, // in pixels
  headWidth = 5    // in pixels
) => {
  const ctx = canvas.getContext("2d")
  if (!ctx) { return }

  const updatePosition = () => {
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    // Get 2D screen positions
    const startPos = new Vector3()
    const endPos = new Vector3()
    sourceObject.getWorldPosition(startPos)
    targetObject.getWorldPosition(endPos)

    const start2D = startPos.project(camera)
    const end2D = endPos.project(camera)

    // Convert to screen coordinates
    const startScreen = {
      x: (start2D.x + 1) * canvas.width / 2,
      y: (-start2D.y + 1) * canvas.height / 2,
    }
    const endScreen = {
      x: (end2D.x + 1) * canvas.width / 2,
      y: (-end2D.y + 1) * canvas.height / 2,
    }

    // Draw arrow
    ctx.beginPath()
    ctx.moveTo(startScreen.x, startScreen.y)
    ctx.lineTo(endScreen.x, endScreen.y)
    ctx.strokeStyle = color
    ctx.stroke()

    // Draw arrow head
    // ... arrow head drawing code ...
  }

  return updatePosition
}