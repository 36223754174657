import React from "react"
import ActionButton from "../../../common/components/ActionButton"
import { ActionButtonContainer } from
  "../../components/main/DesignScreen/scene/part/parts/segmentedTube/ui/ActionPanelStyles"
import { ReactComponent as TrashIcon }
  from "../../../common/assets/icons/Trash.svg"

interface Props {
  activeTab: "move" | "rotate" | "duplicate" | "delete" | "resize" | "nx" | null;
  onMoveClick: () => void;
  onRotateClick: () => void;
  onDuplicateClick: () => void;
  onDeleteClick: () => void;
  onClearClick: () => void;
  onNxClick: () => void;
  onResizeClick: () => void;
  isAdmin?: boolean;
  setShowGroupNamingModal: (show: boolean) => void;
}


const ActionsUI: React.FC<Props> = ({
  activeTab,
  onMoveClick,
  onRotateClick,
  onDuplicateClick,
  onClearClick,
  onDeleteClick,
  onResizeClick,
  onNxClick,
  isAdmin,
  setShowGroupNamingModal,
}) => {
  return (
    <ActionButtonContainer>
      <ActionButton selected={activeTab === "move"} onClick={onMoveClick}>
        Move
      </ActionButton>
      <ActionButton selected={activeTab === "rotate"} onClick={onRotateClick}>
        Rotate
      </ActionButton>
      <ActionButton onClick={onDuplicateClick}>2x</ActionButton>
      <ActionButton selected={activeTab === "nx"} onClick={onNxClick}>Nx</ActionButton>
      {/*this was called resize before but it doesn't actually resize so we changed it to length*/}
      <ActionButton selected={activeTab === "resize"} onClick={onResizeClick}>
        Length
      </ActionButton>
      <ActionButton onClick={() => setShowGroupNamingModal(true)}>
        {"Group"}
      </ActionButton>
      <ActionButton onClick={onDeleteClick} style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: 0,
        paddingRight: 0,
      }}>
        <TrashIcon />
      </ActionButton>
    </ActionButtonContainer>
  )
}

export default ActionsUI