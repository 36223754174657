import { Box3, Object3D, Scene } from "three"
import { DragMesh } from "./types"
import { CameraControls } from "camera-controls/dist/CameraControls"
function updateCamera(
    dragMesh: DragMesh,
    fitAndLookAtBox: any,
    scene: Scene,
    slideSide: any,
    useCameraUp: boolean,
    viewHelpers: boolean,
    useFit: boolean,
    offsets: { y: number, x: number, z: number, },
    draggableObjectCenter: Object3D,
    boundingBox: Box3 | null,
    cameraControls?: CameraControls,
) {
    // we use the middle slide side as a reference marker to face the camera
    const slideSideKeys = Object.keys(slideSide)
    const middleIndex = Math.floor(slideSideKeys.length / 2)
    const middleSlideSideKey = slideSideKeys[middleIndex]
    const closestSlideSide = slideSide[middleSlideSideKey]

    fitAndLookAtBox(
        dragMesh.boundingBox,
        dragMesh.center,
        closestSlideSide.outer,
        scene,
        viewHelpers,
        offsets,
        draggableObjectCenter,
        boundingBox,
        cameraControls,
    )
}

export {
    updateCamera,
}