/* eslint-disable max-len */
import { atom, selector, selectorFamily } from "recoil"
import { GenericPartState, PartIdType } from "../../utils/Types"
import { sceneDefault, SceneType, UnitType, GroupType } from "./types"
import { Box3, Mesh, Vector3 } from "three"
import { PartTypeAPI } from "../../../common/api/Types"
import { CompatiblePart } from "../../components/main/DesignScreen/mainLayout/PartsModal/partListModal/partLists/SamePartsPanel"
import { BoxMeshWithUserData } from "../../utils/PartUtils"

export const sceneAtom = atom<SceneType>({
    key: "sceneAtom",
    default: sceneDefault,
})

export const scenePartsSelector = selector<Record<string, GenericPartState>>({
    key: "sceneParts",
    get: ({ get, }) => {
        return get(sceneAtom).parts
    },
})

export const groupsSelector = selector<GroupType[]>({
    key: "groupsSelector",
    get: ({ get, }) => {
        return get(sceneAtom).groups || []
    },
})

interface SizeStringType {
    readout: string;
    width: number;
    height: number;
    depth: number;
    unit: string;
    imperial?: {
        width: number,
        height: number,
        depth: number,
        readout: string,
    };
    metric?: {
        width: number,
        height: number,
        depth: number,
        readout: string,
    };
}

export const sizeStringAtom = atom<SizeStringType>({
    key: "sizeString",
    default: {
        readout: "",
        width: 0,
        height: 0,
        depth: 0,
        unit: "",
        imperial: {
            width: 0,
            height: 0,
            depth: 0,
            readout: "",
        },
        metric: {
            width: 0,
            height: 0,
            depth: 0,
            readout: "",
        },
    },
})

export const partsIdsSelector = selector<PartIdType[]>({
    key: "partsIdsSelector",
    get: ({ get, }) => {
        return get(sceneAtom).partsIds
    },
})

export const partsSelector
    = selectorFamily<GenericPartState | undefined, { id: string, } | undefined | null>({
        key: "partsSelector",
        get: (params) => ({ get, }) => {
            if (params) {
                return get(sceneAtom).parts[params.id]
            } else {
                return undefined
            }
        },
    })

export const unitSelector = selector<UnitType>({
    key: "unitSelector",
    get: ({ get, }) => {
        return get(sceneAtom).unit || "in"
    },
})

export const showCameraControlsState = atom<boolean>({
    key: "showCameraControlsState",
    default: false,
})

export const combinedBoxAtomUserSelection = atom<BoxMeshWithUserData<Mesh> | null>({
    key: "combinedBoxUserSelection",
    default: null,
})

export interface FirestoreCacheState {
    needsRefresh: boolean;
    refreshCount: number;
}

export const firestoreCacheAtom = atom<FirestoreCacheState>({
    key: "firestoreCacheAtom",
    default: {
        needsRefresh: false,
        refreshCount: 0,
    },
})

export const combinedBoxAtom = atom<BoxMeshWithUserData<Mesh> | null>({
    key: "combinedBox",
    default: null,
})

export interface BoundingBoxState {
    box: Box3;
    show: boolean;
}

export const boundingBoxAtom = atom<BoundingBoxState>({
    key: "boundingBox",
    default: { box: new Box3(), show: false, },
})

export interface RulerState {
    show: boolean;
    points: Vector3[];
}

export const rulerAtom = atom<RulerState>({
    key: "ruler",
    default: { show: false, points: [], },
})

export interface RulerPointInfo {
    partId: string;
    pointId: string;
}

export interface RulerRelationship {
    point1: RulerPointInfo;
    point2: RulerPointInfo;
    color: string;
}

export const rulerRelationshipsAtom = atom<RulerRelationship[]>({
    key: "rulerRelationships",
    default: [],
})

export const emptyPositionForNewPartAtom = atom<Vector3>({
    key: "emptyPositionForNewPart",
    default: new Vector3(0, 0, 0,),
})

export const isSaveInProgressAtom = atom<boolean>({
    key: "isSaveInProgress",
    default: false,
})

export const showGroupingAtom = atom<boolean>({
    key: "showGrouping",
    default: true,
})

export interface SwapJob {
    sourcePartIds: string[];
    sourcePartApiTypeId: string;
    targetPart: CompatiblePart;
    status: "pending" | "completed" | "failed";
    targetPartIds?: string[];
}

export const swapJobsAtom = atom<SwapJob[]>({
    key: "swapJobsAtom",
    default: [],
})

export const partsByApiTypeSelector = selectorFamily<GenericPartState[], string>({
    key: "partsByApiTypeSelector",
    get: (apiTypeId) => ({ get, }) => {
        const parts = get(scenePartsSelector)

        return Object.values(parts).filter(part =>
            part.apiTypeId === apiTypeId
        )
    },
})

export const multiSelectionAtom = atom<string[]>({
    key: "multiSelectionAtom",
    default: [],
})

// Add a new atom to track group visibility without persisting it in the scene
export const groupVisibilityAtom = atom<Record<string, boolean>>({
    key: "groupVisibilityAtom",
    default: {},
})

// Add a new atom to track which groups are in "only view" mode
export const groupOnlyViewAtom = atom<Record<string, boolean>>({
    key: "groupOnlyViewAtom",
    default: {},
})

export const partVisibilityAtom = atom<string[]>({
    key: "partVisibility",
    default: [],
})

export type FinishedLoadingState = "loading" | "done" | "camera-adjusted" | "";

export const finishedLoadingAtom = atom<FinishedLoadingState>({
    key: "finishedLoadingAtom",
    default: "",
})
