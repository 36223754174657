/* eslint-disable max-statements */
/* eslint-disable no-negated-condition */
/* eslint-disable max-len */
/* eslint-disable max-lines */

import { CameraControls } from "../cameraProvider/CameraControls"
import { Object3D, Vector3, Mesh, Camera, WebGLRenderer, Box3, Box3Helper, Color, Scene, Material, Plane, Vector2, ArrowHelper, PlaneHelper, SphereGeometry, MeshBasicMaterial } from "three"
import { PolygonUtils } from "../../utils/MeshUtils"
import { PartDataWithMarkerInfo } from "./MultiSelectProvider"
import { MutableRefObject } from "react"
import { Raycaster } from "three"
import { visualizeSingleFace } from "../../utils/PartUtils"
import { LineBasicMaterial, BufferGeometry, Line } from "three"

export const clearCanvas = (ctxRef: React.RefObject<CanvasRenderingContext2D>, overlayCanvasRef: React.RefObject<HTMLCanvasElement>) => {
    if (ctxRef.current && overlayCanvasRef.current) {
        ctxRef.current.clearRect(0, 0, overlayCanvasRef.current.width, overlayCanvasRef.current.height)
    }
}

interface LineOptions {
    lineColor?: number;
    lineWidth?: number;
    startSphereColor?: number;
    endSphereColor?: number;
    startSphereRadius?: number;
    endSphereRadius?: number;
    showStartSphere?: boolean;
    showEndSphere?: boolean;
    depthTest?: boolean;
}

export const createLine = (
    start: Vector3,
    end: Vector3,
    options: LineOptions = {}
): Object3D => {
    const {
        lineColor = 0x0000ff,
        lineWidth = 1,
        startSphereColor = "blue",
        endSphereColor = 0x00bfff,
        startSphereRadius = 0.05,
        endSphereRadius = 0.05,
        showStartSphere = true,
        showEndSphere = true,
        depthTest = false,
    } = options

    const container = new Object3D()
    container.name = "line-with-endpoints"

    // Create the line
    const material = new LineBasicMaterial({
        color: lineColor,
        linewidth: lineWidth,
        depthTest,
    })

    const geometry = new BufferGeometry().setFromPoints([start, end,])
    const line = new Line(geometry, material)
    line.name = "connection-line"
    container.add(line)

    // Create spheres at endpoints if requested
    if (showStartSphere) {
        const startSphere = new Mesh(
            new SphereGeometry(startSphereRadius),
            new MeshBasicMaterial({
                color: startSphereColor, depthTest: false, depthWrite: false, transparent: true,
                opacity: 0.5,
            })
        )
        startSphere.position.copy(start)
        startSphere.name = "start-sphere"
        container.add(startSphere)
    }

    if (showEndSphere) {
        const endSphere = new Mesh(
            new SphereGeometry(endSphereRadius),
            new MeshBasicMaterial({
                color: endSphereColor, depthTest: false, depthWrite: false, transparent: true,
                opacity: 0.5,
            })
        )
        endSphere.position.copy(end)
        endSphere.name = "end-sphere"
        container.add(endSphere)
    }

    return container
}

/*const checkForEligbleAlignmentsAndColorThem = (combinedBoxRef: MutableRefObject<Object3D>, alignmentMatchesRef: MutableRefObject<{ partId: string, face: { points: { id: string, position: Vector3, }[], direction: Vector3, }, }[]>, debugVisualsRef: MutableRefObject<Object3D[]>, scene: Scene, debug = true) => {
    //not using this yet
    console.log("checkForEligbleAlignmentsAndColorThem")
    if (!combinedBoxRef.current) {
        console.warn("combinedBoxRef.current is null")
        return
    }

    alignmentMatchesRef.current = []

    // Cleanup previous debug visualization
    if (debug && debugVisualsRef.current.length > 0) {
        debugVisualsRef.current.forEach(obj => {
            scene.remove(obj)
            if (obj instanceof Mesh) {
                obj.geometry.dispose()
                obj.material.dispose()
            }
        })
        debugVisualsRef.current = []
    }



    const movingBoxFaces = combinedBoxRef.current.userData.getAllFaces()

    console.log(directionalFacesOfNotHighlightedPartsRef.current, "starting run directionalFacesOfNotHighlightedPartsRef.current")


    const movingFacesMatchingDirectionResults: {
        movingFace: { points: { id: string, position: Vector3, }[], direction: Vector3, },
        matchingFaces: { partId: string, face: { points: { id: string, position: Vector3, }[], direction: Vector3, }, }[],
    }[] = []

    // For each face of the moving box we're going to see which from the non moving boxes have the same direction
    Object.entries(movingBoxFaces).forEach(([movingFaceName, movingFace,]) => {
        console.log(movingFace, "movingFace")
        const movingFaceDirection = movingFace.direction

        if (!directionalFacesOfNotHighlightedPartsRef.current) {
            console.warn("directionalFacesOfNotHighlightedPartsRef.current is null")
            return
        }
        const facesWithSameDirection = getFacesWithSameDirection(movingFaceDirection)
        movingFacesMatchingDirectionResults.push({
            movingFace: {
                points: movingFace.points.map(p => ({
                    id: p.id,
                    position: new Vector3(p.position.x, p.position.y, p.position.z),
                })),
                direction: movingFace.direction,
            },
            matchingFaces: facesWithSameDirection,
        })

        if (facesWithSameDirection.length > 0) {
            if (debug) {
                // Visualize all points on both matching faces

                facesWithSameDirection.forEach(({ partId, face, }) => {
                    //visualizeSingleFace(face.points, face.direction, scene, randomColor, 5000, 0.1)
                })

                console.log(facesWithSameDirection, "facesWithSameDirection")
            }
        }

    })

    //now we to see which from our movingFacesMatchingDirectionResults which are overlapping each other on that same axis they match
    const overlappingFaces = movingFacesMatchingDirectionResults.flatMap(({ movingFace, matchingFaces, }) => {
        const cylinderRadius = 0.05 // This is effectively our padding in the perpendicular plane
        const matchingAxis = movingFace.direction

        return matchingFaces.filter(({ face: matchingFace, }) => {
            // Get center point of moving face
            const movingCenter = new Vector3()
            movingFace.points.forEach(p => movingCenter.add(p.position))
            movingCenter.divideScalar(movingFace.points.length)

            // For each point in the matching face, calculate:
            // 1. Distance along matching axis (for overlap check)
            // 2. Distance perpendicular to matching axis (for cylinder radius check)
            const isWithinCylinder = matchingFace.points.some(point => {
                const toPoint = point.position.clone().sub(movingCenter)

                // Get vector component along matching axis
                const alongAxis = matchingAxis.clone().multiplyScalar(toPoint.dot(matchingAxis))

                // Get vector component perpendicular to matching axis
                const perpendicular = toPoint.clone().sub(alongAxis)

                // If perpendicular distance is within cylinder radius, this point is a candidate
                return perpendicular.length() <= cylinderRadius
            })

            if (debug && isWithinCylinder) {
                const overlapColor = 0xff0000
                visualizeSingleFace(movingFace.points.map(p => p.position), movingFace.direction, scene, overlapColor, 5000, 0.3)
                visualizeSingleFace(matchingFace.points.map(p => p.position), matchingFace.direction, scene, overlapColor, 5000, 0.3)
            }

            return isWithinCylinder
        })
    })

    //alignmentMatchesRef.current = overlappingFaces
    console.log(overlappingFaces, "overlappingFaces")
}*/
export const createOverlayCanvas = () => {
    const canvasId = "multiselect-overlay-canvas"

    const existingCanvas = document.getElementById(canvasId)
    if (existingCanvas) {
        //existingCanvas.remove()
        //console.log("existing canvas", existingCanvas)
    }
    const canvas = document.createElement("canvas")
    canvas.id = canvasId
    canvas.style.position = "absolute"
    canvas.style.top = "0"
    canvas.style.left = "0"
    canvas.style.backgroundColor = "rgba(0, 0, 0, 0.0)"
    canvas.style.pointerEvents = "none"
    canvas.style.zIndex = "1000" // Ensure it's on top of other elements

    const resizeCanvas = () => {
        canvas.width = window.innerWidth
        canvas.height = window.innerHeight
    }

    // Set initial size
    resizeCanvas()

    // Add resize listener
    window.addEventListener("resize", resizeCanvas)

    document.body.appendChild(canvas)

    return canvas
}

export const find3DPointFromScreenPoint = (
    screenPoint: { x: number, y: number, },
    anchor3DPoint: Vector3,
    direction3D: Vector3,
    perpendicularDirections: Vector3[],
    camera: Camera,
    gl: WebGLRenderer,
    debug = false,
    scene?: Scene
) => {
    const rect = gl.domElement.getBoundingClientRect()
    const ndcX = (((screenPoint.x - rect.left) / rect.width) * 2) - 1
    const ndcY = -(((screenPoint.y - rect.top) / rect.height) * 2) + 1

    const raycaster = new Raycaster()
    raycaster.setFromCamera(new Vector2(ndcX, ndcY), camera)

    // Create two planes using the perpendicular directions
    const plane1 = new Plane()
    const plane2 = new Plane()
    plane1.setFromNormalAndCoplanarPoint(perpendicularDirections[0], anchor3DPoint)
    plane2.setFromNormalAndCoplanarPoint(perpendicularDirections[1], anchor3DPoint)

    // Find intersections with both planes
    const intersection1 = new Vector3()
    const intersection2 = new Vector3()
    const hit1 = raycaster.ray.intersectPlane(plane1, intersection1)
    const hit2 = raycaster.ray.intersectPlane(plane2, intersection2)

    if (!hit1 || !hit2) { return anchor3DPoint.clone() }

    // Find closest points on direction line from each intersection
    const normalizedDirection = direction3D.clone().normalize()

    // Project both intersections onto direction line
    const toIntersection1 = intersection1.clone().sub(anchor3DPoint)
    const toIntersection2 = intersection2.clone().sub(anchor3DPoint)
    const projection1 = toIntersection1.dot(normalizedDirection)
    const projection2 = toIntersection2.dot(normalizedDirection)

    // Use the projection that's closer to the ray
    const dist1 = intersection1.distanceTo(raycaster.ray.origin)
    const dist2 = intersection2.distanceTo(raycaster.ray.origin)

    const finalProjection = dist1 < dist2 ? projection1 : projection2
    const finalIntersection = dist1 < dist2 ? intersection1 : intersection2

    // Get final position along direction vector
    const final3DPosition = anchor3DPoint.clone().add(
        normalizedDirection.multiplyScalar(finalProjection)
    )

    if (debug && scene) {
        // Clear previous debug objects
        scene.children
            .filter(child => child.name?.startsWith("debug_"))
            .forEach(child => scene.remove(child))

        const debugObjects = {
            // Visualize both planes
            plane1: new PlaneHelper(plane1, 10, 0xff0000),
            plane2: new PlaneHelper(plane2, 10, 0x00ff00),

            // Visualize the direction vector
            directionLine: new ArrowHelper(
                normalizedDirection,
                anchor3DPoint,
                direction3D.length(),
                0x0000ff
            ),

            // Visualize the ray
            ray: new ArrowHelper(
                raycaster.ray.direction,
                raycaster.ray.origin,
                10,
                0xffff00
            ),

            // Visualize both intersection points
            intersection1: new Mesh(
                new SphereGeometry(0.05),
                new MeshBasicMaterial({ color: 0xff0000, depthTest: false, })
            ),
            intersection2: new Mesh(
                new SphereGeometry(0.05),
                new MeshBasicMaterial({ color: 0x00ff00, depthTest: false, })
            ),

            // Visualize final position
            finalPosition: new Mesh(
                new SphereGeometry(0.05),
                new MeshBasicMaterial({ color: 0x0000ff, depthTest: false, })
            ),
        }

        // Set positions
        debugObjects.intersection1.position.copy(intersection1)
        debugObjects.intersection2.position.copy(intersection2)
        debugObjects.finalPosition.position.copy(final3DPosition)

        // Add names for cleanup
        Object.keys(debugObjects).forEach(key => {
            (debugObjects[key as keyof typeof debugObjects]).name = `debug_${key}`
        })

        // Add to scene
        Object.values(debugObjects).forEach(obj => scene.add(obj))

        console.log("Debug info:", {
            screenPoint,
            anchor3DPoint: anchor3DPoint.toArray(),
            direction3D: direction3D.toArray(),
            perpendicularDirections: perpendicularDirections.map(d => d.toArray()),
            intersection1: intersection1.toArray(),
            intersection2: intersection2.toArray(),
            final3DPosition: final3DPosition.toArray(),
            projections: { projection1, projection2, },
            distances: { dist1, dist2, },
        })
    }

    return final3DPosition
}


export const calculate3DPositionFromMatch = (
    matchingPair: {
        point3D: Vector3,
        matchingPoint3D: Vector3,
        direction: Vector3,
        movingLine: { start: { x: number, y: number, }, vector: { x: number, y: number, }, },
        matchingLine: { start: { x: number, y: number, }, vector: { x: number, y: number, }, },
        movingFaceDirection: Vector3,
        perpendicularDirections: { direction: Vector3, label?: string, }[],
    },
    camera: Camera,
    gl: WebGLRenderer,
    scene?: Scene,
) => {
    // Get normalized direction in 2D
    /*const directionStart = matchingPair.matchingPoint3D.clone()
    const directionEnd = matchingPair.matchingPoint3D.clone().add(matchingPair.direction)

    const directionStart2D = projectTo2D(directionStart, camera, gl)
    const directionEnd2D = projectTo2D(directionEnd, camera, gl)

    // Calculate 2D vectors and normalize them
    const direction2D = {
        x: directionEnd2D.x - directionStart2D.x,
        y: directionEnd2D.y - directionStart2D.y,
    }
    const direction2DLength = Math.sqrt(direction2D.x * direction2D.x + direction2D.y * direction2D.y)
    const normalizedDirection2D = {
        x: direction2D.x / direction2DLength,
        y: direction2D.y / direction2DLength,
    }

    const movement2D = {
        x: matchingPair.movingLine.start.x - matchingPair.matchingLine.start.x,
        y: matchingPair.movingLine.start.y - matchingPair.matchingLine.start.y,
    }
    const movement2DLength = Math.sqrt(movement2D.x * movement2D.x + movement2D.y * movement2D.y)
    const normalizedMovement2D = {
        x: movement2D.x / movement2DLength,
        y: movement2D.y / movement2DLength,
    }

    // Calculate angle between movement and direction in 2D
    const dot2D = (normalizedDirection2D.x * normalizedMovement2D.x
        + normalizedDirection2D.y * normalizedMovement2D.y)
    const angle2D = Math.acos(Math.abs(dot2D))

    // Angle compensation factor (1/cos(angle) for adjacent to hypotenuse)
    const angleCompensation = 1 / Math.cos(angle2D)

    // Get direction multiplier
    const directionMultiplier = dot2D > 0 ? 1 : -1

    // Calculate pixel distance
    const distance2D = Math.sqrt(
        Math.pow(matchingPair.movingLine.start.x - matchingPair.matchingLine.start.x, 2)
        + Math.pow(matchingPair.movingLine.start.y - matchingPair.matchingLine.start.y, 2)
    )*/

    const newPositionUsingNewMethod = find3DPointFromScreenPoint(
        matchingPair.movingLine.start,
        matchingPair.matchingPoint3D,
        matchingPair.direction,
        matchingPair.perpendicularDirections.map(direction => direction.direction),
        camera,
        gl,
        false,
        scene
    )

    // Get vector between points
    const vectorBetweenPoints = newPositionUsingNewMethod.clone()
        .sub(matchingPair.matchingPoint3D)

    // Project this vector onto our direction to get signed distance
    const distance3D = vectorBetweenPoints.dot(matchingPair.direction.clone().normalize())

    // Create new position
    const newPosition = matchingPair.matchingPoint3D.clone()
        .add(matchingPair.direction.clone().normalize()
            .multiplyScalar(distance3D))

    return {
        newPosition,
        debug: {
            target2D: matchingPair.movingLine.start,
            distance3D,
            originalStart: matchingPair.matchingLine.start,
            newPositionUsingNewMethod,
            newPosition,
        },
    }
}

const createDebugBox = (box: Box3, color: number) => {
    const boxHelper = new Box3Helper(box, new Color(color))
    boxHelper.name = "debugBox"
    return boxHelper
}

const clearDebugBoxes = (scene: Scene) => {
    scene.children
        .filter(child => child.name === "debugBox")
        .forEach(child => scene.remove(child))
}


const getTransformedSelectionBoundingBox = (debug = false, transformsAnchorRef: Object3D, scene: Scene) => {
    if (!transformsAnchorRef) {
        return null
    }

    // Create a single combined box
    const combinedBox = new Box3()
    let hasBox = false

    // Store part info with partIds as keys
    const partInfo: Record<string, {
        center: Vector3,
        boundingBox: Box3,
    }> = {}

    transformsAnchorRef.children.forEach(child => {
        if (child.name.includes("cloned_")) {
            child.children.forEach(childObject => {
                if (childObject.name.includes("boundingBox")) {
                    const boundingBoxMesh = childObject as Mesh
                    const geometry = boundingBoxMesh.geometry

                    if (!geometry.boundingBox) {
                        geometry.computeBoundingBox()
                    }

                    if (geometry.boundingBox) {
                        const box = new Box3().copy(geometry.boundingBox)
                        box.applyMatrix4(boundingBoxMesh.matrixWorld)

                        // Get part ID from the cloned_ prefix
                        const partId = boundingBoxMesh.userData.partId

                        // Get world center from userData
                        const center = new Vector3()
                        if (boundingBoxMesh.userData.getWorldCenter) {
                            center.copy(boundingBoxMesh.userData.getWorldCenter())
                        } else {
                            // Fallback to computing center from bounding box
                            box.getCenter(center)
                        }

                        // Store part info using partId as key
                        partInfo[partId] = {
                            center,
                            boundingBox: box,
                        }

                        if (!hasBox) {
                            combinedBox.copy(box)
                            hasBox = true
                        } else {
                            combinedBox.union(box)
                        }
                    }
                }
            })
        }
    })

    if (debug && hasBox) {
        clearDebugBoxes(scene)
        scene.add(createDebugBox(combinedBox, 0x0000ff))
        const expandedBox = combinedBox.clone().expandByScalar(0.005)
        scene.add(createDebugBox(expandedBox, 0xffff00))
    }

    return hasBox ? {
        combinedBox,
        parts: partInfo,
    } : null
}


export const getOverlappingPartIds = (debug = false, transformsAnchorRef: Object3D, cameraControls: CameraControls, ctxRef: CanvasRenderingContext2D, gl: WebGLRenderer, notHighlightedPartsMarkerInfo: PartDataWithMarkerInfo[]) => {
    // Get transformed polygons
    if (!transformsAnchorRef || !cameraControls || !ctxRef || !gl) {
        return {
            overlappingIds: [],
            transformedPartIds: [],
        }
    }
    const transformedPolygons = getTransformed2DPolygons(10, debug, transformsAnchorRef, cameraControls, ctxRef, gl)
    if (!transformedPolygons) {
        return {
            overlappingIds: [],
            transformedPartIds: [],
        }
    }

    const overlappingIdsWithPercentage: { id: string, overlap: number, transformedId: string, }[] = []

    // Check each not-highlighted part's polygon against each transformed polygon
    notHighlightedPartsMarkerInfo.forEach(notHighlightedPart => {
        if (!notHighlightedPart.twodPolygon) { return }

        // Check against each transformed polygon
        Object.entries(transformedPolygons).forEach(([transformedPartId, transformedData,]) => {
            const overlapResult = PolygonUtils.polygonsOverlap(
                transformedData.polygon,
                notHighlightedPart.twodPolygon
            )

            if (overlapResult !== false && overlapResult <= 0.5 && overlapResult > 0) {
                overlappingIdsWithPercentage.push({
                    id: notHighlightedPart.id,
                    overlap: overlapResult,
                    transformedId: transformedPartId,
                })
            }
        })
    })

    // Sort by overlap percentage (ascending - least overlap first)
    const sortedOverlappingIds = overlappingIdsWithPercentage
        .sort((a, b) => a.overlap - b.overlap)
        .map(item => item.id)

    // Get only the transformed part IDs that had overlaps
    const transformedPartsWithOverlaps = Array.from(new Set(
        overlappingIdsWithPercentage.map(item => item.transformedId)
    ))

    debug && console.log({
        overlappingIdsWithPercentage,
        sortedOverlappingIds,
        transformedPartsWithOverlaps,
        transformedPolygons,
        notHighlightedPolygons: notHighlightedPartsMarkerInfo
            .filter(p => p.twodPolygon)
            .map(p => ({ id: p.id, polygon: p.twodPolygon, })),
    })

    return {
        overlappingIds: sortedOverlappingIds,
        transformedPartIds: transformedPartsWithOverlaps,
    }
}


export const checkIntersectionsBetweenAnchorChildrenAndNotHighlightedParts = (
    startExpand = 0.5,
    step = 0.5,
    debug = false,
    transformsAnchorRef: Object3D,
    scene: Scene,
    cameraControls: CameraControls,
    boundingBoxesOfNotHighlightedParts: MutableRefObject<{ partId: string, boundingBox: Box3, }[]>,
) => {
    const transformedBox = getTransformedSelectionBoundingBox(false, transformsAnchorRef, scene)
    if (!transformedBox) { return { intersectingIds: [], otherIdsByDistance: [], } }

    const centerDistances: { partId: string, distance: number, }[] = []

    // For each transformed part
    Object.entries(transformedBox.parts).forEach(([partId, partData,]) => {
        // Compare against each not-highlighted part
        Object.entries(transformedBox.parts).forEach(([highlightedPartId, highlightedPartData,]) => {
            const highlightedCenter = new Vector3()
            highlightedPartData.boundingBox.getCenter(highlightedCenter)

            // Project both centers to 2D
            const center2D = partData.center.clone().project(cameraControls.camera)
            const highlightedCenter2D = highlightedCenter.clone().project(cameraControls.camera)

            // Calculate 2D distance
            const distance = Math.sqrt(
                Math.pow(center2D.x - highlightedCenter2D.x, 2)
                + Math.pow(center2D.y - highlightedCenter2D.y, 2)
            )

            centerDistances.push({
                partId: highlightedPartId,
                distance,
            })
        })
    })

    // Get unique closest parts
    const closestHighlightedParts = Array.from(
        new Map(
            centerDistances
                .sort((a, b) => a.distance - b.distance)
                .map(item => [item.partId, item,])
        ).values()
    )

    debug && console.log(transformedBox, "transformedBox")
    // Try 3 different expansion sizes
    const options = [
        { expand: startExpand, },
        { expand: startExpand + step, },
        { expand: startExpand + (2 * step), },
    ]

    // Colors for each expansion level
    const expansionColors = [
        0x00ff00, // Green for initial
        0x0000ff, // Blue for first expansion
        0xff0000,  // Red for second expansion
    ]

    let allIntersectingBoxes: { partId: string, distance: number, }[] = []

    // Test each option
    options.forEach((option, index) => {
        const transformBoxClone = new Box3().copy(transformedBox.combinedBox)
        transformBoxClone.expandByScalar(option.expand)

        if (debug) {
            // Visualize the expanded box
            const boxHelper = new Box3Helper(transformBoxClone, new Color(expansionColors[index]))
            boxHelper.name = "expansionDebugBox"
                ; (boxHelper.material as Material).transparent = true
                ; (boxHelper.material as Material).opacity = 0.3
                ; (boxHelper.material as Material).depthTest = false
            //scene.add(boxHelper)
        }

        // Calculate center of transformed box
        const transformedCenter = new Vector3()
        transformBoxClone.getCenter(transformedCenter)

        // Get intersecting boxes with distances
        const intersectingBoxes = boundingBoxesOfNotHighlightedParts.current
            .filter(box => transformBoxClone.intersectsBox(box.boundingBox))
            .map(box => {
                const boxCenter = new Vector3()
                box.boundingBox.getCenter(boxCenter)
                // Project centers to 2D
                const boxCenter2D = boxCenter.clone().project(cameraControls.camera)
                const transformedCenter2D = transformedCenter.clone().project(cameraControls.camera)

                // Calculate 2D distance
                const distance = Math.sqrt(
                    Math.pow(boxCenter2D.x - transformedCenter2D.x, 2)
                    + Math.pow(boxCenter2D.y - transformedCenter2D.y, 2)
                )
                return {
                    partId: box.partId,
                    distance,
                }
            })

        allIntersectingBoxes = [...allIntersectingBoxes, ...intersectingBoxes,]
    })

    // Remove duplicates and sort by distance
    const uniqueBoxes = Array.from(
        new Map(allIntersectingBoxes.map(box => [box.partId, box,])).values()
    ).sort((a, b) => a.distance - b.distance)

    return {
        intersectingIdsStationaryOfNotHighlightedParts: uniqueBoxes.map(box => box.partId),
        closestIdsMovingHighlightedParts: closestHighlightedParts.map(part => part.partId),
    }
}

export const drawPoint = (ctx: CanvasRenderingContext2D, x: number, y: number, radius = 5, color = "red") => {
    //console.log("Drawing point:", { x, y, radius, color, })
    if (ctx) {
        ctx.beginPath()
        ctx.arc(x, y, radius, 0, 2 * Math.PI, false)
        ctx.globalAlpha = 0.40
        ctx.fillStyle = color
        ctx.fill()
    } else {
        console.warn("ctxRef.current or overlayCanvasRef.current is null or undefined")
    }

}

export const drawRectangle = (ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, color = "red") => {
    if (ctx) {
        ctx.beginPath()
        ctx.rect(x, y, width, height)
        ctx.fillStyle = color
        ctx.globalAlpha = 0.5
        ctx.fill()
    }
}



//another debug function if needed
export const drawDebugPolygonForHighlightedAndNotHighlighedParts = (notHighlightedPartsMarkerInfo: PartDataWithMarkerInfo[], highlightedPartsMarkerInfo: PartDataWithMarkerInfo[], ctxRef: React.RefObject<CanvasRenderingContext2D>) => {
    notHighlightedPartsMarkerInfo.forEach((part) => {
        const polygon = PolygonUtils.createPolygon(part.markers.map(marker => ({
            x: marker.camera2dPosition?.x || 0,
            y: marker.camera2dPosition?.y || 0,
        })), 2)
        PolygonUtils.debugDrawPolygon(ctxRef.current!, polygon)
    })
    highlightedPartsMarkerInfo.forEach((part) => {
        const polygon = PolygonUtils.createPolygon(part.markers.map(marker => ({
            x: marker.camera2dPosition?.x || 0,
            y: marker.camera2dPosition?.y || 0,
        })), 2)
        PolygonUtils.debugDrawPolygon(ctxRef.current!, polygon)
    })
}

export const projectTo2D = (position: Vector3, camera: Camera, gl: WebGLRenderer): { x: number, y: number, } => {
    const vector = position.clone().project(camera)

    // Get the renderer's DOM element to account for any offset
    const rendererDomElement = gl.domElement
    const rect = rendererDomElement.getBoundingClientRect()

    return {
        x: Math.round((vector.x + 1) * rect.width / 2),
        y: (Math.round((-vector.y + 1) * rect.height / 2)) + rect.top,
    }
}



export const getTransformed2DPolygons = (scale = 1, debug = false, transformsAnchorRef: Object3D, cameraControls: CameraControls, ctxRef: CanvasRenderingContext2D, gl: WebGLRenderer) => {
    if (!transformsAnchorRef || !cameraControls || !ctxRef) {
        return null
    }

    const cam = cameraControls
    const minHeight = 70 / cam.distance

    // Store part info with partIds as keys
    const partPolygons: Record<string, {
        points2D: { x: number, y: number, }[],
        polygon: {
            center: { x: number, y: number, },
            vertices: { x: number, y: number, }[],
        },
        debug?: {
            corners3D: Vector3[],
            points2D: { x: number, y: number, }[],
        },
    }> = {}

    transformsAnchorRef.children.forEach(child => {
        if (child.name.includes("cloned_")) {
            child.children.forEach(childObject => {
                if (childObject.name.includes("boundingBox")) {
                    const boundingBoxMesh = childObject as Mesh
                    const geometry = boundingBoxMesh.geometry
                    const partId = boundingBoxMesh.userData.partId

                    if (!geometry.boundingBox) {
                        geometry.computeBoundingBox()
                    }

                    if (geometry.boundingBox) {
                        // Get the 8 corners of the bounding box
                        const box = geometry.boundingBox
                        const corners3D = [
                            new Vector3(box.min.x, box.min.y, box.min.z),
                            new Vector3(box.min.x, box.min.y, box.max.z),
                            new Vector3(box.min.x, box.max.y, box.min.z),
                            new Vector3(box.min.x, box.max.y, box.max.z),
                            new Vector3(box.max.x, box.min.y, box.min.z),
                            new Vector3(box.max.x, box.min.y, box.max.z),
                            new Vector3(box.max.x, box.max.y, box.min.z),
                            new Vector3(box.max.x, box.max.y, box.max.z),
                        ]

                        // Transform corners to world space
                        corners3D.forEach(corner => corner.applyMatrix4(boundingBoxMesh.matrixWorld))

                        // Project to 2D
                        const points2D = corners3D.map(corner => {
                            const projected = projectTo2D(corner, cam.camera, gl)
                            return { x: projected.x, y: projected.y, }
                        })

                        // Create polygon
                        const simplifiedPoints = PolygonUtils.simplifyPolygonToRectangle(points2D)
                        const polygon = PolygonUtils.createPolygon(simplifiedPoints, minHeight, scale)
                        debug && PolygonUtils.debugDrawPolygon(ctxRef, polygon)


                        partPolygons[partId] = {
                            points2D,
                            polygon,
                            debug: debug ? {
                                corners3D,
                                points2D,
                            } : undefined,
                        }
                    }
                }
            })
        }
    })

    return Object.keys(partPolygons).length > 0 ? partPolygons : null
}
