/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from "react"
import { Input, UnitLabel, InputRow } from "./SegmentedSliderStyles"
import isMobile from "ismobilejs"
import { ReactComponent as ArrowUpDownIcon } from "../../../../../../../../../common/assets/icons/ArrowUpDown.svg"
import { ReactComponent as ArrowLeftRightIcon } from "../../../../../../../../../common/assets/icons/ArrowLeftRight.svg"


interface NumberInputProps {
    value: number;
    onChange: (value: number, shouldTriggerAlignment: boolean) => void;
    unit?: string;
    onKeyDown?: (e: React.KeyboardEvent) => void;
    step?: string;
    direction?: "up" | "left";
}

const NumberInput: React.FC<NumberInputProps> = ({
    value,
    onChange,
    unit,
    onKeyDown,
    step = "1",
    direction = "up",
}) => {
    const isSpinnerClick = useRef(false)
    const [localValue, setLocalValue,] = useState(value.toString())
    const isMobileResult = isMobile()
    const isAndroid = isMobileResult.android.device

    useEffect(() => {
        setLocalValue(value.toString())
    }, [value,])

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (onKeyDown) {
            onKeyDown(e)
        }

        if (e.key === "ArrowUp") {
            onChange(value + 1, true)
            e.preventDefault()
        } else if (e.key === "ArrowDown") {
            onChange(value - 1, true)
            e.preventDefault()
        }
    }

    const handleMouseDown = (e: React.MouseEvent<HTMLInputElement>) => {
        const input = e.currentTarget
        const rect = input.getBoundingClientRect()
        const spinnerWidth = 20
        isSpinnerClick.current = e.clientX > rect.right - spinnerWidth
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value
        setLocalValue(inputValue)

        if (inputValue === "" || isNaN(parseFloat(inputValue))) {
            return
        }

        const numericValue = parseFloat(inputValue)
        onChange(numericValue, isSpinnerClick.current)
        isSpinnerClick.current = false
    }

    return (
        <InputRow>
            {direction === "up" ? <ArrowUpDownIcon /> : <ArrowLeftRightIcon />}
            <Input
                type={isAndroid ? "text" : "number"}
                inputMode={isAndroid? "numeric": "text"}
                value={localValue}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onMouseDown={handleMouseDown}
                step={step}
            />
            {unit && <UnitLabel>{unit}</UnitLabel>}
        </InputRow>
    )
}

export default NumberInput