/* eslint-disable */
import React, { forwardRef, ForwardedRef, MutableRefObject, useEffect, useRef, useState } from 'react';
import {
	MOUSE,
	Vector2,
	Vector3,
	Vector4,
	Quaternion,
	Matrix4,
	Spherical,
	Box3,
	Sphere,
	Raycaster,
	MathUtils,
} from 'three';
import { ReactThreeFiber, extend, useFrame, useThree } from '@react-three/fiber';
import CameraControlsDefault from 'camera-controls';
import { GizmoHelper, GizmoViewcube } from '@react-three/drei';
import { useRecoilState, useRecoilValue } from 'recoil';
import { showCameraControlsState } from '../../state/scene/atoms';
import { CameraGizmo } from './CameraGizmo';

export const CAMERA_ACTIONS = CameraControlsDefault.ACTION

declare global {
	namespace JSX {
		interface IntrinsicElements {
			cameraControlsDefault: ReactThreeFiber.Node<CameraControlsDefault, typeof CameraControlsDefault>;
		}
	}
}

const subsetOfTHREE = {
	MOUSE: MOUSE,
	Vector2: Vector2,
	Vector3: Vector3,
	Vector4: Vector4,
	Quaternion: Quaternion,
	Matrix4: Matrix4,
	Spherical: Spherical,
	Box3: Box3,
	Sphere: Sphere,
	Raycaster: Raycaster,
	MathUtils: {
		DEG2RAD: MathUtils.DEG2RAD,
		clamp: MathUtils.clamp,
	},
};

CameraControlsDefault.install({ THREE: subsetOfTHREE });
extend({ CameraControlsDefault });

export const CameraControls = forwardRef<CameraControlsDefault, unknown>((_, ref) => {
	const cameraControls = useRef<CameraControlsDefault | null>(null);
	const [showCameraControls] = useRecoilState(showCameraControlsState);
	const camera = useThree((state) => state.camera);
	const renderer = useThree((state) => state.gl);
	useFrame((_, delta) => cameraControls.current?.update(delta));
	useEffect(() => () => cameraControls.current?.dispose(), []);

	return (
		<>
			<cameraControlsDefault
				ref={mergeRefs<CameraControlsDefault>(cameraControls, ref)}
				args={[camera, renderer.domElement]}
				minDistance={0.5}
				maxDistance={100}
				dampingFactor={0.15}
			/>
			{showCameraControls && <CameraGizmo />}
		</>
	);
});

export type CameraControls = CameraControlsDefault;

function mergeRefs<T>(...refs: (MutableRefObject<T> | ForwardedRef<T>)[]) {
	return (instance: T): void => {
		for (const ref of refs) {
			if (typeof ref === 'function') {
				ref(instance);
			} else if (ref) {
				ref.current = instance;
			}
		}
	};
}