/* eslint-disable max-lines-per-function */
/* eslint-disable guard-for-in */
/* eslint-disable max-statements */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useThree } from "@react-three/fiber"
import React, { createContext, useRef } from "react"
import {
    BufferGeometry,
    DynamicDrawUsage,
    InstancedMesh,
    Mesh,
    MeshMatcapMaterial,
    SRGBColorSpace,
    Texture,
    Color,
} from "three"

// Maximum number of instances per InstancedMesh batch
const BATCH_SIZE = 150

interface Props {
    children: React.ReactNode;
}

type NewMeshType = {
    index: number,
    instancedMesh: InstancedMesh,
    batchIndex: number, // Added batchIndex to track which batch this instance belongs to
}

type MeshCache = {
    createMesh: (
        id: string,
        meshName: string,
        mesh: Mesh,
        callback: () => void,
        updateInstance: (newIndex: number, batchIndex: number) => void,
        woodTexture?: Texture) => NewMeshType,
    deleteMesh: (meshName: string, id: string) => void,
    clickCallback: (meshName: string, instanceId: number, batchIndex: number) => void,
    getPartId: (meshName: string, instanceId: number, batchIndex: number) => string | undefined,
}

type Instance = {
    id: string | undefined,
    index: number,
    batchIndex: number, // Added to track which batch this instance belongs to
    callback: (() => void) | undefined,
    updateInstance: ((newIndex: number, batchIndex: number) => void) | undefined,
}

type InstancedMeshBatch = {
    instancedMesh: InstancedMesh,
    count: number,
}

type Cache = {
    meshName: string,
    instances: Instance[],
    batches: InstancedMeshBatch[], // Changed to an array of batches
}

export type InstancedMeshContextType = MeshCache | undefined

export const instancedMeshContext = createContext<InstancedMeshContextType>(undefined)

const InstancedMeshProvider: React.FC<Props> = ({ children, }) => {
    const cache = useRef<Cache[]>([])
    const { scene, } = useThree()

    // Helper function to extract the original mesh name from a batch-specific name
    const extractOriginalMeshName = (batchName: string): string => {
        // If the name includes -batch-, extract the part before it
        const match = batchName.match(/^(.*?)-batch-\d+$/)
        return match ? match[1] : batchName
    }

    const deleteMesh = (meshName: string, id: string) => {
        // Extract original mesh name if it's a batch name
        const originalMeshName = extractOriginalMeshName(meshName)
        const meshCache = cache.current.find((c) => c.meshName === originalMeshName)

        if (!meshCache) {return}

        // Find the instance to delete
        const instanceIndex = meshCache.instances.findIndex((i) => i.id === id)
        if (instanceIndex === -1) {return}

        const instance = meshCache.instances[instanceIndex]
        const { batchIndex, index, } = instance
        const batch = meshCache.batches[batchIndex]

        if (!batch) {return}

        // If this batch has color attributes, handle color shifting within this batch
        if (batch.instancedMesh.instanceColor) {
            // Shift colors down within this batch
            for (let i = index; i < batch.count - 1; i++) {
                const nextColor = new Color()
                batch.instancedMesh.getColorAt(i + 1, nextColor)
                batch.instancedMesh.setColorAt(i, nextColor)
            }
            batch.instancedMesh.instanceColor.needsUpdate = true
        }

        // Remove the instance from our instances array
        meshCache.instances.splice(instanceIndex, 1)

        // Update the indices of all instances in the same batch with higher indices
        meshCache.instances.forEach((i) => {
            if (i.batchIndex === batchIndex && i.index > index) {
                i.index--
                if (i.updateInstance) {
                    i.updateInstance(i.index, i.batchIndex)
                }
            }
        })

        // Decrement the batch count
        batch.count--
        batch.instancedMesh.count = batch.count
        batch.instancedMesh.instanceMatrix.needsUpdate = true
        batch.instancedMesh.computeBoundingSphere()

        // If this batch is now empty and it's not the first batch, we could remove it
        // This is optional and depends on your memory management strategy
        if (batch.count === 0 && batchIndex > 0) {
            scene.remove(batch.instancedMesh)
            meshCache.batches.splice(batchIndex, 1)

            // Update batchIndex for all instances in higher batches
            meshCache.instances.forEach((i) => {
                if (i.batchIndex > batchIndex) {
                    // Update instance's batch index
                    const newBatchIndex = i.batchIndex - 1
                    i.batchIndex = newBatchIndex

                    // Update userData on the instancedMesh
                    const updatedBatch = meshCache.batches[newBatchIndex]
                    if (updatedBatch && updatedBatch.instancedMesh) {
                        updatedBatch.instancedMesh.userData.batchIndex = newBatchIndex
                    }

                    // Notify the instance about the batch index change
                    if (i.updateInstance) {
                        i.updateInstance(i.index, i.batchIndex)
                    }
                }
            })
        }
    }

    const getGeometryByteLength = (geometry: BufferGeometry) => {
        let total = 0

        if (geometry.index) { total += geometry.index.array.length }

        for (const name in geometry.attributes) {
            total += geometry.attributes[name].array.length
        }

        return total
    }

    const formatBytes = (bytes: number, decimals: number) => {
        if (bytes === 0) { return "0 bytes" }

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["bytes", "KB", "MB",]

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    // Creates a new InstancedMesh batch with the given mesh and material
    const createInstancedMeshBatch = (
        meshName: string,
        mesh: Mesh,
        materialColor?: string,
        woodTexture?: Texture,
        batchIndex = 0
    ) => {
        let material = new MeshMatcapMaterial({ color: "white", })
        if (materialColor && woodTexture) {
            woodTexture.colorSpace = SRGBColorSpace
            material = new MeshMatcapMaterial({ color: "#fff", map: woodTexture, })
        }
        material.normalMap = (mesh.material as any).normalMap

        const instancedMesh = new InstancedMesh(mesh.geometry, material, BATCH_SIZE)
        instancedMesh.userData = {
            type: "InstancedMesh",
            batchName: meshName,
            originalMeshName: meshName, // Store the original mesh name for lookups
            batchIndex: batchIndex, // Store the batch index for raycasting
        }
        instancedMesh.count = 0
        instancedMesh.instanceMatrix.setUsage(DynamicDrawUsage)
        // Use a shorter format for the batch name to avoid long names
        instancedMesh.name = `${meshName}-batch-${Date.now()}`
        scene.add(instancedMesh)

        return instancedMesh
    }

    const createMesh = (
        id: string,
        meshName: string,
        mesh: Mesh,
        callback: () => void,
        updateInstance: (newIndex: number, batchIndex: number) => void,
        woodTexture?: Texture
    ) => {
        const meshCache = cache.current.find((c) => c.meshName === meshName)

        if (meshCache) {
            // Find the last batch that isn't full
            let batchIndex = meshCache.batches.findIndex(batch => batch.count < BATCH_SIZE)

            // If all batches are full, create a new batch
            if (batchIndex === -1) {
                const materialColor = meshName.split("-mat-")[1]
                // Use the next available batch index (current length)
                const newBatchIndex = meshCache.batches.length
                const newInstancedMesh = createInstancedMeshBatch(
                  meshName, mesh, materialColor, woodTexture, newBatchIndex
                )

                meshCache.batches.push({
                    instancedMesh: newInstancedMesh,
                    count: 0,
                })

                batchIndex = newBatchIndex

                const memoryListElement = document.getElementById("memory-debug")
                if (memoryListElement) {
                    const geometryByteLength = getGeometryByteLength(mesh.geometry)
                    const li = document.createElement("li")
                    li.innerHTML
                      = `<p>${meshName} (batch ${batchIndex}): ${formatBytes(BATCH_SIZE * 16
                      + geometryByteLength, 2)}</p>`
                    memoryListElement.appendChild(li)
                }
            }

            // Get the batch to use
            const batch = meshCache.batches[batchIndex]
            const index = batch.count

            // Add new instance
            meshCache.instances.push({
                index,
                batchIndex,
                id,
                callback,
                updateInstance,
            })

            // Update batch count
            batch.count++
            batch.instancedMesh.count = batch.count

            return {
                index,
                batchIndex,
                instancedMesh: batch.instancedMesh,
            }
        } else {
            // No existing cache for this mesh name, create a new one
            const materialColor = meshName.split("-mat-")[1]
            // First batch, so index is 0
            const instancedMesh = createInstancedMeshBatch(
              meshName, mesh, materialColor, woodTexture, 0
            )

            // Create the first instance
            const newInstance = {
                index: 0,
                batchIndex: 0,
                id,
                callback,
                updateInstance,
            }

            // Create a new cache entry
            const newCache = {
                meshName,
                instances: [newInstance,],
                batches: [{
                    instancedMesh,
                    count: 1,
                },],
            }

            cache.current.push(newCache)
            instancedMesh.count = 1

            const memoryListElement = document.getElementById("memory-debug")
            if (memoryListElement) {
                const geometryByteLength = getGeometryByteLength(mesh.geometry)
                const li = document.createElement("li")
                li.innerHTML
                  = `<p>${meshName} (batch 0): ${formatBytes(BATCH_SIZE * 16
                  + geometryByteLength, 2)}</p>`
                memoryListElement.appendChild(li)
            }

            return {
                index: 0,
                batchIndex: 0,
                instancedMesh,
            }
        }
    }

    const init = () => {
        return {
            createMesh,
            deleteMesh,
            clickCallback,
            getPartId,
        }
    }

    const clickCallback = (meshName: string, instanceId: number, batchIndex = 0) => {
        if (instanceId !== -1) {
            // Extract the original mesh name from the batch name
            const originalMeshName = extractOriginalMeshName(meshName)

            // Find in cache using the original mesh name
            const meshCache = cache.current.find((c) => c.meshName === originalMeshName)
            if (!meshCache) {
                console.warn(`No mesh cache found for ${originalMeshName} (from ${meshName})`)
                return
            }

            // Find the instance by its index and batch
            const instance = meshCache.instances.find(
                (i) => i.index === instanceId && i.batchIndex === batchIndex
            )

            if (instance?.callback) {
                instance.callback()
            } else {
                console.warn(`No instance or callback found for ${originalMeshName}, 
                  index: ${instanceId}, batch: ${batchIndex}`)
            }
        }
    }

    const getPartId = (meshName: string, instanceId: number, batchIndex = 0) => {
        if (instanceId !== -1) {
            // Extract the original mesh name from the batch name
            const originalMeshName = extractOriginalMeshName(meshName)

            // Find in cache using the original mesh name
            const meshCache = cache.current.find((c) => c.meshName === originalMeshName)
            if (!meshCache) {
                console.warn(`No mesh cache found for ${originalMeshName} (from ${meshName})`)
                return
            }

            // Find the instance by its index and batch
            const instance = meshCache.instances.find(
                (i) => i.index === instanceId && i.batchIndex === batchIndex
            )

            if (instance) {
                return instance.id
            } else {
                console.warn(`No instance found for ${originalMeshName}, 
                  index: ${instanceId}, batch: ${batchIndex}`)
            }
        }
        return undefined
    }

    return <instancedMeshContext.Provider value={init()} children={children} />
}

export default InstancedMeshProvider