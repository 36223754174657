import React, { useState } from "react"
import styled from "styled-components"
import { DesignTypeAPI } from "../../../../common/api/Types"
import DesignItem from "./DesignItem/DesignItem"
import { useInfiniteScroll } from "../../../../common/hooks/useInfiniteScroll"

type Props = {
    designs: DesignTypeAPI[],
    onDuplicateDesign: (positionId: string, copy: DesignTypeAPI) => void,
    removeDesign: (id: string) => void,
    onLoadMore: () => void,
    hasMore: boolean,
    loadingMore: boolean,
}

const DesignsList = (props: Props) => {

    const { observerTarget, } = useInfiniteScroll({
        onLoadMore: props.onLoadMore,
        hasMore: props.hasMore,
        loadingMore: props.loadingMore,
    })

    const getDesigns = () => {
        return props.designs.map(
            d => <DesignItem
                design={d}
                key={d.id}
                removeDesign={props.removeDesign}
                onDuplicateDesign={props.onDuplicateDesign}
            />
        )
    }

    return (
        <>
            <Container>{getDesigns()}</Container>
            <LoadingTrigger ref={observerTarget}>
                {props.hasMore && <LoadingText>Loading more designs...</LoadingText>}
            </LoadingTrigger>
        </>
    )
}

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 4px;
    padding: 8px;
`

const LoadingTrigger = styled.div`
    display: flex;
    justify-content: center;
    padding: 16px;
    height: 50px;
`

const LoadingText = styled.div`
    color: #666;
    font-size: 14px;
`

export default DesignsList