/* eslint-disable max-statements */
import { useRecoilValue } from "recoil"
import { NoProviderEnum, PartTypeAPI, ShopifyIdAPI } from "../../../../../../../common/api/Types"
import {
  materialsSelector,
  subclassesSelector
} from "../../../../../../state/initialDataSelectors"
import { scenePartsSelector, unitSelector } from "../../../../../../state/scene/atoms"
import { GenericPartState, SegmentedTubePart, PartTypeEnum } from "../../../../../../utils/Types"
import {
  GroupByList,
  ObjDictionary,
  toDictionary
} from "../../../../../../../common/utils/utils"
import {
  roundLength,
  roundNumber
} from "../../../../../../utils/utils"
import { convertInToCm } from "../../../utils/UnitUtils"

export const TUBE_LENGTH_MAX_BUY = 36

export type PartListItem = {
  imgURL: string,
  title: string,
  subTitle: string,
  isATube: boolean,
  longerThan: boolean,
  affiliateURL?: string,
  noProvider?: NoProviderEnum,
  cartNotice?: string,
  id: string,
}

interface usePartsListParams {
  parts: PartTypeAPI[];
  shopifyIds: ObjDictionary<ShopifyIdAPI>;
  allowCm: boolean;
}

export const getSegmentedTubeLength = (part: SegmentedTubePart) => {
  const {
    length,
    lengthNegativeSide,
    segmentLength,
    startSegmentLength,
    endSegmentLength,
  } = part
  return roundLength(
    (length + lengthNegativeSide)
    * segmentLength
    + startSegmentLength
    + endSegmentLength
  )
}

const normalizeText = (text: string): string => {
  // Only normalize patterns like: 2" x 2" or 2'' x 2''
  return text.replace(/(\d+)(['″"]{1,2})\s*x\s*(\d+)(['″"]{1,2})/g, '$1" x $3"')
}

export const usePartsList = ({ parts, shopifyIds, allowCm, }: usePartsListParams) => {
  const sceneAtomList = useRecoilValue(scenePartsSelector)
  const materialData = useRecoilValue(materialsSelector)
  const subclassesData = useRecoilValue(subclassesSelector)
  const unit = useRecoilValue(unitSelector)

  const partsDictionary = toDictionary(parts, (part) => part.id)
  const partsInStock: GenericPartState[] = []
  const partsNoStock: GenericPartState[] = []

  Object.values(sceneAtomList).forEach(part => {
    let generalTubeLength: number | undefined
    switch (part?.type) {
      case PartTypeEnum.tube:
        generalTubeLength = roundNumber(part?.length, 0)
        break
      case PartTypeEnum.segmentedTube:
        generalTubeLength = roundNumber(part?.length * part.segmentLength, 0)
        break
      default:
        generalTubeLength = undefined
    }

    const isTubeLengthValid = generalTubeLength
      ? generalTubeLength <= TUBE_LENGTH_MAX_BUY
      : true

    const isPartInShopify = Boolean(shopifyIds[part.apiTypeId])

    if (isPartInShopify && isTubeLengthValid) {
      partsInStock.push(part)
    } else {
      partsNoStock.push(part)
    }
  })

  const getPartSubclass = (part: GenericPartState) => {
    const subclass = subclassesData.find(({ id, }) => id
      === partsDictionary[part.apiTypeId].subclassId)
    if (subclass) {
      return subclass.subclassName
    }
    return "No Subclass data"
  }

  const getPartMaterial = (part: GenericPartState) => {
    const material = materialData.find(({ id, }) => id
      === partsDictionary[part.apiTypeId].materialId)
    if (material) {
      return material.materialName
    } else {
      return "No Material data"
    }
  }

  const showCm = () : boolean => {
    return unit === "cm" && allowCm
  }

  const getRows = (parts: GenericPartState[]) => {
    const partsShow: PartListItem[] = parts.map(part => {
      const partInfo = partsDictionary[part.apiTypeId]
      if (!partInfo) { return null } // Skip parts that are not found
      const { modelIcon, affiliateURL, noProvider, cartNotice, } = partInfo
      const name = normalizeText(part.name)
      const material = getPartMaterial(part)
      const subclass = getPartSubclass(part)
      let isATube = false
      let longerThan = false
      let dimension = ""

      if (part.type === PartTypeEnum.tube) {
        let tubeLength = roundLength(part.length)
        if (showCm()) {
          tubeLength = roundLength(convertInToCm(tubeLength))
        }
        dimension = `- ${tubeLength}${showCm() ? " cm" : "\""} Length`
        isATube = true
        longerThan = tubeLength >= TUBE_LENGTH_MAX_BUY
      }

      if (part.type === PartTypeEnum.segmentedTube) {
        let segmentedTubeLength = getSegmentedTubeLength(part)
        if (showCm()) {
          segmentedTubeLength = roundLength(convertInToCm(segmentedTubeLength))
        }
        dimension = `- ${segmentedTubeLength}${showCm() ? " cm" : "\""} Length`
        longerThan = segmentedTubeLength >= TUBE_LENGTH_MAX_BUY
      }

      return {
        id: part.id,
        imgURL: modelIcon,
        title: `${name} ${dimension}`,
        subTitle: `${material} - ${subclass}`,
        isATube,
        longerThan,
        affiliateURL,
        noProvider,
        cartNotice,
      }
    }).filter(part => part !== null) as PartListItem[]

    const tubesFirst = partsShow.sort((a, b) => Number(b.isATube) - Number(a.isATube))

    const groupPartsToShow = GroupByList(
      tubesFirst, ({ title, subTitle, }) => `${title}/${subTitle}`
    )

    const result = Object.values(groupPartsToShow).map(({ values, }) => ({
      ...values[0],
      quantity: values.length,
      partIds: values.map(part => part.id),
    }))

    return result
  }

  return { getRows, partsInStock, partsNoStock, }
}