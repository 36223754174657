import React, { useState } from "react"
import { Html, useCursor } from "@react-three/drei"
import styled from "styled-components"
import { Vector3 } from "three"
import { createGlobalStyle } from "styled-components"

type Props = {
    position: Vector3,
    text: string,
    onPointerEnter?: () => void,
    onPointerLeave?: () => void,
    onClick?: () => void,
    color?: string,
    textColor?: string,
}

type LabelProps = {
    $hasHandlers: boolean,
    $color: string,
    $textColor: string,
}

const GlobalStyle = createGlobalStyle`
  .html-wrapper {
    pointer-events: none;
  }
`

function TextLabel({
    position,
    text,
    onPointerEnter,
    onPointerLeave,
    onClick,
    color = "#ffffff",
    textColor = "black",
}: Props) {
    const hasHandlers = Boolean(onPointerEnter || onPointerLeave || onClick)
    const pointerEvents = hasHandlers ? "auto" : "none"
    const [isHovered, setIsHovered,] = useState(false)
    useCursor(isHovered, "pointer")
    const onPointerEnterHandler = () => {
        setIsHovered(true)
        onPointerEnter?.()
    }
    const onPointerLeaveHandler = () => {
        setIsHovered(false)
        onPointerLeave?.()
    }
    return (
        <>
            <GlobalStyle />
            <Html
                wrapperClass="html-wrapper"
                zIndexRange={[0, 0,]}
                position={position}
                className="overlay"
                pointerEvents={pointerEvents}
                center>
                <Label
                    onPointerEnter={onPointerEnterHandler}
                    onPointerLeave={onPointerLeaveHandler}
                    onClick={onClick}
                    className="ui-element"
                    $hasHandlers={hasHandlers}
                    $color={color}
                    $textColor={textColor}
                >
                    <p>{text}</p>
                </Label>
            </Html>
        </>
    )
}

const Label = styled.div<LabelProps>`
    box-sizing: border-box;
    width: auto;
    min-width: 47px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: ${props => (props.$hasHandlers ? "auto" : "none")};

    background: rgba(255, 255, 255, 0.75);
    ${props => props.$color && `background: ${props.$color};`}
    border: 1px solid #E5E5E5;
    box-shadow: 0px 0px 6.2px rgba(0, 0, 0, 0.05);
    border-radius: 9px;

    p {
        white-space: nowrap;
        text-align: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        padding: 0 5px;
        font-size: 12px;
        color: ${props => props.$textColor || "black"};
        margin: 0;
        pointer-events: inherit;
    }

    * {
        pointer-events: inherit;
    }
`

export default TextLabel