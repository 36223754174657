import React, { useState, useEffect } from "react"
import { Button } from "antd"
import { CustomTooltip as Tooltip } from "../../../../../../ui/CustomTooltip"
import styled from "styled-components"
import { ReactComponent as PencilIcon }
    from "../../../../../../../../common/assets/icons/Pencil.svg"

const ActionsContainer = styled.div`
    display: flex;
    gap: 5px;
`

const StyledPencilIcon = styled(PencilIcon)`
    width: 14px;
    height: 14px;
`

const ButtonText = styled.span<{ $visible: boolean, }>`
    position: absolute;
    opacity: ${props => (props.$visible ? 1 : 0)};
    transition: all 0.2s ease;
    white-space: nowrap;
    left: 50%;
    top: 50%;
    transform: ${props => (props.$visible
        ? "translate(-50%, -50%)"
        : "translate(-50%, calc(-50% + 5px))"
    )};
`

const ButtonContainer = styled.span`
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`

const ActionButton = styled(Button)`
    padding: 0 6px;
    height: 29px;
    border-radius: 7px;
    font-weight: 700;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #0089ff;
    color: #0089ff;
    transition: all 0.2s ease;

    &:not(:disabled).ant-btn:hover,
    &.active {
        background-color: #0089ee;
        color: white;
    }

    &.undo-button {
        background-color: #0089ee;
        color: white;
        width: 65px;
        padding: 0;
    }
`

interface PartListActionsProps {
    onSwapClick: (e: React.MouseEvent) => void;
    onEditClick: (e: React.MouseEvent) => void;
    onUndoClick?: (e: React.MouseEvent) => void;
    isUndo?: boolean;
    partName?: string;
    isSwapActive?: boolean;
    onSwapComplete?: () => void;
}

export const PartListActions: React.FC<PartListActionsProps> = ({
    onSwapClick,
    onEditClick,
    onUndoClick,
    isUndo = false,
    partName,
    isSwapActive = false,
    onSwapComplete,
}) => {
    const [showSwapped, setShowSwapped,] = useState(false)

    useEffect(() => {
        if (isUndo) {
            setShowSwapped(true)
            const timer = setTimeout(() => {
                setShowSwapped(false)
            }, 2000)
            return () => clearTimeout(timer)
        }
    }, [isUndo,])

    return (
        <ActionsContainer className="no-print">
            {isUndo && (
                <ActionButton
                    className="undo-button"
                    onClick={onUndoClick}
                >
                    <ButtonContainer>
                        <ButtonText $visible={showSwapped}>
                            Swapped!
                        </ButtonText>
                        <ButtonText $visible={!showSwapped}>
                            Undo
                        </ButtonText>
                    </ButtonContainer>
                </ActionButton>
            )}
            {!isUndo && (
                <ActionButton
                    className={`swap-button ${isSwapActive ? "active" : ""}`}
                    onClick={onSwapClick}
                >
                    Swap
                </ActionButton>
            )}
            <Tooltip title={`Select All ${partName}`}>
                <ActionButton
                    className="edit-button"
                    onClick={onEditClick}
                >
                    <StyledPencilIcon />
                </ActionButton>
            </Tooltip>
        </ActionsContainer>
    )
}