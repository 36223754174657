/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-negated-condition */
import React from "react"
import { useRecoilValue } from "recoil"
import styled from "styled-components"
import { layoutState } from "../../../../state/layoutState"
import isMobile from "ismobilejs"
import Header from "../header/Header"
import { motion } from "framer-motion"
import DesignsScreen from "../../../../../manager/components/Designs/Designs"
import PartsList from "./PartsModal/partListModal/PartsListModal"
import PartModal from "./PartsModal/PartsModal"
import useViewOnly from "../../../../providers/viewerProvider/useViewOnly"
import ModalProvider from "../../../../../common/providers/modalProvider/modalProvider"
import SelectedPartName from "../scene/SelectedPartName"
import { SceneRef } from "../../../../state/types"

interface Props {
    scene: JSX.Element;
    sceneRefs: SceneRef;
    partCount: number;
}

type MobileProps = {
    $isMobile: boolean,
}

const scene_variants = {
    "visible": {
        width: "calc(100% - 370px)",
    },
    "visible_mobile": {
        width: "100%",
    },
    "hidden": {
        width: "100%",
    },
}

const drawer_variants = {
    "visible": {
        x: "0%",
    },
    "hidden": {
        x: "100%",
    },
}

const drawerContent_variants = {
    "visible": {
        opacity: 1,
    },
    "hidden": {
        opacity: 0,
    },
}

const overlay_variants = {
    "visible": {
        opacity: 0.5,
    },
    "hidden": {
        opacity: 0,
    },
}

const WorkArea = (props: Props) => {
    const isViewOnly = useViewOnly()
    const layoutValue = useRecoilValue(layoutState)

    const getHeight = () => {
        return isMobile(window.navigator).phone || isViewOnly
            ? window.innerHeight
            : window.innerHeight - 44
    }

    const [dimensions, setDimensions,] = React.useState({
        height: getHeight(),
        width: window.innerWidth,
    })

    React.useEffect(() => {
        const handleResize = () => {
            setDimensions({
                height: getHeight(),
                width: window.innerWidth,
            })
        }

        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [isViewOnly,])
    return (
        <Container>
            <Canvas $isMobile={isMobile(window.navigator).phone}>
                {isMobile(window.navigator).phone && !isViewOnly && <Header isMobile={isMobile(window.navigator).phone} />}
                <Scene
                    $isMobile={isMobile(window.navigator).phone}
                    variants={!isMobile(window.navigator).phone ? scene_variants : {}}
                    animate={layoutValue.isDrawerOpen ? "visible" : "hidden"}>
                    <div className="holder"
                        style={{
                            width: dimensions.width,
                            height: dimensions.height,
                        }}>
                        {/* <Overlay
                            id="drawer-overlay"
                            style={{ pointerEvents: layoutValue.isDrawerOpen ? "all" : "none", }}
                            variants={overlay_variants}
                            animate={layoutValue.isDrawerOpen ? "visible" : "hidden"} /> */}
                        {props.scene}
                    </div>
                    <SelectedPartName isMobile={isMobile(window.navigator).phone} sceneRefs={props.sceneRefs} />
                    <ActionsContainer $isMobile={isMobile(window.navigator).phone} id="actions-container" />
                    {/* we dont have this footer anymore for mobile */}
                    {!isMobile(window.navigator).phone && <CameraButton $isMobile={isMobile(window.navigator).phone} id="camera_button" style={{ marginLeft: !isMobile(window.navigator).phone ? "-15px" : undefined, }}
                    />}
                </Scene>
            </Canvas>
            <Drawer
                $isMobile={isMobile(window.navigator).phone}
                variants={drawer_variants}
                animate={layoutValue.isDrawerOpen ? "visible" : "hidden"}>
                <DrawerContent
                    id="drawer-content"
                    variants={drawerContent_variants}
                    layout
                    animate={layoutValue.isDrawerOpen ? "visible" : "hidden"}>
                    <ModalProvider normal>
                        <PartModal />
                    </ModalProvider>
                    <PartsList sceneRefs={props.sceneRefs} />
                    <DesignsScreen sceneRefs={props.sceneRefs} />
                </DrawerContent>
            </Drawer>
        </Container>
    )
}

const CameraButton = styled.div<MobileProps>`
    position: absolute;
    bottom: ${(props) => (props.$isMobile ? 0 : "14px")};
    left: ${(props) => (props.$isMobile ? 0 : "45px")};
    width: ${(props) => (props.$isMobile ? "100%" : "auto")};
    height: ${(props) => (props.$isMobile ? "46px" : "auto")};
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: ${(props) => (props.$isMobile ? "white" : "transparent")};
    box-shadow: ${(props) => (props.$isMobile ? "0px -1px 4px rgb(0 0 0 / 12%)" : "none")};
    z-index: 1;
`

const Container = styled(motion.div)`
    position: relative;
    height: 100%;
    width: 100vw;
`

//this -1 is to cover the border basically.. should refactor to make this cleaner

const ActionsContainer = styled(motion.div) <MobileProps>`
    position: absolute;
    bottom: ${(props) => (props.$isMobile ? "-1px" : "0px")};
    left: 0;
    right: 0;
    margin: 0 auto;
    width: ${(props) => (props.$isMobile ? "90%" : "100%")};

`

const Canvas = styled(motion.div) <MobileProps>`
    background-color: white;
    height: 100%;
    /* position: absolute; */
`

const Scene = styled(motion.div) <MobileProps>`
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Overlay = styled(motion.div)`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 2;
`

const Drawer = styled(motion.div) <MobileProps>`
    border-left: 1px solid #DFDFDF;
    width: ${(props) => (props.$isMobile ? "100%" : "370px")};
    z-index: ${(props) => (props.$isMobile ? 3 : 2)};
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-color: white;
`

const DrawerContent = styled(motion.div)`
    width: 100%;
    background-color: white;
    height: 100%;
    position: relative;
`

export default WorkArea