/* eslint-disable max-len */
import { Camera, WebGLRenderer, Scene } from "three"

import { Vector3 } from "three"
import { MeasurementLines, ScalerOBBResult } from "../components/ScalerUILines"
import { LineSet } from "../components/Nxer"
import { getDirectionMultiplierFromVector } from "../components/main/DesignScreen/utils/scalerUtils"

export const pixelsTo3DUnits = (pixels: number, camera: Camera, targetZ = 0, gl: WebGLRenderer) => {
    // Get two points in screen space, separated by the pixel distance
    const startPoint = new Vector3()
    const endPoint = new Vector3()

    // Get the camera's position and target distance
    const cameraPosition = camera.position.clone()
    const targetPoint = new Vector3(0, 0, targetZ)
    const distanceToTarget = cameraPosition.distanceTo(targetPoint)

    // Convert to NDC space (-1 to +1)
    startPoint.x = (0 / gl.domElement.clientWidth) * 2 - 1
    startPoint.y = -(0 / gl.domElement.clientHeight) * 2 + 1
    startPoint.z = -1 // Use near plane

    endPoint.x = (pixels / gl.domElement.clientWidth) * 2 - 1
    endPoint.y = -(0 / gl.domElement.clientHeight) * 2 + 1
    endPoint.z = -1 // Use near plane

    // Unproject to get world space coordinates
    startPoint.unproject(camera)
    endPoint.unproject(camera)

    // Calculate the direction vectors
    const startDir = startPoint.sub(camera.position).normalize()
    const endDir = endPoint.sub(camera.position).normalize()

    // Scale the vectors to hit the target distance
    const startWorldPos = camera.position.clone().add(startDir.multiplyScalar(distanceToTarget))
    const endWorldPos = camera.position.clone().add(endDir.multiplyScalar(distanceToTarget))

    // Return the world space distance
    return startWorldPos.distanceTo(endWorldPos)
}

export const calculateDistanceAndOffset = (
    event: { clientX: number, clientY: number, },
    originalMouseXY: { x: number, y: number, },
    activeLine: LineInfo,
    camera: Camera,
    gl: WebGLRenderer,
    linePoints: LineInfo[],
    scene?: Scene,
    debug = false,
    drawCanvasSplit = false,
) => {
    const diffinX = Math.abs(event.clientX - originalMouseXY.x)
    const diffinY = Math.abs(event.clientY - originalMouseXY.y)


    const { multiplier: directionMultiplier, orientation, } = getDirectionMultiplierFromVector(
        activeLine,
        originalMouseXY,
        { x: event.clientX, y: event.clientY, },
        camera,
        activeLine.growthDirection ?? new Vector3(),
        scene,
        debug,
        drawCanvasSplit
    )

    const cursorType = orientation === "horizontal" ? "ew-resize" : "ns-resize"
    const diffWeCareAbout = cursorType === "ns-resize" ? diffinY : diffinX

    const rawDistance = pixelsTo3DUnits(Math.abs(diffWeCareAbout), camera, undefined, gl)
    const distance = rawDistance * Math.sign(diffWeCareAbout) * directionMultiplier

    const offset = activeLine.growthDirection?.clone().multiplyScalar(distance) ?? new Vector3()

    if (debug) {
        console.group("Distance and Offset Debug")
        console.log("Cursor Type:", cursorType)
        console.log("Mouse Movement - X:", diffinX, "Y:", diffinY)
        console.log("Using Movement:", diffWeCareAbout)
        console.log("Raw Distance:", rawDistance)
        console.log("Direction Multiplier:", directionMultiplier)
        console.log("Final Distance:", distance)
        console.log("Final Offset:", offset)
        console.groupEnd()
    }

    return { distance, offset, }
}

export const calculateDashParameters = (
    points: Vector3[],
    isMobileDevice: boolean,
    camera?: Camera,
    cameraDistance?: number
) => {
    const lineLength = points[0].distanceTo(points[1])
    const distance = cameraDistance ?? camera?.position.distanceTo(points[0]) ?? 1

    // Define min and max dash sizes that look good visually
    const minDashSize = (isMobileDevice ? 0.2 : 0.1) * (distance / 10)
    const maxDashSize = (isMobileDevice ? 0.8 : 0.4) * (distance / 5)

    // Calculate how many dashes would fit using min and max sizes
    const maxPossibleDashes = Math.floor(lineLength / minDashSize)
    const minPossibleDashes = Math.max(3, Math.floor(lineLength / maxDashSize))

    // Choose number of dashes that keeps dash size within our bounds
    // and scales reasonably with line length
    const targetDashSize = lineLength / Math.max(minPossibleDashes,
        Math.min(maxPossibleDashes,
            Math.floor(lineLength / (minDashSize * 1.5))
        )
    )

    // Ensure we stay within our size bounds
    const finalDashSize = Math.max(minDashSize, Math.min(maxDashSize, targetDashSize))
    let totalDashes = Math.floor(lineLength / finalDashSize)

    // Ensure minimum of 3 dashes
    if (totalDashes < 2) {
        totalDashes = 2
        const totalSize = lineLength / totalDashes
        // When forcing 2 dashes, we'll use 60% of space for dash, 40% for gap
        return {
            dashSize: totalSize * 0.6,
            gapSize: totalSize * 0.4,
        }
    }

    // Normal case - Adjust dash and gap size to fit evenly along the line
    const totalSize = lineLength / totalDashes
    const dashSize = totalSize * 0.6
    const gapSize = totalSize * 0.4

    return { dashSize, gapSize, }
}

export function createMeasurementLines(result: ScalerOBBResult): MeasurementLines {
    const { startFace, endFace, } = result

    // For each face, we'll create width and height lines
    // Width lines will be at the vertical center
    // Height lines will be at the horizontal center

    // Helper function to get center point between two vectors
    const getMidpoint = (v1: Vector3, v2: Vector3): Vector3 => {
        return new Vector3().addVectors(v1, v2)
            .multiplyScalar(0.5)
    }

    // Start face measurements
    const startLeft = getMidpoint(startFace[0], startFace[3])    // Left middle
    const startRight = getMidpoint(startFace[1], startFace[2])   // Right middle
    const startTop = getMidpoint(startFace[2], startFace[3])     // Top middle
    const startBottom = getMidpoint(startFace[0], startFace[1])  // Bottom middle

    // End face measurements
    const endLeft = getMidpoint(endFace[0], endFace[3])         // Left middle
    const endRight = getMidpoint(endFace[1], endFace[2])        // Right middle
    const endTop = getMidpoint(endFace[2], endFace[3])          // Top middle
    const endBottom = getMidpoint(endFace[0], endFace[1])       // Bottom middle

    return {
        startFace: {
            width: [startLeft, startRight,],     // Horizontal line at vertical center
            height: [startBottom, startTop,],     // Vertical line at horizontal center
        },
        endFace: {
            width: [endLeft, endRight,],         // Horizontal line at vertical center
            height: [endBottom, endTop,],         // Vertical line at horizontal center
        },
    }
}

export type LineInfo = {
    points: [Vector3, Vector3],
    viewType: "width" | "height" | "depth",
    position: "left" | "right" | "top" | "bottom",
    offsetDirection?: Vector3,
    perpendicularDirection?: Vector3,
    growthDirection?: Vector3,
    color?: string,
    perpendicularMatchingDimension?: string,
}


export type LineInfoNX = {
    points: [Vector3, Vector3],
    viewType: "width" | "height" | "depth",
    position: "left" | "right" | "top" | "bottom",
    offsetDirection?: Vector3,
    perpendicularDirection?: Vector3,
    growthDirection?: Vector3,
    isPerpendicularToNormal?: boolean,
    color?: string,
}
function dimensionLinesToLineSets(lines: { start: Vector3, end: Vector3, }[], dimension: "width" | "height" | "depth", direction: Vector3): LineSet[] {
    return lines.map(line => ({
        points: [line.start, line.end,] as [Vector3, Vector3],
        dimension,
        direction,
    }))
}

export function convertDimensionLinesToLinePoints(
    dimensionLines: {
        width: { start: Vector3, end: Vector3, }[],
        height: { start: Vector3, end: Vector3, }[],
        depth: { start: Vector3, end: Vector3, }[],
    },
    directions: {
        widthDirection: Vector3,
        heightDirection: Vector3,
        depthDirection: Vector3,
        widthPerpendicularDirection: Vector3,
        heightPerpendicularDirection: Vector3,
        depthPerpendicularDirection: Vector3,
        widthPerpendicularMatchingDimension?: string,
        heightPerpendicularMatchingDimension?: string,
        depthPerpendicularMatchingDimension?: string,
    }
): LineInfo[] {
    const convertedLines = {
        width: dimensionLinesToLineSets(dimensionLines.width, "width", directions.widthDirection),
        height: dimensionLinesToLineSets(dimensionLines.height, "height", directions.heightDirection),
        depth: dimensionLinesToLineSets(dimensionLines.depth, "depth", directions.depthDirection),
    }

    const linePoints: LineInfo[] = []

    // Helper function to calculate center of a line
    const getLineCenter = (line: { start: Vector3, end: Vector3, }) => {
        return new Vector3().addVectors(line.start, line.end)
            .multiplyScalar(0.5)
    }

    // Helper function to determine growth direction for a pair of lines
    const getGrowthDirections = (line1: { start: Vector3, end: Vector3, }, line2: { start: Vector3, end: Vector3, }, perpDirection: Vector3) => {
        const center1 = getLineCenter(line1)
        const center2 = getLineCenter(line2)
        const centerToCenter = new Vector3().subVectors(center2, center1)

        // If perpDirection points from line1 to line2, flip it
        const baseDirection = centerToCenter.dot(perpDirection) > 0
            ? perpDirection.clone().negate()
            : perpDirection.clone()

        return {
            line1Direction: baseDirection,
            line2Direction: baseDirection.clone().negate(),
        }
    }

    // Process width lines
    const widthGrowthDirs = getGrowthDirections(
        dimensionLines.width[0],
        dimensionLines.width[1],
        directions.widthPerpendicularDirection
    )
    convertedLines.width.forEach((line, index) => {
        const position = index === 0 ? "left" : "right"
        linePoints.push({
            points: line.points,
            viewType: "width",
            position,
            offsetDirection: directions.widthDirection.clone(),
            perpendicularDirection: directions.widthPerpendicularDirection.clone(),
            growthDirection: index === 0 ? widthGrowthDirs.line1Direction : widthGrowthDirs.line2Direction,
            color: "red",
            perpendicularMatchingDimension: directions.widthPerpendicularMatchingDimension,
        })
    })

    // Process height lines
    const heightGrowthDirs = getGrowthDirections(
        dimensionLines.height[0],
        dimensionLines.height[1],
        directions.heightPerpendicularDirection
    )
    convertedLines.height.forEach((line, index) => {
        const position = index === 0 ? "top" : "bottom"
        linePoints.push({
            points: line.points,
            viewType: "height",
            position,
            offsetDirection: directions.heightDirection.clone(),
            perpendicularDirection: directions.heightPerpendicularDirection.clone(),
            growthDirection: index === 0 ? heightGrowthDirs.line1Direction : heightGrowthDirs.line2Direction,
            color: "green",
            perpendicularMatchingDimension: directions.heightPerpendicularMatchingDimension,
        })
    })

    // Process depth lines
    const depthGrowthDirs = getGrowthDirections(
        dimensionLines.depth[0],
        dimensionLines.depth[1],
        directions.depthPerpendicularDirection
    )
    convertedLines.depth.forEach((line, index) => {
        const position = index === 0 ? "left" : "right"
        linePoints.push({
            points: line.points,
            viewType: "depth",
            position,
            offsetDirection: directions.depthDirection.clone(),
            perpendicularDirection: directions.depthPerpendicularDirection.clone(),
            growthDirection: index === 0 ? depthGrowthDirs.line1Direction : depthGrowthDirs.line2Direction,
            color: "blue",
            perpendicularMatchingDimension: directions.depthPerpendicularMatchingDimension,
        })
    })

    return linePoints
}