import React, { useRef } from "react"
import { Html } from "@react-three/drei"
import { Vector3 } from "three"
import { motion } from "framer-motion"
import dragSVG from "../../../../../../../../../common/assets/hand-cursor.svg"

interface MoveOnboardingProps {
    center: Vector3;
    hide: boolean;
    uniqueId: string;
}

const MoveOnboarding = ({ center, hide, uniqueId, }: MoveOnboardingProps) => {
    const ref = useRef<HTMLDivElement>(null)

    return (
        <Html ref={ref} position={center}
            key={uniqueId}
            style={{ pointerEvents: "none", }} zIndexRange={[1, 0,]}>
            <motion.div
                initial={{ opacity: 1, }}
                animate={{ opacity: hide ? 0 : 1, }}
                transition={{ duration: 0.5, }}
                style={{ pointerEvents: "none", }}
            >
                <motion.img
                    src={dragSVG}
                    alt="Drag instruction"
                    style={{
                        width: "50px",
                        height: "50px",
                        opacity: 0.7,
                    }}
                    animate={{
                        x: [-10, 0, -10,],
                    }}
                    transition={{
                        duration: 0.8,
                        repeat: 3,
                        ease: "easeInOut",
                    }}
                />
            </motion.div>
        </Html>
    )
}


export default MoveOnboarding
