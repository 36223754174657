import { motion } from "framer-motion"
import styled from "styled-components"
import { CONNECTOR_UI } from "./connector/types/types"
import { SEGMENTED_TUBE_UI } from "./segmentedTube/types/types"

type ActionsContainerProps = {
    $partUI?: CONNECTOR_UI | SEGMENTED_TUBE_UI | "multiselect",
    $isMobilePhone?: boolean,
}

export const ActionsContainer = styled(motion.div) <ActionsContainerProps>`
    position: absolute;
    height: fit-content;
    width: 100%;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    right: 0;
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: ${({ $partUI, $isMobilePhone, }) => {
        if ($isMobilePhone) {
            return 0
        }
        return $partUI === "multiselect" ? -3 : -17
    }}px;
    border-radius: 6px;
    background-color: #ffffff7a;
    box-shadow: ${(
        { $isMobilePhone, }
    ) => ($isMobilePhone ? "none" : "1px 5px 15px -6px #0000007a")};
`

export const SliderContainer = styled.div<{ $isMobile: boolean, }>`
    display: flex;
    flex-direction: column;
    background-color: #ffffff7a;
    backdrop-filter: blur(10px);
    border: 1px solid #d2d3d2;
    border-bottom-left-radius: ${(props) => (props.$isMobile ? "0px" : "6px")};
    border-bottom-right-radius: ${(props) => (props.$isMobile ? "0px" : "6px")};
`

export const MultipleCheckBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
    background-color: #ffffff7a;
    backdrop-filter: blur(10px);
    border-radius: 0 0 6px 6px;
    padding: 13px;
    font-size: 14px;
    border: 1px solid #d2d3d2;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: grey;
    justify-content: space-around;
`

export const MultipleCheckBoxItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    input[type="checkbox"] {
        margin: 0;
    }
`

export const SimpleCheckBoxContainer = styled.div<{ $isMobile: boolean, }>`
    display: flex;
    flex-direction: column;
    background-color: #ffffff7a;
    backdrop-filter: blur(10px);
    border-radius: ${(props) => (props.$isMobile ? "0px" : "0 0 6px 6px")};
    padding: 13px;
    font-size: 14px;
    border: 1px solid #d2d3d2;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color:grey;

`

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #00000024;
    padding: 14px;
    margin: 0;
`

export const HeaderContainerCompact = styled.div<{ $isMobile: boolean, }>`
    display: flex;
    justify-content: center;
    border-bottom: 0px solid #00000024;
    padding: ${(props) => (props.$isMobile ? "5px" : "14px")};
    padding-bottom: 0;
    margin: 0;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color:grey;
    font-size:13px;
`

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #00000024;
`

export const Title = styled.h3`
    font-size: 16px;
    padding: 14px;
    margin: 0;
`

export const SegmentedTubeSliderTitle = styled.h3`
    font-size: 16px;
    margin: 0;
`

export const ButtonContainer = styled.div`
    padding: 14px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
`

export const DoubleButtonContainer = styled.div`
    width: 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    button:first-child {
        border-radius: 6px 0px 0px 6px;
    }
    button:last-child {
        border-radius: 0px 6px 6px 0px;
    }
`

export enum DoubleButtonSideEnum {
    LEFT = "LEFT",
    RIGTH = "RIGTH"
}

export const DoubleButton = styled.button`
    z-index: 2;
    height: 25px;
    cursor: pointer
    padding: 2px;
    border: 1px solid #00000024;
    border-radius: 4px;
    display: flex;
    position: relative;
    align-items: center;
`

export const LeftButton = styled.button`
    z-index: 2;
    height: 25px;
    cursor: pointer;
    padding: 2px;
    border: 1px solid #00000024;
    border-radius: 4px 0 0 4px; // Adjusted border radius
    display: flex;
    position: relative;
    align-items: center;
`

export const RightButton = styled.button`
    z-index: 2;
    height: 25px;
    cursor: pointer;
    padding: 2px;
    border: 1px solid #00000024;
    border-radius: 0 4px 4px 0; // Adjusted border radius
    display: flex;
    position: relative;
    align-items: center;
`

export const LeftContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 16px;
`

export const RightContent = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 16px;
    margin-right: 2vw;
`

export const ActionButtonContainer = styled.div`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;

    button{
        flex-grow: 1;
        border-radius: 0;
    }
    button:first-child {
        border-radius: 6px 0px 0px 6px;
    }
    button:last-child:not(:first-child) {
        border-left: none;
        border-radius: 0px 6px 6px 0px;
    }
    button:only-child {
        border-radius: 6px;
    }
    button:not(:first-child):not(:last-child) {
        border-left: none;
    }
    button:nth-child(2):last-child {
        border-left: none;
    }
`

export const NumberSliderContainer = styled.div`
    padding: 0 17px 17px;
`