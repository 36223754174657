import React from "react"
import styled from "styled-components"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { multiSelectionSelector } from "../../../../state/scene/selectors"
import { scenePartsSelector } from "../../../../state/scene/atoms"
import { layoutState } from "../../../../state/layoutState"
import { SceneRef } from "../../../../state/types"

const StyledCount = styled.span`
  text-decoration: underline;
  cursor: pointer;
  &.StyledCount {
    pointer-events: auto;
  }
`

interface MultiSelectionInfoProps {
    sceneRefs: SceneRef;
    isMobile?: boolean;
}

const MultiSelectionInfo = ({ sceneRefs, isMobile, }: MultiSelectionInfoProps) => {
    const multiSelection = useRecoilValue(multiSelectionSelector)
    const sceneAtomList = useRecoilValue(scenePartsSelector)
    const setLayout = useSetRecoilState(layoutState)

    // Filter out null parts and get their apiTypeIds
    const validParts = multiSelection
        .map(partId => sceneAtomList[partId])
        .filter((part): part is NonNullable<typeof part> =>
            part !== null && part !== undefined && part.apiTypeId !== null
        )

    // Count unique apiTypeIds from valid parts
    const uniqueTypes = new Set(
        validParts.map(part => part.apiTypeId)
    ).size

    const validPartsCount = validParts.length

    const handleClick = () => {
        setLayout(draft => ({
            ...draft,
            showPartList: true,
            isDrawerOpen: true,
            showDesignsList: false,
            from: "multiSelectionInfo",
        }))
    }

    if (validPartsCount === 0) {
        return null
    }

    return (
        <StyledCount onClick={handleClick} className="StyledCount">
            {validPartsCount > 1
                ? `Selected: ${uniqueTypes} Part Types, ${validPartsCount} Total`
                : `Selected: ${uniqueTypes} Part Types`}
        </StyledCount>
    )
}

export default MultiSelectionInfo