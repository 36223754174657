/* eslint-disable max-len */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import React, { useCallback, useEffect, useRef, useState } from "react"
import tinycolor from "tinycolor2"
import { MeshBasicMaterial, Vector3 } from "three"
import { RulerPointCube } from "./RulerPointCube"
import { PointInfo, RulerPointsProps, Point } from "./types"
import PreviewMeasurementLine from "./PreviewMeasurementLine"
import { ThreeEvent } from "@react-three/fiber"
import { messageUtils } from "../../components/main/DesignScreen/scene/LowerRightMessages"
import { useLevaControls } from "../debugProvider/useLevaControls"
import hotkeys from "hotkeys-js"
import { useCursor } from "@react-three/drei"
import { selectedItemID } from "../../exportables"
import { useRecoilValue } from "recoil"
import isMobile from "ismobilejs"

const hitAreaMaterial = new MeshBasicMaterial({
    color: "green",
    transparent: true,
    opacity: 0.5,
})

const inactiveMaterial = new MeshBasicMaterial({
    color: "blue",
    transparent: true,
    opacity: 0.5,
})

const activeMaterial = new MeshBasicMaterial({
    color: "blue",
    transparent: true,
    opacity: 1.0,
})

const RulerPoints: React.FC<RulerPointsProps> = ({
    partDataRef,
    show,
    onRelationshipCreated,
    partsWithFaces,
    sceneRefs,
}) => {
    const [lastClickedPoint, setLastClickedPoint,] = useState<PointInfo | null>(null)
    const [hasCursorPosition, setHasCursorPosition,] = useState(false)
    const hoveredPointsRef = useRef(new Set<string>())
    useCursor(hasCursorPosition, "grabbing")
    const selectedPart = useRecoilValue(selectedItemID)
    const isMobileAny = isMobile().any
    const { rulerDebug, } = useLevaControls()
    // Add cancel measurement function
    const cancelMeasurement = () => {
        if (lastClickedPoint) {
            setLastClickedPoint(null)
        }
    }

    // Add ESC key handler
    useEffect(() => {
        hotkeys("esc", (event) => {
            event.preventDefault()
            cancelMeasurement()
        })

        return () => {
            hotkeys.unbind("esc")
        }
    }, [lastClickedPoint,])

    useEffect(() => {
        if (selectedPart) {
            cancelMeasurement()
        }
    }, [selectedPart,])

    // Three.js right click handler
    const handleThreeRightClick = (event: ThreeEvent<MouseEvent>) => {
        event.stopPropagation()
        cancelMeasurement()
    }

    const handleDOMRightClick = useCallback((event: MouseEvent) => {
        if (show && lastClickedPoint) {
            event.preventDefault()
            event.stopPropagation()
            cancelMeasurement()
        }
    }, [show, lastClickedPoint,])

    // Add DOM right click handler
    useEffect(() => {
        document.addEventListener("contextmenu", handleDOMRightClick)

        return () => {
            document.removeEventListener("contextmenu", handleDOMRightClick)
        }
    }, [handleDOMRightClick,])

    const getPartColor = (partId: string) => {
        return tinycolor.random().toRgb()
    }

    const handlePointClick = ({
        partId,
        pointId,
        position,
    }: {
        partId: string,
        pointId: string,
        position: [number, number, number],
    }) => {
        const pointInfo: PointInfo = {
            partId,
            pointId,
            position: new Vector3(...position),
        }

        if (lastClickedPoint) {
            if (onRelationshipCreated) {
                onRelationshipCreated(lastClickedPoint, pointInfo)
            }
            setLastClickedPoint(null)
        } else {
            setLastClickedPoint(pointInfo)
            messageUtils.custom("Click on another point to create a measurement!", {
                duration: 20,
                showUpTo: 3,
                minTimeBetweenShows: 30,
                showCloseIcon: true,
            })
        }
    }

    // Add this to track rendered positions
    const renderedPositions = new Set<string>()

    // Update getPositionKey to handle Vector3
    const getPositionKey = (position: Vector3 | [number, number, number]) => {
        if (position instanceof Vector3) {
            return `${position.x}_${position.y}_${position.z}`
        }
        return `${position[0]}_${position[1]}_${position[2]}`
    }

    const getPointKey = (partId: string, pointId: string) => `${partId}-${pointId}`

    const handlePointerEnter = (partId: string, pointId: string) => {
        hoveredPointsRef.current.add(getPointKey(partId, pointId))
    }

    const handlePointerLeave = (partId: string, pointId: string) => {
        hoveredPointsRef.current.delete(getPointKey(partId, pointId))
    }

    if (!show) {
        return null
    }
    return (
        <group onContextMenu={handleThreeRightClick}>
            {Object.entries(partsWithFaces).map(([partId, faces,]) => {
                const color = getPartColor(partId)
                return Object.keys(faces).map((faceName) => {
                    const face = faces[faceName as keyof typeof faces]
                    return face.points.map((point: Point) => {
                        const position = point.position instanceof Vector3
                            ? point.position
                            : new Vector3(...(point.position as [number, number, number]))

                        const positionKey = getPositionKey(position)
                        if (renderedPositions.has(positionKey)) { return null }
                        renderedPositions.add(positionKey)

                        const position3 = [position.x, position.y, position.z,] as [number, number, number]
                        const pointKey = { partId, pointId: point.id, position: position3, }

                        return (
                            <RulerPointCube
                                key={`${pointKey.partId}-${pointKey.pointId}`}
                                position={position3}
                                color={color}
                                pointKey={pointKey}
                                partId={pointKey.partId}
                                pointId={pointKey.pointId}
                                onClick={handlePointClick}
                                onPointerEnter={handlePointerEnter}
                                onPointerLeave={handlePointerLeave}
                                isSelected={
                                    lastClickedPoint?.partId === pointKey.partId
                                    && lastClickedPoint?.pointId === pointKey.pointId
                                }
                                rulerDebug={rulerDebug}
                                hitAreaMaterial={hitAreaMaterial}
                                inactiveMaterial={inactiveMaterial}
                                activeMaterial={activeMaterial}
                                isMobile={isMobileAny}
                            />
                        )
                    }).filter(Boolean)
                })
            })}

            {/* Preview measurement line */}
            {lastClickedPoint && !isMobileAny && (
                <PreviewMeasurementLine
                    point1={lastClickedPoint}
                    isPreview={true}
                    color="#000000"
                    hoveredPoints={hoveredPointsRef.current}
                    sceneRefs={sceneRefs}
                />
            )}
        </group>
    )
}

export default RulerPoints