import React, { useEffect, useState } from "react"
import { GizmoHelper, GizmoViewcube } from "@react-three/drei"
import { useRecoilValue } from "recoil"
import { layoutState } from "../../state/layoutState"
import isMobile from "ismobilejs"

const DRAWER_WIDTH = 185
const TRANSITION_DURATION = 1000

const getGizmoMargin = (isDrawerOpen: boolean, isMobileDevice: boolean) => {
    // For mobile phones, position in top-left corner
    if (isMobileDevice) {
        return [50, 140,] as [number, number]
    }

    // Desktop positioning remains the same
    const baseLeftMargin = 60
    const leftMargin = isDrawerOpen ? baseLeftMargin + DRAWER_WIDTH : baseLeftMargin
    return [leftMargin, 100,] as [number, number]
}

const getGizmoAlignment = (isMobileDevice: boolean) => {
    return isMobileDevice ? "top-left" : "bottom-left"
}

export const CameraGizmo: React.FC = () => {
    const layoutValue = useRecoilValue(layoutState)
    const isDrawerOpen = layoutValue.isDrawerOpen

    // Specifically check for mobile phones
    const isMobileDevice = isMobile(window.navigator).phone
    const targetMargin = getGizmoMargin(isDrawerOpen, isMobileDevice)
    const [currentMargin, setCurrentMargin,] = useState(targetMargin)
    const alignment = getGizmoAlignment(isMobileDevice)

    useEffect(() => {
        const startMargin = currentMargin[0]
        const endMargin = targetMargin[0]
        const startTime = Date.now()

        const animate = () => {
            const elapsed = Date.now() - startTime
            const progress = Math.min(elapsed / TRANSITION_DURATION, 1)

            const easeOut = 1 - Math.pow(1 - progress, 3)

            const newLeftMargin = startMargin + (endMargin - startMargin) * easeOut
            setCurrentMargin([newLeftMargin, currentMargin[1],])

            if (progress < 1) {
                requestAnimationFrame(animate)
            }
        }

        animate()
    }, [isDrawerOpen,])

    return (
        <GizmoHelper
            visible={false}
            alignment={alignment}
            margin={currentMargin}
        >
            <group scale={0.8}>
                <GizmoViewcube
                    strokeColor="black"
                    color="white"
                    textColor="black"
                    opacity={1}
                    onClick={(e) => {
                        // deactivate clicking behavior, cause it fires unexpectedly
                        return null
                    }}
                />
            </group>
        </GizmoHelper>
    )
}