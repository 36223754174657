
import React, { useEffect, useState } from "react"
import Button from "../../../../../../../../common/components/Button"
import { breadcrumb } from "../../../../../../../../common/utils/sentrySetup"
import {
    ButtonContainer,
    NumberSliderContainer,
} from "../ActionPanelStyles"
import { SliderContainer } from "../segmentedTube/ui/ActionPanelStyles"

import NumberSlider from "./Slider"
import isMobile from "ismobilejs"

interface Props {
    onRotationChange: (value: number) => void;
    onRotationSave: (value: number) => void;
    handleMouseDown: () => void;
    handleMouseUp: () => void;
    onFinishEditing: () => void;
    getRotation: () => number;
    steps: number;
    auxButtons?: JSX.Element;
}

enum SliderFrom {
    change = "change",
    mouseUp = "mouseUp"
}

const RotationSlider = (props: Props) => {
    const isMobilePhone = isMobile(window.navigator).phone

    const [slider, setSlider,] = useState<{ value: number, from: SliderFrom | undefined, }>(() => {
        return {
            value: props.getRotation(),
            from: undefined,
        }
    })

    useEffect(() => {
        setSlider({
            value: props.getRotation(),
            from: undefined,
        })
    }, [props.getRotation,])

    useEffect(() => {
        if (slider.from === SliderFrom.change) {
            props.onRotationChange(slider.value)
        }
        if (slider.from === SliderFrom.mouseUp) {
            props.onRotationSave(slider.value)
        }
    }, [slider,])

    const handleSlider = (e: any) => {
        setSlider({ value: Number(e), from: SliderFrom.change, })
    }

    const handleMouseUp = (e: any) => {
        setSlider({ value: Number(e), from: SliderFrom.mouseUp, })
        props.handleMouseUp()
    }

    const getValue = () => {
        return slider.value
    }

    const handleFinish = () => {
        breadcrumb({
            message: "User done rotating part",
            level: "info",
            data: {
                rotation: getValue(),
            },
        })
        props.onFinishEditing()
    }

    return <SliderContainer $isMobile={isMobilePhone}>
        <NumberSliderContainer>
            <NumberSlider
                readonly
                checkSteps
                unit="deg"
                min={0}
                max={360}
                step={props.steps}
                handleMouseUp={handleMouseUp}
                onChange={handleSlider}
                getValue={getValue}
                handleMouseDown={props.handleMouseDown}
                marks={true}
                inputNumber={false}
            />
        </NumberSliderContainer>
    </SliderContainer>
}

export default RotationSlider