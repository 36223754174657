/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { MutableRefObject } from "react"
import { RecoilState, useRecoilValue } from "recoil"
import { allConnections } from "../../../../../../../../state/scene/selectors"
import { ConnectionOfPart } from "../../../../../../../../state/scene/types"
import { getSideAndPosition } from "../../../../../../../../utils/MarkerUtil"
import { SegmentedTubeInfo, SegmentedTubeMarker } from "../types/types"
import NewPartButton from "./NewPartButton"
import segmentedTubeUtils from "../utils/SegmentedTubeUtils"
import { Group, Mesh, Object3D, Scene, Vector3 } from "three"
import { XYZ, XYZW } from "../../../../../../../../utils/Types"
import { SceneRef } from "../../../../../../../../state/types"

type NewPartButtonsInfo = {
  partId: string,
  info: MutableRefObject<SegmentedTubeInfo>,
  length: number,
  partConnectionsValue: ConnectionOfPart[],
  rotation: XYZW,
  position: XYZ,
  setIsSelected: (selected: boolean) => void,
  scene?: Scene,
  isItemSelected?: (param: string) => RecoilState<boolean>,
  sceneRefs: SceneRef,
}

const BATCH_SIZE = 1 // Adjust this value based on performance

const NewPartButtonsGroup = (props: NewPartButtonsInfo) => {
  const connections = useRecoilValue(allConnections)
  const [renderedCount, setRenderedCount,] = useState(0)
  const [markers, setMarkers,] = useState<(SegmentedTubeMarker & { isMiddleSection: boolean, })[]>([])

  useEffect(() => {
    if (renderedCount < markers.length) {
      const timeoutId = setTimeout(() => {
        setRenderedCount(prev => Math.min(prev + BATCH_SIZE, markers.length))
      }, 0)
      return () => clearTimeout(timeoutId)
    }
  }, [renderedCount, markers.length,])

  const getAllRelevantMeshes = useMemo(() => {
    const relevantMeshes: Object3D[] = []

    if (props.scene) {
      props.scene.traverse((object) => {
        if (object instanceof Mesh
          && object.name.includes("boxMesh")
          && object.userData.partId !== props.partId) {
          relevantMeshes.push(object)
        }
      })
    }

    return relevantMeshes
  }, [props.partId,])

  const getMarkers = useCallback(() => {
    const markers: (SegmentedTubeMarker & { isMiddleSection: boolean, })[] = []

    Object.keys(props.info.current.startSection).forEach(key => {
      if (props.partConnectionsValue.every(c => {
        const { markerSide: markerNumber, } = getSideAndPosition(c.partMarkerName)
        const firstKey = Object.keys(props.info.current.startSection)[0]
        if (props.info.current.startSection[firstKey] && "mesh" in props.info.current.startSection[firstKey]) {
          return true
        } else {
          return markerNumber !== key
        }
      })) {
        markers.push({ ...props.info.current.startSection[key], isMiddleSection: false, })
      }
    })

    Object.keys(props.info.current.endSection).forEach(key => {
      if (props.partConnectionsValue.every(c => {
        const { markerSide: markerNumber, } = getSideAndPosition(c.partMarkerName)
        const firstKey = Object.keys(props.info.current.endSection)[0]
        if (props.info.current.endSection[firstKey] && "mesh" in props.info.current.endSection[firstKey]) {
          return true
        } else {
          return markerNumber !== key
        }
      })) {
        markers.push({ ...props.info.current.endSection[key], isMiddleSection: false, })
      }
    })
    //console.log(props.info.current.middleSection, "middleSection")
    if (Object.keys(props.info.current.middleSection).length) {
      Object.keys(props.info.current.middleSection).forEach(key => {
        // Remove the condition that checks for connections
        const section = props.info.current.middleSection[key]
        // Assuming the first item in the section array is the default marker
        markers.push({ ...section[0], isMiddleSection: true, })
      })
    }
    return markers
  }, [props.info.current.middleSection, props.partConnectionsValue, props.length, props.partId,])

  useEffect(() => {
    setMarkers(getMarkers())
  }, [getMarkers,])

  useEffect(() => {
    if (renderedCount < markers.length) {
      const timeoutId = setTimeout(() => {
        setRenderedCount(prev => Math.min(prev + BATCH_SIZE, markers.length))
      }, 0)
      return () => clearTimeout(timeoutId)
    }
  }, [renderedCount, markers.length,])

  return (
    <>
      {markers.slice(0, renderedCount).map(marker =>
        marker && marker.outer && (
          <NewPartButton
            partId={props.partId}
            info={props.info}
            relevantMeshes={getAllRelevantMeshes}
            marker={marker}
            key={`${marker.outer.name}-${props.length}`}
            isMiddleSection={marker.isMiddleSection}
            setIsSelected={props.setIsSelected}
            scene={props.scene}
            partConnectionsValue={props.partConnectionsValue}
            length={props.length}
            sceneRefs={props.sceneRefs}
          />
        )
      )}
    </>
  )
}

export default NewPartButtonsGroup