/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import React, { useState } from "react"
import { Collapse } from "antd"
import { partsListStyles, rowTemplate } from "./partsListHelpers"
import { PartTypeAPI } from "../../../../../../../../common/api/Types"
import { useRecoilValue, useRecoilCallback } from "recoil"
import { initialData } from "../../../../../../../state/atoms"
import { SwappablePartsList } from "./SwappablePartsList"
import styled from "styled-components"
import { SwapJob, swapJobsAtom, partsByApiTypeSelector } from "../../../../../../../state/scene/atoms"
import { useSetRecoilState } from "recoil"
import { openPartsComparisonWindow } from "./PartsComparisonWindow"
import { useMarkerComparison } from "../../../../../../../hooks/useMarkerComparison"
import { useComponentRegistry } from "../../../../../../../providers/multiselectProvider/useComponentMethods"
import { useLevaControls } from "../../../../../../../providers/debugProvider/useLevaControls"
import { Mesh } from "three"
import { PartListActions } from "./PartListActions"
import { layoutState } from "../../../../../../../state/layoutState"
import { produce } from "immer"
import { useHistoryNavigation } from "../../../../History"
import { SceneRef } from "../../../../../../../state/types"

interface SamePartsPanelProps {
    typeKey: string;
    sameParts: any[];
    handleItemHover: (itemIds: string[]) => void;
    handleItemLeave: (itemIds: string[]) => void;
    sceneRefs: SceneRef;
    getRows: (parts: any[]) => any[];
    isLastSwappedType?: boolean;
    handleUndoCallback?: () => void;
}

export type CompatiblePart = {
    part: PartTypeAPI,
    markerMap: { [key: string]: string, } | undefined,
}

const StyledCollapse = styled(Collapse)`
    margin-bottom: 10px;
`

const StyledCollapsePanel = styled(Collapse.Panel)`
    > .ant-collapse-content-box {
        padding: 0;
    }
`

export const SamePartsPanel: React.FC<SamePartsPanelProps> = ({
    typeKey,
    sameParts,
    handleItemHover,
    handleItemLeave,
    sceneRefs,
    getRows,
    isLastSwappedType,
    handleUndoCallback,
}) => {
    const allParts = useRecoilValue(initialData)
    const [compatibleParts, setCompatibleParts,] = useState<CompatiblePart[]>([])
    const [isSwapActive, setIsSwapActive,] = useState(false)
    const setSwapJobs = useSetRecoilState(swapJobsAtom)
    const { getQuaternionAndRunComparison, } = useMarkerComparison()
    const { getComponent, } = useComponentRegistry()
    const { multiswapDebug, } = useLevaControls()
    const setLayout = useSetRecoilState(layoutState)
    const { goToPreviousState, } = useHistoryNavigation()
    const [activeKeys, setActiveKeys,] = useState<string[]>([typeKey,])

    const getPartsOfType = useRecoilCallback(({ snapshot, }) => async (apiTypeId: string) => {
        return await snapshot.getPromise(partsByApiTypeSelector(apiTypeId))
    }, [])

    const getCompatiblePartsByType = (thisPart: PartTypeAPI, parts: PartTypeAPI[]) => {
        return parts?.filter(p => {
            if (p.id === thisPart.apiTypeId) {
                // ignore the same part
                return false
            }

            const sourceConnectorCounts = new Map()
            const targetConnectorCounts = new Map()

            thisPart.connections.forEach(c => {
                sourceConnectorCounts.set(c.connectorId,
                    ((sourceConnectorCounts.get(c.connectorId) as number) ?? 0) + 1)
            })

            p.connections.forEach(c => {
                targetConnectorCounts.set(c.connectorId,
                    ((targetConnectorCounts.get(c.connectorId) as number) ?? 0) + 1)
            })

            return Array.from(sourceConnectorCounts.entries()).every(([id, count,]) =>
                targetConnectorCounts.get(id) === count)
                && sourceConnectorCounts.size === targetConnectorCounts.size
        })
    }

    const getQuaternionCompatibleParts = async (thisPart: PartTypeAPI, parts: PartTypeAPI[]) => {
        // Now we can use getPartsOfType inside the async function
        const partsOfSameType = await getPartsOfType(thisPart.id)

        let thisPartComponent
        let markers

        for (const part of partsOfSameType) {
            thisPartComponent = getComponent(part.id)
            markers = thisPartComponent?.getAllMarkers(true) as Mesh[]

            if (thisPartComponent && markers) {
                break // Exit loop if valid component and markers are found
            }
        }

        if (!thisPartComponent || !markers) {
            throw new Error("No valid part component or markers found.")
        }

        const innerMarkers = markers.filter((m: Mesh) => m.name.includes("inner") || m.name.includes("TOP") || m.name.includes("BOTTOM"))

        const compatibleParts = []
        const incompatibleParts = []

        for (const part of parts) {
            const quaternionCompatibleParts = await getQuaternionAndRunComparison(thisPart, part, innerMarkers, {
                enabled: true,
                visualize: true,
            })
            if (quaternionCompatibleParts.isCompatible) {
                compatibleParts.push({
                    part,
                    markerMap: quaternionCompatibleParts.equivalences,
                })
            } else {
                incompatibleParts.push(part)
            }
        }

        return { compatibleParts, incompatibleParts, }
    }

    const findCompatibleParts = async (part: PartTypeAPI) => {
        // Ensure panel is expanded when swap is clicked
        setActiveKeys([typeKey,])

        const thisPart = allParts?.parts.find(p => p.id === part.apiTypeId)
        if (!thisPart) {
            setCompatibleParts([])
            setIsSwapActive(false)
            return
        }

        // Quick filter by connection count
        const partsWithSameNumberOfConnections = allParts?.parts.filter(p =>
            p.connections.length === thisPart.connections.length
        )

        // Detailed comparison of connector types
        const partsWithSameConnections = getCompatiblePartsByType(thisPart, partsWithSameNumberOfConnections!)
        const compatiblePartsList = (partsWithSameConnections || [])
        const compatiblePartsListFiltered = compatiblePartsList.filter(p => {
            if (p.id === part.apiTypeId) {
                return false
            }
            if (p.hideFromSidebar) {
                return false
            }
            return true
        })

        const compatibleAfterQuaternion = await getQuaternionCompatibleParts(thisPart, compatiblePartsListFiltered)

        setCompatibleParts(compatibleAfterQuaternion.compatibleParts)
        setIsSwapActive(true)

        // Open the comparison window
        if (multiswapDebug) {
            openPartsComparisonWindow(
                thisPart,
                compatibleAfterQuaternion.compatibleParts.map(p => p.part),
                compatibleAfterQuaternion.incompatibleParts
            )
        }
    }

    const handlePartSwap = (newPart: CompatiblePart) => {
        if (!newPart.part.id || !sameParts[0]?.id) { return }

        const newJob: SwapJob = {
            sourcePartIds: sameParts.map(p => p.id),
            sourcePartApiTypeId: sameParts[0].apiTypeId,
            targetPart: newPart,
            status: "pending",
        }
        setSwapJobs(prev => [...prev, newJob,])
    }

    const resetSwapState = () => {
        setIsSwapActive(false)
        setCompatibleParts([])
    }

    const handleEditClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        if (sceneRefs?.current?.setIdsAsHighlightedAndTurnOnControl) {
            sceneRefs.current.setIdsAsHighlightedAndTurnOnControl(
                sameParts.map(p => p.id),
                "translate"
            )
        }
        // Close the drawer
        setLayout(produce((draft) => {
            draft.isDrawerOpen = false
            draft.showPartList = false
            draft.filteredPartIds = undefined
            draft.from = undefined
        }))
    }

    const handleUndoClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        goToPreviousState()
        handleUndoCallback?.()
    }

    // Update activeKeys handler to reset swap state when panel is closed
    const handleActiveKeysChange = (key: string | string[]) => {
        const keys = Array.isArray(key) ? key : [key,]
        setActiveKeys(keys)
        if (!keys.includes(typeKey)) {
            resetSwapState()
        }
    }

    return (
        <StyledCollapse
            activeKey={activeKeys}
            onChange={handleActiveKeysChange}
        >
            <StyledCollapsePanel
                key={typeKey}
                header={
                    <div
                        onMouseEnter={() => handleItemHover(sameParts.map(p => p.id))}
                        onMouseLeave={() => handleItemLeave(sameParts.map(p => p.id))}
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                        }}
                    >
                        <span>{`${sameParts[0].name} (${sameParts.length})`}</span>
                        <PartListActions
                            onSwapClick={(e) => {
                                e.stopPropagation()
                                if (isSwapActive) {
                                    resetSwapState()
                                } else {
                                    findCompatibleParts(sameParts[0])
                                }
                            }}
                            onEditClick={handleEditClick}
                            onUndoClick={handleUndoClick}
                            isUndo={isLastSwappedType}
                            partName={sameParts[0].name}
                            isSwapActive={isSwapActive}
                        />
                    </div>
                }
            >
                {isSwapActive && (
                    <SwappablePartsList
                        compatibleParts={compatibleParts}
                        onPartSelect={handlePartSwap}
                        onCollapse={resetSwapState}
                        showEmptyState={compatibleParts.length === 0}
                    />
                )}
                {!isSwapActive && (
                    <partsListStyles.RowsContainer>
                        {getRows(sameParts).map((formattedRow, index) =>
                            rowTemplate({
                                ...formattedRow,
                                key: `${typeKey}-${index}`,
                                inStock: true,
                                onMouseEnter: () => handleItemHover(formattedRow.partIds),
                                onMouseLeave: () => handleItemLeave(formattedRow.partIds),
                            })
                        )}
                    </partsListStyles.RowsContainer>
                )}
            </StyledCollapsePanel>
        </StyledCollapse>
    )
}