import React, { useEffect, useState } from "react"
import Button from "../../../../../../../../../common/components/Button"
import { breadcrumb } from "../../../../../../../../../common/utils/sentrySetup"
import NumberSlider from "../../sliders/Slider"
import {
    SliderContainer,
    ButtonContainer,
    LeftContent,
    LeftRightContainer,
    RightContent,
    SliderText
} from "./ActionPanelStyles"
import isMobile from "ismobilejs"

interface Props {
    onRotationChange: (value: number) => void;
    onRotationSave: (value: number) => void;
    handleMouseDown: () => void;
    handleMouseUp: () => void;
    onFinishEditing: () => void;
    getRotation: () => number;
    steps: number;
    auxButtons?: JSX.Element;
    disabled?: boolean;
}

const RotationSlider = (props: Props) => {
    const isMobilePhone = isMobile(window.navigator).phone
    enum SliderFrom {
        change = "change",
        mouseUp = "mouseUp"
    }

    const [slider, setSlider,] = useState<{ value: number, from: SliderFrom | undefined, }>(() => {
        return {
            value: props.getRotation(),
            from: undefined,
        }
    })

    useEffect(() => {
        setSlider({
            value: props.getRotation(),
            from: undefined,
        })
    }, [props.getRotation,])

    useEffect(() => {
        if (slider.from === SliderFrom.change) {
            props.onRotationChange(slider.value)
        }
        if (slider.from === SliderFrom.mouseUp) {
            props.onRotationSave(slider.value)
        }
    }, [slider,])

    const handleSlider = (e: any) => {
        setSlider({ value: Number(e), from: SliderFrom.change, })
    }

    const handleMouseUp = (e: any) => {
        setSlider({ value: Number(e), from: SliderFrom.mouseUp, })
        props.handleMouseUp()
    }

    const getValue = () => {
        return slider.value
    }

    const handleFinish = () => {
        breadcrumb({
            message: "User done rotating part",
            level: "info",
            data: {
                rotation: getValue(),
            },
        })
        props.onFinishEditing()
    }

    return <SliderContainer $isMobile={isMobilePhone}>
        {props.disabled ? <SliderText>
            Rotation is disabled for connected parts
        </SliderText>
            : <LeftRightContainer>
                <LeftContent>
                    <NumberSlider
                        readonly
                        disabledTyping
                        checkSteps
                        unit="deg"
                        min={0}
                        max={360}
                        step={props.steps}
                        handleMouseUp={handleMouseUp}
                        onChange={handleSlider}
                        getValue={getValue}
                        handleMouseDown={props.handleMouseDown}
                        marks={true}
                        inputNumber={false}
                        disabled={props.disabled}
                    />
                </LeftContent>
                {props.auxButtons
                    && <RightContent>{props.auxButtons}</RightContent>}
            </LeftRightContainer>
        }
    </SliderContainer>
}

export default RotationSlider