/* eslint-disable complexity */
/* eslint-disable max-params */
/* eslint-disable no-negated-condition */
/* eslint-disable max-len */
/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines-per-function */
import { Plane } from "@react-three/drei"
import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import {
  Box3,
  Box3Helper,
  Color,
  DoubleSide,
  Euler,
  MathUtils,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneGeometry,
  Quaternion,
  Vector3
} from "three"
import { useThree } from "@react-three/fiber"
import {
  attachTubeTrackerTo,
  checkExactSnap,
  checkSnap,
  createShape,
  DRAG_VALUE_OFFSET,
  getDrag,
  getLengthOnRelease,
  getMarkers,
  getOriginMarker,
  getSnap,
  getTubeInternals,
  handleTubeSwap,
  invertTube,
  proyectRay,
  removeGuidelines,
  setMaxPossibleLength,
  setPosition,
  setTubeLength,
  setTubeLengthSimple,
  setTubeRotation,
  SNAP_VALUE_OFFSET,
} from "./utils/TubeUtils"
import useCollision from "../../../../../../../providers/collisionProvider/useCollision"
import useSelector from "../../../../../../../providers/mouseProvider/useSelector"
import { TubeInternalsType, TUBE_UI } from "./types/types"
import TubeUI from "./ui/TubeUI"
import useTube from "./utils/useTube"
import { Marker, MarkerType, PartDataStore, PartTypeEnum, TubeMarkerEnum } from "../../../../../../../utils/Types"
import { addPartModal, isItemSelected } from "../../../../../../../state/atoms"
import { PartConnectionType, UnitType } from "../../../../../../../state/scene/types"
import { partConnections } from "../../../../../../../state/scene/selectors"
import {
  connectionTypesSelector,
  sizesSelector
} from "../../../../../../../state/initialDataSelectors"
import { ConnectorMarkerType } from "../connector/types/types"
import { initialData } from "../../../../../../../state/atoms"

import {
  getConnectionMarkerName,
  getOtherPartOfTheConnection,
  isInConnection,
  useGetMarkerData
} from "../../../../../../../state/scene/util"
import {
  MarkerUserData,
  MAX_POSSIBLE_LENGTH_REDUCTION,
  getNormalDirection,
  visualizeMarkerPositionsWithLabels,
  visualizeBoundingBoxLabel,
  createLabelAtPosition,
} from "../../../../../../../utils/MarkerUtil"
import { useNewPart, useSetSnap, useUnsnap } from "../../../../../../../state/scene/setters"
import useModal from "../../../../../../../../common/providers/modalProvider/useModal"
import { createBoxWithCenterSphere, drawVector3Point, Faces, getFreeMarkers, handleCollisionOnCreation, visualizeFaces, visualizeOrientedBoundingBox } from "../../../../../../../utils/PartUtils"
import {
  useRegisterMultipleMovement
} from "../../../../../../../providers/multipleMovementProvider/useMultipleMovement"
import { MultipleMovementUtils } from "./utils/MultipleMovementUtils"
import { SoundHelper } from "../../../../utils/SoundHelper"
import useWoodTexture from "../hooks/useWoodTexture"
import NewPartButtonTube from "./newPartButton"
import { MeshUtils } from "../../../../../../../utils/MeshUtils"
import { getLocalStorage, SELECTED_PART_COLOR } from "../../../../../../../../common/utils/utils"
import { useComponentRegistry, useRegisterComponent } from
  "../../../../../../../providers/multiselectProvider/useComponentMethods"
import hotkeys from "hotkeys-js"
import useGetDebugVariables from "../../../../utils/useGetDebugVariables"
import { Text } from "@react-three/drei"
import VisualDebug, { VisualDebugData } from "../../common/VisualDebug"
import { useLevaControls } from "../../../../../../../providers/debugProvider/useLevaControls"
import { convertFromInchToMeter } from "../../../../../../../utils/PartUtils"
import { cameraUtils } from "../../../../utils/cameraUtils"
import { SceneRef } from "../../../../../../../state/types"

interface Props {
  id: string;
  handleDeletePart: (id: string) => void;
  partDataRef: React.MutableRefObject<Record<string, PartDataStore>>;
  sceneRefs: SceneRef;
}

const Tube: React.FC<Props> = (props) => {
  const { scene, } = useThree()
  const { showModal, } = useModal()

  const [tubeUI, setTubeUI,] = useState<TUBE_UI>(TUBE_UI.NONE)
  const [initiated, setInitiated,] = useState(false)
  const [shouldIgnoreGuidelines, setShouldIgnoreGuidelines,] = useState(false)

  const { tube, updateTubeValues, } = useTube(props.id)
  const [color, updateColor,] = useState<Color | string>()
  const { showDebugText, viewMarkerPositionsWithLabels, showAllPartsIds, seeOBBdebugOnSelect, logRenders, visualizeFacesForOBB, } = useLevaControls()

  const { material, woodTexture, } = useWoodTexture(tube.color, 8, 8)
  const [isSelected, setIsSelected,] = useRecoilState(isItemSelected(tube.id))
  const setNewConnectionData = useSetRecoilState(addPartModal)
  const getConnectionTypes = useRecoilCallback(({ snapshot, }) => () => {
    return snapshot.getLoadable(connectionTypesSelector).contents
  }, [])
  const partConnectionsValue = useRecoilValue(partConnections(tube.id))
  const getMarkerData = useGetMarkerData()
  const getSizes = useRecoilCallback(({ snapshot, }) => () => {
    return snapshot.getLoadable(sizesSelector).contents
  }, [])
  const setSnap = useSetSnap()
  const unsnap = useUnsnap()
  const { getVariables, } = useGetDebugVariables()
  const isAdmin = getVariables().isAdmin


  const getAllParts = useRecoilCallback(({ snapshot, }) => () => {
    return snapshot.getLoadable(initialData).contents
  }, [])

  const tubeInternalsRef = useRef<TubeInternalsType>(getTubeInternals())
  const tubeMeshRef = useRef<Mesh>()
  const [isColliding, setIsColliding,] = useState(false)

  const { getInAutofocusMode, setInAutofocusMode, addMeshToSceneBounds, } = props.sceneRefs.current
  const { fit, getBoundingBoxForConnector, } = cameraUtils

  const [boundingBox, setBoundingBox,] = useState<Box3 | null>(null)
  const { unregister, } = useComponentRegistry()

  const [visualDebug, setVisualDebug,] = useState<VisualDebugData>({})

  const wasDuplicated = !!tube.duplicatedFrom || !!tube.bulkCreated
  const [debugNormals, setDebugNormals,] = useState(false)
  const [debugLabels, setDebugLabels,] = useState<{
    position: Vector3,
    text: string,
  }[]>([])

  const [boundingBoxCenter, setBoundingBoxCenter,] = useState<Vector3 | null>(null)



  const [uiVisible, setUiVisible,] = useState(false)
  const [unit, updateUnit,] = useState(tube.partUnits ?? "in")

  const mountedRef = useRef(true)

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])


  const onCollide = (colliding: boolean) => {
    setIsColliding(colliding)
  }

  const handleTubeClick = () => {
    setIsSelected(true)
    props.sceneRefs.current.setTransformMode?.("off")
    setTubeUI(TUBE_UI.SLIDER)
    setInAutofocusMode?.(true)
  }

  const {
    updateCollider,
    buildCollider,
    deleteCollider, } = useCollision(
      tube.id,
      tubeMeshRef,
      tubeMeshRef,
      onCollide,
      tube.type,
    )
  const { registerRegularMesh, } = useSelector(tube.id, handleTubeClick)

  const setInternals = useCallback((newTubeInternals: TubeInternalsType) => {
    tubeInternalsRef.current = newTubeInternals
  }, [tubeInternalsRef,])

  const detachFromMarker = MultipleMovementUtils.useDetachFromMarker({
    tube,
    tubeInternalsRef,
    scene,
    buildCollider,
    updateCollider: (connectedParts: string[]) => updateCollider(undefined, connectedParts),
    setInternals,
    compatibilityList: getConnectionTypes(),
  })
  useRegisterMultipleMovement({
    id: props.id,
    attachToMarker: (marker: Mesh) => {
      const origin = getOriginMarker(tubeInternalsRef)
      marker.attach(origin)
    },
    detachFromMarker: (
      marker: Mesh,
      connections: PartConnectionType[],
      getExactSnaps?: boolean,
      checkCollisions?: boolean,
      resetGuidelines?: boolean,
    ) => {
      const tubeConnections = connections
        .filter(c => isInConnection(c, tube.id))
        .map(c => {
          return {
            connectedPartId: getOtherPartOfTheConnection(c, tube.id),
            connectedMarkerName: getConnectionMarkerName(c, tube.id)!,
          }
        })
      const newConnections = detachFromMarker(
        marker,
        tubeConnections.map(c => c.connectedPartId),
        tubeConnections.map(c => c.connectedMarkerName),
        checkCollisions,
        resetGuidelines
      )
      return newConnections
    },
    checksOnLengthMove: (
      elongationDirection: Vector3,
      connections: PartConnectionType[],
      lengthDirection: number
    ) => {
      return {
        ...MultipleMovementUtils.checkSnap({
          tube,
          scene,
          tubeInternalsRef,
          setInternals,
          elongationDirection,
          compatibilityList: getConnectionTypes(),
        }),
        ...MultipleMovementUtils.checkAlignments({
          tube,
          scene,
          tubeInternalsRef,
          setInternals,
          elongationDirection,
          connections,
          lengthDirection,
        }),
      }
    },
    checksOnSegmentedTubeLengthMove: (
      intersectableMeshes: Object3D[],
      connections: PartConnectionType[]
    ) => {
      MultipleMovementUtils.checkAlignments({
        tube,
        scene,
        tubeInternalsRef,
        setInternals,
        connections,
        intersectableMeshes,
      })
    },
    checksOnRotationMove: (connections: PartConnectionType[],) => {
      MultipleMovementUtils.checkAlignments({
        tube,
        scene,
        tubeInternalsRef,
        setInternals,
        connections,
      })

      if (
        checkExactSnap(
          scene,
          tubeInternalsRef.current,
          getFreeMarkers(
            tube,
            connections.filter(c => isInConnection(c, tube.id))
              .map(c => getConnectionMarkerName(c, tube.id)!)
          ),
          tube,
          getConnectionTypes(),
        ).length > 0
      ) {
        SoundHelper.playUnsnap()
      }
    },
    getMaxLength: (direction: Vector3, connections?: PartConnectionType[]) => {
      return MultipleMovementUtils.getMaxLength({
        direction,
        tube,
        scene,
        tubeInternalsRef,
        setInternals,
        connections,
        compatibilityList: getConnectionTypes(),
      })
    },
    getPosAndRot: () => {
      const origin = getOriginMarker(tubeInternalsRef)
      const pos = MeshUtils.copyWorldPosition(origin!)
      origin!.rotateY(MathUtils.degToRad(-180))
      const rot = MeshUtils.copyWorldQuaternion(origin!)
      origin!.rotateY(MathUtils.degToRad(180))
      return { pos, rot, }
    },
  })

  useEffect(() => {
    // eslint-disable-next-line no-negated-condition
    if (isSelected) {
      if (tube.loaded) {
        setTubeUI(TUBE_UI.SLIDER)
      }
      if ((getLocalStorage("autofocusMode") ?? getInAutofocusMode?.())) {
        fit(tubeMeshRef.current!, tubeMeshRef.current!.matrixWorld, props.sceneRefs.current.cameraControls?.current)
      }
    }
    if (!isSelected) {
      removeGuidelines(tubeInternalsRef, scene, setInternals)
      setTubeUI(TUBE_UI.NONE)
    }
    updateColor(getColor())
  }, [isSelected,])

  useEffect(() => {
    const scope = tube.id
    if (isSelected) {
      hotkeys.setScope(scope)
      hotkeys("delete,backspace", scope, (event, handler) => {
        event.preventDefault()
        handleTubeDelete()
      })
    } else {
      hotkeys.deleteScope(scope)
    }

    // Cleanup function
    return () => {
      hotkeys.deleteScope(scope)
    }
  }, [isSelected,])

  const setSnapState = useCallback((length: number, connection: PartConnectionType) => {
    setSnap(props.id, length, connection)
  }, [props.id,])

  useEffect(() => {

    if (partConnectionsValue.length < 2) {
      const { snapState, } = tubeInternalsRef.current
      if ((snapState.isSnapped && tube.length < snapState.maxPossibleLength - DRAG_VALUE_OFFSET)
        || !snapState.targetMarker) {
        tubeInternalsRef.current.snapState.isSnapped = false
      }
      if (!tubeInternalsRef.current.snapState.isSnapped) {
        if (tubeInternalsRef.current.movableMarkerMesh?.name === tube.originMarkerName) {
          handleInvertWithStateSave()
        }
        const tubeMarkerName = tubeInternalsRef.current.movableMarkerMesh?.name!
        const markerIsAvailable = !partConnectionsValue.some(
          ({ partMarkerName, }) => partMarkerName === tubeMarkerName
        )
        if (markerIsAvailable) {
          setTubeLength({
            lengthInInches: tube.length,
            tube,
            scene,
            tubeInternalsRef,
            tubeMeshRef,
            setInternals,
            setSnap: setSnapState,
            updateCamera,
            compatibilityList: getConnectionTypes(),
            ignoreGuidelines: shouldIgnoreGuidelines,
            unit: tube.partUnits ?? "in",
          })
          setShouldIgnoreGuidelines(false)
        } else {
          tubeInternalsRef.current.snapState.isSnapped = true
          setTubeLengthSimple(
            tube.length,
            tubeInternalsRef,
            tubeMeshRef,
            updateCamera,
          )
        }
      }
    } else {
      const { dragState, mmSnapState, } = tubeInternalsRef.current
      let mmLength = tube.length
      if (mmSnapState.isSnapped
        && mmSnapState.maxPossibleLength - DRAG_VALUE_OFFSET < tube.length) {
        mmLength = mmSnapState.maxPossibleLength
      } else if (tubeInternalsRef.current.dragState.drag) {
        mmLength = dragState.dragValue
      }

      setTubeLengthSimple(
        mmLength,
        tubeInternalsRef,
        tubeMeshRef,
        updateCamera,
      )
    }
    setupOrientedBoundingBox(seeOBBdebugOnSelect)
  }, [tube.length, tube.originMarkerName,])

  const updateMarkerPositionMatrixInfo = () => {
    //console.log("updateMarkerPositionMatrixInfo for tubes")

    const markers = getAllMarkers(false)
    if (markers.length > 0 && markers[0] && markers[1]) {
      const markersToPush = [{ name: markers[0].name, sizeId: tube.marker.sizeId, id: tube.marker.id, }, { name: markers[1].name, sizeId: tube.marker.sizeId, id: tube.marker.id, },]

      const updatedMarkers = markersToPush.map((marker) => {
        let sceneObject: Object3D | undefined
        scene.traverse((object) => {
          if (object.name === marker.name && object.userData.partId === tube.id) {
            sceneObject = object
          }
        })

        if (sceneObject && sceneObject.userData.partId === tube.id) {
          const worldPosition = new Vector3()
          sceneObject.getWorldPosition(worldPosition)
          return {
            ...marker,
            positionXYZ: {
              x: worldPosition.x,
              y: worldPosition.y,
              z: worldPosition.z,
            },
          }
        } else {
          console.warn(`Marker not found in scene: ${marker.name}`)
          return marker
        }
      })
      updateTubeValues(tube.id, (t) => {
        t.markers = updatedMarkers as Marker[]
      }, true)

    }
  }

  const getBoundingBoxForTube = () => {
    if (tubeInternalsRef.current.boundingBoxMesh?.userData.calculateBoundingBox) {
      //lets debug it as well
      const boundingBox = tubeInternalsRef.current.boundingBoxMesh.userData.calculateBoundingBox()
      //const boxHelper = new Box3Helper(boundingBox, new Color(0xff0000))
      //scene.add(boxHelper)
      return boundingBox
    } else if (tubeMeshRef.current) {
      //had to keep this here because of issues with instanced meshes during initialization
      return getBoundingBoxForConnector(tubeMeshRef.current, undefined, props.sceneRefs.current.cameraControls?.current)
    } else {
      return new Box3(new Vector3(0.5, 0.5, 0.5), new Vector3(-0.5, -0.5, -0.5))
    }
  }

  useEffect(() => {
    if (initiated) {
      const origin = getOriginMarker(tubeInternalsRef)
      setPosition(origin, tube)
      origin!.rotateY(MathUtils.degToRad(-180))
      setTubeRotation(origin, tube)
      origin!.rotateY(MathUtils.degToRad(180))
      const bounder = getBoundingBoxForTube()
      setBoundingBox(bounder)
      setupOrientedBoundingBox(seeOBBdebugOnSelect)
      //updateMarkerPositionMatrixInfo()
    }
  }, [tube.position, tube.rotation,])


  useEffect(() => {
    const center = new Vector3()
    boundingBox?.getCenter(center)
    showDebugText && setBoundingBoxCenter(center)
  }, [boundingBox,])

  const setupOrientedBoundingBox = (debug: boolean) => {
    debug && console.log("setupOrientedBoundingBox")
    const { origin, movable, } = getMarkers(tube, tubeInternalsRef)

    const originWorldQuaternion = new Quaternion()
    origin!.getWorldQuaternion(originWorldQuaternion).normalize()
    const topMesh = tubeInternalsRef.current.markerTopMesh
    const bottomMesh = tubeInternalsRef.current.markerBottomMesh

    const topPos = new Vector3()
    const bottomPos = new Vector3()
    topMesh!.getWorldPosition(topPos)
    bottomMesh!.getWorldPosition(bottomPos)

    const boundingBoxCenter = new Vector3()
    boundingBoxCenter.lerpVectors(topPos, bottomPos, 0.5)



    //the logic here is much simpler for tubes because we know the height and width and length clearly
    //all these are in inches
    const realSizes = {
      width: convertFromInchToMeter(tube.diameter.outer),
      height: convertFromInchToMeter(tube.diameter.outer),
      length: convertFromInchToMeter(tube.length),
    }

    const boundingBox = new Box3()
    // Create box extents based on half dimensions
    const halfWidth = realSizes.width / 2
    const halfHeight = realSizes.height / 2
    const halfLength = realSizes.length / 2

    // Set box min/max from center point
    boundingBox.min.set(
      boundingBoxCenter.x - halfWidth,
      boundingBoxCenter.y - halfHeight,
      boundingBoxCenter.z - halfLength
    )
    boundingBox.max.set(
      boundingBoxCenter.x + halfWidth,
      boundingBoxCenter.y + halfHeight,
      boundingBoxCenter.z + halfLength
    )


    /*if (debug) {
      const helper = new Box3Helper(boundingBox, new Color("blue"))
      scene.add(helper)
      createLabelAtPosition(scene, boundingBoxCenter, "boundingBoxCenter of Tube", {
        color: "white",
        fontSize: "15px",
        timer: 1000,
      })
      setTimeout(() => {
        scene.remove(helper)
      }, 1000)
    }*/

    //const topmeshWorldPos = new Vector3()
    //tubeInternalsRef.current.markerTopMesh!.getWorldPosition(topmeshWorldPos)
    //const bottommeshWorldPos = new Vector3()
    //tubeInternalsRef.current.markerBottomMesh!.getWorldPosition(bottommeshWorldPos)

    //drawVector3Point(topmeshWorldPos, scene, "blue", 0.01, undefined, true)
    //drawVector3Point(bottommeshWorldPos, scene, "green", 0.01, undefined, true)

    const { centerSphere, boxMesh, } = createBoxWithCenterSphere({
      width: realSizes.width,
      height: realSizes.height,
      depth: realSizes.length,
      tubeId: tube.id,
      partType: PartTypeEnum.tube,
      boxColor: "red",
      boxOpacity: 0.5,
      sphereVisible: !!debug,
      boxVisible: !!debug,
    })

    //remove the old bounding box and center sphere
    tubeInternalsRef.current.centerObject?.removeFromParent()
    tubeInternalsRef.current.boundingBoxMesh?.removeFromParent()

    if (!props.partDataRef.current[tube.id]) {
      props.partDataRef.current[tube.id] = {}
    }
    props.partDataRef.current[tube.id].boundingBoxMesh = boxMesh



    tubeInternalsRef.current.boundingBoxMesh = boxMesh
    tubeInternalsRef.current.centerObject = centerSphere

    //this becomes our reference for the sizes for the future because sometimes the boundingbox is
    //attached to under another marker midway so we cant use the getDimensinos method for this at some points
    tubeInternalsRef.current.boxMeshLastUnScaledDimensions = realSizes

    //always set posiition before attaching
    centerSphere.position.copy(boundingBoxCenter)
    centerSphere.quaternion.copy(originWorldQuaternion)
    origin!.attach(centerSphere)
  }



  useEffect(() => {
    const { origin, movable, } = getMarkers(tube, tubeInternalsRef)
    const { snapState, mmSnapState, } = tubeInternalsRef.current
    tubeInternalsRef.current.movableMarkerMesh = movable
    const tubeMesh = tubeMeshRef.current

    origin!.rotation.set(0, MathUtils.degToRad(180), 0)

    attachTubeTrackerTo(tubeMesh!, movable!, origin!)

    if (tubeMesh) {
      setPosition(origin, tube)
      setTubeRotation(origin, tube)
      origin!.rotateY(MathUtils.degToRad(180))
      tubeInternalsRef.current.shape = createShape(tube)

      if (isAdmin) {
        const wireframeRedMaterial = new MeshBasicMaterial({ color: "red", wireframe: true, })
        tubeInternalsRef.current.markerTopMesh!.material = wireframeRedMaterial
        tubeInternalsRef.current.markerBottomMesh!.material = wireframeRedMaterial
      }
      setMaxPossibleLength(tube.maxLength, snapState)
      setMaxPossibleLength(tube.maxLength, mmSnapState)
      //on initialsetup, no need to fit camera
      setTubeLength({
        lengthInInches: tube.length,
        tube,
        scene,
        tubeInternalsRef,
        tubeMeshRef,
        setInternals,
        setSnap: setSnapState,
        updateCamera: undefined,
        compatibilityList: getConnectionTypes(),
        unit: tube.partUnits ?? "in",
      })
      registerRegularMesh()
      if (!tube.loaded && !wasDuplicated) {
        setIsSelected(true)
        setTubeUI(TUBE_UI.SLIDER)
      }
      setInitiated(true)
      updateTubeValues(tube.id, (t) => {
        t.instanciated = true
      }, true)
      removeGuidelines(tubeInternalsRef, scene, setInternals)
      if (!wasDuplicated) {
        handleCollisionOnCreation({
          buildCollider,
          updateCollider,
          deleteCollider,
          partType: PartTypeEnum.tube,
          onRemove: handleTubeDelete,
          showModal,
        })
      }
      addMeshToSceneBounds?.()
      //we can do this here bc eventhough the tube is already rotated, we're using the the origin's rotation as our reference for the centerSphere and the diameter for height and width
      setupOrientedBoundingBox(seeOBBdebugOnSelect)
      //when this is first instanciated, our faster boundinbox is not ready yet so we will use this method instead
      const bounder = getBoundingBoxForConnector(tubeMeshRef.current!, undefined, props.sceneRefs.current.cameraControls?.current)
      setBoundingBox(bounder)
      setInAutofocusMode?.(true)
      if (!tube.markers || !tube.markers[0].positionXYZ) {
        //updateMarkerPositionMatrixInfo()
      }
      //need to migrate the tube.length to the unitRealValue
      setupUnitsRealValue()
    }

    return () => {
      deleteCollider()
      if (tubeInternalsRef.current) {
        removeGuidelines(tubeInternalsRef, scene, setInternals)
      }
    }
  }, [])

  const handleMouseDown = useCallback((e: number, partsToMove?: string[]) => {
    if (partsToMove) {
      tubeInternalsRef.current.snapState.isSnapped = false
      tubeInternalsRef.current.snapState.maxLengthSetted = false
      tubeInternalsRef.current.snapState.maxPossibleLength = tube.maxLength
      tubeInternalsRef.current.snapState.targetMarker = null
      proyectRay(scene, tubeInternalsRef, setInternals)
      checkSnap(e,
        tube, tubeInternalsRef, tubeMeshRef.current, setInternals, setSnapState, scene)
    }
  }, [tubeInternalsRef, tube, tubeMeshRef, setInternals, setSnapState, unit,])

  const updateCamera = (mesh?: Mesh, matrixWorld?: Matrix4) => {
    if (getLocalStorage("autofocusMode") ?? getInAutofocusMode?.()) {
      fit(
        mesh ? mesh : tubeMeshRef.current!,
        matrixWorld ? matrixWorld : tubeMeshRef.current!.matrixWorld,
        props.sceneRefs.current.cameraControls?.current
      )
    }
  }

  // eslint-disable-next-line complexity
  const handleTubeLength = useCallback((
    lengthValueFromSlider: number,
    handleMovement: (
      partsIds: string[],
      onDrag: (distance: number) => void,
      onSnap: (newConnections: PartConnectionType) => void,
      direction: Vector3
    ) => void,
    partsToMove?: string[],
    ignoreGuidelines?: boolean,
  ) => {
    const { snapState, dragState, mmSnapState, } = tubeInternalsRef.current

    const tubeLengthToUseInInches = unit === "cm" ? lengthValueFromSlider / 2.54 : lengthValueFromSlider

    setIsSelected(true)
    //the 2 inches used here was too much so I'm using 1 for unsnapping
    if (snapState.isSnapped && tubeLengthToUseInInches < snapState.maxPossibleLength - 1) {
      tubeInternalsRef.current.snapState.isSnapped = false
      unsnap(props.id, [tubeInternalsRef.current.movableMarkerMesh!.name,])
      SoundHelper.playSnap()
    }
    if (snapState.hasCollied && tubeLengthToUseInInches < snapState.maxPossibleLength) {
      tubeInternalsRef.current.snapState.hasCollied = false
    }
    if (dragState.drag
      && (
        tubeLengthToUseInInches < dragState.dragValue - DRAG_VALUE_OFFSET
        || tubeLengthToUseInInches > dragState.dragValue + DRAG_VALUE_OFFSET
      )) {
      tubeInternalsRef.current.dragState.drag = false
      tubeInternalsRef.current.dragState.dragValue = 0
    }
    if (partsToMove && partsToMove.length > 0) {
      if (mmSnapState.isSnapped && tubeLengthToUseInInches < mmSnapState.maxPossibleLength - SNAP_VALUE_OFFSET) {
        tubeInternalsRef.current.mmSnapState.isSnapped = false
        tubeInternalsRef.current.mmSnapState.newConnection = undefined
        SoundHelper.playSnap()
      }
      let mmLength = tubeLengthToUseInInches
      if (mmSnapState.isSnapped
        && mmSnapState.maxPossibleLength - DRAG_VALUE_OFFSET < tubeLengthToUseInInches) {
        mmLength = mmSnapState.maxPossibleLength
      } else if (tubeInternalsRef.current.dragState.drag) {
        mmLength = dragState.dragValue
      }
      setTubeLengthSimple(
        mmLength,
        tubeInternalsRef,
        tubeMeshRef,
        updateCamera,
      )
      const handleDrag = MultipleMovementUtils.getHandleDrag(
        mmLength,
        tubeInternalsRef,
        tubeMeshRef.current!,
        setInternals
      )
      const handleSnap = MultipleMovementUtils.getHandleSnap(
        mmLength,
        tubeInternalsRef,
        tubeMeshRef.current!,
        setInternals
      )
      const direction = MeshUtils.copyWorldDirection(tubeInternalsRef.current.movableMarkerMesh!)
      handleMovement(partsToMove, handleDrag, handleSnap, direction)
    } else if (tubeLengthToUseInInches >= snapState.maxPossibleLength) {
      setTubeLength({
        lengthInInches: snapState.maxPossibleLength,
        tube,
        scene,
        tubeInternalsRef,
        tubeMeshRef,
        setInternals,
        setSnap: setSnapState,
        updateCamera,
        compatibilityList: getConnectionTypes(),
        unit: tube.partUnits ?? "in",
      })
    } else if (!dragState.drag && !snapState.isSnapped && !snapState.hasCollied) {
      setTubeLength({
        lengthInInches: tubeLengthToUseInInches,
        tube,
        scene,
        tubeInternalsRef,
        tubeMeshRef,
        setInternals,
        setSnap: setSnapState,
        updateCamera,
        buildCollider,
        updateCollider,
        compatibilityList: getConnectionTypes(),
        ignoreGuidelines,
        unit: tube.partUnits ?? "in",
      })
    }
    setTimeout(() => {
      props.sceneRefs.current.updateMultiSelectProviderWithNewMakersInfo?.()
    }, 100)
  }, [tubeInternalsRef, tube, tubeMeshRef, setInternals, setSnapState, unit,])

  const handleUnitsChange = (unit: string) => {
    updateTubeValues(tube.id, (t) => {
      t.partUnits = unit
    })
    updateUnit(unit)
  }

  const handleTubeState = useCallback((lengthValueFromSlider: number, ignoreGuidelines?: boolean) => {
    const { markerTopMesh, snapState, dragState, } = tubeInternalsRef.current
    const lengthInInches = unit === "cm" ? lengthValueFromSlider / 2.54 : lengthValueFromSlider
    const lengthInInchesConsideringMax = getLengthOnRelease(lengthInInches, snapState, dragState)
    const unitRealValueConsideringMax = unit === "cm" ? lengthInInchesConsideringMax * 2.54 : lengthInInchesConsideringMax

    if (markerTopMesh) {
      const positionMarker = MeshUtils.copyWorldPosition(markerTopMesh)
      if (ignoreGuidelines) {
        setShouldIgnoreGuidelines(true)
      }
      updateTubeValues(tube.id, (t) => {
        t.length = lengthInInchesConsideringMax
        t.unitRealValue = unitRealValueConsideringMax
        t.partUnits = unit
        t.marker = tube.marker
      })

      tubeInternalsRef.current.dragState = {
        drag: false, dragValue: 0,
      }
    }
    buildCollider()
    updateCollider(undefined, [snapState.targetMarker?.object.userData.partId,])
    removeGuidelines(tubeInternalsRef, scene, setInternals)
  }, [tubeInternalsRef, setInternals, unit, tube,])

  const getMarker = useCallback((markerType: TubeMarkerEnum, rotation: number) => {
    const markerIsAvailable = !partConnectionsValue.some(
      ({ partMarkerName, }) => partMarkerName === markerType
    )

    const userData: MarkerUserData = {
      userDataType: "MarkerUserData",
      id: tube.marker.id,
      type: MarkerType.COLLISION,
      partId: tube.id,
      partApiId: tube.apiTypeId,
      partType: PartTypeEnum.tube,
      sizeId: tube.marker.sizeId,
      innerOuter: ConnectorMarkerType.outer,
      iELength: tube.marker.iELenght,
      markerName: markerType,
    }
    return (
      <Plane
        ref={(r) => {
          markerType === TubeMarkerEnum.TOP
            ? tubeInternalsRef.current.markerTopMesh = r as Mesh
            : tubeInternalsRef.current.markerBottomMesh = r as Mesh
        }}
        rotation={[0, MathUtils.degToRad(rotation), 0,]}
        args={[0.01, 0.01,]}
        userData={userData}
        name={markerType}>
        {/* do not add partid as key here - breaks lots of stuff */}
        <Plane args={[80, 80,]} userData={{ type: MarkerType.COLLISION_PLANE, tubeID: tube.id, }}>
          <meshPhongMaterial
            attach="material"
            alphaTest={0}
            visible={false}
            color={"#ffbd08"}
            side={DoubleSide} />
        </Plane>
        <meshPhongMaterial
          attach="material"
          color={"#ff0808"}
          alphaTest={0}
          visible={false}
          side={DoubleSide}
        />
        {isSelected && markerIsAvailable
          && <NewPartButtonTube
            trackType={markerType}
            tubeInternalsRef={tubeInternalsRef}
            clearTubeUI={setTubeUI}
            setIsSelected={setIsSelected}
            tube={tube}
            sceneRefs={props.sceneRefs}
          />
        }
      </Plane>
    )
  }, [tubeInternalsRef, tube, partConnectionsValue, isSelected, setTubeUI, setIsSelected,])

  const handleTubeDelete = () => {
    props.handleDeletePart(tube.id)
    unregister(tube.id)
    delete props.partDataRef.current[tube.id]
    if (tubeInternalsRef.current.boundingBoxMesh) {
      tubeInternalsRef.current.boundingBoxMesh.removeFromParent()
    }
    if (tubeInternalsRef.current.centerObject) {
      tubeInternalsRef.current.centerObject.removeFromParent()
    }
  }

  const handleSwap = useCallback(() => {
    handleTubeSwap(
      { ...tube, type: PartTypeEnum.tube, },
      partConnectionsValue,
      getConnectionTypes(),
      getSizes(),
      getMarkerData,
      setNewConnectionData,
      scene,
      tubeMeshRef.current!,
    )
  }, [tube, partConnectionsValue, getConnectionTypes, getSizes, setNewConnectionData, tubeMeshRef,])

  const handleInvertWithStateSave = () => {
    handleInvert(tube.originMarkerName)
    const { origin, } = getMarkers(tube, tubeInternalsRef)
    const newPos = MeshUtils.copyWorldPosition(origin!)
    origin!.rotateY(MathUtils.degToRad(-180))
    const newRot = MeshUtils.copyWorldQuaternion(origin!)
    origin!.rotateY(MathUtils.degToRad(180))

    const EPSILON = 0.0001 // Very small buffer for floating point comparisons
    if (Math.abs(newPos.x - tube.position.x) < EPSILON
      && Math.abs(newPos.y - tube.position.y) < EPSILON
      && Math.abs(newPos.z - tube.position.z) < EPSILON
      && Math.abs(newRot.x - tube.rotation.x) < EPSILON
      && Math.abs(newRot.y - tube.rotation.y) < EPSILON
      && Math.abs(newRot.z - tube.rotation.z) < EPSILON
      && Math.abs(newRot.w - tube.rotation.w) < EPSILON) {
      return
    }

    updateTubeValues(tube.id, (t) => {
      t.position = { x: newPos.x, y: newPos.y, z: newPos.z, }
      t.rotation = { x: newRot.x, y: newRot.y, z: newRot.z, w: newRot.w, }
    })
  }

  const handleInvert = useCallback((newOrigin: string) => {
    invertTube(
      newOrigin
        === TubeMarkerEnum.TOP ? TubeMarkerEnum.BOTTOM : TubeMarkerEnum.TOP,
      tubeMeshRef,
      tubeInternalsRef,
      scene
    )
  }, [tubeMeshRef, tubeInternalsRef,])

  useEffect(() => {
    if (initiated && tube.originMarkerName === tubeInternalsRef.current.movableMarkerMesh?.name) {
      console.log("inverting", tube.originMarkerName, "inside tube")
      invertTube(
        tube.originMarkerName
          === TubeMarkerEnum.TOP ? TubeMarkerEnum.BOTTOM : TubeMarkerEnum.TOP,
        tubeMeshRef,
        tubeInternalsRef,
        scene)
      const { origin, } = getMarkers(tube, tubeInternalsRef)
      const newPos = MeshUtils.copyWorldPosition(origin!)
      origin!.rotateY(MathUtils.degToRad(-180))
      const newRot = MeshUtils.copyWorldQuaternion(origin!)
      origin!.rotateY(MathUtils.degToRad(180))

      const EPSILON = 0.0001 // Very small buffer for floating point comparisons
      if (Math.abs(newPos.x - tube.position.x) < EPSILON
        && Math.abs(newPos.y - tube.position.y) < EPSILON
        && Math.abs(newPos.z - tube.position.z) < EPSILON
        && Math.abs(newRot.x - tube.rotation.x) < EPSILON
        && Math.abs(newRot.y - tube.rotation.y) < EPSILON
        && Math.abs(newRot.z - tube.rotation.z) < EPSILON
        && Math.abs(newRot.w - tube.rotation.w) < EPSILON) {
        return
      }

      updateTubeValues(tube.id, (t) => {
        t.position = { x: newPos.x, y: newPos.y, z: newPos.z, }
        t.rotation = { x: newRot.x, y: newRot.y, z: newRot.z, w: newRot.w, }
      })
    }
  }, [tube.originMarkerName,])

  const getColor = () => {
    if (isSelected) {
      return new Color(SELECTED_PART_COLOR)
    } else {
      return material ? "#fff" : new Color(tube.color)
    }
  }
  const originalColor = () => {
    updateColor(getColor())
  }

  const getPartInfo = () => {
    return tube
  }

  const updateInitialMarkerName = (newMarkerName: TubeMarkerEnum) => {
    setUItoNone()
    updateTubeValues(tube.id, (t) => {
      t.originMarkerName = newMarkerName
    })
  }

  const getBoundingBox = () => {
    const bounder = getBoundingBoxForTube()
    if (showAllPartsIds) {
      visualizeBoundingBoxLabel(scene, bounder, tube.id, { color: "green", })
    }
    return bounder
  }

  const getBoundingBoxMesh = () => {
    return tubeInternalsRef.current.boundingBoxMesh
  }

  const findPartById = (apiTypeId: string) => {
    return getAllParts()?.parts.find((part: any) => part.id === apiTypeId)
  }

  const createPart = useNewPart()

  const duplicatePart = async (
    historyKey: string, position: Vector3,
    originMarkerName?: string,
    markerOffset?: Vector3,
    rotation?: Quaternion,
    length?: number
  ) => {
    const matchingPart = findPartById(tube.apiTypeId)
    if (!matchingPart) {
      console.warn("part no longer exists and cannot be duplicated", tube.apiTypeId)
      return
    }
    const defaultQuaternion = new Quaternion()
    let newPart: any
    if (matchingPart && position) {
      newPart = {
        part: {
          ...matchingPart,
        },
        posAndRot: {
          inner: {
            pos: position,
            rot: rotation || tube.rotation || defaultQuaternion,
          },
          outer: {
            pos: position,
            rot: rotation || tube.rotation || defaultQuaternion,
          },
        },
      }
    }
    if (matchingPart && !position) {
      newPart = {
        part: {
          ...matchingPart,
        },
        posAndRot: {
          inner: {
            pos: new Vector3(0, 0, 0),
            rot: rotation || tube.rotation || defaultQuaternion,
          },
          outer: {
            pos: new Vector3(0, 0, 0),
            rot: rotation || tube.rotation || defaultQuaternion,
          },
        },
      }
    }
    if (originMarkerName || tube.originMarkerName) {
      newPart.originMarkerName = originMarkerName || tube.originMarkerName
    }
    if (length || tube.length) {
      newPart.length = length || tube.length
    }
    newPart.duplicatedFrom = tube.id
    const { newPartId, } = await createPart(newPart, historyKey)
    return newPartId
  }

  const setUItoNone = () => {
    setTubeUI(TUBE_UI.NONE)
    setIsSelected(false)
  }

  // eslint-disable-next-line max-len
  const updatePositionAndRotation = async (position: Vector3, rotation: Quaternion, markerOffset?: Vector3, historyKey?: string): Promise<void> => {
    await new Promise((resolve, reject) => {
      // Check if already unmounted
      if (!mountedRef.current) {
        reject(new Error("Component unmounted"))
        return
      }

      requestAnimationFrame(() => {
        // Check again after frame
        if (!mountedRef.current) {
          reject(new Error("Component unmounted"))
          return
        }

        updateTubeValues(tube.id, (t) => {
          t.position = position
          t.rotation = rotation
        }, false, historyKey)

        const idleCallback = () => {
          // Final check before resolving
          if (!mountedRef.current) {
            reject(new Error("Component unmounted"))
            return
          }

          updateCollider()
          resolve(undefined)
        }

        if ("requestIdleCallback" in window) {
          requestIdleCallback(idleCallback, { timeout: 100, })
        } else {
          setTimeout(idleCallback, 100)
        }
      })
    }).catch(err => {
      // Handle cancellation - you can either:
      // 1. Silently ignore: do nothing
      // 2. Log for debugging
      console.debug("Position update cancelled:", err.message)
      // 3. Rethrow if you want upstream handlers to know
      // throw err
    })
  }

  const getMesh = (color?: string) => {
    const mesh = tubeMeshRef.current?.parent
    if (tubeMeshRef.current && color) {
      (tubeMeshRef.current.material as MeshBasicMaterial).color.set(color)
    }
    return mesh
  }

  const getStartAndEndInnerMarkers = () => {
    const markers = getAllMarkers()
    const startMarker = markers.find(marker => marker?.name === tubeInternalsRef.current.markerTopMesh?.name)
    const endMarker = markers.find(marker => marker?.name === tubeInternalsRef.current.markerBottomMesh?.name)
    if (startMarker && endMarker) {
      return { startMarker, endMarker, }
    } else {
      return { startMarker: undefined, endMarker: undefined, }
    }
  }

  const getAllMarkers = (includeMiddleMesh = true) => {
    const topMesh = tubeInternalsRef.current.markerTopMesh
    const bottomMesh = tubeInternalsRef.current.markerBottomMesh

    // Create middle marker by cloning top marker
    if (topMesh && bottomMesh) {
      // Get world positions
      const topPos = new Vector3()
      const bottomPos = new Vector3()
      topMesh.getWorldPosition(topPos)
      bottomMesh.getWorldPosition(bottomPos)

      // Calculate middle position
      const middlePos = new Vector3()
      middlePos.lerpVectors(topPos, bottomPos, 0.5)

      // Clone top marker and set position
      const middleMesh = new Mesh(
        new PlaneGeometry(0.025, 0.025),
        new MeshBasicMaterial({
          color: new Color("#ff0808"),
          transparent: false,
          alphaTest: 0,
          side: DoubleSide,
        })
      )
      middleMesh.name = "tube_middleMarker"
      middleMesh.userData.partId = tube.id

      // Copy position and rotation from top mesh
      middleMesh.position.copy(middlePos)
      middleMesh.quaternion.copy(topMesh.getWorldQuaternion(new Quaternion()).normalize())


      if (includeMiddleMesh) {
        return [topMesh, middleMesh, bottomMesh,]
      } else {
        return [topMesh, bottomMesh,]
      }
    }

    return [topMesh, bottomMesh,].filter(Boolean)
  }

  const getFacingDirectionOfMarkers = useCallback((
    specificRotation?: { x: number, y: number, z: number, },
    debug?: boolean,
    addCloneToScene?: boolean,
    customLabelPrepend?: string,
  ) => {
    const originMarker = getOriginMarker(tubeInternalsRef)
    if (!originMarker) {
      return [{ originalName: "", facing: "", },]
    }

    const processMarkersWithRotation = (rotation?: { x: number, y: number, z: number, }, useRandomPosition?: boolean) => {
      const clone = originMarker.clone(true)
      clone.userData.clone = true

      // Apply rotation
      if (rotation) {
        //console.log(rotation, "rotation in getFacingDirectionOfMarkers")
        clone.rotation.set(
          MathUtils.degToRad(rotation.x),
          MathUtils.degToRad(rotation.y),
          MathUtils.degToRad(rotation.z),
        )
        clone.updateMatrixWorld(true)
      }

      // Position clone slightly offset for visibility if debugging
      if (useRandomPosition) {
        const randomPosition = new Vector3(
          debug ? 0.5 + Math.random() * 0.5 : 0,
          debug ? 0.5 + Math.random() * 0.5 : 0,
          debug ? 0.5 + Math.random() * 0.5 : 0
        )
        clone.position.copy(randomPosition)
      }

      if (addCloneToScene) {
        scene.add(clone)
      }

      const initialMarkerWorldQuat = new Quaternion()

      if (clone) {
        clone.rotateY(MathUtils.degToRad(180))
        clone.getWorldQuaternion(initialMarkerWorldQuat).normalize()
        clone.updateMatrixWorld(true)
        clone.rotateY(MathUtils.degToRad(-180))
      }

      const markers = getAllMarkers(false)
      const markerInfo = tube.marker


      const markerDirections = markers.map(marker => {
        if (!marker) { return null }

        const matchingCloneMarker = clone.getObjectByName(marker.name)
        matchingCloneMarker?.updateMatrixWorld(true)
        if (!matchingCloneMarker) { return null }

        return {
          originalName: marker.name,
          sizeId: markerInfo.sizeId,
          typeId: markerInfo.id,
          facing: getNormalDirection(matchingCloneMarker, `t${customLabelPrepend}-${tube.id.slice(-4)}`, scene, debug, setDebugNormals, setDebugLabels),
          initialMarkerWorldQuat,
        }
      }).filter((item): item is { originalName: string, facing: string, initialMarkerWorldQuat: Quaternion, sizeId: string, typeId: string, } => item !== null)

      // Cleanup clone if not needed for debug
      if (!addCloneToScene) {
        clone.traverse((obj: Object3D) => {
          if (obj instanceof Mesh) {
            obj.geometry?.dispose()
            if (Array.isArray(obj.material)) {
              obj.material.forEach(mat => mat.dispose())
            } else {
              obj.material?.dispose()
            }
          }
        })
      }

      return markerDirections
    }

    return processMarkersWithRotation(specificRotation, !!specificRotation)
  }, [tubeInternalsRef,])


  const setPositionWithMarker = (marker: Mesh, pos: Vector3, historyKey?: string) => {
    // Get origin marker
    const originMarker = getOriginMarker(tubeInternalsRef)
    if (!originMarker) {
      return
    }

    // Calculate current offset between marker and origin
    const markerWorldPos = MeshUtils.copyWorldPosition(marker)
    const originWorldPos = MeshUtils.copyWorldPosition(originMarker)
    const offset = markerWorldPos.clone().sub(originWorldPos)

    // Calculate new position by subtracting offset from desired position
    const newPosition = pos.clone().sub(offset)

    // Update tube position
    updateTubeValues(tube.id, (t) => {
      t.position = newPosition
    }, false, historyKey)

    // Update matrices
    originMarker.updateMatrixWorld(true)
  }

  const addToLength = (distanceInMm: number, movableMarkerName?: string, movableMarkerPosition?: Vector3, lengthValueOverride?: number, historyKey?: string): void => {

    const valueInCm = distanceInMm * 100
    const valueInInches = valueInCm / 2.54

    const allMarkers = getAllMarkers()
    const marker = allMarkers.find(marker => marker?.name === movableMarkerName)
    if (!marker) { return }

    /*const valueToAdd = tube.partUnits === "cm"
      ? valueInCm
      : valueInInches*/

    const newLengthInInches = lengthValueOverride ?? (tube.length + valueInInches)

    const mesh = getMesh()
    if (mesh) {
      mesh.visible = false
    }
    updateLength(newLengthInInches, historyKey)

    if (movableMarkerPosition) {
      setTimeout(() => {
        setPositionWithMarker(marker, movableMarkerPosition, historyKey)
        if (mesh) {
          setTimeout(() => {
            mesh.visible = true
            setTimeout(() => {
              removeGuidelines(tubeInternalsRef, scene, setInternals)
            }, 50)
          }, 0)
        }
      }, 0)
    }

  }

  const updateMatrix = () => {
    const originMarker = getOriginMarker(tubeInternalsRef)
    if (originMarker) {
      originMarker.updateWorldMatrix(true, true)
    }
  }

  const updateLength = (lengthInInches: number, historyKey?: string) => {
    setTubeLengthSimple(lengthInInches, tubeInternalsRef, tubeMeshRef, updateCamera)
    const lengthForUnitRealValue = unit === "cm" ? lengthInInches * 2.54 : lengthInInches
    updateTubeValues(tube.id, (t) => {
      t.length = lengthInInches
      t.unitRealValue = lengthForUnitRealValue
    }, false, historyKey)
  }

  const setupUnitsRealValue = () => {
    if (!tube.unitRealValue) {
      const lengthInInches = unit === "cm" ? tube.length / 2.54 : tube.length
      updateTubeValues(tube.id, (t) => {
        t.unitRealValue = tube.length
        t.length = lengthInInches
      })
    }
  }


  const visualDebugSetter = useCallback((
    content: string | string[],
    scope = "default",
    clear = false,
    objects?: Object3D[]
  ) => {
    setVisualDebug(prev => {
      const newEntry = {
        scope,
        contents: Array.isArray(content) ? content : [content,],
        objects: objects || [],
      }

      if (clear) { return { [scope]: newEntry, } }

      return {
        ...prev,
        [scope]: {
          ...prev[scope],
          contents: [...(prev[scope]?.contents || []), ...newEntry.contents,].slice(-20),
          objects: [...(prev[scope]?.objects || []), ...newEntry.objects,],
        },
      }
    })
  }, [])

  const setVisibility = (visible: boolean) => {
    if (tubeMeshRef.current) {
      tubeMeshRef.current.visible = visible
    }
  }

  const getVisibility = () => {
    if (tubeMeshRef.current) {
      return tubeMeshRef.current.visible
    }
    return true
  }

  useRegisterComponent(props.id, {
    updateColor, originalColor, getColor, deletePart: handleTubeDelete,
    getPartInfo, updateInitialMarkerName,
    getBoundingBox, getBoundingBoxMesh, getFacingDirectionOfMarkers,
    duplicatePart, setUItoNone, setPositionWithMarker,
    updatePositionAndRotation, setVisibility, getAllMarkers, getMesh,
    updateMatrix, updateLength, addToLength, getEveryMarker: getAllMarkers,
    visualDebugSetter, getStartAndEndInnerMarkers, getVisibility,
  },)

  const getInternals = useCallback(() => {
    return tubeInternalsRef
  }, [tubeInternalsRef,])

  const setMaxLengthAsCurrentLength = useCallback(() => {
    tubeInternalsRef.current.snapState.maxLengthSetted = true
    tubeInternalsRef.current.snapState.maxPossibleLength
      = tube.length
    tubeInternalsRef.current.snapState.isSnapped = true
  }, [tubeInternalsRef, tube,])

  useEffect(() => {
    if (isSelected) {
      setUiVisible(true)
      if (isAdmin) {

        if (tubeInternalsRef.current.boundingBoxMesh?.userData.getWorldCorners && seeOBBdebugOnSelect) {
          visualizeOrientedBoundingBox(tubeInternalsRef.current.boundingBoxMesh, scene)
        }
        if (visualizeFacesForOBB && tubeInternalsRef.current.boundingBoxMesh) {
          const allFaces: Faces = tubeInternalsRef.current.boundingBoxMesh.userData.getAllFaces()
          //draw all the face points with a different color per face
          console.log(allFaces, "allFaces")
          visualizeFaces(allFaces, scene, 5000, true)
        }

        console.log(tube.id, "is selected", tube)
        if (viewMarkerPositionsWithLabels) {
          const markers = getAllMarkers()
          visualizeMarkerPositionsWithLabels(scene, markers as Mesh[], { color: "red", timer: 10000, sphereRadius: 0.01, depthTestAndWrite: true, })
        }
      }
      if (isAdmin) {
        console.log(tubeInternalsRef.current, "tubeInternalsRef.current")
        console.log(tube.id, tube, "selected basic tube")
        console.log(partConnectionsValue, "partConnectionsValue")
      }
      setTubeUI(TUBE_UI.NONE)
      const timer = setTimeout(() => {
        setTubeUI(TUBE_UI.SLIDER)
      }, 150)
      return () => clearTimeout(timer)
    } else {
      // Delay hiding UI to allow for any final updates
      const timer = setTimeout(() => {
        setUiVisible(false)
      }, 300)
      return () => clearTimeout(timer)
    }
  }, [isSelected,])



  return React.useMemo(() => {
    logRenders && console.log(`Tube render ${props.id}`)
    return (
      <React.Fragment>
        {getMarker(TubeMarkerEnum.BOTTOM, 0)}
        {getMarker(TubeMarkerEnum.TOP, 0)}
        <mesh
          ref={(r) => { tubeMeshRef.current = r as Mesh }}
          userData={{ type: "COLLISION_TUBE", partId: tube.id, }}
          name={props.id}>
          <meshMatcapMaterial
            map={material && woodTexture ? woodTexture : null}
            attach="material"
            opacity={0.4}
            color={color} />
        </mesh>
        {uiVisible && <TubeUI
          getTubeInternals={getInternals}
          tube={tube}
          tubeUI={tubeUI}
          setTubeUI={setTubeUI}
          getDrag={() => getDrag(tubeInternalsRef.current.dragState)}
          getSnap={() => getSnap(tubeInternalsRef.current.snapState)}
          handleTubeDelete={handleTubeDelete}
          handleTubeSwap={handleSwap}
          handleTubeLength={handleTubeLength}
          handleTubeState={handleTubeState}
          handleMouseDown={handleMouseDown}
          handleInvert={handleInvert}
          buildCollider={buildCollider}
          updateCollider={updateCollider}
          setMaxLengthAsCurrentLength={setMaxLengthAsCurrentLength}
          setInternals={setInternals}
          onDuplicate={props.sceneRefs.current.duplicateSelectedParts ?? (() => { })}
          setIdsAsHighlightedAndTurnOnControl
          ={props.sceneRefs.current.setIdsAsHighlightedAndTurnOnControl ?? (() => { })}
          unsnap={unsnap}
          hasTubeConnections={partConnectionsValue.length > 0}
          updateUnit={handleUnitsChange}
          unit={unit as UnitType}
        />}
        {debugNormals && debugLabels.map((label, index) => (
          <Text
            key={index}
            position={label.position}
            material-depthTest={false}
            material-depthWrite={false}
            fontSize={0.02}
            color="black"
            anchorX="center"
            anchorY="middle"
            userData={{ ignoreRaycast: true, }}
          >
            {label.text}
          </Text>
        ))}
        {showDebugText && boundingBoxCenter && isSelected && (
          <VisualDebug
            contents={visualDebug}
            position={boundingBoxCenter}
            visible={showDebugText}
            isSelected={isSelected}
            partId={tube.id}
          />
        )}
      </React.Fragment>
    )
  }, [
    tube,
    tube.partUnits,
    tubeUI,
    uiVisible,
    color,
    isSelected,
    partConnectionsValue,
    unit,
  ])
}

export default Tube