/* eslint-disable max-len */
import * as React from "react"
import { useEffect } from "react"
import { useRecoilValue } from "recoil"
import { sceneAtom } from "../../state/scene/atoms"
import { useLocation, useParams } from "react-router-dom"
import { useThree } from "@react-three/fiber"

const LOCAL_STORAGE_KEY = "scene_backup"

const CRASH_STORAGE_KEY = "last_crash_info"

interface CrashInfo {
    error: {
        message: string,
        stack?: string,
        type?: string,
    };
    timestamp: string;
    url: string;
}

export const recordCrash = (error: Error) => {
    try {
        const crashInfo: CrashInfo = {
            error: {
                message: error.message,
                stack: error.stack,
                type: error.name,
            },
            timestamp: new Date().toISOString(),
            url: window.location.href,
        }
        //console.log(crashInfo, "crashInfo")
        localStorage.setItem(CRASH_STORAGE_KEY, JSON.stringify(crashInfo))
    } catch (e) {
        console.error("Failed to save crash info:", e)
    }
}

// Usage example in your app's entry point:
// window.addEventListener("error", (event) => {
//     recordCrash(event.error)
// })

// Optional: handle unhandled promise rejections
//window.addEventListener("unhandledrejection", (event) => {
//    recordCrash(new Error(event.reason))
//})

interface BackupData {
    sceneData: any;
    lastUpdated: string;
    userId?: string;
    designId?: string;
}

const LocalStorageProvider = ({ children, sceneRefs, }: { children: React.ReactNode, sceneRefs: any, }) => {
    const sceneData = useRecoilValue(sceneAtom)
    const location = useLocation()
    const { userId, designId, } = useParams()
    const { scene, gl, camera, } = useThree()

    sceneRefs.current = {
        ...sceneRefs.current,
        scene,
        gl,
        camera,
    }


    // Track if we've had our first parts change
    const [hasPartsChanged, setHasPartsChanged,] = React.useState(false)
    const previousPartsLength = React.useRef(Object.keys(sceneData.parts).length || 0)

    useEffect(() => {
        const currentPartsLength = Object.keys(sceneData.parts).length

        // Check if parts length has changed
        if (!hasPartsChanged && currentPartsLength !== previousPartsLength.current) {
            setHasPartsChanged(true)
        }
        previousPartsLength.current = currentPartsLength

        //console.log("hasPartsChanged", hasPartsChanged)
        //console.log("currentPartsLength", currentPartsLength)
        //console.log("previousPartsLength", previousPartsLength.current)
        //console.log(sceneData)

        // Only proceed with backup if we've had our first parts change
        if (!hasPartsChanged) { return }

        try {
            // Extract userId and designId from URL

            //console.log(userId, designId, "userId, designId")

            // Create backup object
            const backupData: BackupData = {
                sceneData,
                lastUpdated: new Date().toISOString(),
                ...(userId && userId !== "design" && { userId, }),
                ...(designId && { designId, }),
            }

            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(backupData))
        } catch (error) {
            console.error("Failed to save scene to localStorage:", error)
        }
    }, [sceneData, location.pathname, hasPartsChanged,])

    return <>{children}</>
}

export default LocalStorageProvider