/* eslint-disable max-lines-per-function */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef } from "react"
import { Color, Group, InstancedMesh, Matrix4, Mesh, Quaternion, Texture, Vector3 } from "three"
import { instancedMeshContext } from "./InstancedMeshProvider"

const useInstancedMesh = (
    mesh: Mesh,
    id: string,
    referenceName: string,
    onClick: (mesh: InstancedMesh, matrix: Matrix4) => void,
    withMaterial?: string,
    woodTexture?: Texture) => {
    const context = useContext(instancedMeshContext)
    const ref = useRef<Group>()
    const instancedMesh = useRef<InstancedMesh>()
    const index = useRef<number>()
    const batchIndex = useRef<number>()
    const lastMatrixWorld = useRef<Matrix4>()

    const updateTransforms = () => {
        if (instancedMesh.current && ref.current && index.current !== undefined) {
            ref.current.updateWorldMatrix(true, true)
            lastMatrixWorld.current = ref.current.matrixWorld
            instancedMesh.current.setMatrixAt(index.current, lastMatrixWorld.current)
            instancedMesh.current.instanceMatrix.needsUpdate = true
            instancedMesh.current.computeBoundingSphere()
        }
    }

    const getIndex = () => {
        return index.current
    }

    const getBatchIndex = () => {
        return batchIndex.current
    }

    const updateInstance = (newIndex: number, newBatchIndex: number) => {
        index.current = newIndex

        // Update batch index
        batchIndex.current = newBatchIndex

        updateTransforms()
        instancedMesh.current?.computeBoundingSphere()
    }

    const deleteMesh = () => {
        if (context && instancedMesh.current) {
            context.deleteMesh(getReferenceName(), id)
            instancedMesh.current.instanceMatrix.needsUpdate = true
            instancedMesh.current.computeBoundingSphere()
        }
    }

    const updateColor = (color: string) => {
        if (instancedMesh.current && index.current !== undefined) {
            instancedMesh.current.setColorAt(index.current, new Color(color))
            instancedMesh.current.instanceColor!.needsUpdate = true
        }
    }

    const createStandAloneMeshFromInstance = () => {
        if (instancedMesh.current && index.current !== undefined) {
            const matrix = new Matrix4()
            instancedMesh.current.getMatrixAt(index.current, matrix)

            // Clone the original mesh
            const clonedMesh = mesh.clone()

            // Apply position, rotation, and scale from the matrix
            const position = new Vector3()
            const quaternion = new Quaternion()
            const scale = new Vector3()
            matrix.decompose(position, quaternion, scale)

            clonedMesh.position.copy(position)
            clonedMesh.quaternion.copy(quaternion)
            clonedMesh.scale.copy(scale)

            // Copy material from instanced mesh
            if (instancedMesh.current.material) {
                if (Array.isArray(instancedMesh.current.material)) {
                    clonedMesh.material = instancedMesh.current.material.map(mat => mat.clone())
                } else {
                    clonedMesh.material = instancedMesh.current.material.clone()
                }
            }

            return clonedMesh
        }

        console.warn("Failed to create stand-alone mesh: instancedMesh or index is not available")
        return null
    }

    const changeVisibilityOnSpecificIndex = (visible: boolean) => {
        if (instancedMesh.current && index.current !== undefined) {
            const matrix = new Matrix4()
            instancedMesh.current.getMatrixAt(index.current, matrix)

            // Use the visible parameter directly to set the scale
            const scale = visible ? 1 : 0.00001
            matrix.scale(new Vector3(scale, scale, scale))

            instancedMesh.current.setMatrixAt(index.current, matrix)
            instancedMesh.current.instanceMatrix.needsUpdate = true
        }
    }

    const getVisibilityOnSpecificIndex = () => {
        if (instancedMesh.current && index.current !== undefined) {
            const matrix = new Matrix4()
            instancedMesh.current.getMatrixAt(index.current, matrix)
            const position = new Vector3()
            const quaternion = new Quaternion()
            const scale = new Vector3()
            matrix.decompose(position, quaternion, scale)

            // Consider visible if scale is >= 0.00001
            return Math.abs(scale.x) >= 0.000001
        }
        return false
    }

    const internalCallback = () => {
        if (instancedMesh.current && index.current !== undefined) {
            const matrix = new Matrix4()
            instancedMesh.current.getMatrixAt(index.current, matrix)
            onClick(instancedMesh.current, matrix)
        }
    }

    const getMatrix = () => {
        if (instancedMesh.current && index.current !== undefined) {
            const matrix = new Matrix4()
            instancedMesh.current.getMatrixAt(index.current, matrix)
            return matrix
        }
    }

    const getReferenceName = () => {
        if (withMaterial) {
            return `${referenceName}-mat-${withMaterial}`
        }

        return referenceName
    }

    const setVisibilityForInstance = (visible: boolean) => {
        if (instancedMesh.current && index.current !== undefined) {
            const matrix = new Matrix4()
            instancedMesh.current.getMatrixAt(index.current, matrix)

            // Extract current components
            const position = new Vector3()
            const quaternion = new Quaternion()
            const scale = new Vector3()
            matrix.decompose(position, quaternion, scale)

            // Set scale based on visibility parameter
            const newScale = visible ? 1 : 0.00001

            // Create new matrix with updated scale
            const newMatrix = new Matrix4().compose(
                position,
                quaternion,
                new Vector3(newScale, newScale, newScale)
            )

            instancedMesh.current.setMatrixAt(index.current, newMatrix)
            instancedMesh.current.instanceMatrix.needsUpdate = true
            instancedMesh.current.computeBoundingSphere()
        }
    }

    useEffect(() => {
        if (context) {
            const instance = context.createMesh(
                id, getReferenceName(), mesh, internalCallback, updateInstance, woodTexture
            )
            instancedMesh.current = instance.instancedMesh
            index.current = instance.index
            batchIndex.current = instance.batchIndex
        }
    }, [])

    return {
        ref,
        updateTransforms,
        deleteMesh,
        instancedMesh,
        updateColor,
        internalCallback,
        getMatrix,
        getIndex,
        getBatchIndex,
        createStandAloneMeshFromInstance,
        changeVisibilityOnSpecificIndex,
        getVisibilityOnSpecificIndex,
        setVisibilityForInstance,
    }
}

export default useInstancedMesh