import React from "react"
import { useRecoilValue } from "recoil"
import { checkExistenceSelector } from "../../../../../state/scene/selectors"
import { PartDataStore, PartTypeEnum } from "../../../../../utils/Types"
import Connector from "./parts/connector/Connector"
import Tube from "./parts/tube/Tube"

import SegmentedTube from "./parts/segmentedTube/SegmentedTube"
interface Props {
    id: string;
    type: PartTypeEnum;
    handleDeletePart: (id: string) => void;
    partDataRef: React.MutableRefObject<Record<string, PartDataStore>>;
    sceneRefs: any;
}

const Part = (props: Props) => {
    const partExists = useRecoilValue(checkExistenceSelector({ id: props.id, }))

    const getPart = () => {
        switch (props.type) {
            case PartTypeEnum.connector:
                return (
                    <Connector
                        id={props.id}
                        handleDeletePart={props.handleDeletePart}
                        partDataRef={props.partDataRef}
                        sceneRefs={props.sceneRefs}
                        key={props.id} />
                )
            case PartTypeEnum.tube:
                return (
                    <Tube
                        id={props.id}
                        handleDeletePart={props.handleDeletePart}
                        partDataRef={props.partDataRef}
                        sceneRefs={props.sceneRefs}
                        key={props.id} />
                )
            case PartTypeEnum.segmentedTube:
                return (
                    <SegmentedTube
                        id={props.id}
                        handleDeletePart={props.handleDeletePart}
                        partDataRef={props.partDataRef}
                        sceneRefs={props.sceneRefs}
                        key={props.id} />
                )
            default:
                return null
        }
    }

    return partExists ? getPart() : null
}

export default Part