/* eslint-disable max-statements */
/* eslint-disable max-len */
import React, { useLayoutEffect, useMemo } from "react"
import { Typography } from "antd"
import { CustomTooltip as Tooltip } from "../../../ui/CustomTooltip"
import styled from "styled-components"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { selectedPartSelector, multiSelectionSelector } from "../../../../state/scene/selectors"
import { boundingBoxAtom, BoundingBoxState, scenePartsSelector } from "../../../../state/scene/atoms"
import PartSize from "./PartSize"
import MultiSelectionInfo from "./MultiSelectionInfo"
import { useComponentRegistry } from "../../../../providers/multiselectProvider/useComponentMethods"
import { layoutState } from "../../../../state/layoutState"
import { GenericPartState } from "../../../../utils/Types"
import { SceneRef } from "../../../../state/types"
const { Text, } = Typography

const TopBarContainer = styled.div<{ $isMobile: boolean, }>`
    position: absolute;
    top: 0;
    left: ${({ $isMobile, }) => ($isMobile ? "0" : "50%")};
    transform: ${({ $isMobile, }) => ($isMobile ? "none" : "translateX(-50%)")};
    text-align: ${({ $isMobile, }) => ($isMobile ? "left" : "center")};
    width: ${({ $isMobile, }) => ($isMobile ? "calc(100% - 130px)" : "calc(100% - 280px)")};
    margin-top: ${({ $isMobile, }) => ($isMobile ? "calc(1rem + 44px)" : "1rem")};
    display: flex;
    justify-content: center;
    align-items: ${({ $isMobile, }) => ($isMobile ? "baseline" : "center")};
    flex-direction: column;
    flex-wrap: wrap;
`

const MainArea = styled.div<{ $isMobile: boolean, }>`
    display: flex;
    justify-content: ${({ $isMobile, }) => ($isMobile ? "space-between" : "center")};
    align-items: center;
    margin-left: ${({ $isMobile, }) => ($isMobile ? "12px" : "0")};
    flex: 0 1 auto;
    width: ${({ $isMobile, }) => ($isMobile ? "calc(100% - 12px)" : "auto")};
    ${({ $isMobile, }) => $isMobile && `
        min-width: 0;
        max-width: 100%;
        overflow: hidden;
    `}
`

const SecondaryArea = styled.div<{ $isMobile: boolean, }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${({ $isMobile, }) => ($isMobile ? `
        position: fixed;
        left: 50%;
        top: 125px;
        transform: translate(-50%, -50%);
        width: calc(100% - 240px);
        text-align: center;
    ` : `
        flex: 0 1 auto;
    `)}
`

const PartNameContainer = styled.div<{ $isMobile: boolean, }>`
    ${({ $isMobile, }) => $isMobile && `
        max-width: 100%;
        overflow: hidden;
    `}
`

const PartNameText = styled(Text) <{ $isMobile: boolean, $clip: boolean, $underline: boolean, }>`
    color: grey;
    cursor: pointer;
    text-decoration: ${({ $underline, }) => ($underline ? "underline" : "none")};
    font-size: ${({ $isMobile, }) => ($isMobile ? "13px" : "14px")};
    ${({ $clip, }) => $clip && `
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `}
    ${({ $isMobile, }) => $isMobile && `
        display: block;
        max-width: 100%;
    `}
`

const SelectedPartName = ({ isMobile, sceneRefs, ...otherProps }:
  { isMobile: boolean, sceneRefs: SceneRef, [key: string]: any, }) => {
  const setBoundingBox = useSetRecoilState(boundingBoxAtom)
  const selectedPart = useRecoilValue(selectedPartSelector)
  const multiSelection = useRecoilValue(multiSelectionSelector)
  const { getComponent, } = useComponentRegistry()
  const sceneAtomList = useRecoilValue(scenePartsSelector)
  const setLayout = useSetRecoilState(layoutState)

  useLayoutEffect(() => {
    setBoundingBox((prevState: BoundingBoxState) => ({ ...prevState, show: false, }))
  }, [])

  const otherPartsWithSameTypeId = useMemo(() => {
    if (!selectedPart) {
      return []
    }
    return Object.values(sceneAtomList).filter(
      part => part.apiTypeId === selectedPart.apiTypeId
    )
  }, [selectedPart, sceneAtomList,])

  const handleClick = () => {
    if (!selectedPart) {
      return
    }
    const component = getComponent(selectedPart.id)
    if (component && component.setUItoNone) {
      component.setUItoNone()
    }

    const partIds = otherPartsWithSameTypeId.map(part => part.id)
    setLayout(draft => ({
      ...draft,
      showPartList: true,
      isDrawerOpen: true,
      showDesignsList: false,
      filteredPartIds: partIds,
      from: "singlePartInfo",
    }))
  }

  if (!selectedPart && !multiSelection.length) {
    return (
      <TopBarContainer $isMobile={isMobile}>
        <MainArea $isMobile={isMobile}>
          <PartSize sceneRefs={sceneRefs} />
          {isMobile && <span id="selected-part-name-root" />}
        </MainArea>
        <SecondaryArea $isMobile={isMobile}>
          {!isMobile && <span id="selected-part-name-root" />}
        </SecondaryArea>
      </TopBarContainer>
    )
  }

  if (multiSelection.length > 0) {
    return (
      <TopBarContainer $isMobile={isMobile}>
        <MainArea $isMobile={isMobile}>
          <PartNameText $isMobile={isMobile} $clip={isMobile} $underline={false}>
            <PartSize sceneRefs={sceneRefs} />
          </PartNameText>
          {isMobile && <span id="selected-part-name-root" />}
        </MainArea>
        <SecondaryArea $isMobile={isMobile}>
          <PartNameText $isMobile={isMobile} $clip={false} $underline={false}>
            <MultiSelectionInfo sceneRefs={sceneRefs} />
          </PartNameText>
          {!isMobile && <span id="selected-part-name-root" />}
        </SecondaryArea>
      </TopBarContainer>
    )
  }

  // Handle null check for selectedPart
  if (!selectedPart) {
    return null
  }

  const totalCount = otherPartsWithSameTypeId.length
  const getTypeFriendlyName = (part: GenericPartState) => {
    if ("baseName" in part && part.baseName) {
      return part.baseName
    }
    if (part.type === "tube_part_type") {
      return "Tube"
    }
    if (part.type === "connector_part_type") {
      return "Connector"
    }
    return "Part"
  }
  const getTooltipText = () => {
    const typeName = getTypeFriendlyName(selectedPart)
    if (totalCount === 1) {
      return `View ${typeName}`
    }
    return `View all ${totalCount} ${typeName.trim()}${totalCount > 1 ? "s" : ""}`
  }
  const tooltipText = getTooltipText()

  return (
    <TopBarContainer $isMobile={isMobile}>
      <MainArea $isMobile={isMobile}>
        <PartNameContainer $isMobile={isMobile}>
          <Tooltip placement="bottom" title={tooltipText}>
            <PartNameText onClick={handleClick} $isMobile={isMobile} $clip={isMobile} $underline={true}>
              {selectedPart.name}
              <PartSize selectedPart={selectedPart} sceneRefs={sceneRefs} />
            </PartNameText>
          </Tooltip>
        </PartNameContainer>
        {isMobile && <span id="selected-part-name-root" />}
      </MainArea>
      <SecondaryArea $isMobile={isMobile}>
        {!isMobile && <span id="selected-part-name-root" />}
      </SecondaryArea>
    </TopBarContainer>
  )
}

export default SelectedPartName