/* eslint-disable max-len */
/* eslint-disable max-statements */
import React, { useRef, useEffect } from "react"
import { useThree } from "@react-three/fiber"
import { projectTo2D } from "../multiselectProvider/MultiSelectUtils"
import { PointInfo } from "./types"
import { SceneRef } from "../../state/types"

interface Point2D {
    x: number;
    y: number;
}

interface MeasurementLineProps {
    point1: PointInfo;
    isPreview?: boolean;
    color?: string;
    hoveredPoints: Set<string>;
    sceneRefs: SceneRef;
}
const MeasurementLine: React.FC<MeasurementLineProps> = ({
    point1,
    isPreview = false,
    color,
    hoveredPoints,
    sceneRefs,
}) => {
    const cursorPositionRef = useRef<Point2D | null>(null)
    const ctxRef = sceneRefs.current.ctxRef
    const { camera, size, gl, } = useThree()

    const clearCanvas = () => {
        const ctx = ctxRef?.current
        if (!ctx) { return }
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    }

    useEffect(() => {
        let isDrawing = false

        const handleMouseMove = (event: MouseEvent) => {
            const canvas = ctxRef?.current?.canvas
            if (!canvas) { return }
            const rect = canvas.getBoundingClientRect()
            const newPosition = {
                x: event.clientX - rect.left,
                y: event.clientY - rect.top,
            }
            cursorPositionRef.current = newPosition
            isDrawing = true
        }

        const draw = () => {
            if (!isDrawing) { return }  // Early return if we're not supposed to be drawing

            const ctx = ctxRef?.current
            if (!ctx) { return }

            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
            const point1Screen = projectTo2D(point1.position, camera, gl)

            if (!cursorPositionRef.current) {
                ctx.beginPath()
                ctx.fillStyle = "red"
                ctx.fill()
                if (isDrawing) {
                    requestAnimationFrame(draw)
                }
                return
            }

            // Draw line and both endpoints
            ctx.beginPath()
            ctx.moveTo(point1Screen.x, point1Screen.y)
            ctx.lineTo(cursorPositionRef.current.x, cursorPositionRef.current.y)
            ctx.strokeStyle = isPreview && hoveredPoints?.size > 0 ? "#0066ff" : (color || "#000000")
            ctx.lineWidth = 4
            ctx.stroke()

            ctx.beginPath()

            if (isDrawing) {
                requestAnimationFrame(draw)
            }
        }

        window.addEventListener("mousemove", handleMouseMove)
        isDrawing = true
        const animationFrame = requestAnimationFrame(draw)

        return () => {
            isDrawing = false
            window.removeEventListener("mousemove", handleMouseMove)
            cancelAnimationFrame(animationFrame)
            clearCanvas()
        }
    }, [point1, color, isPreview, hoveredPoints, ctxRef, camera, size,])

    return null
}

export default MeasurementLine