import { atom } from "recoil"

export type LayoutType = {
    isDrawerOpen: boolean,
    isActionsOpen: boolean,
    isSliderOpen: boolean,
    showPartList: boolean,
    showDesignsList: boolean,
    filteredPartIds?: string[],
    from?: "multiSelectionInfo" | "partsList" | "singlePartInfo" | undefined,
}

export const layoutState = atom<LayoutType>({
    key: "layoutState",
    default: {
        isDrawerOpen: false,
        isActionsOpen: false,
        isSliderOpen: false,
        showPartList: false,
        showDesignsList: false,
        filteredPartIds: undefined,
        from: undefined,
    },
})