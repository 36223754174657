import React from "react"
import PillToggle from "../../../../../common/components/PillToggle"
import isMobile from "ismobilejs"

interface PartsViewToggleProps {
    showSelectedOnly: boolean;
    onChange: (showSelected: boolean) => void;
}

const PartsViewToggle = ({ showSelectedOnly, onChange, }: PartsViewToggleProps) => {
    const isMobileDevice = isMobile().any
    const handleToggle = (value: string) => {
        onChange(value === "Selected")
    }

    return (
        <PillToggle
            options={["All", "Selected",]}
            activeOption={showSelectedOnly ? "Selected" : "All"}
            onChange={handleToggle}
            width={isMobileDevice ? 100 : 90}
        />
    )
}

export default PartsViewToggle