/* eslint-disable max-len */
import CameraControls from "camera-controls"
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react"
import ReactDOM from "react-dom"
import Button from "../../../../../common/components/Button"
import { PartListIcon } from "../../../../../common/components/icons/Icons"
import { useRecoilState } from "recoil"
import { layoutState } from "../../../../state/layoutState"
import isMobile from "ismobilejs"
import produce from "immer"
import useViewOnly from "../../../../providers/viewerProvider/useViewOnly"
import { ReactComponent as QuestionIcon } from "../../../../assets/icons/question_mark.svg"
import { BottomSheet } from "../../../../../common/components/BottomSheet/BottomSheet"
import ButtonLinks from "../../../../../common/components/ButtonLinks/ButtonLinks"
import helpLinks from "../../../../../common/utils/HelpLinks"
import styled from "styled-components"
import {
    ReactComponent as RefreshIcon
} from "../../../../assets/icons/reset-15-2.svg"
import {
    ReactComponent as FocusIcon
} from "../../../../assets/icons/FocusIcon.svg"
import {
    ReactComponent as FocusIconWhite
} from "../../../../assets/icons/FocusIconWhite.svg"
import { showCameraControlsState } from "../../../../state/scene/atoms"
import { CustomTooltip } from "../../../ui/CustomTooltip"
import { cameraUtils } from "../utils/cameraUtils"

interface Props {
    cameraControls: React.MutableRefObject<CameraControls | null>;
    autofocusMode: boolean;
    setAutofocusMode: (value: boolean) => void;
    userSelectionAutoFocusMode: React.MutableRefObject<boolean | null>;
}

const StyledRefreshIcon = styled(RefreshIcon) <{ $isMobile: boolean, }>`
  fill: #767676;
  width: ${props => (props.$isMobile ? "auto" : 15)}px;
  height: ${props => (props.$isMobile ? "auto" : 15)}px;

`

const StyledFocusIcon = styled(FocusIcon) <{ $isMobile: boolean, }>`
  fill: #767676;
  width: ${props => (props.$isMobile ? "auto" : 12)}px;
  height: ${props => (props.$isMobile ? "auto" : 12)}px;

`

const StyledFocusIconWhite = styled(FocusIconWhite) <{ $isMobile: boolean, }>`
  fill: #767676;
  width: ${props => (props.$isMobile ? "auto" : 12)}px;
  height: ${props => (props.$isMobile ? "auto" : 12)}px;

`

const Footer = (props: Props) => {
    const { resetCameraRotation, } = cameraUtils
    const [showCameraControls,] = useRecoilState(showCameraControlsState)
    const [updateLayout, setUpdateLayout,] = useState(0)
    const cameraContainer = document.getElementById("camera_button")
    const [layoutValue, setLayout,] = useRecoilState(layoutState)
    const isMobilePhone = isMobile(window.navigator).phone

    const isViewOnly = useViewOnly()

    useLayoutEffect(() => {
        // Read autofocus preference from localStorage on component mount
        const savedAutofocus = localStorage.getItem("autofocusMode")
        if (savedAutofocus !== null) {
            const isAutofocus = savedAutofocus === "true"
            props.userSelectionAutoFocusMode.current = isAutofocus
            props.setAutofocusMode(isAutofocus)
        }
        setUpdateLayout(updateLayout + 1)
    }, [])

    const handlePartList = () => {
        setLayout(produce((draft) => {
            draft.showPartList = true
            draft.showDesignsList = false
            draft.isDrawerOpen = true
            draft.from = "partsList"
        }))
    }

    const toggleAutofocus = useCallback(() => {
        const newMode = !props.autofocusMode
        props.userSelectionAutoFocusMode.current = newMode
        props.setAutofocusMode(newMode)
        // Save autofocus preference to localStorage
        localStorage.setItem("autofocusMode", String(newMode))
    }, [props.autofocusMode, props.setAutofocusMode, props.userSelectionAutoFocusMode,])
    return (
        //we only have this footer (technically not a footer now) for desktop
        //we've removed the autofocus and reset camera controls for mobile bc of space issues
        cameraContainer
        && ReactDOM.createPortal(
            <React.Fragment>
                {
                    showCameraControls && !isMobilePhone
                    && (
                        <CustomTooltip title="Reset Camera">
                            <Button
                                onClick={() => resetCameraRotation(props.cameraControls.current!)}
                                style={{
                                    paddingTop: isMobilePhone ? "14px" : "8px", border: "1px solid white",
                                }}
                            >
                                <StyledRefreshIcon $isMobile={isMobilePhone} />
                            </Button>
                        </CustomTooltip>
                    )
                }
                {
                    showCameraControls
                    && !isMobilePhone
                    && <CustomTooltip
                        title={props.autofocusMode ? "Autofocus On" : "Autofocus Off"}
                        placement="top"
                    >
                        <Button
                            onClick={toggleAutofocus}
                            style={{
                                border: "1px solid white",
                                ...(props.autofocusMode && { border: "1px solid gray", }),
                                paddingTop: isMobilePhone ? "14px" : "8px",
                            }}
                        >
                            <StyledFocusIcon $isMobile={isMobile(window.navigator).phone} />
                        </Button>
                    </CustomTooltip>
                }
            </React.Fragment>,
            cameraContainer)


    )
}

export default Footer