/* eslint-disable complexity */
/* eslint-disable no-continue */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-negated-condition */
/* eslint-disable no-useless-return */
/* eslint-disable default-case */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState, useCallback } from "react"
import { Vector3, Line as LineThree, BufferGeometry, LineBasicMaterial, Color, Box3, Box3Helper, Mesh, Scene, MeshBasicMaterial, ArrowHelper, Camera, Matrix4, Raycaster, CylinderGeometry, Quaternion, Object3D } from "three"
import { CameraControls, Html, Line, Text, Icosahedron, Billboard } from "@react-three/drei"
import { useComponentRegistry } from "../providers/multiselectProvider/useComponentMethods"
import { ScalerUIConfig } from "../providers/multiselectProvider/MultiSelectProvider"
import { useThree } from "@react-three/fiber"
import tinycolor2 from "tinycolor2"
import useGetDebugVariables from "./main/DesignScreen/utils/useGetDebugVariables"
import { messageUtils } from "./main/DesignScreen/scene/LowerRightMessages"
import { useLevaControls } from "../providers/debugProvider/useLevaControls"
import isMobile from "ismobilejs"
import { BackSide } from "three"
import { PartConnectionType } from "../state/scene/types"
import { useRecoilCallback, useRecoilValue } from "recoil"
import { sceneAtom, unitSelector } from "../state/scene/atoms"
import { scalerCreateOrientedBoundingBox, createCombinedOrientedBoundingBoxUsingNormal, generateDirectionalMarkers, getNormalRelativePositions, createSpatialRelationships, BoxSpatialInfo, getPartIdsByAxis, getDirectionMultiplier, getCursorType, drawAllFacesOfOBBAndCenter, SpatialMaps, calculateAndAdjustPercentages, visualizePartPercentages } from "./main/DesignScreen/utils/scalerUtils"
import { drawVector3Point, getAllConnectedPartIdsRecursiveWithNames, visualizeGrowthDirections } from "../utils/PartUtils"
import { calculateDashParameters, createMeasurementLines, LineInfoNX, pixelsTo3DUnits } from "../utils/ScalerNxerutils"
import debounce from "lodash/debounce"

export type viewForScalerUI = {
    viewID: string,
    normal: Vector3,
    meshes: Mesh[],
    uniqueIds: string[],
    markers: { [partId: string]: Mesh[], },
    normalMarkers: { [partId: string]: Mesh, }, // Add this new field
    types: string[],
    normalLineSequences?: string[][],
    partsOrder: string[],
    directionalMarkers?: DirectionalMarkers,
    scalingDirection?: "horizontal" | "vertical",
    lines?: LineInfo[],
    startAndEndMarkers?: { [partId: string]: { startMarker: Mesh, endMarker: Mesh, }, },
    allNormalMarkers?: { [partId: string]: Mesh[], },
    obb?: ScalerOBBResult,
    percentagesOfSequences?: Map<string[], Map<string, number>>,
}


interface ScalerUILinesProps {
    views: viewForScalerUI[];

    cameraControls: React.MutableRefObject<CameraControls | any>;
    onScaleStart: (view: viewForScalerUI, position: Vector3, scaleDirection: "horizontal" | "vertical") => void;
    onScaleEnd: (view: viewForScalerUI, line: LineInfo, distance: number, scaleDirection: "horizontal" | "vertical" | null, callback: () => void, debug: boolean) => void;
    onScaleUpdate: (view: viewForScalerUI, position: Vector3) => void;
    userIsResizingNxing: boolean;
    setUserIsResizingNxing: (value: boolean) => void;
    setAutofocusMode: (value: boolean) => void;
    highlightedPartIds: React.MutableRefObject<string[]>;
    autofocusMode: boolean;
    config: ScalerUIConfig;
    scene: Scene;
    enabled: boolean;
    lastViewsFirstPartId: string | null;
    selectionBox: Box3;
    dontResetSelection: React.MutableRefObject<boolean>;
    setOnTopOfTransformControls: (value: boolean) => void;
}

// Update the LineInfo type to include marker information
type MarkerInfo = {
    partId: string,
    marker: Mesh,  // The movable marker mesh
    distance: number,  // Distance from marker to line center
}

export type LineInfo = {
    points: Vector3[],
    isPerpendicularToNormal: boolean,
    viewType: "width" | "height",
    position: "left" | "right" | "top" | "bottom",
    markers: MarkerInfo[],  // Add this new field
    offsetDirection?: Vector3,
}


// Add this type for marker grouping
export type DirectionalMarkers = {
    normalAligned: {
        position: Vector3,
        partId: string,
        markerName: string,
        direction: Vector3,
    }[],
    inverseAligned: {
        position: Vector3,
        partId: string,
        markerName: string,
        direction: Vector3,
    }[],
}

// Add new state and types
interface ViewButtonInfo {
    position: Vector3;
    viewIndex: number;
    viewId: string;
    color: string;
}



export interface ScalerOBBResult {
    box: Box3;
    startFace: Vector3[]; // 4 points defining start face
    endFace: Vector3[];   // 4 points defining end face
    leftFace: Vector3[];
    rightFace: Vector3[];
    width: number;
    height: number;
    depth: number;
    center: Vector3;
    transformation: Matrix4;
    mesh: Mesh;
    adjustedWidth: number;
    adjustedHeight: number;
    adjustedDepth: number;
}

export interface ScalerOBBOptions {
    minWidth?: number;
    minHeight?: number;
    minDepth?: number;
    padding?: number;
}

export interface MeasurementLines {
    startFace: {
        width: [Vector3, Vector3],  // Points for width line
        height: [Vector3, Vector3], // Points for height line
    };
    endFace: {
        width: [Vector3, Vector3],
        height: [Vector3, Vector3],
    };
}

export type LineSet = {
    points: [Vector3, Vector3],
    position: "left" | "right" | "top" | "bottom",
    viewType: "width" | "height",
    isPerpendicular?: boolean,
    offsetDirection?: Vector3,
}



const ScalerUILines: React.FC<ScalerUILinesProps> = ({ views, cameraControls, config, enabled, scene, selectionBox, onScaleStart, onScaleUpdate, onScaleEnd, setUserIsResizingNxing, userIsResizingNxing, setAutofocusMode, autofocusMode, highlightedPartIds, lastViewsFirstPartId, setOnTopOfTransformControls, dontResetSelection, }) => {
    const debugBoxRef = useRef<Box3Helper | null>(null)
    const normalHelperRef = useRef<ArrowHelper | null>(null)
    const { getComponent, } = useComponentRegistry()
    const [activeView, setActiveView,] = useState<viewForScalerUI | null>(null)
    const [viewToClean, setViewToClean,] = useState<viewForScalerUI | null>(null)
    const [linePoints, setLinePoints,] = useState<LineInfo[]>([])
    const [isVisible, setIsVisible,] = useState(false)
    const [hoveredLineIndex, setHoveredLineIndex,] = useState<number | null>(null)
    const { gl, } = useThree()
    const originalMouseXYRef = useRef<{ x: number, y: number, } | null>(null)
    const originalCursorRef = useRef<string>("auto")
    const [activeLine, setActiveLine,] = useState<LineInfo | null>(null)
    const scaleTypeRef = useRef<"horizontal" | "vertical" | null>(null)
    const { getVariables, } = useGetDebugVariables()
    const [hoveredOverViewButton, setHoveredOverViewButton,] = useState(false)
    const unit = useRecoilValue(unitSelector)

    const helperObjectRef = useRef<Object3D[]>([])

    const isAdmin = getVariables().isAdmin
    const autoFocusModeRef = useRef<boolean>(autofocusMode ?? false)

    const { showScalerNormals, showOrientedBoundingBox, logNormalLineSequences, scaleEndLogs, showMarkerNormals, spatialMapDebug, viewPercentageLabels, } = useLevaControls()

    const [cameraDistance, setCameraDistance,] = useState(cameraControls.current?.distance ?? 0)

    // Debounced camera update function
    const updateCameraDistance = useCallback(
        debounce((camera) => {
            const newDistance = camera.distance
            if (newDistance !== cameraDistance) {
                setCameraDistance(newDistance)
            }
        }, 150), // Adjust debounce delay as needed
        []
    )

    // Watch for camera changes
    useEffect(() => {
        const handleCameraChange = () => {
            updateCameraDistance(cameraControls.current)
        }

        // Add camera change listener
        cameraControls.current.addEventListener("update", handleCameraChange)
        cameraControls.current.addEventListener("transitionstart", handleCameraChange)

        return () => {
            cameraControls.current.removeEventListener("update", handleCameraChange)
            cameraControls.current.removeEventListener("transitionstart", handleCameraChange)
            updateCameraDistance.cancel()
        }
    }, [cameraControls, updateCameraDistance,])

    // Add new state and types
    const [viewButtons, setViewButtons,] = useState<ViewButtonInfo[]>([])

    // Add new state for part distances
    const [partDistances, setPartDistances,] = useState<{
        position: Vector3,
        distance: number,
        partId: string,
    }[]>([])

    // Calculate button positions for all views
    const calculateViewButtonPositions = useCallback(() => {
        const buttonInfos: ViewButtonInfo[] = views.map((view, index) => {
            // Create OBB for the view
            const viewDirectionalMarkers = generateDirectionalMarkers(view, scene, showMarkerNormals)

            const obb = scalerCreateOrientedBoundingBox(viewDirectionalMarkers, {
                minWidth: 0.02,
                minHeight: 0.02,
                minDepth: 0.02,
                padding: isMobile(window.navigator).any ? 0.125 * 2 : 0.2 * 2,
            })

            if (!obb) {
                console.warn("No OBB could be created for view", view)
                return {
                    position: new Vector3(),
                    viewIndex: index,
                    viewId: view.viewID,
                    color: tinycolor2.random()
                        .darken(20)
                        .toHexString(),
                }
            }

            // Calculate center of left face
            const leftFaceCenter = new Vector3()
            obb.leftFace.forEach(point => leftFaceCenter.add(point))
            leftFaceCenter.divideScalar(4)

            return {
                position: leftFaceCenter,
                viewIndex: index,
                viewId: view.viewID,
                color: tinycolor2.random()
                    .darken(20)
                    .toHexString(),
            }
        })

        // Add spacing between buttons that are too close
        const minDistance = 0.3 // Minimum distance between buttons
        const adjustedButtonInfos = [...buttonInfos,]

        for (let i = 0; i < adjustedButtonInfos.length; i++) {
            for (let j = i + 1; j < adjustedButtonInfos.length; j++) {
                const buttonA = adjustedButtonInfos[i]
                const buttonB = adjustedButtonInfos[j]

                const distance = buttonA.position.distanceTo(buttonB.position)

                if (distance < minDistance) {
                    // Calculate direction vector between buttons
                    const direction = new Vector3()
                        .subVectors(buttonB.position, buttonA.position)
                        .normalize()

                    // Calculate how much we need to move each button
                    const moveDistance = (minDistance - distance) / 2

                    // Move buttons apart in opposite directions
                    buttonA.position.sub(direction.multiplyScalar(moveDistance))
                    buttonB.position.add(direction.multiplyScalar(moveDistance))
                }
            }
        }

        return adjustedButtonInfos
    }, [views,])

    useEffect(() => {
        if (hoveredOverViewButton) {
            //console.log("changing to pointer")
            gl.domElement.style.cursor = "pointer"
            dontResetSelection.current = true
        } else {
            //console.log("changing to default")
            gl.domElement.style.cursor = "default"
            dontResetSelection.current = false
        }

        return () => {
            gl.domElement.style.cursor = "default"
        }
    }, [hoveredOverViewButton,])


    const handleClick = (viewIndex: number) => {
        //console.log("handleClick", viewIndex)
        const viewToActivate = views[viewIndex]
        if (activeView === viewToActivate) {
            //console.log("same view")
            return
        }
        setViewToClean(activeView)
        const { duplicateLines, orderedView, } = processView(showOrientedBoundingBox, showScalerNormals, viewToActivate)
        if (duplicateLines.length === 0) {
            messageUtils.custom("Sorry there was an issue with this view. Try reselecting it", {
                forceShow: true,
            })

            console.warn("No duplicate lines found, skipping view")
            return
        }
        setLinePoints(duplicateLines)
        setActiveView(orderedView)
        setIsVisible(true)
    }



    // Calculate button positions when views change
    useEffect(() => {
        const newViewButtons = calculateViewButtonPositions()
        if (newViewButtons.length > 1) {
            setViewButtons(newViewButtons)
        } else {
            setViewButtons([])
        }
    }, [views,])




    // Add new state for preview lines
    const [previewLines, setPreviewLines,] = useState<LineInfo[]>([])

    // Add new state for debug points
    const [debugPoints, setDebugPoints,] = useState<Vector3[]>([])

    // Add new state for the distance display
    const [scaleDistance, setScaleDistance,] = useState<number | null>(null)
    const [scalePosition, setScalePosition,] = useState<Vector3 | null>(null)



    const findNormalLineSequences = (
        view: viewForScalerUI,
        spatialMaps: SpatialMaps,
        connections: PartConnectionType[],
        debug: boolean
    ) => {
        const normalLineSequences: string[][] = []
        const processedParts = new Set<string>()
        const remainingParts = new Set(view.partsOrder)

        debug && console.groupCollapsed("Normal Line Detection")
        debug && console.log("Initial parts order:", view.partsOrder)
        debug && console.log(spatialMaps, "spatialMaps")

        // Helper function to check Z-axis overlap between parts
        const hasZAxisOverlap = (partId: string, sequenceIds: string[]) => {
            const spatialInfoA = spatialMaps.spatialRelationshipsByPartId.get(partId)

            if (!spatialInfoA) {
                debug && console.warn(`Missing spatial info for part ${partId}`)
                return false
            }
            // Check if any of the sequence parts exist in the overlappingZ array
            const result = spatialInfoA.overlappingZ.some(p => sequenceIds.includes(p.partId))
            return result
        }

        // Helper function to check if a part can be added to a sequence
        const canAddPartToSequence = (partId: string, sequence: string[]) => {
            debug && console.log("sequence", sequence)
            return !hasZAxisOverlap(partId, sequence)
        }

        while (remainingParts.size > 0) {
            // Get the next unprocessed part from the original order
            const nextPart = Array.from(remainingParts)[0]
            if (!nextPart) { break }

            debug && console.log(`Processing part ${nextPart}`)


            //we cant use the markerNames here because regular tubes invert tubes
            // Get connected parts using marker names
            const connectedPartIds = getAllConnectedPartIdsRecursiveWithNames(
                connections,
                nextPart,
                undefined,
            )

            debug && console.log(`Connected parts for ${nextPart}:`, connectedPartIds)

            // Filter connected parts to only include those in remaining parts
            const validConnectedParts = connectedPartIds.filter(partId =>
                remainingParts.has(partId)
            )

            // Start building the sequence with the initial part
            const currentSequence: string[] = [nextPart,]
            processedParts.add(nextPart)
            remainingParts.delete(nextPart)

            debug && console.log("validConnectedParts", validConnectedParts)

            // Try to add each connected part if it doesn't overlap
            validConnectedParts.forEach(partId => {
                if (canAddPartToSequence(partId, currentSequence)) {
                    currentSequence.push(partId)
                    processedParts.add(partId)
                    remainingParts.delete(partId)
                }
            })

            debug && console.log("Created sequence:", currentSequence)

            // Filter out single connector parts
            if (currentSequence.length === 1) {
                const component = getComponent(currentSequence[0])
                const partType = component?.getPartInfo()?.type
                if (partType !== "connector_part_type") {
                    normalLineSequences.push(currentSequence)
                }
            } else {
                normalLineSequences.push(currentSequence)
            }
        }

        debug && console.log("Final normal lines:", normalLineSequences)
        debug && console.groupEnd()

        return normalLineSequences
    }


    const getLatestSceneData = useRecoilCallback(
        ({ snapshot, }) =>
            () => {
                return snapshot.getLoadable(sceneAtom).contents
            },
        [],
    )

    // Modify orderMarkersForView to include the normal line detection
    const orderMarkersForView = (view: viewForScalerUI, obb: ScalerOBBResult, cam: CameraControls, lines: LineInfo[], spatialRelationships: SpatialMaps, debug: boolean) => {
        // Add a map to store part types
        const partTypeMap = new Map<string, string>()

        const markerPositions: {
            partId: string,
            marker: Mesh,
            position: Vector3,
            partType?: string, // Add partType to the marker position info
        }[] = []

        // Use the markers object which is keyed by partId
        if (view.markers) {
            Object.entries(view.markers).forEach(([partId, meshes,]) => {
                if (Array.isArray(meshes)) {
                    // Get part type once per partId
                    const component = getComponent(partId)
                    const partType = component?.getPartInfo()?.type
                    if (partType) {
                        partTypeMap.set(partId, partType)
                    }

                    meshes.forEach(marker => {
                        if (marker instanceof Mesh) {
                            markerPositions.push({
                                partId,
                                marker,
                                position: marker.getWorldPosition(new Vector3()),
                                partType, // Add part type to marker position
                            })
                        }
                    })
                }
            })
        }

        // Store the part type map in the view for easy access
        view.lines = lines

        //get the parts in the order from the spatial map
        const mostAwayFromNormalToClosest = getPartIdsByAxis(spatialRelationships, "z", true)
        isAdmin && console.log("mostAwayFromNormalToClosest", mostAwayFromNormalToClosest)

        view.partsOrder = mostAwayFromNormalToClosest

        const latestSceneData = getLatestSceneData()
        const connections = latestSceneData.connections

        // After existing logic, add normal line detection

        const normalLineSequences = findNormalLineSequences(view, spatialRelationships, connections, logNormalLineSequences)

        const percentagesOfSequences = calculateAndAdjustPercentages(spatialRelationships, normalLineSequences, viewPercentageLabels)
        logNormalLineSequences && console.log("percentagesOfSequences", percentagesOfSequences)
        logNormalLineSequences && console.log("normalLineSequences", normalLineSequences)

        viewPercentageLabels && visualizePartPercentages(spatialRelationships, percentagesOfSequences, scene, obb)


        view.normalLineSequences = normalLineSequences // Add to view object
        view.percentagesOfSequences = percentagesOfSequences

        return view
    }

    useEffect(() => {
        // Add debug log at the start of effect
        //console.log("Effect running with enabled:", enabled)

        //console.log("viewToClean", viewToClean)
        // Cleanup previous view when viewToClean changes
        if (viewToClean) {
            // Reset colors
            viewToClean.uniqueIds.forEach((id: string) => {
                const component = getComponent(id)
                if (component) {
                    component.updateColor(0x1b7fe3)
                }
            })

            // Hide lines and debug box for previous view
            if (debugBoxRef.current) { debugBoxRef.current.visible = false }
            if (normalHelperRef.current) {
                scene.remove(normalHelperRef.current)
                normalHelperRef.current = null
            }
            setViewToClean(null)

        }
    }, [viewToClean,])



    const processView = (showOrientedBoundingBox: boolean, showScalerNormals: boolean, view: viewForScalerUI) => {
        // Add debug log before line creation
        //console.log("Creating lines for views:", views)
        //console.group("View Angle Check")

        // Create a view-level collection for all markers

        // First, collect all markers from all parts

        let viewDirectionalMarkers = generateDirectionalMarkers(view, scene, showScalerNormals)

        if (viewDirectionalMarkers.normalAligned.length === 0 && viewDirectionalMarkers.inverseAligned.length === 0) {
            // a retry
            viewDirectionalMarkers = generateDirectionalMarkers(view, scene, showScalerNormals)

            if (viewDirectionalMarkers.normalAligned.length === 0 && viewDirectionalMarkers.inverseAligned.length === 0) {
                console.warn("No directional markers found, skipping view")
                return {
                    duplicateLines: [],
                    orderedView: view,
                }
            }
        }


        const boxMeshes = view.uniqueIds.map((partId) => {
            return getComponent(partId)?.getBoundingBoxMesh()
        }).filter(Boolean)

        showOrientedBoundingBox && console.log("view", view)
        showOrientedBoundingBox && console.log("boxMeshes", boxMeshes)

        const obb = createCombinedOrientedBoundingBoxUsingNormal(
            boxMeshes,
            view.normal,
            {
                minWidth: 0.02,
                minHeight: 0.02,
                minDepth: 0.02,
                padding: isMobile(window.navigator).any ? 0.125 : 0.2,
            }
        )

        const spatialMaps = createSpatialRelationships(
            obb,
            boxMeshes,
            view.normal,
            0.05,
            scene,
            spatialMapDebug,
        )

        spatialMapDebug && console.log("spatialMap", spatialMaps)


        //debugging
        //draw vectors 3 for each of the faces of the obb

        if (showOrientedBoundingBox) {
            drawAllFacesOfOBBAndCenter(obb, scene)
            console.log("obb", obb)
            console.log("viewDirectionalMarkers", viewDirectionalMarkers, "debug", showOrientedBoundingBox)
            scene.add(obb.mesh)
        }

        const measurementLines = createMeasurementLines(obb)

        const color = 0xff0000
        const color2 = 0x0000ff

        const material = new LineBasicMaterial({
            color: color,
            linewidth: 2,  // Note: linewidth only works in WebGLRenderer with special extensions
            depthTest: true,
            depthWrite: true,
            transparent: true,
            opacity: 0.1,
        })

        const material2 = new LineBasicMaterial({
            color: color2,
            linewidth: 5,  // Note: linewidth only works in WebGLRenderer with special extensions
            depthTest: true,
            depthWrite: true,
            transparent: true,
            opacity: 0.1,
        })

        const widthDistance = measurementLines.startFace.width[0].distanceTo(measurementLines.startFace.width[1])
        const heightDistance = measurementLines.startFace.height[0].distanceTo(measurementLines.startFace.height[1])

        // Determine if width or height is longer to use for the lines
        const isWidthLonger = widthDistance > heightDistance

        if (isAdmin) {
            //console.log("isWidthLonger", isWidthLonger,)
        }

        // Create lines with appropriate materials
        const geometry1 = new BufferGeometry().setFromPoints(measurementLines.startFace.width)
        const line1 = new LineThree(geometry1, isWidthLonger ? material2 : material)


        const geometry2 = new BufferGeometry().setFromPoints(measurementLines.endFace.width)
        const line2 = new LineThree(geometry2, isWidthLonger ? material2 : material)


        const geometry3 = new BufferGeometry().setFromPoints(measurementLines.startFace.height)
        const line3 = new LineThree(geometry3, isWidthLonger ? material : material2)


        const geometry4 = new BufferGeometry().setFromPoints(measurementLines.endFace.height)
        const line4 = new LineThree(geometry4, isWidthLonger ? material : material2)


        const color3 = 0x00ff00
        const material3 = new LineBasicMaterial({
            color: color3,
            linewidth: 5,  // Note: linewidth only works in WebGLRenderer with special extensions
            depthTest: true,
            depthWrite: true,
            transparent: true,
            opacity: 0.1,
        })
        //draw connecting lines

        const geometryConnectingHeights0 = new BufferGeometry().setFromPoints([measurementLines.startFace.height[0], measurementLines.endFace.height[0],])
        const lineConnectingHeights0 = new LineThree(geometryConnectingHeights0, isWidthLonger ? material : material3)

        const geometryConnectingHeights1 = new BufferGeometry().setFromPoints([measurementLines.startFace.height[1], measurementLines.endFace.height[1],])
        const lineConnectingHeights1 = new LineThree(geometryConnectingHeights1, isWidthLonger ? material : material3)


        const geometryConnectingWidths0 = new BufferGeometry().setFromPoints([measurementLines.startFace.width[0], measurementLines.endFace.width[0],])
        const lineConnectingWidths0 = new LineThree(geometryConnectingWidths0, isWidthLonger ? material3 : material)


        const geometryConnectingWidths1 = new BufferGeometry().setFromPoints([measurementLines.startFace.width[1], measurementLines.endFace.width[1],])
        const lineConnectingWidths1 = new LineThree(geometryConnectingWidths1, isWidthLonger ? material3 : material)


        if (showOrientedBoundingBox) {
            scene.add(line1)
            scene.add(line2)
            scene.add(line3)
            scene.add(line4)
            scene.add(lineConnectingWidths0)
            scene.add(lineConnectingWidths1)
            scene.add(lineConnectingHeights0)
            scene.add(lineConnectingHeights1)

            setTimeout(() => {
                scene.remove(obb.mesh)
                scene.remove(line1)
                scene.remove(line2)
                scene.remove(line3)
                scene.remove(line4)
                scene.remove(lineConnectingWidths0)
                scene.remove(lineConnectingWidths1)
                scene.remove(lineConnectingHeights0)
                scene.remove(lineConnectingHeights1)
            }, 5000)
        }

        view.directionalMarkers = viewDirectionalMarkers

        const duplicateLines: LineInfo[] = []

        //pushing first the top lines

        const lineToUseForStartFace = isWidthLonger ? measurementLines.startFace.width : measurementLines.startFace.height
        const centerOfLineToUseTwoPoints = new Vector3()
        centerOfLineToUseTwoPoints.addVectors(
            lineToUseForStartFace[0],
            lineToUseForStartFace[1]
        ).divideScalar(2)



        const lineToUseForEndFace = isWidthLonger ? measurementLines.endFace.width : measurementLines.endFace.height
        const centerOfLineToUseTwoPointsEndFace = new Vector3()
        centerOfLineToUseTwoPointsEndFace.addVectors(
            lineToUseForEndFace[0],
            lineToUseForEndFace[1]
        ).divideScalar(2)




        const lineToUseForConnectingLinesFace1 = isWidthLonger ? [measurementLines.startFace.width[0], measurementLines.endFace.width[0],] : [measurementLines.startFace.height[0], measurementLines.endFace.height[0],]
        const centerOfLineToUseTwoPointsConnectingLinesFace1 = new Vector3()
        centerOfLineToUseTwoPointsConnectingLinesFace1.addVectors(
            lineToUseForConnectingLinesFace1[0],
            lineToUseForConnectingLinesFace1[1]
        ).divideScalar(2)



        const lineToUseForConnectingLinesFace2 = isWidthLonger ? [measurementLines.startFace.width[1], measurementLines.endFace.width[1],] : [measurementLines.startFace.height[1], measurementLines.endFace.height[1],]
        const centerOfLineToUseTwoPointsConnectingLinesFace2 = new Vector3()
        centerOfLineToUseTwoPointsConnectingLinesFace2.addVectors(
            lineToUseForConnectingLinesFace2[0],
            lineToUseForConnectingLinesFace2[1]
        ).divideScalar(2)

        //draw the normal of the obb with arrow
        if (showOrientedBoundingBox) {
            const normal = new Vector3()
            obb.mesh.getWorldPosition(normal)
            const arrowHelperNormal = new ArrowHelper(view.normal, obb.center, 1.25, "green", 0.18, 0.18)
            scene.add(arrowHelperNormal)

            setTimeout(() => {
                scene.remove(arrowHelperNormal)
            }, 15000)

            drawVector3Point(obb.center, scene, "pink", 0.011, 5000, true, undefined, "obb_corner")

        }


        const classifications = getNormalRelativePositions(
            [[lineToUseForStartFace[0], lineToUseForStartFace[1],], [lineToUseForEndFace[0], lineToUseForEndFace[1],],] as [[Vector3, Vector3], [Vector3, Vector3]],
            [[lineToUseForConnectingLinesFace1[0], lineToUseForConnectingLinesFace1[1],], [lineToUseForConnectingLinesFace2[0], lineToUseForConnectingLinesFace2[1],],] as [[Vector3, Vector3], [Vector3, Vector3]],
            obb.center,
            view.normal,
            scene,
            showOrientedBoundingBox
        )

        showOrientedBoundingBox && console.log("classifications", classifications)


        classifications.forEach(({ points, isPerpendicular, viewType, position, offsetDirection, }) => {
            const markerInfos: MarkerInfo[] = []
            // Iterate through each part ID in the view
            view.uniqueIds.forEach((partId: string) => {
                const markersForPart = view.markers?.[partId]
                if (!markersForPart?.length) { return }

                // Get line center in world coordinates
                const lineCenter = new Vector3()
                lineCenter.addVectors(
                    points[0],
                    points[1]
                ).divideScalar(2)
                const lineCenterWorld = lineCenter.clone()

                // Find the closest marker using world positions
                let closestMarker = markersForPart[0]
                let closestDistance = closestMarker.getWorldPosition(new Vector3()).distanceTo(lineCenterWorld)

                markersForPart.forEach((marker: Mesh) => {
                    // Get marker's world position
                    const markerWorldPos = marker.getWorldPosition(new Vector3())
                    const distance = markerWorldPos.distanceTo(lineCenterWorld)

                    if (distance < closestDistance) {
                        closestDistance = distance
                        closestMarker = marker
                    }
                })

                markerInfos.push({
                    partId,
                    marker: closestMarker,
                    distance: closestDistance,
                })
            })
            duplicateLines.push({
                points,
                isPerpendicularToNormal: isPerpendicular ?? false,
                viewType,
                position,
                markers: markerInfos,
                offsetDirection,
            })
        })

        if (showOrientedBoundingBox) {
            console.log("duplicateLines", duplicateLines)
        }


        debugBoxRef.current && (debugBoxRef.current.visible = true)
        view.uniqueIds.forEach((id: string) => {
            const component = getComponent(id)
            if (component) {
                const darkBlue = new Color(0x00008B)
                component.updateColor(darkBlue)
            }
        })

        // Update debug box
        debugBoxRef.current && (debugBoxRef.current.box.copy(selectionBox))

        // Order the markers before setting the active view
        const orderedView = orderMarkersForView(view, obb, cameraControls.current, duplicateLines, spatialMaps, logNormalLineSequences)
        if (isAdmin) {
            console.log("orderedView", orderedView)
        }


        orderedView.obb = obb


        messageUtils.custom("The dashed lines allow you to resize the dark blue parts at once.", {
            duration: 5,
            showUpTo: 3,
            minTimeBetweenShows: 30,
        })

        if (views.length > 1) {
            setTimeout(() => {
                messageUtils.custom("Click on a circle to change your selection of resizable parts.", {
                    duration: 8,
                    showUpTo: 5,
                    minTimeBetweenShows: 30,
                })
            }, 8000)
        }

        const size = new Vector3()
        obb.box.getSize(size)

        // Show both lines and bbox together
        debugBoxRef.current && (debugBoxRef.current.visible = true)

        // Add normal vector visualization
        if (normalHelperRef.current) {
            scene.remove(normalHelperRef.current)
        }


        setTimeout(() => {
            if (debugBoxRef.current) {
                scene.remove(debugBoxRef.current)
            }
        }, 5000)

        //console.groupEnd() // Close Processing Valid View group
        //console.groupEnd() // Close View Angle Check group
        //setIsVisible(true)  // Show lines when we have a valid view
        return { duplicateLines, orderedView, }

    }




    //console.log("linePoints", linePoints)

    const handlePointerOver = (index: number, event: any) => {
        if (!activeView || userIsResizingNxing) { return }

        const line = linePoints[index]
        isAdmin && console.log("line", line)
        // Don't handle hover for lines that are not parallel to normal
        if (!line.isPerpendicularToNormal) { return }

        setHoveredLineIndex(index)
        originalCursorRef.current = gl.domElement.style.cursor
        gl.domElement.style.cursor = getCursorType(cameraControls.current.camera, activeView.normal)
    }



    const handlePointerDown = (index: number, event: any) => {
        setOnTopOfTransformControls(true)
        const line = linePoints[index]
        // Don't handle click for lines that are not parallel to normal
        if (!line.isPerpendicularToNormal) { return }

        event.stopPropagation()
        setUserIsResizingNxing(true)
        scaleTypeRef.current = line.viewType === "width" ? "vertical" : "horizontal"
        originalMouseXYRef.current = { x: event.clientX, y: event.clientY, }
        setActiveLine(line)

        //remeber the autofocus mode
        autoFocusModeRef.current = autofocusMode ?? false
        //console.log("autoFocusModeRef.current", autofocusMode)
        setAutofocusMode(false)

        // Create preview lines
        const selectedLine = linePoints[index]
        const otherLines = linePoints.filter((line, i) => {
            return line.viewType !== selectedLine.viewType || line.position === selectedLine.position
        })

        // Calculate debug points for perpendicular lines
        const fixedPoints: Vector3[] = []
        otherLines.forEach(line => {
            if (line.viewType !== selectedLine.viewType) {
                // For perpendicular lines, get the point furthest from the selected line
                const point1 = line.points[0]
                const point2 = line.points[1]

                // Calculate distances from points to selected line center
                const selectedLineCenter = new Vector3().addVectors(
                    selectedLine.points[0],
                    selectedLine.points[1]
                )
                    .multiplyScalar(0.5)

                const dist1 = point1.distanceTo(selectedLineCenter)
                const dist2 = point2.distanceTo(selectedLineCenter)

                // The point with larger distance should be fixed
                fixedPoints.push(dist1 > dist2 ? point1.clone() : point2.clone())
            }
        })

        setDebugPoints(fixedPoints)
        setPreviewLines(otherLines.map(line => ({
            ...line,
            points: line.points.map(point => point.clone()),
        })))
    }

    const handlePointerOut = (index: number, event: any) => {
        //console.log("handle pointer out")
        if (userIsResizingNxing) { return }
        setHoveredLineIndex(null)
        setActiveLine(null)
        scaleTypeRef.current = null
        gl.domElement.style.cursor = originalCursorRef.current
    }


    // Add useEffect for mouse up listener
    useEffect(() => {
        const handleGlobalMouseUp = (event: MouseEvent | TouchEvent) => {
            if (userIsResizingNxing && activeLine && originalMouseXYRef.current) {
                gl.domElement.style.cursor = originalCursorRef.current
                setHoveredLineIndex(null)

                // Get coordinates based on event type
                const clientX = "touches" in event
                    ? (event as TouchEvent).changedTouches[0].clientX
                    : (event as MouseEvent).clientX
                const clientY = "touches" in event
                    ? (event as TouchEvent).changedTouches[0].clientY
                    : (event as MouseEvent).clientY
                const diffinX = clientX - originalMouseXYRef.current.x
                const diffinY = clientY - originalMouseXYRef.current.y

                // Rest of your existing logic remains the same
                const cursorType = getCursorType(cameraControls.current.camera, activeView!.normal)
                const diffWeCareAbout = cursorType === "ns-resize" ? diffinY : diffinX
                let directionMultiplier = 1
                if (activeView) {
                    directionMultiplier = getDirectionMultiplier(activeLine, linePoints, cameraControls.current.camera, activeView.normal)
                }


                const rawDistance = pixelsTo3DUnits(Math.abs(diffWeCareAbout), cameraControls.current.camera, undefined, gl)
                const distance = rawDistance * Math.sign(diffWeCareAbout) * directionMultiplier

                const putBackInAutofocusMode = setTimeout(() => {
                    setAutofocusMode(autoFocusModeRef.current)
                }, 500)
                setOnTopOfTransformControls(false)

                if (activeView) {
                    onScaleEnd(activeView, activeLine, distance, scaleTypeRef.current!, () => {
                        clearTimeout(putBackInAutofocusMode)
                        setTimeout(() => {
                            setAutofocusMode(autoFocusModeRef.current)
                        }, 500)
                    }, scaleEndLogs ?? false)
                }
            }

            // Clear preview lines
            setUserIsResizingNxing(false)
            scaleTypeRef.current = null
            setDebugPoints([])
            //setActiveLine(null)
            //setPreviewLines([])
        }

        window.addEventListener("mouseup", handleGlobalMouseUp)
        window.addEventListener("touchend", handleGlobalMouseUp)
        return () => {
            window.removeEventListener("mouseup", handleGlobalMouseUp)
            window.removeEventListener("touchend", handleGlobalMouseUp)
        }
    }, [userIsResizingNxing, activeLine, activeView, onScaleEnd,])

    // Modify the handlePointerMove effect to include part distance calculations
    useEffect(() => {
        const handlePointerMove = (event: PointerEvent) => {
            if (!userIsResizingNxing || !activeLine || !originalMouseXYRef.current || !activeView) { return }

            const diffinX = event.clientX - originalMouseXYRef.current.x
            const diffinY = event.clientY - originalMouseXYRef.current.y

            const cursorType = getCursorType(cameraControls.current.camera, activeView.normal)
            const diffWeCareAbout = cursorType === "ns-resize" ? diffinY : diffinX
            const directionMultiplier = getDirectionMultiplier(activeLine, linePoints, cameraControls.current.camera, activeView.normal)
            const rawDistance = pixelsTo3DUnits(Math.abs(diffWeCareAbout), cameraControls.current.camera, undefined, gl)
            const distance = rawDistance * Math.sign(diffWeCareAbout) * directionMultiplier

            // Calculate individual part distances
            const newPartDistances: { position: Vector3, distance: number, partId: string, }[] = []

            if (activeView.normalLineSequences && activeView.percentagesOfSequences) {
                activeView.normalLineSequences.forEach(sequence => {
                    sequence.forEach(partId => {
                        const component = getComponent(partId)
                        if (component) {
                            const mesh = component.getBoundingBoxMesh()
                            if (mesh?.userData?.getWorldCenter()) {
                                const percentage = activeView.percentagesOfSequences?.get(sequence)?.get(partId) || 0
                                const partDistance = unit === "cm" ? distance * percentage : distance * percentage / 2.54

                                newPartDistances.push({
                                    position: mesh.userData.getWorldCenter().clone(),
                                    distance: partDistance,
                                    partId,
                                })
                            }
                        }
                    })
                })
            }

            setPartDistances(newPartDistances)

            // Update scale distance for display
            setScaleDistance(distance)

            if (!activeLine.offsetDirection) {
                console.warn("No offset direction defined for active line")
                return
            }

            // Calculate the actual offset using the stored offsetDirection
            const offset = activeLine.offsetDirection.clone().multiplyScalar(distance)

            // Update preview lines positions
            const updatedPreviewLines = previewLines.map(line => {
                if (line.viewType !== activeLine.viewType) {
                    // Find the fixed point for perpendicular lines
                    const point1 = line.points[0].clone()
                    const point2 = line.points[1].clone()

                    const activeLineCenter = new Vector3().addVectors(
                        activeLine.points[0],
                        activeLine.points[1]
                    )
                        .multiplyScalar(0.5)

                    const dist1 = point1.distanceTo(activeLineCenter)
                    const dist2 = point2.distanceTo(activeLineCenter)

                    if (dist1 > dist2) {
                        // point1 is fixed, point2 moves
                        return {
                            ...line,
                            points: [
                                point1,                           // Fixed point
                                point2.clone().add(offset),       // Moving point with offset
                                point1,                           // Back to fixed point
                            ],
                        }
                    } else {
                        // point2 is fixed, point1 moves
                        return {
                            ...line,
                            points: [
                                point2,                           // Fixed point
                                point1.clone().add(offset),       // Moving point with offset
                                point2,                           // Back to fixed point
                            ],
                        }
                    }
                }

                // For parallel lines, move both points by the offset
                return {
                    ...line,
                    points: line.points.map(point => point.clone().add(offset)),
                }
            })

            // Update position for distance text display
            //const topLine = updatedPreviewLines.find(line => line.position === "top")
            if (activeLine) {
                const center = new Vector3()
                center.addVectors(activeLine.points[0], activeLine.points[1]).multiplyScalar(0.5)

                // Adjust offset based on line position
                switch (activeLine.position) {
                    case "top":
                        center.y += 0.1  // Offset above
                        break
                    case "bottom":
                        center.y -= 0.1  // Offset below
                        break
                    case "left":
                        center.x -= 0.1  // Offset to the left
                        break
                    case "right":
                        center.x += 0.1  // Offset to the right
                        break
                }

                setScalePosition(center)
            }

            setPreviewLines(updatedPreviewLines)

            if (activeView) {
                const mousePosition = new Vector3(event.clientX, event.clientY, 0)
                onScaleUpdate(activeView, mousePosition)
            }
        }

        window.addEventListener("pointermove", handlePointerMove)
        return () => window.removeEventListener("pointermove", handlePointerMove)
    }, [userIsResizingNxing, activeLine, activeView,])



    const onHover = (event: any) => {
        event.stopPropagation()
        setHoveredOverViewButton(true)
        const material = event.object.material
        // Darken the color by creating a darker version of the current color
        const currentColor = material.color.getHexString()
        const darkerColor = tinycolor2(currentColor).darken(5)
            .toHexString()
        material.color.set(darkerColor)

        // Get the view index from the object's userData
        const viewIndex = event.object.userData.viewIndex
        const view = views[viewIndex]
    }

    const onHoverOut = (event: any) => {
        event.stopPropagation()
        setHoveredOverViewButton(false)
        // Restore the original color
        const material = event.object.material
        const originalColor = event.object.userData.originalColor
        material.color.set(originalColor)
    }

    // Initial check
    useEffect(() => {
        //setting the first view as the default

        //here you want to try to use the same view that the user just scaled
        const viewToUse = lastViewsFirstPartId
            ? views.find(view => view.uniqueIds.includes(lastViewsFirstPartId)) || views[0]
            : views[0]

        const { duplicateLines, orderedView, } = processView(showOrientedBoundingBox, showMarkerNormals, viewToUse)
        if (duplicateLines.length === 0) {
            console.warn("No duplicate lines found, skipping view")
            messageUtils.custom("Sorry there was an issue with this view. Try reselecting it", {
                forceShow: true,
            })
            return
        }
        setLinePoints(duplicateLines)
        setActiveView(orderedView)
        setIsVisible(true)
    }, [])

    useEffect(() => {
        //console.log("unmounting", highlightedPartIds)
        //put colors back on the highlighted parts
        return () => {
            highlightedPartIds.current.forEach(partId => {
                const component = getComponent(partId)
                if (component) {
                    component.updateColor(0x1b7fe3)
                }
            })
        }
    }, [])

    // Generate view selection buttons
    const renderViewButtons = () => {
        return viewButtons.map((buttonInfo) => {
            const isActiveView = activeView?.viewID === buttonInfo.viewId

            return (
                <group key={buttonInfo.viewId}>
                    {/* Add border sphere when active */}
                    {isActiveView && (
                        <Icosahedron
                            args={[0.25 / 2 + 0.03, 3,]}
                            position={buttonInfo.position}
                            userData={{ ignoreRaycast: true, scalerButton: true, }}
                        >
                            <meshBasicMaterial
                                color="#00008B" // Blue border color
                                transparent
                                opacity={0.5}
                                side={BackSide}
                            />
                        </Icosahedron>
                    )}

                    {/* Main button */}
                    <Icosahedron
                        args={[0.25 / 2, 3,]}
                        position={buttonInfo.position}
                        onPointerLeave={onHoverOut}
                        onPointerEnter={onHover}
                        onClick={(e) => {
                            e.nativeEvent.stopImmediatePropagation()
                            //handleClick(buttonInfo.viewIndex)
                        }}
                        onPointerDown={(e) => {
                            e.nativeEvent.stopImmediatePropagation()
                            handleClick(buttonInfo.viewIndex)
                        }}
                        onPointerUp={(e) => {
                            e.nativeEvent.stopImmediatePropagation()
                        }}
                        userData={{
                            viewIndex: buttonInfo.viewIndex,
                            originalColor: buttonInfo.color,
                            scalerButton: true,
                        }}
                    >
                        <meshBasicMaterial
                            color={buttonInfo.color}
                            side={BackSide}
                        />
                        <Billboard>
                            <Text
                                fontSize={0.1}
                                color={"#fff"}
                                userData={{ ignoreRaycast: true, scalerButton: true, }}
                                raycast={() => null}
                            >
                                {buttonInfo.viewIndex + 1}
                                <meshBasicMaterial
                                    color="#ffffff"
                                    transparent
                                />
                            </Text>
                        </Billboard>
                    </Icosahedron>
                </group>
            )
        })
    }

    useEffect(() => {
        const arrows = visualizeGrowthDirections(linePoints as unknown as LineInfoNX[], scene, { width: gl.domElement.clientWidth, height: gl.domElement.clientHeight, }, { length: 250, thickness: 15, }, 3500, true)
        helperObjectRef.current.push(...arrows)
    }, [linePoints,])

    useEffect(() => {
        return () => {
            helperObjectRef.current.forEach(arrow => scene.remove(arrow))
        }
    }, [])

    const isMobileDevice = isMobile(window.navigator).any

    return (
        <>
            {linePoints.map((line, index) => {
                const { dashSize, gapSize, } = calculateDashParameters(line.points, isMobile(window.navigator).any, cameraControls.current?.camera, cameraDistance)

                return (
                    <React.Fragment key={`line-group-${index}`}>
                        {/* Invisible wider line for better touch targeting on mobile */}
                        {isMobileDevice && (
                            <Line
                                key={`grab-helper-${line.viewType}-${line.position}`}
                                points={line.points}
                                dashed={false}
                                color={"red"}
                                lineWidth={config.lineThickness * 3}
                                opacity={0}
                                transparent={true}
                                userData={{ ignoreRaycast: true, }}
                                visible={isVisible}
                                onPointerOver={(e) => handlePointerOver(index, e)}
                                onPointerDown={(e) => handlePointerDown(index, e)}
                                onPointerOut={(e) => handlePointerOut(index, e)}
                            />
                        )}
                        {/* Regular visible line */}
                        <Line
                            key={`main-${line.viewType}-${line.position}`}
                            points={line.points}
                            dashed={line.isPerpendicularToNormal}
                            dashSize={dashSize}
                            gapSize={gapSize}
                            color={!line.isPerpendicularToNormal
                                ? config.disabledColor
                                : hoveredLineIndex === index
                                    ? config.hoverColor
                                    : config.activeColor}
                            lineWidth={!line.isPerpendicularToNormal
                                ? config.disabledLineThickness
                                : isMobile(window.navigator).any
                                    ? config.lineThickness * 2
                                    : config.lineThickness}
                            opacity={1}
                            userData={{ ignoreRaycast: true, }}
                            visible={isVisible}
                            {...(!isMobileDevice && {
                                onPointerOver: (e) => handlePointerOver(index, e),
                                onPointerDown: (e) => handlePointerDown(index, e),
                                onPointerOut: (e) => handlePointerOut(index, e),
                            })}
                        />
                    </React.Fragment>
                )
            })}

            {/* Preview lines */}
            {previewLines.map((line, index) => (
                <Line
                    key={`preview-${line.viewType}-${line.position}-${index}`}
                    points={line.points}
                    color={config.previewLines.color}
                    lineWidth={config.previewLines.lineWidth}
                    dashed={false}
                    opacity={config.previewLines.opacity}
                    visible={isVisible}
                    userData={{ ignoreRaycast: true, }}
                />
            ))}

            {/* Debug points */}
            {debugPoints.map((point, index) => (
                <mesh
                    key={`debug-point-${index}`}
                    position={point}
                    visible={isVisible && userIsResizingNxing}
                >
                    <sphereGeometry args={[0.01, 16, 16,]} />
                    <meshBasicMaterial color={0xff0000} />
                </mesh>
            ))}

            {/* Part distance displays */}
            {scaleDistance !== null && scalePosition && partDistances && partDistances.length > 0 && partDistances.map(({ position, distance, partId, }) => {
                if (!distance || Math.abs(distance) < 0.001) { return null }
                return (
                    <Html
                        key={`distance-${partId}`}
                        position={position}
                        center
                        style={{
                            transform: "translate3d(-50%, -50%, 0)",
                            pointerEvents: "none",
                            fontFamily: "Roboto,sans-serif",
                            fontSize: "12px",
                            color: "#333",
                            background: "rgba(255, 255, 255, 0.8)",
                            padding: "2px 6px",
                            borderRadius: "3px",
                            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {`${Math.abs(distance).toFixed(1)}${unit === "cm" ? "cm" : "in"}`}
                    </Html>
                )
            })}

            {/* Distance display text */}
            {scaleDistance !== null && scalePosition && (
                <Html
                    position={scalePosition}
                    center
                    style={{
                        transform: "translate3d(-50%, -100%, 0)",
                        pointerEvents: "none",
                        fontFamily: "Roboto,sans-serif",
                        fontSize: "14px",
                        color: "#333",
                        background: "rgba(255, 255, 255, 0.9)",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        whiteSpace: "nowrap",
                    }}
                >
                    {`${scaleDistance < 0 ? "Reducing Length by" : "Increasing Length by"} ${Math.abs(scaleDistance * 100).toFixed(1)}cm | ${Math.abs(scaleDistance * 39.3701).toFixed(1)}in`}

                </Html>
            )}

            {renderViewButtons()}
        </>
    )
}

export default ScalerUILines