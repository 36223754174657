import React, { useState } from "react"
import { Vector3 } from "three"
import { PointInfo } from "./types"
import TShapedLine
from "../../components/main/DesignScreen/scene/part/parts/connector/ui/measurements/TShapedLine"
import TextLabel
from "../../components/main/DesignScreen/scene/part/parts/connector/ui/measurements/TextLabel"
import { distanceToUnit }
from "../../components/main/DesignScreen/scene/part/parts/connector/ui/measurements/Measurement"
import useUnitConversion from "../../components/main/DesignScreen/utils/UnitUtils"
import tinycolor from "tinycolor2"

interface MeasurementLineProps {
    point1: PointInfo;
    point2: PointInfo;
    onRemove?: () => void;
    isPreview?: boolean;
    color?: string;
}

const MeasurementLine: React.FC<MeasurementLineProps> = ({
    point1,
    point2,
    onRemove,
    color,
}) => {
    const [isHovered, setIsHovered,] = useState(false)
    const midPoint = new Vector3()
        .addVectors(point1.position, point2.position)
        .multiplyScalar(0.5)

    const distance = point1.position.distanceTo(point2.position)
    const { distanceCm, distanceIn, } = distanceToUnit(distance)
    const { unit, } = useUnitConversion()
    const textLabel = unit === "cm" ? "cm" : "\""

    let displayText = "Remove"
    if (!isHovered) {
        displayText = unit === "cm"
            ? `${distanceCm.toFixed(2)} ${textLabel}`
            : `${distanceIn.toFixed(2)}${textLabel}`
    }

    const getLineWidth = () => {
        return isHovered ? 6 : 4
    }

    const labelColor = tinycolor(color).desaturate(10)
.darken(10)
.toHexString()

    return (
        <group>
            <TShapedLine
                pointA={point1.position}
                pointB={point2.position}
                lineWidth={getLineWidth()}
                opacity={1}
                color={color}
            />
            <TextLabel
                position={midPoint}
                text={displayText}
                onPointerEnter={() => {
                        setIsHovered(true)
                    }}
                    onPointerLeave={() => {
                        setIsHovered(false)
                    }}
                    onClick={() => {
                        onRemove?.()
                }}
                color={labelColor}
                textColor={"white"}
            />
        </group>
    )
}

export default MeasurementLine