/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import React, { useState, useRef, useEffect } from "react"
import { Html, useCursor } from "@react-three/drei"
import styled from "styled-components"
import { Vector3 } from "three"
import { createGlobalStyle } from "styled-components"
import isMobile from "ismobilejs"

type Props = {
    position: Vector3,
    number: number,
    unit: string,
    onPointerEnter?: () => void,
    onPointerLeave?: () => void,
    onClick?: () => void,
    onTextChange?: (newValue: number) => void,
    onFocusChange?: (isFocused: boolean) => void,
    color?: string,
    textColor?: string,
}

type LabelProps = {
    $hasHandlers: boolean,
    $color: string,
    $textColor: string,
    $isFocused?: boolean,
}

const GlobalStyle = createGlobalStyle`
  .html-wrapper {
    pointer-events: none;
  }
`

function InputLabel({
    position,
    number,
    unit,
    onPointerEnter,
    onPointerLeave,
    onClick,
    onTextChange,
    onFocusChange,
    color = "#ffffff",
    textColor = "black",
}: Props) {
    const [inputValue, setInputValue,] = useState(number.toString())
    const [originalValue, setOriginalValue,] = useState(number.toString())
    const [isFocused, setIsFocused,] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)
    const labelRef = useRef<HTMLDivElement>(null)
    const previousFocusState = useRef(false)
    const isMobileDevice = isMobile().any

    // Disable clickability and pointer events on mobile
    const hasHandlers = !isMobileDevice
    const pointerEvents = isMobileDevice ? "none" : "auto"

    const [isHovered, setIsHovered,] = useState(false)
    useCursor(isHovered && !isMobileDevice, "pointer")

    // Update inputValue when text prop changes
    useEffect(() => {
        setInputValue(number.toString())
        setOriginalValue(number.toString())
    }, [number,])

    // Report focus state changes - only when the state actually changes
    useEffect(() => {
        // Only notify parent if focus state actually changed
        if (previousFocusState.current !== isFocused) {
            onFocusChange?.(isFocused)
            previousFocusState.current = isFocused
        }
    }, [isFocused, onFocusChange,])

    useEffect(() => {
        return () => {
            if (isFocused) {
                onFocusChange?.(false)
            }
        }
    }, [])

    // Handle window blur event to cancel editing
    useEffect(() => {
        const handleWindowClick = (e: MouseEvent) => {
            if (isFocused && inputRef.current && !inputRef.current.contains(e.target as Node)) {
                cancelEditing()
            }
        }

        if (isFocused) {
            // Add event listener after a small delay to avoid the click that started the focus
            const timeoutId = setTimeout(() => {
                window.addEventListener("mousedown", handleWindowClick)
            }, 50)

            return () => {
                clearTimeout(timeoutId)
                window.removeEventListener("mousedown", handleWindowClick)
            }
        }
    }, [isFocused,])

    const cancelEditing = () => {
        setInputValue(originalValue)

        // Only set focus to false if actually focused
        if (isFocused) {
            setIsFocused(false)
        }

        if (inputRef.current) {
            inputRef.current.blur()
        }
    }

    const onPointerEnterHandler = () => {
        if (isMobileDevice) {return}
        setIsHovered(true)
        onPointerEnter?.()
    }

    const onPointerLeaveHandler = () => {
        if (isMobileDevice) {return}
        setIsHovered(false)
        onPointerLeave?.()
    }

    const handleClick = () => {
        if (isMobileDevice) {return}

        if (inputRef.current && !isFocused) {
            inputRef.current.focus()
        }
        onClick?.()
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value)
    }

    const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const isArrowKey = e.key === "ArrowUp" || e.key === "ArrowDown"

        if (e.key === "Enter") {
            // Don't set isFocused here; let the blur event handle it
            if (inputRef.current) {
                inputRef.current.blur()
            }
        } else if (e.key === "Escape") {
            cancelEditing()
        } else if (isArrowKey) {
            // Apply the value immediately when using arrow keys
            // But don't change focus state
            const currentValue = parseFloat(inputValue)
            const isNumber = !isNaN(currentValue)
            if (isNumber && onTextChange) {
                // We use setTimeout to ensure the input value has been updated by the browser
                setTimeout(() => {
                    const newValue = parseFloat(inputRef.current?.value || inputValue)
                    if (!isNaN(newValue) && newValue !== currentValue) {
                        onTextChange(newValue)
                    }
                }, 25)
            }
        }
    }

    const handleFocus = () => {
        if (isMobileDevice) {
            // If on mobile, immediately blur to prevent focus
            if (inputRef.current) {
                inputRef.current.blur()
            }
            return
        }

        if (!isFocused) {
            setIsFocused(true)
            setOriginalValue(inputValue)
        }
    }

    const handleBlur = () => {
        if (isFocused) {
            setIsFocused(false)

            // Validate if the input is a valid number
            const parsedValue = parseFloat(inputValue)

            if (isNaN(parsedValue)) {
                // If not a valid number, revert to original value
                setInputValue(originalValue)
            } else if (parsedValue !== number && onTextChange) {
                // Only call onTextChange if the value is different and valid
                onTextChange(parsedValue)
            }
        }
    }

    return (
        <>
            <GlobalStyle />
            <Html
                wrapperClass="html-wrapper"
                zIndexRange={[0, 0,]}
                position={position}
                className="overlay"
                pointerEvents={pointerEvents}
                center>
                <Label
                    ref={labelRef}
                    onPointerEnter={onPointerEnterHandler}
                    onPointerLeave={onPointerLeaveHandler}
                    onClick={handleClick}
                    className="ui-element"
                    $hasHandlers={hasHandlers}
                    $color={color}
                    $textColor={textColor}
                    $isFocused={isFocused}
                >
                    <input
                        ref={inputRef}
                        type="number"
                        step={0.1}
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleInputKeyDown}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        readOnly={!isFocused || isMobileDevice}
                        style={{ width: `${Math.max(inputValue.length * 8, 14)}px`, }}
                    />
                    <span>{unit}</span>
                </Label>
            </Html>
        </>
    )
}

const Label = styled.div<LabelProps>`
    box-sizing: border-box;
    width: auto;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    pointer-events: ${props => (props.$hasHandlers ? "auto" : "none")};
    cursor: ${props => (props.$isFocused ? "text" : "pointer")};
    position: relative;

    background: rgba(255, 255, 255, 0.75);
    ${props => props.$color && `background: ${props.$color};`}
    border: 1px solid #E5E5E5;
    box-shadow: 0px 0px 6.2px rgba(0, 0, 0, 0.05);
    border-radius: 9px;
    padding: 0 6px;
    white-space: nowrap;

    input {
        flex: 0 1 auto;
        background: transparent;
        border: none;
        outline: none;
        text-align: center;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: ${props => props.$textColor || "black"};
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        cursor: inherit;
        pointer-events: auto;
        caret-color: ${props => (props.$isFocused ? "auto" : "transparent")};
        position: relative;
        cursor: text;
        z-index: 2; /* Higher than the span to capture clicks */
        
        /* Style to make it look like a label when not focused */
        appearance: none;
        
        /* Hide text selection when not focused */
        user-select: ${props => (props.$isFocused ? "text" : "none")};
        
        /* Hide number input arrows (spinners) */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        
        /* For Firefox */
        -moz-appearance: textfield;
    }

    * {
        pointer-events: inherit;
    }

    /* Override for span to make it unclickable */
    span {
        flex: 0 0 auto;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: ${props => props.$textColor || "black"};
        margin-left: 2px;
        white-space: nowrap;
        pointer-events: none !important;
        position: relative;
        z-index: 1;
    }
`

export default InputLabel
