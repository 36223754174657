import React from "react"
import Button from "../../../../../../../../../common/components/Button"
import { ButtonContainer, SliderContainer } from "../../segmentedTube/ui/ActionPanelStyles"
import { DescriptionBodyContainer } from "../../../../../../../../../common/components/DrawerShared"
import isMobile from "ismobilejs"

type Props = {
    onFinishEditing: () => void,
}

const CancelUI = (props: Props) => {
    const isMobilePhone = isMobile(window.navigator).phone
    return <SliderContainer $isMobile={isMobilePhone}>
        <DescriptionBodyContainer>
        </DescriptionBodyContainer>
    </SliderContainer>
}

export default CancelUI