/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useThree } from "@react-three/fiber"
import { BoundingBoxState, partsIdsSelector, unitSelector, finishedLoadingAtom } from "../../state/scene/atoms"
import {
    Box3,
    Box3Helper,
    Color,
    Group,
    InstancedMesh,
    MathUtils,
    Mesh,
    MeshBasicMaterial,
    MeshStandardMaterial,
    Object3D,
    Quaternion,
    Sphere,
    Vector3,
    Scene,
    SphereGeometry,
    BufferGeometry,
    Material,
} from "three"
import { CameraControls, CAMERA_ACTIONS } from "./CameraControls"
import { useLevaControls } from "../debugProvider/useLevaControls"
import { sceneAtom, boundingBoxAtom, emptyPositionForNewPartAtom, combinedBoxAtom, combinedBoxAtomUserSelection } from "../../state/scene/atoms"
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { showCameraControlsState } from "../../state/scene/atoms"
import { selectedItemID } from "../../state/atoms"
import DimensionLabels from "../../components/main/DesignScreen/scene/DimensionLabels"
import { multiSelectionSelector, undoRedoInProgressSelector } from "../../state/scene/selectors"
import { BoxMeshWithUserData, createCombinedOrientedBoundingBox, drawCombinedBoxAndMeshes, drawVector3Point, visualizeFaces } from "../../utils/PartUtils"
import { messageUtils } from "../../components/main/DesignScreen/scene/LowerRightMessages"
import { createSpatialRelationshipsFromUserData } from "../../components/main/DesignScreen/utils/scalerUtils"
import { calcWorldSizeWithTargetPixelSize, getLocalStorage } from "../../../common/utils/utils"
import { PartDataStore } from "../../utils/Types"
import { set } from "lodash"
import useGetDebugVariables from "../../components/main/DesignScreen/utils/useGetDebugVariables"
import tinycolor from "tinycolor2"
import isMobile from "ismobilejs"
import LoadingOverlay from "../../../common/components/LoadingOverlay/LoadingOverlay"
import { createRoot } from "react-dom/client"
import { tryVerifyPartsInScene, verifyPartsInScene } from "../../utils/MeshUtils"
import { cameraUtils } from "../../components/main/DesignScreen/utils/cameraUtils"
import { PerspectiveCamera } from "three"
import { SceneRef } from "../../state/types"


interface Props {
    cameraControls: React.MutableRefObject<CameraControls | null>;
    partCount: number;
    designId: string | undefined;
    children: React.ReactNode;
    autofocusMode: boolean;
    setAutofocusMode: (value: boolean) => void;
    userSelectionAutoFocusMode: React.MutableRefObject<boolean | null>;
    sceneRefs: SceneRef;
    combinedBoxRef: React.MutableRefObject<BoxMeshWithUserData<Mesh> | null>;
    partDataRef: React.MutableRefObject<Record<string, PartDataStore>>;
}

type CameraValues = {
    getRef: () => React.MutableRefObject<CameraControls | null>,
    getInAutofocusMode: () => boolean,
    setInAutofocusMode: (value: boolean) => void,
    addMeshToSceneBounds: () => void,
    calculateSizeOfBoxFactor: (box: Box3, debug?: boolean) => {
        result: number,
        rect?: { x: number, y: number, }[],
        scaleFactor: number,
        targetPixelSize: number,
    },
}

type SceneBounds = {
    loadedCount: number,
}

export type CameraContextType = CameraValues | undefined

export const cameraContext = createContext<CameraContextType>(undefined)


const CameraProvider: React.FC<Props> = ({ children, cameraControls, partCount, designId, autofocusMode, setAutofocusMode, userSelectionAutoFocusMode, sceneRefs, combinedBoxRef, partDataRef, }) => {
    const sceneData = useRecoilValue(sceneAtom)
    const selectedItem = useRecoilValue(selectedItemID)
    const { scene, gl, } = useThree()
    const inAutofocusModeRef = useRef(true)
    const { removeCameraLock, azimuthRotateSpeed, polarRotateSpeed, drawBoundingBoxMeshesOfUserSelection, debugCameraCentering, debugBoundingBoxesForAllParts, } = useLevaControls()
    const [finishedLoading, setFinishedLoading,] = useRecoilState(finishedLoadingAtom)
    const [, setShowCameraControls,] = useRecoilState(showCameraControlsState)
    const undoRedoInProgress = useRecoilValue(undoRedoInProgressSelector)
    const boundingBox3Ref = useRef<Box3>(new Box3())
    const firstLoadTimeRef = useRef<number>(Date.now())
    const multiSelection = useRecoilValue(multiSelectionSelector)
    const [partsLength, setPartsLength,] = useState(0)
    const partIdsList = useRecoilValue(partsIdsSelector)
    const lastCameraDistance = useRef(0)

    const { getVariables, } = useGetDebugVariables()
    const isAdmin = getVariables().isAdmin

    const isMobileVar = isMobile(window.navigator).any


    const getPartsLength = useRecoilCallback(
        ({ snapshot, }) =>
            () => {
                const sceneData = snapshot.getLoadable(sceneAtom).contents
                return Object.keys(sceneData.parts).length
            },
        []
    )

    useEffect(() => {
        const length = getPartsLength()
        setPartsLength(length)
    }, [sceneData, getPartsLength,])



    const setCombinedBoxUserSelection = useSetRecoilState(combinedBoxAtomUserSelection)
    const combinedBoxUserSelectionRecoil = useRecoilValue(combinedBoxAtomUserSelection)

    const setCombinedBox = useSetRecoilState(combinedBoxAtom)
    const combinedBoxRecoil = useRecoilValue(combinedBoxAtom)
    const processingBoxesRef = useRef(false)

    // Add a state to track when to run the heavy computation

    const [boundingBox, setBoundingBox,] = useRecoilState<BoundingBoxState>(boundingBoxAtom)


    const processBoxes = useCallback(() => {
        console.log("processing dimensions")

        //const time = performance.now()

        if (processingBoxesRef.current) {
            //console.log("processingBoxesRef.current is true, aborting")
            return
        }
        processingBoxesRef.current = true


        const selectedBoxMeshes: BoxMeshWithUserData<Mesh>[] = []
        const selectedIdsOfBoxMeshes: string[] = []
        const allBoxMeshes: BoxMeshWithUserData<Mesh>[] = []
        const allIdsOfBoxMeshes: string[] = []

        Object.values(partDataRef.current).forEach((partData) => {
            if (partData.boundingBoxMesh) {
                // Add to all meshes array
                allBoxMeshes.push(partData.boundingBoxMesh)
                allIdsOfBoxMeshes.push(partData.boundingBoxMesh.uuid)

                // Add to selected meshes array if in multiSelection
                if (multiSelection.length > 0 && multiSelection.includes(partData.boundingBoxMesh.userData.partId)) {
                    selectedBoxMeshes.push(partData.boundingBoxMesh)
                    selectedIdsOfBoxMeshes.push(partData.boundingBoxMesh.uuid)
                }
            }
        })


        debugBoundingBoxesForAllParts && console.log(allBoxMeshes, "boxMeshes")

        if (allBoxMeshes.length > 0) {
            try {
                const boundingBoxClones = allBoxMeshes.map((boxMesh) => {
                    const clonedBoundingBox = boxMesh.clone()
                    clonedBoundingBox.userData = { ...boxMesh.userData, }
                    clonedBoundingBox.visible = true
                    clonedBoundingBox.matrix.copy(boxMesh.matrixWorld)
                    clonedBoundingBox.matrix.decompose(
                        clonedBoundingBox.position,
                        clonedBoundingBox.quaternion,
                        clonedBoundingBox.scale
                    )
                    clonedBoundingBox.material = new MeshBasicMaterial({ color: 0x00ff00, wireframe: true, })
                    return clonedBoundingBox
                })

                const selectedBoundingBoxClones = multiSelection.length > 0 ? selectedBoxMeshes.map((boxMesh) => {
                    const clonedBoundingBox = boxMesh.clone()
                    clonedBoundingBox.userData = { ...boxMesh.userData, }
                    clonedBoundingBox.visible = true
                    clonedBoundingBox.matrix.copy(boxMesh.matrixWorld)
                    clonedBoundingBox.matrix.decompose(
                        clonedBoundingBox.position,
                        clonedBoundingBox.quaternion,
                        clonedBoundingBox.scale
                    )
                    clonedBoundingBox.material = new MeshBasicMaterial({ color: 0x00ff00, wireframe: true, })
                    return clonedBoundingBox
                }) : []

                const boxMeshesToUse = selectedBoundingBoxClones.length > 0 ? selectedBoundingBoxClones : boundingBoxClones
                const combinedBoxForRecoilUserSelection = createCombinedOrientedBoundingBox(boxMeshesToUse)


                //need two of them because one needs to get assigned to recoil

                const combinedBoxUserSelection = createCombinedOrientedBoundingBox(boxMeshesToUse)

                //create a box3 out of the combinedBox


                //console.log(boundingBox3Ref.current, "boundingBoxRef.current")


                drawBoundingBoxMeshesOfUserSelection && console.log(combinedBoxForRecoilUserSelection, "combinedBoxForRecoilUserSelection")


                if (drawBoundingBoxMeshesOfUserSelection) {
                    const faces = combinedBoxForRecoilUserSelection.userData.getAllFaces()
                    console.log(faces, "faces")
                    visualizeFaces(faces, scene, 1000, true)
                    drawCombinedBoxAndMeshes(boxMeshesToUse, scene)
                }

                if (combinedBoxRef.current) {
                    combinedBoxRef.current.removeFromParent()
                }
                combinedBoxRef.current = combinedBoxUserSelection


                setCombinedBoxUserSelection(combinedBoxForRecoilUserSelection)

                //now we need to create the combined box for the entire scene


                const combinedBoxFromAllBoxMeshes = createCombinedOrientedBoundingBox(allBoxMeshes)
                const spatialMaps = createSpatialRelationshipsFromUserData(
                    combinedBoxFromAllBoxMeshes,
                    allBoxMeshes,
                    combinedBoxFromAllBoxMeshes.userData.worldDimensions.depthDirection,
                    0.01,
                    scene,
                    false,
                )
                combinedBoxFromAllBoxMeshes.userData.spatialMaps = spatialMaps
                combinedBoxFromAllBoxMeshes.userData.idsofBoxMeshes = allIdsOfBoxMeshes

                setCombinedBox(combinedBoxFromAllBoxMeshes)

                debugBoundingBoxesForAllParts && console.log(combinedBoxFromAllBoxMeshes, "combinedBoxFromAllBoxMeshes")
                debugBoundingBoxesForAllParts && drawCombinedBoxAndMeshes(allBoxMeshes, scene)

                //console.log(spatialMaps, "spatialMaps")

                // Don't set shouldProcessBoxes to false inside the useMemo
                // Instead, return the result and handle state update separately

                const combinedBoxFromAllBoxMeshesForBoundingBox = createCombinedOrientedBoundingBox(allBoxMeshes)

                // Create a Box3 explicitly
                const box3 = new Box3()
                box3.setFromObject(combinedBoxFromAllBoxMeshesForBoundingBox.clone())

                boundingBox3Ref.current = box3
                setBoundingBox((prevState) => ({
                    ...prevState,
                    box: box3,
                }))


            } catch (error) {
                isAdmin && console.error("Error creating combined box:", error)
            }
        }
        //console.log("setting processingBoxesRef.current to false")
        processingBoxesRef.current = false
        //console.log("processing done", performance.now() - time)

    }, [processingBoxesRef.current, multiSelection,])


    useEffect(() => {
        setTimeout(() => {
            if (finishedLoading === "done" || finishedLoading === "camera-adjusted") {
                processBoxes()
                optimizeSceneGeometries(scene)
            }
        }, 250)
    }, [sceneData, scene, finishedLoading, partDataRef, multiSelection,])


    const sceneBounds = useRef<SceneBounds>({
        loadedCount: 0,
    })

    const [emptyPositionForNewPart, setEmptyPositionForNewPart,] = useRecoilState(emptyPositionForNewPartAtom)

    const { findEmptyPositionForBox, } = cameraUtils

    const isInfiniteBox = (box: Box3) => {
        return box.min.toArray().some(v => v === Infinity
            || v === -Infinity)
            || box.max.toArray().some(v => v === Infinity
                || v === -Infinity)
    }


    useEffect(() => {
        if (finishedLoading !== "camera-adjusted") {
            let attemptCount = 0
            const interval = setInterval(() => {
                attemptCount++
                const partIds = Object.keys(sceneData.parts)
                const allPartsFound = verifyPartsInScene(scene, partIds)

                if (allPartsFound) {
                    setTimeout(() => {
                        setFinishedLoading("done")
                        console.log("finished loading")
                        sceneRefs.current.updateMultiSelectProviderWithNewMakersInfo?.()
                        processBoxes()
                        optimizeSceneGeometries(scene)
                    }, 1000)
                    clearInterval(interval)
                } else if (attemptCount >= 100) {
                    setFinishedLoading("done")
                    clearInterval(interval)
                }
            }, 250)

            // Cleanup interval on effect cleanup
            return () => clearInterval(interval)
        }
    }, [finishedLoading, partsLength,])

    const isValidMesh = (mesh: any) => {
        return !(isNaN(mesh.position.x) || isNaN(mesh.position.y) || isNaN(mesh.position.z)
            || isNaN(mesh.quaternion.x) || isNaN(mesh.quaternion.y) || isNaN(mesh.quaternion.z) || isNaN(mesh.quaternion.w)
            || isNaN(mesh.scale.x) || isNaN(mesh.scale.y) || isNaN(mesh.scale.z))
    }

    const getGeometryHash = (geometry: BufferGeometry): string => {
        const positions = geometry.attributes.position?.array
        if (!positions) { return "" }

        const sampleSize = Math.min(20, positions.length)
        let hash = positions.length.toString()

        for (let i = 0; i < sampleSize; i++) {
            const index = Math.floor(i * (positions.length / sampleSize))
            hash += `-${positions[index].toFixed(4)}`
        }

        return hash
    }

    const getMaterialHash = (material: Material | Material[]): string => {
        if (Array.isArray(material)) {
            return material.map(m => m.type).join("-")
        }
        return material.type
    }

    const areMaterialsSimilar = (mat1: Material | Material[], mat2: Material | Material[]): boolean => {
        // If one is array and other isn't, they're not similar
        if (Array.isArray(mat1) !== Array.isArray(mat2)) { return false }

        // If both are arrays
        if (Array.isArray(mat1) && Array.isArray(mat2)) {
            if (mat1.length !== mat2.length) { return false }
            return mat1.every((m, i) => m.type === mat2[i].type)
        }

        // If both are single materials
        if (!Array.isArray(mat1) && !Array.isArray(mat2)) {
            return mat1.type === mat2.type
        }

        return false
    }

    const optimizeSceneGeometries = useCallback((scene: Scene) => {
        const geometryMap = new Map<string, BufferGeometry>()
        let totalGeometriesOptimized = 0

        console.log("optimizing geometries")

        // First pass: collect all unique geometries and materials
        scene.traverse((object: Object3D) => {
            if ("geometry" in object && (object.name.includes("inner") || object.name.includes("outer") || object.name.includes("Sphere") || object.name.includes("mesh") || object.name.includes("box") || object.name.includes("MergedMesh") || object.name.includes("Origin") || object.name.includes("Outlines"))) {
                const mesh = object as Mesh
                const geometry = mesh.geometry
                // Generate hash for this geometry
                const geomHash = getGeometryHash(geometry)

                // Check if we've seen this geometry before
                if (!geometryMap.has(geomHash)) {
                    // Create a clone of the geometry to ensure it's properly managed
                    const sharedGeometry = geometry.clone()
                    // Important: Copy all attributes but use the same buffers
                    geometry.attributes = sharedGeometry.attributes
                    geometryMap.set(geomHash, sharedGeometry)
                }

            } else {
                //console.log(object, "object.name")
            }
        })

        // Track all geometries to dispose
        const geometriesToDispose: BufferGeometry[] = []

        // Second pass: optimize by replacing with shared instances
        scene.traverse((object: Object3D) => {
            if (object instanceof Mesh) {
                const mesh = object as Mesh
                const geomHash = getGeometryHash(mesh.geometry)

                // Replace geometry if we have a match
                const sharedGeometry = geometryMap.get(geomHash)
                if (sharedGeometry && sharedGeometry !== mesh.geometry) {
                    // Track geometry for disposal after all replacements are done
                    geometriesToDispose.push(mesh.geometry)
                    mesh.geometry = sharedGeometry
                    totalGeometriesOptimized++
                }

            }
        })

        // Dispose all old geometries after they've been replaced
        geometriesToDispose.forEach(geometry => {
            geometry.dispose()
        })

        // Force WebGL to update its state
        const renderer = gl.renderLists
        if (renderer) {
            // This renders a frame which forces WebGL to update its state
            gl.renderLists.dispose()
        }

        //console.log(gl.info, "gl.info after optimization")

        // Return stats for verification
        return {
            geometriesOptimized: totalGeometriesOptimized,
        }
    }, [])

    const runThroughSceneAndMakeGroupFocusCamera = useCallback((distance: number, firstLoad: boolean, cameraUpdate = true, debug = false) => {
        console.log("runnin")
        if (cameraControls.current) {
            cameraControls.current.dampingFactor = 0.3
        }

        const createBox3FromDimensions = (width: number, height: number, depth: number) => {
            const halfWidth = width / 2
            const halfHeight = height / 2
            const halfDepth = depth / 2

            return new Box3(
                new Vector3(-halfWidth, -halfHeight, -halfDepth),
                new Vector3(halfWidth, halfHeight, halfDepth)
            )
        }

        let boxToUse = boundingBox3Ref.current

        //when the user has not clicked out of any part,
        //boundingbox is not calculated yet and returns w infinty values

        if (boundingBox3Ref.current === null || isInfiniteBox(boundingBox3Ref.current)) {
            boxToUse = createBox3FromDimensions(0.07, 0.1, 0.07)
        }

        const emptyPosition = findEmptyPositionForBox(scene, createBox3FromDimensions(0.07, 0.1, 0.07), boxToUse, false)

        debug && console.log(emptyPosition, "emptyPosition")

        setEmptyPositionForNewPart(emptyPosition || new Vector3(0, 0, 0))

        const sphere = new Sphere()
        boundingBox3Ref.current.getBoundingSphere(sphere)

        const debugObjects: Object3D[] = []

        if (debug) {
            const randomColor = tinycolor.random().toHexString()
            emptyPosition && drawVector3Point(emptyPosition, scene, randomColor, 0.01, 3000, false, false, "emptyPosition", false, "sphere")
            const boxHelper = new Box3Helper(boundingBox3Ref.current, new Color(randomColor))
            scene.add(boxHelper)
            debugObjects.push(boxHelper)
        }

        // Calculate sphere radius and center from the box dimensions
        const boxSize = new Vector3()
        boundingBox3Ref.current.getSize(boxSize)
        const boxCenter = new Vector3()
        boundingBox3Ref.current.getCenter(boxCenter)

        // Set sphere properties manually based on box
        sphere.center.copy(boxCenter)
        // Use the longest dimension to ensure everything fits
        //have to divide by 4 because its just way too zoomed in
        sphere.radius = (Math.max(boxSize.x, boxSize.y, boxSize.z) / 4)

        if (debug) {
            const randomColor = tinycolor.random().toHexString()
            //show the sphere for debugging
            const sphereHelper = new Mesh(
                new SphereGeometry(sphere.radius, 32, 32),
                new MeshBasicMaterial({ color: randomColor, wireframe: true, })
            )
            sphereHelper.position.copy(sphere.center)
            debugObjects.push(sphereHelper)

            scene.add(sphereHelper)

            // Clean up helpers after a delay
            setTimeout(() => {
                debugObjects.forEach((object) => {
                    scene.remove(object)
                })
            }, 3000)
        }

        if (cameraUpdate) {
            debugCameraCentering && console.log("cameraUpdate is set to true")
            debugCameraCentering && console.log(getInAutofocusMode(), "getInAutofocusMode")
            if (getInAutofocusMode()) {
                cameraControls.current?.fitToSphere(sphere, true)
            }
            if (firstLoad) {
                debug && console.log("first load")
                cameraControls.current?.fitToSphere(sphere, true)
                if (inAutofocusModeRef.current && userSelectionAutoFocusMode.current === null) {
                    //setAutofocusMode(false)
                    debugCameraCentering && console.log("first load autofocus mode")
                }
            }
            if (cameraControls.current) {
                debugCameraCentering && console.log("setting camera distance", distance)
                !firstLoad && (cameraControls.current.distance = distance)
            }
        }

        return {
            boundingBox: boundingBox3Ref.current,
        }
    }, [cameraControls.current, removeCameraLock, azimuthRotateSpeed, polarRotateSpeed, userSelectionAutoFocusMode, setAutofocusMode,])

    useEffect(() => {
        sceneRefs.current = {
            ...sceneRefs.current,
            setAutofocusMode,
            cameraControls,
            getInAutofocusMode,
            overRideAutofocusMode,
            setInAutofocusMode,
            addMeshToSceneBounds,
            calculateSizeOfBoxFactor,
        }
    }, [])

    useEffect(() => {
        if (partCount === 0) {
            setShowCameraControls(false)
            gl.autoClear = true
        } else {
            setShowCameraControls(true)
        }
    }, [partCount,])
    const prevDesignIdRef = useRef<string | undefined>()


    useEffect(() => {
        if (designId !== prevDesignIdRef.current) {
            setFinishedLoading("loading")
        }
    }, [designId,])

    useEffect(() => {
        lastCameraDistance.current = cameraControls.current?.distance ?? 0
        debugCameraCentering && console.log(lastCameraDistance.current, "lastCameraDistance")
        if (selectedItem === null && partsLength > 0) {
            if (designId !== prevDesignIdRef.current && finishedLoading === "done") {
                setTimeout(() => {

                    runThroughSceneAndMakeGroupFocusCamera(lastCameraDistance.current, true, undefined, debugCameraCentering)
                }, 1000)
                setFinishedLoading("camera-adjusted")
                debugCameraCentering && console.log("finished loading was done - now camera-adjusted")
            }
            else {
                runThroughSceneAndMakeGroupFocusCamera(lastCameraDistance.current, false, true, debugCameraCentering)
            }
            setBoundingBox((prevState: BoundingBoxState) => ({ ...prevState, show: false, }))
        }
        else {
        }
    }, [undoRedoInProgress, selectedItem, partsLength, finishedLoading, designId, debugCameraCentering,])

    useEffect(() => {
        if (partsLength > 10 && (Date.now() - firstLoadTimeRef.current > 1000 * 60 * 2)) {
            messageUtils.custom("Looks like you're on your way to building something big! Let us know if you have any feedback!", {
                duration: 20,
                buttonText: "Email us",
                showCloseIcon: true,
                showSpinner: false,
                onButtonClick: () => {
                    window.open("mailto:hi@craftyamigo.com", "_blank")
                },
            })
        }
    }, [partsLength, debugCameraCentering,])


    useEffect(() => {
        if (boundingBox.show && combinedBoxRef.current) {

            //make it lite grey transparent
            combinedBoxRef.current.material = new MeshBasicMaterial({ color: 0x000000, wireframe: true, opacity: 0.5, transparent: true, })
            combinedBoxRef.current.userData.ignoreRaycast = true
            combinedBoxRef.current.raycast = () => { return false }
            scene.add(combinedBoxRef.current)
        }
        if (boundingBox.show === false) {
            combinedBoxRef.current?.removeFromParent()

        }
    }, [boundingBox.show, combinedBoxUserSelectionRecoil,])


    useEffect(() => {
        inAutofocusModeRef.current = autofocusMode
    }, [autofocusMode,])

    useEffect(() => {
        if (cameraControls.current) {
            cameraControls.current.touches.one = CAMERA_ACTIONS.TOUCH_OFFSET
            cameraControls.current.mouseButtons.left = CAMERA_ACTIONS.OFFSET

            if (removeCameraLock) {
                cameraControls.current.minAzimuthAngle = -Infinity
                cameraControls.current.maxAzimuthAngle = Infinity
                cameraControls.current.minPolarAngle = 0
                cameraControls.current.maxPolarAngle = Math.PI
            } else {
                cameraControls.current.minAzimuthAngle = MathUtils.degToRad(0)
                // cameraControls.current.minAzimuthAngle = MathUtils.degToRad(0.2)
                cameraControls.current.maxAzimuthAngle = Infinity
                cameraControls.current.minPolarAngle = MathUtils.degToRad(0)
                // cameraControls.current.minPolarAngle = MathUtils.degToRad(0.2)
                cameraControls.current.maxPolarAngle = 2 * Math.PI
            }
            cameraControls.current.polarRotateSpeed = polarRotateSpeed
            cameraControls.current.azimuthRotateSpeed = azimuthRotateSpeed

            cameraControls.current.addEventListener("control", () => {
                if (inAutofocusModeRef.current && userSelectionAutoFocusMode.current === null) {
                    //setAutofocusMode(false)
                }
            })
        }
    }, [cameraControls.current, removeCameraLock, azimuthRotateSpeed, polarRotateSpeed, userSelectionAutoFocusMode, setAutofocusMode,])

    const getRef = useCallback(() => {
        return cameraControls
    }, [cameraControls,])


    const getInAutofocusMode = () => {
        return getLocalStorage("autofocusMode") || autofocusMode
    }

    const overRideAutofocusMode = useCallback((value: boolean) => {
        userSelectionAutoFocusMode.current = value
    }, [userSelectionAutoFocusMode,])

    const setInAutofocusMode = useCallback((value: boolean) => {
        if (userSelectionAutoFocusMode.current === null) {
            setAutofocusMode(value)
        }
    }, [userSelectionAutoFocusMode, setAutofocusMode,])

    const addMeshToSceneBounds = useCallback(() => {
        sceneBounds.current.loadedCount = sceneBounds.current.loadedCount + 1
    }, [sceneBounds,])

    const zoomRef = useRef(cameraControls.current?.distance ? cameraControls.current.distance * 100 : 0)
    const lastUpdate = useRef(Date.now())

    useEffect(() => {
        const cam = cameraControls.current
        zoomRef.current = cam?.distance ? cam.distance * 100 : 0

        // Define the event listener function separately so we can reference it in cleanup
        const handleUpdate = () => {
            if (cam?.camera instanceof PerspectiveCamera) {
                const currentZoom = Math.round(cam.distance * 100)
                const timeSinceLastUpdate = Date.now() - lastUpdate.current

                if (currentZoom !== zoomRef.current && timeSinceLastUpdate > 50) {
                    const newFOV = 10 + (50 * Math.min(cam.distance / 10, 1))
                    cam.camera.fov = newFOV
                    cam.camera.updateProjectionMatrix()
                    zoomRef.current = currentZoom
                    lastUpdate.current = Date.now()
                }
            }
        }

        // Add the event listener
        cam?.addEventListener("update", handleUpdate)

        // Remove the same function reference in cleanup
        return () => {
            cam?.removeEventListener("update", handleUpdate)
        }
    }, [])

    //the reason this is here beacuse otherwise we'd have to all kinds of improts for all of the parts for a calcuation that needs to be only done once

    const calculateSizeOfBoxFactor = useCallback((box: Box3, debug = false) => {
        if (!cameraControls.current || !sceneRefs.current.ctxRef) {
            return {
                result: 0.02,
                scaleFactor: 1,
                targetPixelSize: isMobileVar ? 25 : 30,
            }
        }
        const calculatedButtonSize = calcWorldSizeWithTargetPixelSize(cameraControls.current, gl, scene, isMobileVar, box, sceneRefs.current.ctxRef, {
            targetPixelSize: isMobileVar ? 25 : 30,
            debug: debug ? {
                enabled: true,
                timeout: 10000,
            } : undefined,
        })
        return calculatedButtonSize
    }, [])


    // Memoize the context value
    const contextValue = useMemo(() => {
        //console.log("cameraProvider context value recalculated")
        return {
            getRef,
            getInAutofocusMode,
            setInAutofocusMode,
            addMeshToSceneBounds,
            calculateSizeOfBoxFactor,
        }
    }, [
        // Only include dependencies that could change
        getRef,
        getInAutofocusMode,
        setInAutofocusMode,
        addMeshToSceneBounds,
    ])

    // Remove the loadingContainer state and modify the useEffect
    useEffect(() => {
        if (finishedLoading !== "camera-adjusted" && partIdsList.length !== 0) {
            const loadingElement = document.createElement("div")
            loadingElement.id = "scene-loading-overlay"
            document.body.appendChild(loadingElement)

            // Create root and render using modern API
            const root = createRoot(loadingElement)
            root.render(<LoadingOverlay isMobile={isMobileVar} />)

            return () => {
                root.unmount()
                document.body.removeChild(loadingElement)
            }
        }
    }, [finishedLoading,])

    // Simplify the render
    return useMemo(() => {
        return (
            <>
                <cameraContext.Provider value={contextValue}>
                    {children}
                    <DimensionLabels />
                </cameraContext.Provider>
            </>
        )
    }, [children, contextValue,])
}

export default CameraProvider