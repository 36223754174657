import React from "react"
import { InputGroup } from "./SegmentedSliderStyles"
import NumberInput from "./NumberInput"

interface Props {
    offsetX: number;
    offsetY: number;
    onOffsetXChange: (value: number, shouldTriggerAlignment: boolean) => void;
    onOffsetYChange: (value: number, shouldTriggerAlignment: boolean) => void;
    unit: "cm" | "in";
    onKeyDown?: (e: React.KeyboardEvent) => void;
}

const OffsetControls: React.FC<Props> = ({
    offsetX,
    offsetY,
    onOffsetXChange,
    onOffsetYChange,
    unit = "in",
    onKeyDown,
}) => {
    const unitLabel = unit === "cm" ? "cm" : "in"

    return (
        <InputGroup>
            <NumberInput
                value={offsetX}
                onChange={onOffsetXChange}
                unit={unitLabel}
                onKeyDown={onKeyDown}
                direction="up"
            />
            <NumberInput
                value={offsetY}
                onChange={onOffsetYChange}
                unit={unitLabel}
                onKeyDown={onKeyDown}
                direction="left"
            />
        </InputGroup>
    )
}

export default OffsetControls