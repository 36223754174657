import isMobile from "ismobilejs"
import React from "react"
import styled from "styled-components"

interface UnsnapButtonProps {
    onClick: () => void;
}

const StyledButton = styled.button<{ $isMobile: boolean, }>`
  width: 57px;
  height: 26px;
  margin: 3px;
  border-radius: 8px;
  background-color: white;
  color: #878787;
  border: 1px solid #878787;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    color: black;
    border-color: black;
  }
  &:active {
    background-color: #d0d0d0;
  }
`

const UnsnapButton: React.FC<UnsnapButtonProps> = ({ onClick, }) => {
    const isMobilePhone = isMobile(window.navigator).phone
    return (
        <StyledButton onClick={onClick} $isMobile={isMobilePhone}>
            Unsnap
        </StyledButton>
    )
}

export default UnsnapButton
