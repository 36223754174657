/* eslint-disable no-loop-func */
/* eslint-disable complexity */
/* eslint-disable no-console */
/* eslint-disable no-negated-condition */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable max-statements */
import { ArrowHelper, Box3, Camera, Color, Matrix4, MeshBasicMaterial, Scene } from "three"
import { Mesh } from "three"
import { Vector3 } from "three"
import { DirectionalMarkers, ScalerOBBResult, ScalerOBBOptions, LineSet, viewForScalerUI } from "../../../ScalerUILines"
import { box3ToMesh, MeshUtils } from "../../../../utils/MeshUtils"
import tinycolor2 from "tinycolor2"
import { CameraControls } from "../../../../providers/cameraProvider/CameraControls"
import { isSameDirection } from "./utilsThree"
import { createLabelAtPosition } from "../../../../utils/MarkerUtil"
import { BoxMeshWithUserData, drawVector3Point } from "../../../../utils/PartUtils"
import { PartConnectionType } from "../../../../state/scene/types"
import { PartTypeEnum } from "../../../../utils/Types"


export const scalerCreateOrientedBoundingBox = (
    markers: DirectionalMarkers,
    options: ScalerOBBOptions = {}
): ScalerOBBResult | null => {
    // Early return if no markers are present
    if (!markers.normalAligned?.length && !markers.inverseAligned?.length) {
        console.warn("No markers provided to scalerCreateOrientedBoundingBox")
        return null
    }

    const {
        minWidth = 0,
        minHeight = 0,
        minDepth = 0,
        padding = 0,
    } = options

    // 1. First, let's extract all positions and compute the center
    const allPositions = [
        ...markers.normalAligned.map(m => m.position),
        ...markers.inverseAligned.map(m => m.position),
    ]

    const center = new Vector3()
    allPositions.forEach(pos => center.add(pos))
    center.divideScalar(allPositions.length)

    // 2. Get the primary axis (normal direction) from the first marker
    const normal = markers.normalAligned.length > 0
        ? markers.normalAligned[0].direction.clone()
        : markers.inverseAligned[0].direction.clone().negate()

    // 3. Find a perpendicular vector to use as the second axis
    const up = new Vector3(0, 1, 0)
    const right = new Vector3().crossVectors(normal, up)
        .normalize()
    if (right.lengthSq() < 0.1) {
        // If normal is parallel to up, use forward instead
        up.set(0, 0, 1)
        right.crossVectors(normal, up).normalize()
    }

    // Get the third axis by crossing normal and right
    const secondAxis = right
    const thirdAxis = new Vector3().crossVectors(normal, secondAxis)
        .normalize()

    // 4. Create rotation matrix from these axes
    const rotationMatrix = new Matrix4()
    rotationMatrix.makeBasis(secondAxis, thirdAxis, normal)

    // 5. Transform all points to the oriented space
    const inverseRotation = rotationMatrix.clone().invert()
    const transformedPoints = allPositions.map(pos => {
        return pos.clone()
            .sub(center)
            .applyMatrix4(inverseRotation)
    })

    // 6. Find min and max in the oriented space
    const min = new Vector3(Infinity, Infinity, Infinity)
    const max = new Vector3(-Infinity, -Infinity, -Infinity)
    transformedPoints.forEach(point => {
        min.min(point)
        max.max(point)
    })

    // 7. Create box and compute dimensions, applying minimum sizes
    const box = new Box3(min, max)
    box.expandByScalar(padding)
    const size = box.getSize(new Vector3())

    // Apply minimum dimensions while maintaining the center
    const adjustedSize = new Vector3(
        Math.max(size.x, minWidth),
        Math.max(size.y, minHeight),
        Math.max(size.z, minDepth)
    )

    // Calculate size differences
    const sizeDiff = new Vector3(
        adjustedSize.x - size.x,
        adjustedSize.y - size.y,
        adjustedSize.z - size.z
    )

    // Adjust min and max to maintain center while applying minimum dimensions
    min.x -= sizeDiff.x / 2
    min.y -= sizeDiff.y / 2
    min.z -= sizeDiff.z / 2
    max.x += sizeDiff.x / 2
    max.y += sizeDiff.y / 2
    max.z += sizeDiff.z / 2

    // 8. Generate the face points using adjusted dimensions
    const halfSize = adjustedSize.clone().multiplyScalar(0.5)
    const startFaceLocal = [
        new Vector3(-halfSize.x, -halfSize.y, -halfSize.z),
        new Vector3(halfSize.x, -halfSize.y, -halfSize.z),
        new Vector3(halfSize.x, halfSize.y, -halfSize.z),
        new Vector3(-halfSize.x, halfSize.y, -halfSize.z),
    ]

    const endFaceLocal = startFaceLocal.map(p =>
        new Vector3(p.x, p.y, halfSize.z * 2 + p.z)
    )

    // Transform faces back to world space
    const startFace = startFaceLocal.map(p =>
        p.clone()
            .applyMatrix4(rotationMatrix)
            .add(center)
    )

    const endFace = endFaceLocal.map(p =>
        p.clone()
            .applyMatrix4(rotationMatrix)
            .add(center)
    )

    // Left face
    const leftFaceLocal = [
        new Vector3(-halfSize.x, -halfSize.y, -halfSize.z),
        new Vector3(-halfSize.x, halfSize.y, -halfSize.z),
        new Vector3(-halfSize.x, halfSize.y, halfSize.z),
        new Vector3(-halfSize.x, -halfSize.y, halfSize.z),
    ]

    // Right face
    const rightFaceLocal = [
        new Vector3(halfSize.x, -halfSize.y, -halfSize.z),
        new Vector3(halfSize.x, halfSize.y, -halfSize.z),
        new Vector3(halfSize.x, halfSize.y, halfSize.z),
        new Vector3(halfSize.x, -halfSize.y, halfSize.z),
    ]

    const leftFace = leftFaceLocal.map(p =>
        p.clone()
            .applyMatrix4(rotationMatrix)
            .add(center)
    )

    const rightFace = rightFaceLocal.map(p =>
        p.clone()
            .applyMatrix4(rotationMatrix)
            .add(center)
    )

    // 9. Create final transformation matrix
    const transformation = new Matrix4()
        .multiply(new Matrix4().makeTranslation(center.x, center.y, center.z))
        .multiply(rotationMatrix)


    const mesh = box3ToMesh(new Box3(min, max))
    mesh.applyMatrix4(transformation)
    //add transparency to the mesh
    const material = new MeshBasicMaterial({
        color: tinycolor2.random().toHexString(),
        transparent: true,
        opacity: 0.25,
        wireframe: true,
    })
    mesh.material = material

    return {
        box: new Box3(min, max),
        startFace,
        endFace,
        leftFace,
        rightFace,
        width: size.x,
        height: size.y,
        depth: size.z,
        adjustedWidth: adjustedSize.x,
        adjustedHeight: adjustedSize.y,
        adjustedDepth: adjustedSize.z,
        center,
        transformation,
        mesh,
    }
}

export function createCombinedOrientedBoundingBoxUsingNormal(
    boxMeshes: Mesh[],
    viewNormal: Vector3,
    options: ScalerOBBOptions = {}
): ScalerOBBResult {
    const {
        minWidth = 0,
        minHeight = 0,
        minDepth = 0,
        padding = 0,
    } = options

    // 1. Get all corners from all boxes in world space
    const allWorldCorners: Vector3[] = []
    boxMeshes.forEach(boxMesh => {
        if (boxMesh?.userData?.getWorldCorners) {
            const corners = boxMesh.userData.getWorldCorners()
            allWorldCorners.push(...corners)
        }
    })

    if (allWorldCorners.length === 0) {
        console.warn("No valid box meshes provided")
    }

    // Calculate initial center for transformation reference
    const initialCenter = new Vector3()
    allWorldCorners.forEach(pos => initialCenter.add(pos))
    initialCenter.divideScalar(allWorldCorners.length)

    // 3. Set up coordinate system based on view normal
    const up = new Vector3(0, 1, 0)
    const right = new Vector3().crossVectors(viewNormal, up)
        .normalize()
    if (right.lengthSq() < 0.1) {
        up.set(0, 0, 1)
        right.crossVectors(viewNormal, up).normalize()
    }

    // Get the third axis by crossing normal and right
    const secondAxis = right
    const thirdAxis = new Vector3().crossVectors(viewNormal, secondAxis)
        .normalize()

    // 4. Create rotation matrix
    const rotationMatrix = new Matrix4()
    rotationMatrix.makeBasis(secondAxis, thirdAxis, viewNormal)

    // 5. Transform points to oriented space
    const inverseRotation = rotationMatrix.clone().invert()
    const transformedPoints = allWorldCorners.map(pos => {
        return pos.clone()
            .sub(initialCenter)
            .applyMatrix4(inverseRotation)
    })

    // 6. Find bounds in oriented space
    const min = new Vector3(Infinity, Infinity, Infinity)
    const max = new Vector3(-Infinity, -Infinity, -Infinity)
    transformedPoints.forEach(point => {
        min.min(point)
        max.max(point)
    })

    // 7. Apply padding and minimum dimensions
    const box = new Box3(min, max)
    const sizeBeforePadding = box.getSize(new Vector3())
    box.expandByScalar(padding)
    const size = box.getSize(new Vector3())

    const adjustedSize = new Vector3(
        Math.max(size.x, minWidth),
        Math.max(size.y, minHeight),
        Math.max(size.z, minDepth)
    )

    // Adjust bounds while maintaining center
    const sizeDiff = new Vector3(
        adjustedSize.x - size.x,
        adjustedSize.y - size.y,
        adjustedSize.z - size.z
    )

    min.x -= sizeDiff.x / 2
    min.y -= sizeDiff.y / 2
    min.z -= sizeDiff.z / 2
    max.x += sizeDiff.x / 2
    max.y += sizeDiff.y / 2
    max.z += sizeDiff.z / 2

    // Calculate the true center from the final min/max in oriented space
    const center = new Vector3()
    center.addVectors(min, max)
        .multiplyScalar(0.5)
        .applyMatrix4(rotationMatrix)
        .add(initialCenter)

    // 8. Generate face points
    const startFaceLocal = [
        new Vector3(min.x, min.y, min.z),
        new Vector3(max.x, min.y, min.z),
        new Vector3(max.x, max.y, min.z),
        new Vector3(min.x, max.y, min.z),
    ]

    const endFaceLocal = [
        new Vector3(min.x, min.y, max.z),
        new Vector3(max.x, min.y, max.z),
        new Vector3(max.x, max.y, max.z),
        new Vector3(min.x, max.y, max.z),
    ]

    const leftFaceLocal = [
        new Vector3(min.x, min.y, min.z),
        new Vector3(min.x, min.y, max.z),
        new Vector3(min.x, max.y, max.z),
        new Vector3(min.x, max.y, min.z),
    ]

    const rightFaceLocal = [
        new Vector3(max.x, min.y, min.z),
        new Vector3(max.x, min.y, max.z),
        new Vector3(max.x, max.y, max.z),
        new Vector3(max.x, max.y, min.z),
    ]

    // Create the final transformation matrix
    const transformation = new Matrix4()
        .multiply(new Matrix4().makeTranslation(initialCenter.x, initialCenter.y, initialCenter.z))
        .multiply(rotationMatrix)

    // Transform all faces to world space
    const startFace = startFaceLocal.map(p =>
        p.clone()
            .applyMatrix4(rotationMatrix)
            .add(initialCenter)
    )

    const endFace = endFaceLocal.map(p =>
        p.clone()
            .applyMatrix4(rotationMatrix)
            .add(initialCenter)
    )

    const leftFace = leftFaceLocal.map(p =>
        p.clone()
            .applyMatrix4(rotationMatrix)
            .add(initialCenter)
    )

    const rightFace = rightFaceLocal.map(p =>
        p.clone()
            .applyMatrix4(rotationMatrix)
            .add(initialCenter)
    )

    // Create and transform the mesh
    const mesh = box3ToMesh(new Box3(min, max))
    mesh.applyMatrix4(transformation)

    const material = new MeshBasicMaterial({
        color: tinycolor2.random().toHexString(),
        transparent: true,
        opacity: 0.9,
        wireframe: true,
    })
    mesh.material = material

    return {
        box: new Box3(min, max),
        width: sizeBeforePadding.z,
        height: sizeBeforePadding.y,
        depth: sizeBeforePadding.x,
        center,
        transformation,
        mesh,
        startFace,
        endFace,
        leftFace,
        rightFace,
        adjustedWidth: adjustedSize.x,
        adjustedHeight: adjustedSize.y,
        adjustedDepth: adjustedSize.z,
    }
}


const getPointPosition = (
    origin: Vector3,
    normal: Vector3,
    point: Vector3,
    buffer = 0.1
): "left" | "right" | "top" | "bottom" => {
    // Get vector from origin to point
    const toPoint = new Vector3().subVectors(point, origin)

    // Get up vector (assuming Y is up)
    const up = new Vector3(0, 1, 0)

    // Get right vector by crossing normal with up
    const right = new Vector3().crossVectors(normal, up)
        .normalize()

    // Get a more stable up vector by crossing right with normal
    const stableUp = new Vector3().crossVectors(right, normal)
        .normalize()

    // Project point onto normal and right vectors
    const normalProjection = toPoint.dot(normal)
    const rightProjection = toPoint.dot(right)
    const upProjection = toPoint.dot(stableUp)

    // Use projections to determine position
    if (Math.abs(normalProjection) > Math.abs(rightProjection) && Math.abs(normalProjection) > Math.abs(upProjection)) {
        // Point is more aligned with normal direction
        return normalProjection > buffer ? "right" : "left"
    } else if (Math.abs(upProjection) > Math.abs(rightProjection)) {
        // Point is more aligned with up/down direction
        return upProjection > buffer ? "top" : "bottom"
    } else {
        // Point is more aligned with right/left direction
        return rightProjection > buffer ? "top" : "bottom"
    }
}

export const getNormalRelativePositions = (
    widthSet: [[Vector3, Vector3] | [], [Vector3, Vector3] | []],
    heightSet: [[Vector3, Vector3] | [], [Vector3, Vector3] | []],
    center: Vector3,
    normal: Vector3,
    scene: Scene,
    debug = false
): LineSet[] => {
    // Combine all lines into a single array
    const allLines = [...widthSet, ...heightSet,]

    // Get center point and position for each line
    const linePositions = allLines.map(line => {
        const start = line[0]
        const end = line[1]
        if (!start || !end) {
            console.warn("Line has no start or end", line)
            return null
        }
        const lineCenter = start.clone().add(end)
            .multiplyScalar(0.5)
        const position = getPointPosition(center, normal, lineCenter)
        debug && console.log(position, "getNormalRelativePositions position")

        return {
            line,
            position,
            lineCenter,
        }
    }).filter(Boolean)

    //if debug is true, draw the center of the lines
    if (debug) {
        console.log(linePositions, "getNormalRelativePositions linePositions")
        linePositions.forEach(line => {
            line?.lineCenter && drawVector3Point(line.lineCenter, scene, "blue", 0.011, 5000, true, undefined, "line_center")
        })
    }

    // Create the LineSet array by finding lines for each position
    const result: (LineSet)[] = [
        {
            points: linePositions.find(l => l?.position === "top")?.line as [Vector3, Vector3],
            viewType: "width",
            position: "top",
            isPerpendicular: false,
        },
        {
            points: linePositions.find(l => l?.position === "bottom")?.line as [Vector3, Vector3],
            viewType: "width",
            position: "bottom",
            isPerpendicular: false,
        },
        {
            points: linePositions.find(l => l?.position === "right")?.line as [Vector3, Vector3],
            viewType: "height",
            position: "right",
            isPerpendicular: true,
            offsetDirection: normal.clone(),
        },
        {
            points: linePositions.find(l => l?.position === "left")?.line as [Vector3, Vector3],
            viewType: "height",
            position: "left",
            isPerpendicular: true,
            offsetDirection: normal.clone().negate(),
        },
    ]

    //draw labels at each line center
    if (debug) {
        console.log(result, "getNormalRelativePositions result")
        result.forEach(line => {
            if (!line.points) { return }
            const lineCenter = line.points[0].clone().add(line.points[1])
                .multiplyScalar(0.5)
            createLabelAtPosition(scene, lineCenter, line.position, { color: "red", fontSize: "12px", timer: 5000, dontAddToScene: false, })
        })
    }

    return result
}


export const generateDirectionalMarkers = (
    view: viewForScalerUI,
    scene: Scene,
    showScalerNormals: boolean
): DirectionalMarkers => {
    const viewDirectionalMarkers: DirectionalMarkers = {
        normalAligned: [],
        inverseAligned: [],
    }

    view.uniqueIds.forEach((partId: string) => {
        const startAndEndMarkers = Object.values(view.startAndEndMarkers?.[partId] ?? {})
        if (!startAndEndMarkers) { return }

        // Establish the two directions
        const normal = view.normal.clone()
        const inverseNormal = normal.clone().negate()

        // Draw debug arrows for the normal and inverse normal
        if (showScalerNormals) {
            const blue = new Color(0x00008B)
            const green = new Color(0x008B00)
            const arrowHelperInverse = new ArrowHelper(inverseNormal, new Vector3(0, 0, 0), 1.25, blue, 0.08, 0.08)
            // Position label at the tip of the inverse arrow with offset
            const inverseArrowTip = new Vector3(0, 0, 0).addScaledVector(inverseNormal, 1.25)
            const inverseLabelOffset = new Vector3(0.2, 0.2, 0)
            createLabelAtPosition(scene, inverseArrowTip.clone().add(inverseLabelOffset), "Inverse Normal", {
                color: "blue",
                fontSize: "12px",
            })

            const arrowHelperNormal = new ArrowHelper(normal, new Vector3(0, 0, 0), 1.25, green, 0.18, 0.18)
            // Position label at the tip of the normal arrow with offset
            const normalArrowTip = new Vector3(0, 0, 0).addScaledVector(normal, 1.25)
            const normalLabelOffset = new Vector3(0.2, 0.2, 0)
            createLabelAtPosition(scene, normalArrowTip.clone().add(normalLabelOffset), "Normal", {
                color: "green",
                fontSize: "12px",
            })
            scene.add(arrowHelperInverse)
            scene.add(arrowHelperNormal)

            setTimeout(() => {
                scene.remove(arrowHelperInverse)
                scene.remove(arrowHelperNormal)
            }, 5000)
        }

        startAndEndMarkers.forEach((marker) => {
            if (!marker) { return }

            const markerPosition = marker.getWorldPosition(new Vector3())
            const markerDirection = MeshUtils.copyWorldDirection(marker)

            // Draw debug arrow for marker direction
            if (showScalerNormals) {
                const randomColor = tinycolor2.random().toHexString()
                const arrowHelperMarker = new ArrowHelper(
                    markerDirection,
                    markerPosition,
                    0.5,
                    randomColor,
                    0.04,
                    0.04
                )
                scene.add(arrowHelperMarker)

                setTimeout(() => {
                    scene.remove(arrowHelperMarker)
                }, 10000)
            }

            // Check marker alignment with normal or inverse normal
            if (isSameDirection(markerDirection, normal)) {
                viewDirectionalMarkers.normalAligned.push({
                    position: markerPosition,
                    partId,
                    markerName: marker.name,
                    direction: markerDirection,
                })
            } else if (isSameDirection(markerDirection, inverseNormal)) {
                viewDirectionalMarkers.inverseAligned.push({
                    position: markerPosition,
                    partId,
                    markerName: marker.name,
                    direction: markerDirection,
                })
            }
        })
    })

    return viewDirectionalMarkers
}

export const drawAllFacesOfOBBAndCenter = (
    obb: ScalerOBBResult,
    scene: Scene,
    duration = 5000
) => {
    // Draw corners for all faces
    obb.startFace.forEach((point) => {
        drawVector3Point(point, scene, 0xff0000, 0.011, 5000, true, undefined, "obb_corner")
    })

    obb.endFace.forEach((point) => {
        drawVector3Point(point, scene, 0xff0000, 0.011, 5000, true, undefined, "obb_corner")
    })

    obb.leftFace.forEach((point) => {
        drawVector3Point(point, scene, 0x00ff00, 0.011, 5000, true, undefined, "obb_corner")
    })

    obb.rightFace.forEach((point) => {
        drawVector3Point(point, scene, 0x00ff00, 0.011, 5000, true, undefined, "obb_corner")
    })

    drawVector3Point(obb.center, scene, 0x0000ff, 0.011, 5000, true, undefined, "obb_corner")
}

const getConnectedPartSequences = (
    orderedParts: string[],
    connections: PartConnectionType[],
    debug = false
): string[][] => {
    const sequences: string[][] = []
    const processedParts = new Set<string>()

    debug && console.groupCollapsed("Getting Connected Part Sequences")
    debug && console.log("Input ordered parts:", orderedParts)

    // Helper function to get direct connections for a part
    const getDirectConnections = (partId: string): string[] => {
        return connections
            .filter(conn =>
                (conn.partA.partId === partId || conn.partB.partId === partId)
            )
            .map(conn =>
                (conn.partA.partId === partId ? conn.partB.partId : conn.partA.partId)
            )
            .filter(connectedId =>
                orderedParts.includes(connectedId) && !processedParts.has(connectedId)
            )
    }

    // Process each part in order
    while (processedParts.size < orderedParts.length) {
        // Find first unprocessed part
        const nextPart = orderedParts.find(partId => !processedParts.has(partId))
        if (!nextPart) { break }

        const currentSequence = [nextPart,]
        processedParts.add(nextPart)

        // Build connected sequence recursively
        const buildSequence = (currentPartId: string) => {
            const connectedParts = getDirectConnections(currentPartId)
                .sort((a, b) => orderedParts.indexOf(a) - orderedParts.indexOf(b))

            connectedParts.forEach(connectedId => {
                if (!processedParts.has(connectedId)) {
                    currentSequence.push(connectedId)
                    processedParts.add(connectedId)
                    buildSequence(connectedId)
                }
            })
        }

        buildSequence(nextPart)
        debug && console.log("Found sequence:", currentSequence)
        sequences.push(currentSequence)
    }

    debug && console.log("Final sequences:", sequences)
    debug && console.groupEnd()

    return sequences
}

export const getCursorType = (
    camera: Camera,
    normal: Vector3
): string => {
    // Get camera's up vector
    const cameraUp = new Vector3(0, 1, 0)
        .applyQuaternion(camera.quaternion)

    // Calculate dot product between view normal and camera up
    // This tells us if the normal is pointing more up/down or left/right
    const upAlignment = Math.abs(normal.dot(cameraUp))

    // If normal is more aligned with up/down (closer to 1 or -1)
    // then the resize should be horizontal (ew-resize)
    // If normal is more aligned with left/right (closer to 0)
    // then the resize should be vertical (ns-resize)
    return upAlignment > 0.5 ? "ns-resize" : "ew-resize"
}

export const assignWorldDirections = (
    directions: Vector3[],
    camera: Camera
): { direction: Vector3, label: "X" | "Y" | "Z", }[] => {
    if (directions.length !== 3) {
        throw new Error("Must provide exactly three direction vectors")
    }

    // Get camera's view vectors
    const cameraDirection = new Vector3(0, 0, -1).applyQuaternion(camera.quaternion)
    const cameraUp = new Vector3(0, 1, 0).applyQuaternion(camera.quaternion)
    const cameraRight = new Vector3(1, 0, 0).applyQuaternion(camera.quaternion)

    // Calculate alignments for each direction
    const alignments = directions.map(dir => {
        const normalizedDir = dir.clone().normalize()
        return {
            direction: dir,  // Keep original direction
            rightScore: Math.abs(normalizedDir.dot(cameraRight)),
            upScore: Math.abs(normalizedDir.dot(cameraUp)),
            depthScore: Math.abs(normalizedDir.dot(cameraDirection)),
        }
    })

    // Track used directions
    const usedIndices = new Set<number>()
    const results: { direction: Vector3, label: "X" | "Y" | "Z", }[] = []

    // Helper to find best match
    const findBestMatch = (scoreKey: "rightScore" | "upScore" | "depthScore"): number => {
        let bestScore = -1
        let bestIndex = -1

        alignments.forEach((alignment, index) => {
            if (!usedIndices.has(index) && alignment[scoreKey] > bestScore) {
                bestScore = alignment[scoreKey]
                bestIndex = index
            }
        })

        usedIndices.add(bestIndex)
        return bestIndex
    }

    // Assign X to the direction most aligned with camera right
    const xIndex = findBestMatch("rightScore")
    results.push({ direction: directions[xIndex], label: "X", })

    // Assign Y to the direction most aligned with camera up
    const yIndex = findBestMatch("upScore")
    results.push({ direction: directions[yIndex], label: "Y", })

    // Assign Z to the remaining direction
    const zIndex = findBestMatch("depthScore")
    results.push({ direction: directions[zIndex], label: "Z", })

    return results
}


export const getCursorTypeFrom2DVector = (
    lineCenter: Vector3,
    normal: Vector3,
    camera: Camera,
    debug = false
): string => {
    // Project line center to screen space
    const screenCenter = lineCenter.clone().project(camera)
    const centerX = (screenCenter.x + 1) * window.innerWidth / 2
    const centerY = (-screenCenter.y + 1) * window.innerHeight / 2

    // Get growth point and project to screen space
    const growthPoint = lineCenter.clone().add(normal)
        .project(camera)
    const growthX = (growthPoint.x + 1) * window.innerWidth / 2
    const growthY = (-growthPoint.y + 1) * window.innerHeight / 2

    // Calculate the 2D growth vector
    const dx = growthX - centerX
    const dy = growthY - centerY

    // If the vector is more horizontal, we want vertical resizing cursor and vice versa
    const isMoreHorizontal = Math.abs(dx) > Math.abs(dy)

    if (debug) {
        console.group("Cursor Type Debug")
        console.log("Growth vector:", { dx, dy, })
        console.log("Is more horizontal:", isMoreHorizontal)
        console.log("Cursor type:", isMoreHorizontal ? "ns-resize" : "ew-resize")
        console.groupEnd()
    }

    // Return opposite cursor type relative to growth direction
    // If growth is more horizontal, we want vertical resizing and vice versa
    return isMoreHorizontal ? "ew-resize" : "ns-resize"
}

function hasSignificantOverlap(overlap: { x: number, y: number, z: number, }): boolean {
    // Consider boxes overlapping if they intersect in all dimensions
    // Even a small actual intersection (e.g. 0.01 or 1%) indicates overlap
    const overlapThreshold = 0.001 // 1% overlap threshold
    return overlap.x > overlapThreshold
        && overlap.y > overlapThreshold
        && overlap.z > overlapThreshold
}

export const getDirectionMultiplier = (
    activeLine: { points: Vector3[], position: "left" | "right" | "top" | "bottom", },
    allLines: { points: Vector3[], position: "left" | "right" | "top" | "bottom", }[],
    camera: Camera,
    normal: Vector3
): number => {
    // Get camera's up vector
    const cameraUp = new Vector3(0, 1, 0)
        .applyQuaternion(camera.quaternion)

    // Calculate dot product between view normal and camera up
    const upAlignment = Math.abs(normal.dot(cameraUp))

    // Calculate active line center in world space
    const activeLineCenter = new Vector3()
        .addVectors(activeLine.points[0], activeLine.points[1])
        .multiplyScalar(0.5)

    // Convert to screen space
    const activeScreenCenter = activeLineCenter.clone().project(camera)

    // Find the opposite line based on position
    const oppositePosition = {
        left: "right",
        right: "left",
        top: "bottom",
        bottom: "top",
    }[activeLine.position]

    const oppositeLine = allLines.find(line => line.position === oppositePosition)
    if (!oppositeLine) { return 1 } // fallback

    const oppositeLineCenter = new Vector3()
        .addVectors(oppositeLine.points[0], oppositeLine.points[1])
        .multiplyScalar(0.5)

    const oppositeScreenCenter = oppositeLineCenter.clone().project(camera)

    if (upAlignment > 0.5) {
        // Vertical case - compare Y positions in screen space
        return activeScreenCenter.y > oppositeScreenCenter.y ? -1 : 1
    } else {
        // Horizontal case - compare X positions in screen space
        return activeScreenCenter.x < oppositeScreenCenter.x ? -1 : 1
    }
}

export const getDirectionMultiplierFromVector = (
    activeLine: { points: Vector3[], position: "left" | "right" | "top" | "bottom", },
    originalMousePos: { x: number, y: number, },
    newMousePos: { x: number, y: number, },
    camera: Camera,
    normal: Vector3,
    scene?: Scene,
    debug = false,
    drawCanvasSplit = false
): { multiplier: number, orientation: "horizontal" | "vertical", } => {
    // Calculate line center in world space and project to screen space
    const lineCenter = new Vector3()
        .addVectors(activeLine.points[0], activeLine.points[1])
        .multiplyScalar(0.5)
    const screenCenter = lineCenter.clone().project(camera)

    // Convert to pixel coordinates
    const centerX = (screenCenter.x + 1) * window.innerWidth / 2
    const centerY = (-screenCenter.y + 1) * window.innerHeight / 2

    // Get a point in the growth direction
    const growthPoint = lineCenter.clone().add(normal)
        .project(camera)
    const growthX = (growthPoint.x + 1) * window.innerWidth / 2
    const growthY = (-growthPoint.y + 1) * window.innerHeight / 2

    // Calculate the 2D growth vector
    const dx = growthX - centerX
    const dy = growthY - centerY

    // Determine if the growth vector is more horizontal or vertical
    const orientation = Math.abs(dx) > Math.abs(dy) ? "horizontal" : "vertical"

    // Get perpendicular vector (rotate 90 degrees)
    const perpX = -dy
    const perpY = dx

    // Function to determine which side of the line a point is on
    const getSide = (px: number, py: number) => {
        return (perpX) * (py - centerY) - (perpY) * (px - centerX)
    }

    // Get the side of the growth point (this will be our positive side)
    const growthSide = getSide(growthX, growthY)
    // Get the side of the end mouse position
    const mouseSide = getSide(newMousePos.x, newMousePos.y)

    // The multiplier is positive if both points are on the same side
    const multiplier = (growthSide * mouseSide > 0) ? 1 : -1

    if (debug && drawCanvasSplit) {
        // Create debug visualization
        const debugDiv = document.createElement("div")
        debugDiv.style.position = "fixed"
        debugDiv.style.left = "0"
        debugDiv.style.top = "0"
        debugDiv.style.width = "100%"
        debugDiv.style.height = "100%"
        debugDiv.style.pointerEvents = "none"
        debugDiv.style.zIndex = "9999"

        // Draw the splitting line
        const lineLength = Math.max(window.innerWidth, window.innerHeight) * 2
        const lineScale = lineLength / Math.sqrt(perpX * perpX + perpY * perpY)
        const x1 = centerX - perpX * lineScale
        const y1 = centerY - perpY * lineScale
        const x2 = centerX + perpX * lineScale
        const y2 = centerY + perpY * lineScale

        // Create two semi-transparent overlays for the two sides
        const createOverlay = (points: string, isPositive: boolean) => {
            const overlay = document.createElement("div")
            overlay.style.position = "absolute"
            overlay.style.left = "0"
            overlay.style.top = "0"
            overlay.style.width = "100%"
            overlay.style.height = "100%"
            overlay.style.clipPath = `polygon(${points})`
            overlay.style.backgroundColor = isPositive ? "rgba(0, 255, 0, 0.1)" : "rgba(255, 0, 0, 0.1)"
            return overlay
        }

        // Create the two halves of the screen
        const positiveSide = createOverlay(
            `${x1}px ${y1}px, ${x2}px ${y2}px, ${x2}px ${window.innerHeight}px, ${x1}px ${window.innerHeight}px`,
            false
        )
        const negativeSide = createOverlay(
            `${x1}px ${y1}px, ${x2}px ${y2}px, ${x2}px 0px, ${x1}px 0px`,
            true
        )

        // Draw the splitting line
        const splitLine = document.createElement("div")
        splitLine.style.position = "absolute"
        splitLine.style.left = `${centerX}px`
        splitLine.style.top = `${centerY}px`
        splitLine.style.width = `${lineLength}px`
        splitLine.style.height = "2px"
        splitLine.style.backgroundColor = "blue"
        splitLine.style.transform = `rotate(${Math.atan2(perpY, perpX)}rad)`
        splitLine.style.transformOrigin = "left"

        // Add all elements
        debugDiv.appendChild(positiveSide)
        debugDiv.appendChild(negativeSide)
        debugDiv.appendChild(splitLine)

        // Add points for visualization
        const createPoint = (x: number, y: number, color: string, label: string) => {
            const point = document.createElement("div")
            point.style.position = "absolute"
            point.style.left = `${x}px`
            point.style.top = `${y}px`
            point.style.width = "10px"
            point.style.height = "10px"
            point.style.backgroundColor = color
            point.style.borderRadius = "50%"
            point.style.transform = "translate(-50%, -50%)"

            const text = document.createElement("div")
            text.style.position = "absolute"
            text.style.left = "15px"
            text.style.top = "-10px"
            text.style.color = color
            text.textContent = label
            point.appendChild(text)

            return point
        }

        debugDiv.appendChild(createPoint(centerX, centerY, "blue", "Center"))
        debugDiv.appendChild(createPoint(growthX, growthY, "green", "Growth"))
        debugDiv.appendChild(createPoint(newMousePos.x, newMousePos.y, "purple", "Mouse"))

        document.body.appendChild(debugDiv)

        // Clean up after delay
        setTimeout(() => {
            document.body.removeChild(debugDiv)
        }, 5000)

        if (debug) {
            console.group("Direction Multiplier Debug")
            console.log("Growth vector:", { dx, dy, })
            console.log("Orientation:", orientation)
            console.log("Multiplier:", multiplier)
            console.groupEnd()
        }
    }

    return { multiplier, orientation, }
}

// First, define the type
export type SpatialMaps = {
    spatialRelationshipsByUUID: Map<string, BoxSpatialInfo>,
    spatialRelationshipsByPartId: Map<string, BoxSpatialInfo>,
}

export const visualizePartPercentages = (
    spatialMaps: SpatialMaps,
    percentageMap: Map<string[], PercentageMap>,
    scene: Scene,
    combinedOBB: ScalerOBBResult,
    timer = 150000
) => {
    // Flatten all percentage maps into a single map for easier lookup
    const flatPercentages = new Map<string, number>()
    percentageMap.forEach(groupMap => {
        groupMap.forEach((percentage, partId) => {
            flatPercentages.set(partId, percentage)
        })
    })

    // Create labels for each part
    spatialMaps.spatialRelationshipsByPartId.forEach((spatialInfo, partId) => {
        const percentage = flatPercentages.get(partId)
        if (percentage === undefined) { return }

        // Get the local center position and transform to world space
        const localCenter = new Vector3(
            combinedOBB.box.min.x + spatialInfo.localPosition.x * (combinedOBB.box.max.x - combinedOBB.box.min.x),
            combinedOBB.box.min.y + spatialInfo.localPosition.y * (combinedOBB.box.max.y - combinedOBB.box.min.y),
            combinedOBB.box.min.z + spatialInfo.localPosition.z * (combinedOBB.box.max.z - combinedOBB.box.min.z)
        )

        // Transform to world space
        const worldPosition = localCenter.clone().applyMatrix4(combinedOBB.transformation)

        // Create label text with last 4 chars of ID and percentage
        const shortId = partId.slice(-4)
        const percentageText = percentage.toFixed(1)
        const labelText = `${shortId}: ${percentageText}%`

        // Add label slightly above the part
        const labelPosition = worldPosition.clone()
        labelPosition.y += 0.05 // Offset upward

        // Create label with different colors based on percentage
        const color = percentage > 0 ? "#00ff00" : "#666666" // Green for parts with percentage, gray for 0%

        createLabelAtPosition(scene, labelPosition, labelText, {
            color,
            fontSize: "12px",
            timer,
        })
    })
}

export const createSpatialRelationshipsFromUserData = (
    mainMesh: BoxMeshWithUserData<Mesh>,
    individualBoxes: BoxMeshWithUserData<Mesh>[],
    viewNormal: Vector3,
    overlapThreshold = 0.01,
    scene?: Scene,
    debug = false
): SpatialMaps => {
    // Get the coordinate system directly from the main mesh's userData
    const secondAxis = mainMesh.userData.worldDimensions.widthDirection.clone()
    const thirdAxis = mainMesh.userData.worldDimensions.heightDirection.clone()
    const normalAxis = mainMesh.userData.worldDimensions.depthDirection.clone()

    if (debug && scene) {
        // Draw debug axes
        const origin = mainMesh.userData.getWorldCenter()
        const size = 1 // Size of debug arrows

        // Create arrows for each axis
        const xAxis = new ArrowHelper(
            secondAxis,
            origin,
            size,
            0xff0000, // Red for X
            0.1,
            0.05
        )
        const yAxis = new ArrowHelper(
            thirdAxis,
            origin,
            size,
            0x00ff00, // Green for Y
            0.1,
            0.05
        )
        const zAxis = new ArrowHelper(
            normalAxis,
            origin,
            size,
            0x0000ff, // Blue for Z
            0.1,
            0.05
        )

        // Add labels for each axis
        const labelOffset = 0.2
        createLabelAtPosition(scene, origin.clone().add(secondAxis.clone().multiplyScalar(size + labelOffset)), "X (width)", {
            color: "#ff0000",
            fontSize: "14px",
            timer: 15000,
        })
        createLabelAtPosition(scene, origin.clone().add(thirdAxis.clone().multiplyScalar(size + labelOffset)), "Y (height)", {
            color: "#00ff00",
            fontSize: "14px",
            timer: 15000,
        })
        createLabelAtPosition(scene, origin.clone().add(normalAxis.clone().multiplyScalar(size + labelOffset)), "Z (depth)", {
            color: "#0000ff",
            fontSize: "14px",
            timer: 15000,
        })

        // Add axes to scene
        scene.add(xAxis)
        scene.add(yAxis)
        scene.add(zAxis)

        // Remove after delay
        setTimeout(() => {
            scene.remove(xAxis)
            scene.remove(yAxis)
            scene.remove(zAxis)
        }, 15000)

        // Add reference points for debugging
        const referencePoints = [
            { pos: new Vector3(0, 0, 0), label: "Center (0.5x, 0.5y, 0.5z)", },
            { pos: new Vector3(-1, -1, -1), label: "Min (0x, 0y, 0z)", },
            { pos: new Vector3(1, 1, 1), label: "Max (1x, 1y, 1z)", },
            // Middle points of each face
            { pos: new Vector3(0, 0, -1), label: "Back (0.5x, 0.5y, 0z)", },
            { pos: new Vector3(0, 0, 1), label: "Front (0.5x, 0.5y, 1z)", },
            { pos: new Vector3(0, -1, 0), label: "Bottom (0.5x, 0y, 0.5z)", },
            { pos: new Vector3(0, 1, 0), label: "Top (0.5x, 1y, 0.5z)", },
            { pos: new Vector3(-1, 0, 0), label: "Left (0x, 0.5y, 0.5z)", },
            { pos: new Vector3(1, 0, 0), label: "Right (1x, 0.5y, 0.5z)", },
        ]

        // Get the main mesh's world corners and center
        const mainWorldCorners = mainMesh.userData.getWorldCorners()
        const mainWorldCenter = mainMesh.userData.getWorldCenter()

        // Calculate bounds of the main mesh
        const mainMin = new Vector3(Infinity, Infinity, Infinity)
        const mainMax = new Vector3(-Infinity, -Infinity, -Infinity)

        mainWorldCorners.forEach(corner => {
            mainMin.min(corner)
            mainMax.max(corner)
        })

        referencePoints.forEach(({ pos, label, }) => {
            // Convert from (-1,1) space to (0,1) space
            const normalizedPos = new Vector3(
                (pos.x + 1) / 2,
                (pos.y + 1) / 2,
                (pos.z + 1) / 2
            )

            // Convert to local space
            const localPos = new Vector3(
                mainMin.x + normalizedPos.x * (mainMax.x - mainMin.x),
                mainMin.y + normalizedPos.y * (mainMax.y - mainMin.y),
                mainMin.z + normalizedPos.z * (mainMax.z - mainMin.z)
            )

            // Draw point
            drawVector3Point(localPos, scene, "#ffff00", 0.05, 15000)

            // Add label
            createLabelAtPosition(
                scene,
                localPos.clone().add(new Vector3(0.1, 0.1, 0.1)),
                label,
                {
                    color: "#ffff00",
                    fontSize: "12px",
                    timer: 15000,
                }
            )
        })
    }

    // Get the main mesh's world corners and center
    const mainWorldCorners = mainMesh.userData.getWorldCorners()
    const mainWorldCenter = mainMesh.userData.getWorldCenter()

    // Calculate bounds of the main mesh
    const mainMin = new Vector3(Infinity, Infinity, Infinity)
    const mainMax = new Vector3(-Infinity, -Infinity, -Infinity)

    mainWorldCorners.forEach(corner => {
        mainMin.min(corner)
        mainMax.max(corner)
    })

    // Create a transformation matrix for the main mesh
    // This will be used to transform all boxes to the main mesh's local space
    const mainTransformation = new Matrix4()
    mainTransformation.makeBasis(secondAxis, thirdAxis, normalAxis)
    mainTransformation.setPosition(mainWorldCenter)

    const inverseTransform = mainTransformation.clone().invert()

    // Transform all boxes to main mesh local space
    const boxesInLocalSpace = new Map<string, LocalGridPosition>()

    individualBoxes.forEach(boxMesh => {
        const worldCorners = boxMesh.userData.getWorldCorners()
        const center = boxMesh.userData.getWorldCenter()

        // Transform corners and center to main mesh local space
        const localCorners = worldCorners.map(corner =>
            corner.clone().applyMatrix4(inverseTransform)
        )
        const localCenter = center.clone().applyMatrix4(inverseTransform)

        // Normalize positions relative to main mesh bounds (0-1)
        const normalizedCenter = new Vector3(
            (localCenter.x - mainMin.x) / (mainMax.x - mainMin.x),
            (localCenter.y - mainMin.y) / (mainMax.y - mainMin.y),
            (localCenter.z - mainMin.z) / (mainMax.z - mainMin.z)
        )

        boxesInLocalSpace.set(boxMesh.uuid, {
            x: normalizedCenter.x,
            y: normalizedCenter.y,
            z: normalizedCenter.z,
            corners: localCorners,
            center: localCenter,
        })
    })

    // Create spatial relationships for each box
    const spatialRelationshipsByUUID = new Map<string, BoxSpatialInfo>()
    const spatialRelationshipsByPartId = new Map<string, BoxSpatialInfo>()

    // Pre-calculate centers and dimensions for all boxes
    const boxData = new Map<string, {
        center: Vector3,
        maxDim: number,
        dimensions: { width: number, height: number, depth: number, },
    }>()

    individualBoxes.forEach(box => {
        const center = box.userData.getWorldCenter()
        const dims = box.userData.getDimensions("m")
        const maxDim = Math.max(dims.width, dims.height, dims.depth)
        boxData.set(box.uuid, { center, maxDim, dimensions: dims, })
    })

    // Process each box
    individualBoxes.forEach(boxA => {
        const boxAData = boxData.get(boxA.uuid)!
        const boxALocal = boxesInLocalSpace.get(boxA.uuid)!

        const relationships: BoxSpatialInfo = {
            id: boxA.uuid,
            partId: boxA.userData.partId,
            localPosition: boxALocal,
            neighbors: {
                front: [],
                back: [],
                above: [],
                below: [],
                left: [],
                right: [],
            },
            overlapping: [],
            overlappingX: [],
            overlappingY: [],
            overlappingZ: [],
            touching: [],
            dimensions: boxAData.dimensions,
            partType: boxA.userData.partType,
        }

        // Quick distance check using pre-calculated data
        individualBoxes.forEach(boxB => {
            if (boxA.uuid === boxB.uuid) { return }

            const boxBData = boxData.get(boxB.uuid)!
            const maxInteractionDistance = (boxAData.maxDim + boxBData.maxDim) * 0.5 * 1.2

            if (boxAData.center.distanceTo(boxBData.center) <= maxInteractionDistance) {
                const boxBLocal = boxesInLocalSpace.get(boxB.uuid)!
                const relativePos = getRelativePosition(boxALocal, boxBLocal, overlapThreshold)
                const proximity = checkBoxProximity(boxALocal, boxBLocal)

                const boxBInfo = { id: boxB.uuid, partId: boxB.userData.partId, }

                // Process spatial relationships
                if (hasSignificantOverlap(relativePos.overlap)) {
                    relationships.overlapping.push(boxBInfo)
                    return
                }

                // Check axis overlaps
                if (relativePos.overlap.x > 0.5) { relationships.overlappingX.push(boxBInfo) }
                if (relativePos.overlap.y > 0.5) { relationships.overlappingY.push(boxBInfo) }
                if (relativePos.overlap.z > 0.5) { relationships.overlappingZ.push(boxBInfo) }

                // Check touching
                if (proximity.isTouching) {
                    relationships.touching.push(boxBInfo)
                }

                // Add to neighbors
                if (relativePos.isInFront) { relationships.neighbors.front.push(boxBInfo) }
                else { relationships.neighbors.back.push(boxBInfo) }

                if (relativePos.isAbove) { relationships.neighbors.above.push(boxBInfo) }
                else { relationships.neighbors.below.push(boxBInfo) }

                if (relativePos.isToRight) { relationships.neighbors.right.push(boxBInfo) }
                else { relationships.neighbors.left.push(boxBInfo) }
            }
        })

        spatialRelationshipsByUUID.set(boxA.uuid, relationships)
        spatialRelationshipsByPartId.set(boxA.userData.partId, relationships)
    })

    return {
        spatialRelationshipsByUUID,
        spatialRelationshipsByPartId,
    }
}

export const createSpatialRelationships = (
    combinedOBB: ScalerOBBResult,
    individualBoxes: BoxMeshWithUserData<Mesh>[],
    viewNormal: Vector3,
    overlapThreshold = 0.01,
    scene?: Scene,
    debug = false
): SpatialMaps => {
    // 1. Transform all boxes to OBB local space
    const boxesInLocalSpace = new Map<string, LocalGridPosition>()
    const inverseTransform = combinedOBB.transformation.clone().invert()

    const up = new Vector3(0, 1, 0)
    const right = new Vector3().crossVectors(viewNormal, up)
        .normalize()
    if (right.lengthSq() < 0.1) {
        up.set(0, 0, 1)
        right.crossVectors(viewNormal, up).normalize()
    }

    // Get the third axis by crossing normal and right
    const secondAxis = right
    const thirdAxis = new Vector3().crossVectors(viewNormal, secondAxis)
        .normalize()

    if (debug && scene) {
        const referencePoints = [
            { pos: new Vector3(0, 0, 0), label: "Center (0.5x, 0.5y, 0.5z)", },
            { pos: new Vector3(-1, -1, -1), label: "Min (0x, 0y, 0z)", },
            { pos: new Vector3(1, 1, 1), label: "Max (1x, 1y, 1z)", },
            // Middle points of each face
            { pos: new Vector3(0, 0, -1), label: "Back (0.5x, 0.5y, 0z)", },
            { pos: new Vector3(0, 0, 1), label: "Front (0.5x, 0.5y, 1z)", },
            { pos: new Vector3(0, -1, 0), label: "Bottom (0.5x, 0y, 0.5z)", },
            { pos: new Vector3(0, 1, 0), label: "Top (0.5x, 1y, 0.5z)", },
            { pos: new Vector3(-1, 0, 0), label: "Left (0x, 0.5y, 0.5z)", },
            { pos: new Vector3(1, 0, 0), label: "Right (1x, 0.5y, 0.5z)", },
        ]

        referencePoints.forEach(({ pos, label, }) => {
            // Convert from (-1,1) space to (0,1) space
            const normalizedPos = new Vector3(
                (pos.x + 1) / 2,
                (pos.y + 1) / 2,
                (pos.z + 1) / 2
            )

            // Convert to local OBB space
            const localPos = new Vector3(
                combinedOBB.box.min.x + normalizedPos.x * (combinedOBB.box.max.x - combinedOBB.box.min.x),
                combinedOBB.box.min.y + normalizedPos.y * (combinedOBB.box.max.y - combinedOBB.box.min.y),
                combinedOBB.box.min.z + normalizedPos.z * (combinedOBB.box.max.z - combinedOBB.box.min.z)
            )

            // Transform to world space
            const worldPos = localPos.clone().applyMatrix4(combinedOBB.transformation)

            // Draw point
            drawVector3Point(worldPos, scene, "#ffff00", 0.05, 15000)

            // Add label
            createLabelAtPosition(
                scene,
                worldPos.clone().add(new Vector3(0.1, 0.1, 0.1)),
                label,
                {
                    color: "#ffff00",
                    fontSize: "12px",
                    timer: 15000,
                }
            )
        })

        const origin = combinedOBB.center
        const size = 1 // Size of debug arrows

        // Create arrows for each axis
        const xAxis = new ArrowHelper(
            secondAxis,
            origin,
            size,
            0xff0000, // Red for X
            0.1,
            0.05
        )
        const yAxis = new ArrowHelper(
            thirdAxis,
            origin,
            size,
            0x00ff00, // Green for Y
            0.1,
            0.05
        )
        const zAxis = new ArrowHelper(
            viewNormal,
            origin,
            size,
            0x0000ff, // Blue for Z
            0.1,
            0.05
        )

        // Add labels for each axis
        const labelOffset = 0.2
        createLabelAtPosition(scene, origin.clone().add(secondAxis.clone().multiplyScalar(size + labelOffset)), "X (right)", {
            color: "#ff0000",
            fontSize: "14px",
            timer: 15000,
        })
        createLabelAtPosition(scene, origin.clone().add(thirdAxis.clone().multiplyScalar(size + labelOffset)), "Y (up)", {
            color: "#00ff00",
            fontSize: "14px",
            timer: 15000,
        })
        createLabelAtPosition(scene, origin.clone().add(viewNormal.clone().multiplyScalar(size + labelOffset)), "Z (normal)", {
            color: "#0000ff",
            fontSize: "14px",
            timer: 15000,
        })


        // Add axes to scene
        scene.add(xAxis)
        scene.add(yAxis)
        scene.add(zAxis)

        // Remove after delay
        setTimeout(() => {
            scene.remove(xAxis)
            scene.remove(yAxis)
            scene.remove(zAxis)
        }, 15000)
    }

    individualBoxes.forEach(boxMesh => {
        const worldCorners = boxMesh.userData.getWorldCorners()
        const center = boxMesh.userData.getWorldCenter()

        // Transform corners and center to OBB local space
        const localCorners = worldCorners.map((corner: Vector3) =>
            corner.clone().applyMatrix4(inverseTransform)
        )
        const localCenter = center.clone().applyMatrix4(inverseTransform)


        // Normalize positions relative to OBB bounds (0-1)
        const normalizedCenter = new Vector3(
            (localCenter.x - combinedOBB.box.min.x) / (combinedOBB.box.max.x - combinedOBB.box.min.x),
            (localCenter.y - combinedOBB.box.min.y) / (combinedOBB.box.max.y - combinedOBB.box.min.y),
            (localCenter.z - combinedOBB.box.min.z) / (combinedOBB.box.max.z - combinedOBB.box.min.z)
        )

        boxesInLocalSpace.set(boxMesh.uuid, {
            x: normalizedCenter.x,
            y: normalizedCenter.y,
            z: normalizedCenter.z,
            corners: localCorners,
            center: localCenter,
        })
    })

    // 2. Create spatial relationships for each box
    const spatialRelationshipsByUUID = new Map<string, BoxSpatialInfo>()
    const spatialRelationshipsByPartId = new Map<string, BoxSpatialInfo>()


    individualBoxes.forEach(boxA => {
        const dimensions = boxA.userData.getDimensions("m")
        const partType = boxA.userData.partType
        const boxALocal = boxesInLocalSpace.get(boxA.uuid)!
        const relationships: BoxSpatialInfo = {
            id: boxA.uuid,
            partId: boxA.userData.partId,
            localPosition: boxALocal,
            neighbors: {
                front: [],
                back: [],
                above: [],
                below: [],
                left: [],
                right: [],
            },
            overlapping: [],
            overlappingX: [],
            overlappingY: [],
            overlappingZ: [],
            touching: [],
            dimensions: {
                width: dimensions.width,
                height: dimensions.height,
                depth: dimensions.depth,
            },
            partType: partType,
        }

        individualBoxes.forEach(boxB => {
            if (boxA.uuid === boxB.uuid) { return }

            const boxBLocal = boxesInLocalSpace.get(boxB.uuid)!
            const relativePos = getRelativePosition(boxALocal, boxBLocal, overlapThreshold)
            const proximity = checkBoxProximity(boxALocal, boxBLocal)


            const boxBInfo = { id: boxB.uuid, partId: boxB.userData.partId, }

            // Check individual axis overlaps
            const significantOverlapThreshold = 0.5 // Adjust this threshold as needed

            if (relativePos.overlap.x > significantOverlapThreshold) {
                relationships.overlappingX.push(boxBInfo)
            }
            if (relativePos.overlap.y > significantOverlapThreshold) {
                relationships.overlappingY.push(boxBInfo)
            }
            if (relativePos.overlap.z > significantOverlapThreshold) {
                relationships.overlappingZ.push(boxBInfo)
            }

            // Check full overlap (all axes)
            if (hasSignificantOverlap(relativePos.overlap)) {
                relationships.overlapping.push(boxBInfo)
                return
            }

            // Check touching
            if (proximity.isTouching) {
                relationships.touching.push(boxBInfo)
            }

            // Add to appropriate neighbor lists based on relative position
            if (relativePos.isInFront) { relationships.neighbors.front.push(boxBInfo) }
            else { relationships.neighbors.back.push(boxBInfo) }

            if (relativePos.isAbove) { relationships.neighbors.above.push(boxBInfo) }
            else { relationships.neighbors.below.push(boxBInfo) }

            if (relativePos.isToRight) { relationships.neighbors.right.push(boxBInfo) }
            else { relationships.neighbors.left.push(boxBInfo) }
        })

        spatialRelationshipsByUUID.set(boxA.uuid, relationships)
        spatialRelationshipsByPartId.set(boxA.userData.partId, relationships)
    })

    return {
        spatialRelationshipsByUUID: spatialRelationshipsByUUID,
        spatialRelationshipsByPartId: spatialRelationshipsByPartId,
    }
}

export const getTouchingPartsMap = (spatialMaps: SpatialMaps): Map<string, string[]> => {
    const touchingMap = new Map<string, string[]>()

    Array.from(spatialMaps.spatialRelationshipsByPartId.values()).forEach(info => {
        touchingMap.set(
            info.partId,
            info.touching.map(t => t.partId)
        )
    })

    return touchingMap
}

export const getPartIdsByAxis = (
    spatialMaps: SpatialMaps,
    axis: "x" | "y" | "z",
    ascending = true
): string[] => {
    // Convert map to array and sort by specified axis
    const sorted = Array.from(spatialMaps.spatialRelationshipsByPartId.values())
        .sort((a, b) => {
            const aValue = a.localPosition[axis]
            const bValue = b.localPosition[axis]
            return ascending ? aValue - bValue : bValue - aValue
        })

    // Return just the partIds
    return sorted.map(info => info.partId)
}

type LocalGridPosition = {
    x: number, // Local coordinate in OBB space (0-1)
    y: number,
    z: number,
    corners: Vector3[], // Corners in OBB local space
    center: Vector3,   // Center in OBB local space
}

type RelativePosition = {
    isInFront: boolean,
    isAbove: boolean,
    isToRight: boolean,
    distance: number,
    overlap: {
        x: number,
        y: number,
        z: number,
    },
}


export type BoxSpatialInfo = {
    id: string,
    partId: string,
    localPosition: LocalGridPosition,
    partType: PartTypeEnum,
    dimensions: {
        width: number,
        height: number,
        depth: number,
    },
    neighbors: {
        front: { id: string, partId: string, }[],
        back: { id: string, partId: string, }[],
        above: { id: string, partId: string, }[],
        below: { id: string, partId: string, }[],
        left: { id: string, partId: string, }[],
        right: { id: string, partId: string, }[],
    },
    overlapping: { id: string, partId: string, }[],
    overlappingX: { id: string, partId: string, }[],
    overlappingY: { id: string, partId: string, }[],
    overlappingZ: { id: string, partId: string, }[],
    touching: { id: string, partId: string, }[],
}


function calculateOverlap(
    cornersA: Vector3[],
    cornersB: Vector3[],
    axis: "x" | "y" | "z"
): number {
    // Find the min and max bounds for each box along the given axis
    const minA = Math.min(...cornersA.map(c => c[axis]))
    const maxA = Math.max(...cornersA.map(c => c[axis]))
    const minB = Math.min(...cornersB.map(c => c[axis]))
    const maxB = Math.max(...cornersB.map(c => c[axis]))

    // Calculate actual overlap distance
    const overlap = Math.min(maxA, maxB) - Math.max(minA, minB)

    // Get the smaller box size to calculate overlap percentage
    const sizeA = maxA - minA
    const sizeB = maxB - minB
    const smallerSize = Math.min(sizeA, sizeB)

    // Return overlap as a percentage of the smaller box size
    return overlap > 0 ? overlap / smallerSize : 0
}

function getRelativePosition(
    boxA: LocalGridPosition,
    boxB: LocalGridPosition,
    overlapThreshold: number
): RelativePosition {
    const dx = boxB.center.x - boxA.center.x
    const dy = boxB.center.y - boxA.center.y
    const dz = boxB.center.z - boxA.center.z

    // Calculate overlap in each dimension
    const overlapX = calculateOverlap(boxA.corners, boxB.corners, "x")
    const overlapY = calculateOverlap(boxA.corners, boxB.corners, "y")
    const overlapZ = calculateOverlap(boxA.corners, boxB.corners, "z")


    return {
        isInFront: dz > overlapThreshold,
        isAbove: dy > overlapThreshold,
        isToRight: dx > overlapThreshold,
        distance: Math.sqrt(dx * dx + dy * dy + dz * dz),
        overlap: {
            x: overlapX,
            y: overlapY,
            z: overlapZ,
        },
    }
}

type BoxProximity = {
    isOverlapping: boolean,
    isTouching: boolean,
    overlap: {
        x: number,
        y: number,
        z: number,
    },
    distance: number,
}


function checkBoxProximity(
    boxA: LocalGridPosition,
    boxB: LocalGridPosition,
    touchingThreshold = 0.001
): BoxProximity {
    const axes = getAxesForSAT(boxA.corners, boxB.corners)
    let minOverlap = Infinity
    let touchingAxis = null

    // Test each axis
    for (const axis of axes) {
        const projectionA = projectOntoAxis(boxA.corners, axis)
        const projectionB = projectOntoAxis(boxB.corners, axis)
        const overlap = getOverlap(projectionA, projectionB)

        // If gap is larger than threshold on any axis, boxes aren't touching
        if (overlap < -touchingThreshold) {
            return {
                isOverlapping: false,
                isTouching: false,
                overlap: { x: 0, y: 0, z: 0, },
                distance: -overlap,
            }
        }

        if (overlap < minOverlap) {
            minOverlap = overlap
            touchingAxis = axis
        }
    }

    // Boxes are touching if they have very small gap OR very small overlap
    const isTouching = Math.abs(minOverlap) <= touchingThreshold
    const isOverlapping = minOverlap > touchingThreshold

    return {
        isOverlapping,
        isTouching,
        overlap: { x: minOverlap, y: minOverlap, z: minOverlap, },
        distance: minOverlap,
    }
}

function getAxesForSAT(cornersA: Vector3[], cornersB: Vector3[]): Vector3[] {
    const axes: Vector3[] = []

    // Get face normals from both boxes (6 axes)
    const boxAAxes = getBoxFaceNormals(cornersA)
    const boxBAxes = getBoxFaceNormals(cornersB)

    // Add face normals
    axes.push(...boxAAxes, ...boxBAxes)

    // Add cross products of all pairs of edges (9 axes)
    for (const axisA of boxAAxes) {
        for (const axisB of boxBAxes) {
            const crossAxis = new Vector3().crossVectors(axisA, axisB)
            if (crossAxis.lengthSq() > 0.001) { // Avoid near-parallel axes
                crossAxis.normalize()
                axes.push(crossAxis)
            }
        }
    }

    return axes
}

function getBoxFaceNormals(corners: Vector3[]): Vector3[] {
    // Assuming corners are arranged in a standard order
    const edge1 = new Vector3().subVectors(corners[1], corners[0])
    const edge2 = new Vector3().subVectors(corners[3], corners[0])
    const edge3 = new Vector3().subVectors(corners[4], corners[0])

    return [
        edge1.normalize(),
        edge2.normalize(),
        edge3.normalize(),
    ]
}

function projectOntoAxis(corners: Vector3[], axis: Vector3): { min: number, max: number, } {
    let min = Infinity
    let max = -Infinity

    for (const corner of corners) {
        const projection = corner.dot(axis)
        min = Math.min(min, projection)
        max = Math.max(max, projection)
    }

    return { min, max, }
}

function getOverlap(projA: { min: number, max: number, }, projB: { min: number, max: number, }): number {
    // Calculate the gap/overlap between projections
    // Positive = overlap amount
    // Negative = gap amount
    if (projA.max < projB.min) { return -(projB.min - projA.max) }
    if (projB.max < projA.min) { return -(projA.min - projB.max) }
    return Math.min(projA.max - projB.min, projB.max - projA.min)
}

type PercentageMap = Map<string, number>

export const getPartPercentage = (
    groupPercentages: Map<string[], PercentageMap>,
    partId: string
): number => {
    // Find the group containing this partId
    const group = Array.from(groupPercentages.keys()).find(group =>
        group.includes(partId)
    )

    if (!group) { return 0 }

    const percentageMap = groupPercentages.get(group)
    return percentageMap?.get(partId) || 0
}

export const calculateAndAdjustPercentages = (
    spatialMaps: SpatialMaps,
    idGroups: string[][],
    debug = false
): Map<string[], PercentageMap> => {
    const groupPercentages = new Map<string[], PercentageMap>()

    debug && console.group("🔍 Calculate and Adjust Percentages")
    debug && console.log("Input groups:", idGroups)

    // First pass: Calculate initial percentages for all groups
    idGroups.forEach((group, groupIndex) => {
        debug && console.group(`\n📊 First Pass - Group ${groupIndex}:`, group)
        const percentageMap = new Map<string, number>()

        // Filter for tube parts and calculate total depth
        const totalDepth = group.reduce((sum, partId) => {
            const spatialInfo = spatialMaps.spatialRelationshipsByPartId.get(partId)
            if (!spatialInfo || !spatialInfo.partType.toLowerCase().includes("tube")) {
                debug && console.log(`  ⚠️ Skipping non-tube part ${partId} (${spatialInfo?.partType})`)
                return sum
            }
            const depth = spatialInfo.dimensions.depth || 0
            debug && console.log(`  📏 Part ${partId}: depth = ${depth}`)
            return sum + depth
        }, 0)

        debug && console.log(`  📐 Total depth for group: ${totalDepth}`)

        // Set percentages
        group.forEach(partId => {
            const spatialInfo = spatialMaps.spatialRelationshipsByPartId.get(partId)
            if (!spatialInfo) {
                debug && console.warn(`  ❌ No spatial info found for part ${partId}`)
                return
            }

            if (!spatialInfo.partType.toLowerCase().includes("tube")) {
                percentageMap.set(partId, 0)
                debug && console.log(`  0️⃣ Set ${partId} to 0% (non-tube part)`)
            } else {
                const percentage = (spatialInfo.dimensions.depth / totalDepth) * 100
                percentageMap.set(partId, percentage)
                debug && console.log(`  💯 Set ${partId} to ${percentage.toFixed(2)}%`)
            }
        })

        groupPercentages.set(group, percentageMap)
        debug && console.groupEnd()
    })

    // Second pass: Adjust for cross-group overlaps
    debug && console.group("\n🔄 Second Pass - Cross-group Overlap Adjustments")

    idGroups.forEach((group, groupIndex) => {
        debug && console.group(`\n  Group ${groupIndex} Analysis:`)
        const currentPercentages = groupPercentages.get(group)
        if (!currentPercentages) {
            debug && console.warn("  ❌ No percentage data found for group")
            debug && console.groupEnd()
            return
        }

        const partsWithCrossGroupOverlap = new Map<string, number>()

        // Track original values for comparison
        const originalPercentages = new Map(currentPercentages)

        // Only consider tube parts for overlap adjustments
        group.forEach(partId => {
            const spatialInfo = spatialMaps.spatialRelationshipsByPartId.get(partId)
            if (!spatialInfo || !spatialInfo.partType.toLowerCase().includes("tube")) {
                return
            }

            debug && console.group(`    📍 Analyzing part ${partId}:`)
            spatialInfo.overlappingZ.forEach(overlap => {
                const otherSpatialInfo = spatialMaps.spatialRelationshipsByPartId.get(overlap.partId)
                if (!otherSpatialInfo || !otherSpatialInfo.partType.toLowerCase().includes("tube")) {
                    debug && console.log(`      ⚠️ Skipping non-tube overlap with ${overlap.partId}`)
                    return
                }

                const otherGroup = idGroups.find(g =>
                    g !== group && g.includes(overlap.partId)
                )

                if (otherGroup) {
                    const otherGroupPercentages = groupPercentages.get(otherGroup)
                    const otherPartPercentage = otherGroupPercentages?.get(overlap.partId) || 0
                    const currentPartPercentage = currentPercentages.get(partId) || 0

                    debug && console.log(`      🔄 Overlap with ${overlap.partId}:`)
                    debug && console.log(`        - Current: ${currentPartPercentage.toFixed(2)}%`)
                    debug && console.log(`        - Other: ${otherPartPercentage.toFixed(2)}%`)

                    if (otherPartPercentage > currentPartPercentage) {
                        partsWithCrossGroupOverlap.set(
                            partId,
                            Math.max(partsWithCrossGroupOverlap.get(partId) || 0, otherPartPercentage)
                        )
                        debug && console.log(`        ⬆️ Needs adjustment to ${otherPartPercentage.toFixed(2)}%`)
                    }
                }
            })
            debug && console.groupEnd()
        })

        if (partsWithCrossGroupOverlap.size > 0) {
            debug && console.group("\n    ✨ Applying Adjustments:")

            // Track all overlapping relationships for convergence
            const overlappingPairs = new Map<string, Set<string>>()

            // Build overlapping relationships map
            group.forEach(partId => {
                const spatialInfo = spatialMaps.spatialRelationshipsByPartId.get(partId)
                if (!spatialInfo?.partType.toLowerCase().includes("tube")) { return }

                spatialInfo.overlappingZ.forEach(overlap => {
                    const otherSpatialInfo = spatialMaps.spatialRelationshipsByPartId.get(overlap.partId)
                    if (!otherSpatialInfo?.partType.toLowerCase().includes("tube")) { return }

                    const otherGroup = idGroups.find(g => g !== group && g.includes(overlap.partId))
                    if (!otherGroup) { return }

                    if (!overlappingPairs.has(partId)) {
                        overlappingPairs.set(partId, new Set())
                    }
                    overlappingPairs.get(partId)?.add(overlap.partId)
                })
            })

            debug && console.log("      Overlapping relationships:",
                Array.from(overlappingPairs.entries())
                    .map(([id, others,]) => `${id} -> [${Array.from(others).join(", ")}]`)
            )

            // Iteratively adjust percentages until convergence
            let iterations = 0
            const MAX_ITERATIONS = 10
            let hasChanges = true

            while (hasChanges && iterations < MAX_ITERATIONS) {
                hasChanges = false
                iterations++
                debug && console.group(`      Iteration ${iterations}:`)

                overlappingPairs.forEach((overlappingWith, partId) => {
                    const currentPercentage = currentPercentages.get(partId) || 0
                    let maxPercentage = currentPercentage

                    // Find maximum percentage among all overlapping parts
                    overlappingWith.forEach(otherId => {
                        const otherGroup = idGroups.find(g => g !== group && g.includes(otherId))
                        if (!otherGroup) { return }

                        const otherPercentages = groupPercentages.get(otherGroup)
                        const otherPercentage = otherPercentages?.get(otherId) || 0
                        maxPercentage = Math.max(maxPercentage, otherPercentage)
                    })

                    if (maxPercentage !== currentPercentage) {
                        hasChanges = true
                        partsWithCrossGroupOverlap.set(partId, maxPercentage)
                        debug && console.log(`        🔄 ${partId}: ${currentPercentage.toFixed(2)}% -> ${maxPercentage.toFixed(2)}%`)
                    }
                })

                if (hasChanges) {
                    // Calculate total needed for overlap
                    const totalNeededForOverlap = Array.from(partsWithCrossGroupOverlap.values())
                        .reduce((sum, percentage) => sum + percentage, 0)
                    const remainingPercentage = Math.max(0, 100 - totalNeededForOverlap)

                    debug && console.log(`        Need: ${totalNeededForOverlap.toFixed(2)}%, Remaining: ${remainingPercentage.toFixed(2)}%`)

                    // Apply adjustments
                    partsWithCrossGroupOverlap.forEach((requiredPercentage, partId) => {
                        currentPercentages.set(partId, requiredPercentage)
                    })

                    // Handle non-overlapping parts
                    const nonOverlappingParts = group.filter(id => {
                        const spatialInfo = spatialMaps.spatialRelationshipsByPartId.get(id)
                        return spatialInfo?.partType.toLowerCase().includes("tube")
                            && !partsWithCrossGroupOverlap.has(id)
                    })

                    if (nonOverlappingParts.length > 0 && remainingPercentage > 0) {
                        const originalNonOverlappingTotal = nonOverlappingParts.reduce((sum, partId) =>
                            sum + (originalPercentages.get(partId) || 0), 0)

                        nonOverlappingParts.forEach(partId => {
                            const originalPercentage = originalPercentages.get(partId) || 0
                            const proportion = originalPercentage / originalNonOverlappingTotal
                            const newPercentage = remainingPercentage * proportion
                            currentPercentages.set(partId, newPercentage)
                        })
                    } else if (nonOverlappingParts.length > 0) {
                        nonOverlappingParts.forEach(partId => {
                            currentPercentages.set(partId, 0)
                        })
                    }
                }
                debug && console.groupEnd()
            }

            if (iterations === MAX_ITERATIONS) {
                debug && console.warn("      ⚠️ Max iterations reached without convergence")
            }

            // Final summary
            debug && console.group("      📊 Final Percentages:")
            Array.from(currentPercentages.entries()).forEach(([partId, value,]) => {
                const originalValue = originalPercentages.get(partId) || 0
                if (Math.abs(originalValue - value) > 0.01) {
                    debug && console.log(`        ${partId}: ${originalValue.toFixed(2)}% -> ${value.toFixed(2)}%`)
                }
            })
            debug && console.groupEnd()
            debug && console.groupEnd()
        } else {
            debug && console.log("    ✅ No cross-group overlaps found")
        }

        // Show final changes summary for this group
        debug && console.group("\n    📊 Final Changes Summary:")
        Array.from(currentPercentages.entries()).forEach(([partId, finalValue,]) => {
            const originalValue = originalPercentages.get(partId) || 0
            if (Math.abs(originalValue - finalValue) > 0.01) {
                debug && console.log(`      ${partId}: ${originalValue.toFixed(2)}% -> ${finalValue.toFixed(2)}%`)
            }
        })
        debug && console.groupEnd()
        debug && console.groupEnd()
    })

    debug && console.groupEnd()
    return groupPercentages
}
