/* eslint-disable max-len */
import React, { useState, useRef } from "react"
import { Box, useCursor } from "@react-three/drei"
import { useThree, useFrame } from "@react-three/fiber"
import { Mesh, MeshBasicMaterial } from "three"

interface PointKey {
    partId: string;
    pointId: string;
    position: [number, number, number];
}

interface RulerPointCubeProps {
    position: [number, number, number];
    color: { r: number, g: number, b: number, };
    pointKey: PointKey;
    onClick: ({ partId, pointId, position, }: PointKey) => void;
    onPointerEnter?: (partId: string, pointId: string) => void;
    onPointerLeave?: (partId: string, pointId: string) => void;
    isSelected: boolean;
    partId: string;
    pointId: string;
    rulerDebug: boolean;
    hitAreaMaterial: MeshBasicMaterial;
    inactiveMaterial: MeshBasicMaterial;
    activeMaterial: MeshBasicMaterial;
    isMobile: boolean;
}

export function RulerPointCube({
    position,
    color,
    pointKey,
    onClick,
    onPointerEnter,
    onPointerLeave,
    isSelected,
    partId,
    pointId,
    rulerDebug,
    hitAreaMaterial,
    inactiveMaterial,
    activeMaterial,
    isMobile,
}: RulerPointCubeProps) {
    const [hovered, setHovered,] = useState(false)
    const visibleCubeRef = useRef<Mesh>(null)
    const hitAreaRef = useRef<Mesh>(null)
    const previousScaleRef = useRef<number>(1)
    const { camera, } = useThree()
    useCursor(hovered)

    // Base size for the visible cube
    const baseSize = 0.005
    const cubeSize = (hovered || isSelected) ? baseSize * 1.5 : baseSize
    const hitAreaMultiplier = 2.25
    const hitAreaSize = baseSize * hitAreaMultiplier

    useFrame(() => {
        if (visibleCubeRef.current) {
            const camDistance = camera.position.distanceTo(visibleCubeRef.current.position)

            // Define your scaling range
            const minDistance = 0    // start scaling from this distance
            const maxDistance = 20   // flatten out after this

            const minScale = 0.5
            const maxScale = 5

            // Remap distance into a 0..1 range
            const t = Math.min(1, Math.max(0, (camDistance - minDistance) / (maxDistance - minDistance)))

            // Use easing function to get diminishing returns (power easing out)
            const easedT = Math.pow(t, 0.7) // lower = more dramatic ramp-up, try 0.5–0.8

            const distanceScale = minScale + (maxScale - minScale) * easedT

            const scaleDifference = Math.abs(distanceScale - previousScaleRef.current)
            const SCALE_THRESHOLD = 0.01

            if (scaleDifference > SCALE_THRESHOLD) {
                visibleCubeRef.current.scale.setScalar(distanceScale)
                previousScaleRef.current = distanceScale
                if (hitAreaRef.current) {
                    hitAreaRef.current.scale.setScalar(distanceScale)
                }
            }
        }
    })

    const handlePointerOver = (e: any) => {
        onPointerEnter?.(partId, pointId)
        setHovered(true)
    }

    const handlePointerOut = (e: any) => {
        onPointerLeave?.(partId, pointId)
        setHovered(false)
    }

    const handleClick = () => {
        onClick({ partId, pointId, position, })
    }

    return (
        <>
            {/* Visible cube */}
            <Box
                ref={visibleCubeRef}
                position={position}
                args={[cubeSize, cubeSize, cubeSize,]}
                userData={{
                    rulerPoint: true,
                    partId,
                    pointId,
                    position,
                }}
                castShadow={false}
                receiveShadow={false}
                name="ruler-point-cube"
                onPointerOver={(e) => {
                    e.stopPropagation()
                    handlePointerOver(e)
                }}
                onPointerOut={(e) => {
                    e.stopPropagation()
                    handlePointerOut(e)
                }}
                onClick={(e) => {
                    e.stopPropagation()
                    handleClick()
                }}
                material={isSelected || hovered ? activeMaterial : inactiveMaterial}
            />
            {isMobile && (
                <Box
                    ref={hitAreaRef}
                    position={position}
                    args={[hitAreaSize, hitAreaSize, hitAreaSize,]}
                onClick={(e) => {
                    e.stopPropagation()
                    handleClick()
                }}
                visible={rulerDebug}
                userData={{
                    rulerPoint: true,
                }}
                    name="ruler-point-cube"
                    onPointerOver={handlePointerOver}
                    onPointerOut={handlePointerOut}
                    material={hitAreaMaterial}
                />
            )}
        </>
    )
}