/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/require-array-sort-compare */
/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
/* eslint-disable max-len */
/* eslint-disable max-statements */
import { query, Timestamp, where, limit, orderBy } from "firebase/firestore"
import { EnvHelper } from "../../utils/EnvHelper"
import { ConnectionTypeAPI, DesignTypeAPI, SizeAPI } from "../Types"
import { captureException } from "@sentry/react"
import {
    getCollection,
    GetDataFromFirebase,
    getDatumFromFirebase,
    getUser,
    saveDataToFirebase,
    setDocInFirebase,
    updateInFirebase
} from "./firebaseUtils"
import { Euler, MathUtils, Quaternion, Vector3 } from "three"
import { innerToOuter, normalizeDirectionalName, outerToInner } from "../../../builder/utils/MarkerUtil"
import { ObjDictionary } from "../../utils/utils"
import pako from "pako"
import { breadcrumb } from "../../utils/sentrySetup"
import { decompressDesign } from "../compression"
import { compressDesign } from "../compression"

const PREPATH = "Users"
const PATH = "Designs"
const SIMPLIFIED_PATH = "SimplifiedDesigns"

const getDesignPath = (userId: string) => {
    return `${PREPATH}/${userId}/${PATH}`
}

const getSimplifiedDesignPath = (userId: string) => {
    return `${PREPATH}/${userId}/${SIMPLIFIED_PATH}`
}

const removeUnderScoreAndWhatFollows = (name: string) => {
    return name.replace(/_.*$/g, "")
}

export interface SimplifiedDesignStateOutput {
    simplifiedDesign: string;
    simplifiedInstructions: string[];
    groupedVersion?: string;
}

const cleanNameOtherWay = (name: string): string => {
    return name.replace(/M/g, "Male").replace(/F/g, "Female")
}

interface PartsDataParsed {
    parts: {
        [key: string]: {
            positioned?: boolean,
            possibleRotations?: { angles: { x: number, y: number, z: number, }, markerPositions: { originalName: string, facing: string, initialMarkerWorldQuat?: Quaternion, }, }[],
            name: string,
            newPartId?: string,
            apiTypeId?: string,
            markerPositions?: { originalName: string, facing: string, initialMarkerWorldQuat?: Quaternion, }[],
            rotation?: { x: number, y: number, z: number, },
            rotationQuaternion?: Quaternion,
            position?: Vector3,
            length?: string,
            skippedTimes?: number,
            connectors?: {
                name: string,
                ref?: string,
                facing: string,
                positionXYZ?: {
                    x: number,
                    y: number,
                    z: number,
                },
                connection?: {
                    partId: string,
                    name: string,
                },
                sizeId?: string,
                typeId?: string,
            }[],
        },
    };
    name: string;
}

const parseTextToJson = (textInput: string[] | string): PartsDataParsed & { partNames: string[], } => {
    // Convert input to array of lines
    const textLines = Array.isArray(textInput)
        ? textInput
        : textInput.replace(/^(Instructions|instructions):?\s*/i, "").split("\n")
            .filter(line => line.trim())

    const parts: PartsDataParsed["parts"] = {}
    const partNames: string[] = []

    textLines.forEach(line => {
        // Parse part ID and base info
        const partMatch = line.match(/^P(\d+)\s*\((.*?)\)(.*)$/)
        if (!partMatch) { return }

        const [_, partId, partInfo, connectionInfo,] = partMatch

        // Parse part name and length
        const lengthMatch = partInfo.match(/(.*?)(?:\s+(\d+\.?\d*(?:"|in)?)\s+length)$/)
        const partName = lengthMatch ? lengthMatch[1].trim() : partInfo.trim()
        const part: PartsDataParsed["parts"][string] = {
            name: cleanNameOtherWay(partName),
        }

        // Add to parts array
        partNames.push(cleanNameOtherWay(partName))

        if (lengthMatch) {
            part.length = lengthMatch[2]
        }

        // Parse connections
        // Parse connections
        if (connectionInfo) {
            // Remove leading dash and clean up
            const cleanedConnectionInfo = connectionInfo.replace(/^\s*-\s*/, "")

            // Split on "and" first
            const mainConnections = cleanedConnectionInfo.split(/,\s*and\s*/)

            // Process each main connection
            part.connectors = mainConnections.map(conn => {
                const connText = conn.trim()

                // Check if this is a "should face" instruction
                if (connText.match(/its .* should face/i)) {
                    const shouldFaceMatch = connText.match(/its (.*?) should face (.*?)(?:\s*$|,|$)/i)
                    if (shouldFaceMatch) {
                        const [__, connectorName, facing,] = shouldFaceMatch
                        return {
                            name: cleanNameOtherWay(connectorName.trim()),
                            facing: facing.trim(),
                        }
                    }
                }

                // Check if this is a connection instruction
                if (connText.match(/connect|connects to/i)) {
                    // Add "connect" prefix if it's missing (for parts after "and")
                    const normalizedConnText = connText.startsWith("connect") ? connText : `connect ${connText}`

                    // Extract the actual connection info
                    const connectionMatch = normalizedConnText.match(/connect (?:its )?(.*?) facing (.*?) connects to part (\d+)'s (.*?)(?:\s*$|,|$)/i)
                    if (connectionMatch) {
                        const [__, connectorName, facing, targetPartId, targetConnectorName,] = connectionMatch
                        return {
                            name: cleanNameOtherWay(connectorName.trim()),
                            facing: facing.trim(),
                            connection: {
                                partId: targetPartId,
                                name: cleanNameOtherWay(targetConnectorName.trim()),
                            },
                        }
                    }
                }

                console.log("Failed to match connection or facing instruction:", connText)
                return null
            }).filter((conn): conn is { name: string, facing: string, connection?: { partId: string, name: string, }, } => conn !== null)

            // Debug log
            console.log(`Part ${partId} connections:`, part.connectors)
        }

        parts[partId] = part
    })

    const getOppositeFacing = (direction: string): string => {
        if (!direction || direction === "unknown") { return direction }

        const parts = direction.split("-")
        const oppositeArray = parts.map(part => {
            switch (part.toLowerCase()) {
                case "left": return "right"
                case "right": return "left"
                case "top": return "bottom"
                case "bottom": return "top"
                case "front": return "back"
                case "back": return "front"
                default: return part
            }
        })

        return normalizeDirectionalName(oppositeArray.join("-"))
    }

    Object.entries(parts).forEach(([partId, part,]) => {
        if (part.connectors) {
            part.connectors.forEach(connector => {
                if (!connector.connection || !connector.connection.partId || !connector.connection.name) { return }
                const targetPart = parts[connector.connection.partId]
                if (!targetPart) { return }

                // Check if the target part already has this connector

                const existingConnector = targetPart.connectors?.find(
                    c => connector.connection && c.name === connector.connection.name
                )

                if (!existingConnector) {
                    // Create the connector array if it doesn't exist
                    if (!targetPart.connectors) {
                        targetPart.connectors = []
                    }

                    const targetPartId = Object.entries(parts).find(([_, p,]) => p === targetPart)?.[0]

                    console.log("creating connector", connector.connection.name, "facing", getOppositeFacing(connector.facing), "in part", targetPartId)

                    // Add the referenced connector without a connection
                    targetPart.connectors.push({
                        name: connector.connection.name,
                        facing: getOppositeFacing(connector.facing), // Optional: infer opposite facing
                    })
                }
            })
        }
    })

    const processedConnections = new Set()

    // First pass - identify all connections and mark the first occurrence
    Object.entries(parts).forEach(([partId, part,]) => {
        if (part.connectors) {
            part.connectors.forEach((connector: any) => {
                if (connector.connection) {
                    // Create a unique key for this connection pair
                    // Sort the IDs to ensure same connection has same key regardless of direction
                    const connectionPair = [
                        `${partId}-${connector.name}`,
                        `${connector.connection.partId}-${connector.connection.name}`,
                    ].sort().join("|")

                    if (processedConnections.has(connectionPair)) {
                        // If we've seen this connection before, remove it
                        delete connector.connection
                    } else {
                        // First time seeing this connection, keep it
                        processedConnections.add(connectionPair)
                    }
                }
            })
        }
    })



    return {
        parts,
        name: "Parsed Structure",
        partNames: partNames,
    }
}

const simplifiedDesignState = (
    designAsString: string,
    componentFunction?: (partId: string) => void,
    connectionTypes?: ObjDictionary<ConnectionTypeAPI>,
    sizes?: ObjDictionary<SizeAPI>,
    simplifiedDesignState?: SimplifiedDesignStateOutput,
): SimplifiedDesignStateOutput | null => {
    //console.log(designAsString, "designAsString")
    try {
        // Parse the design state string to JSON
        const design = JSON.parse(designAsString)

        //console.log(connectionTypes, "connectionTypes from simplifiedDesignState")
        //console.log(sizes, "sizes from simplifiedDesignState")

        console.log(design, "design, before processing")

        // Clean parts
        if (design.parts) {
            for (const partKey in design.parts) {
                if (Object.prototype.hasOwnProperty.call(design.parts, partKey)) {
                    const part = design.parts[partKey]

                    // console.log(part, "part, simplifiedDesignState")

                    if (part.type.includes("segmented")) {
                        if (part.unitRealValue) {
                            part.length = part.unitRealValue
                            delete part.unitRealValue
                        }
                        else if (!part.unitRealValue && (part.startSegmentLength && part.endSegmentLength) && part.segmentLength) {
                            const endsAdded = (part.startSegmentLength as number) + (part.endSegmentLength as number)
                            const negAndPos = (part.lengthNegativeSide as number ?? 0) + (part.length as number)
                            const calculatedLength = ((negAndPos) * (part.segmentLength as number)) + endsAdded
                            part.length = calculatedLength
                        }
                    }

                    if (part.type.includes("segmented")) {
                        part.lengthUnits = part.partUnits ?? "in"
                        delete part.partUnits
                    }

                    if (part.modifiedWidth) {
                        part.width = part.modifiedWidth
                        delete part.modifiedWidth
                    }

                    if (part.modifiedHeight) {
                        part.height = part.modifiedHeight
                        delete part.modifiedHeight
                    }

                    if (part.modifiedWidthUnits) {
                        part.widthUnits = part.modifiedWidthUnits
                        delete part.modifiedWidthUnits
                    }

                    if (part.modifiedHeightUnits) {
                        part.heightUnits = part.modifiedHeightUnits
                        delete part.modifiedHeightUnits
                    }



                    // Remove specified keys from each part which we can populate from our db
                    const keysToRemove = [
                        "defaultLength", "unit", "fileURL", "segmentLength",
                        "rotationSteps", "rotationDisabled", "endSegmentLength",
                        "startSegmentLength", "color", "position", "loaded", "specialRotationMarker",
                        "maxMiddles", "minMiddles", "lengthNegativeSide", "oppositeOf", "initialMarkerName",
                        "realHeight", "realWidth", "maxLength", "heightScaling", "widthScaling",
                        "baseName", "userRotation", "markerOffset", "scaledSegmentLength",
                        "segmentScaleFactor", "duplicatedFrom", "type", "instanciated",
                        "unitRealValue", "direction", "diameter", "opacity", "angle", "isCreated",
                    ]

                    //account for regular tubes
                    if (part.originMarkerName) {
                        part.rotationMarkerName = part.originMarkerName
                        part.initialMarkerName = part.originMarkerName
                        delete part.originMarkerName
                    }

                    //fix bad names for inches
                    if (part.name && part.name.includes("'")) {
                        part.name = part.name
                            .replace(/''/g, '"')
                            .replace(/\\/g, "")  // Remove all backslashes
                    }

                    // Convert rotation to radians if exists
                    if (part.rotation) {
                        //console.log(part.rotation, "part.rotation")
                        const rotationQuartenian = new Quaternion(part.rotation.x, part.rotation.y, part.rotation.z, part.rotation.w)
                        const rotationEuler = new Euler().setFromQuaternion(rotationQuartenian)

                        const x = Math.round(MathUtils.radToDeg(rotationEuler.x))
                        const y = Math.round(MathUtils.radToDeg(rotationEuler.y))
                        const z = Math.round(MathUtils.radToDeg(rotationEuler.z))

                        part.rotation = {
                            x,
                            y,
                            z,
                        }
                    }

                    // Handle marker name changes
                    if (part.rotationMarkerName) {
                        // If rotationMarkerName exists, remove initialMarkerName
                        //delete part.initialMarkerName
                    } else if (part.initialMarkerName && part.type.includes("segmented")) {
                        // If no rotationMarkerName but initialMarkerName exists, rename it
                        part.rotationMarkerName = part.initialMarkerName
                        delete part.initialMarkerName
                    }

                    //account for names like inner3_0
                    if (part.type.includes("segmented")) {
                        part.rotationMarkerName = removeUnderScoreAndWhatFollows(outerToInner(part.rotationMarkerName))
                    }

                    let rotatedRotationFriendlyNamesToUse: { originalName: string, facing: string, }[] = [{ originalName: "", facing: "", },]

                    const partFunctions = componentFunction?.(part.id)
                    if (partFunctions && (partFunctions as any).getFacingDirectionOfMarkers) {
                        const rotatedRotationFriendlyNames = (partFunctions as any).getFacingDirectionOfMarkers(undefined, false, false)
                        //console.log(friendlyNamesForMarkers, "friendlyNamesForMarkers")
                        rotatedRotationFriendlyNamesToUse = rotatedRotationFriendlyNames

                        console.log(rotatedRotationFriendlyNamesToUse, "rotatedRotationFriendlyNamesToUse")
                        //console.log(originalRotationFriendlyNamesToUse, "originalRotationFriendlyNamesToUse")
                    }

                    //another special case for regular tubes
                    if (part.marker && !part.markers) {
                        console.log(part.marker, "part.marker")
                        //part.markers = [{ ref: part.marker.name, name: part.marker.name, sizeId: part.marker.sizeId, id: part.marker.id, },]
                        const partFunctions = componentFunction?.(part.id)
                        if (partFunctions && (partFunctions as any).getAllMarkers) {
                            const markers = (partFunctions as any).getAllMarkers(false)
                            part.markers = [{ ref: markers[0].name, name: markers[0].name, sizeId: part.marker.sizeId, id: part.marker.id, }, { ref: markers[1].name, name: markers[1].name, sizeId: part.marker.sizeId, id: part.marker.id, },]
                            //console.log(markers, "markers for regular tubes")
                        }
                    }

                    if (part.marker) {
                        delete part.marker
                    }

                    console.log(part.markers, "part.markers")

                    // Clean markers if they exist
                    if (part.markers) {
                        //const markersArray: string[] = []
                        part.markers = part.markers.map((marker: any) => {
                            const markerKeysToRemove = [
                                "id", "sizeId", "iELenght", "position",
                                "lateralFacing", "boundary", "fullLeg",
                                "oppositeOf",
                            ]

                            if (rotatedRotationFriendlyNamesToUse.length > 0) {
                                //console.log(marker.name, "marker.name")
                                //find marker inside friendlyNamesForMarkers where originalName === marker.name
                                const friendlyNameMarkerForRotatedOrientation = rotatedRotationFriendlyNamesToUse.find((friendlyName: any) => friendlyName.originalName.includes(innerToOuter(marker.name)))
                                if (friendlyNameMarkerForRotatedOrientation) {
                                    marker.ref = marker.name
                                    marker.facing = friendlyNameMarkerForRotatedOrientation.facing
                                }
                            }

                            if (connectionTypes) {
                                const connectionType = connectionTypes[marker.id]
                                if (connectionType) {
                                    marker.name = connectionType.connectionName
                                }
                            }

                            if (sizes) {
                                const size = sizes[marker.sizeId]
                                if (size) {
                                    marker.name = `${size.friendlyIntegerName || size.friendlyName} ${marker.name}`
                                }
                            }

                            markerKeysToRemove.forEach(key => delete marker[key])


                            //markersArray.push(removeUnderScoreAndWhatFollows(outerToInner(marker.name)))
                            return marker
                        })
                        //part.markers = markersArray
                    }



                    //add the rotation value under the rotationMarkerName
                    //find marker inside part.markers with name === rotationMarkerName
                    let rotationMarker: any | undefined

                    if (part.type.includes("segmented")) {
                        rotationMarker = part.markers?.find((marker: any) => marker.ref === part.rotationMarkerName)
                    } else if (part.type.includes("connector")) {
                        rotationMarker = part.markers?.find((marker: any) => marker.ref === outerToInner(part.rotationMarkerName))
                    } else if (part.type.includes("tube")) {
                        //console.log(part.initialMarkerName, "part.initialMarkerName")
                        //console.log(part.markers, "part.markers")
                        rotationMarker = part.markers?.find((marker: any) => marker.ref === part.initialMarkerName)
                    }

                    if (rotationMarker) {
                        //rotationMarker.rotation = part.rotation
                    }


                    delete part.rotation
                    delete part.rotationMarkerName


                    // remove the keys
                    keysToRemove.forEach(key => delete part[key])

                    if (part.length) {
                        part.length = `${part.length}"`
                    }



                }
            }
        }

        // Clean connections
        if (design.connections) {
            design.connections = design.connections.map((connection: any) => {
                if (connection.partA) {
                    delete connection.partA.slidePosition
                    delete connection.partA.connectionLength
                }
                if (connection.partB) {
                    delete connection.partB.slidePosition
                    delete connection.partB.connectionLength
                }
                return connection
            })
        }

        // Remove partsIds and unit
        delete design.partsIds
        delete design.unit

        // Create a mapping of old IDs to new sequential numbers to keep the json smaller and avoid
        // ai getting confused
        const idMapping: { [key: string]: string, } = {}
        let counter = 1

        // First pass: create mapping
        if (design.parts) {
            for (const partKey in design.parts) {
                if (Object.prototype.hasOwnProperty.call(design.parts, partKey)) {
                    idMapping[partKey] = counter.toString()
                    counter++
                }
            }

            // Second pass: replace IDs
            const newParts: { [key: string]: any, } = {}
            for (const partKey in design.parts) {
                if (Object.prototype.hasOwnProperty.call(design.parts, partKey)) {
                    const newKey = idMapping[partKey]
                    newParts[newKey] = design.parts[partKey]
                }
            }

            //get component function using the part id from design.parts
            Object.entries(design.parts).forEach(([oldPartId, part,]: [string, any]) => {
                const partFunctions = componentFunction?.(oldPartId)
                if (partFunctions) {
                    const newPartId = idMapping[oldPartId]
                        // Use the new part ID as the last parameter
                        ; (partFunctions as any).getFacingDirectionOfMarkers(undefined, true, false, newPartId)
                }
            })

            design.parts = newParts

            // Update connections if they exist
            if (design.connections) {
                design.connections = design.connections.map((connection: any) => {
                    if (connection.partA && connection.partA.partId) {
                        connection.partA.partId = idMapping[connection.partA.partId]
                        connection.partA.markerName = removeUnderScoreAndWhatFollows(outerToInner(connection.partA.markerName))
                    }
                    if (connection.partB && connection.partB.partId) {
                        connection.partB.partId = idMapping[connection.partB.partId]
                        connection.partB.markerName = removeUnderScoreAndWhatFollows(outerToInner(connection.partB.markerName))
                    }
                    return connection
                })
            }
        }

        const findConnection = (markerName: string, partId: string, connections: any[]) => {
            //console.log(connections, "design.connections", markerName, partId)
            const connection = connections?.find((connection: any) => {
                const partAMatch = connection.partA?.partId === partId && connection.partA?.markerName === markerName
                const partBMatch = connection.partB?.partId === partId && connection.partB?.markerName === markerName
                return partAMatch || partBMatch
            })

            if (!connection) {
                console.warn("No connection found", { partId, markerName, })
                return null
            }

            // If we matched with partA, return partB details, and vice versa
            if (connection.partA?.partId === partId) {
                if (!connection.partB.markerName) {
                    console.warn("No marker name on partB", { connection, })
                }
                return {
                    partId: connection.partB?.partId,
                    markerName: connection.partB?.markerName,
                    ...(connection.partB?.relativeSlidePosition && {
                        relativeSlidePosition: connection.partB.relativeSlidePosition,
                    }),
                }
            } else {
                if (!connection.partA?.markerName) {
                    console.warn("No marker name on partA", { connection, })
                }
                return {
                    partId: connection.partA?.partId,
                    markerName: connection.partA?.markerName,
                    ...(connection.partA?.relativeSlidePosition && {
                        relativeSlidePosition: connection.partA.relativeSlidePosition,
                    }),
                }
            }
        }

        Object.entries(design.parts).forEach(([partId, part,]: [string, any]) => {
            //console.log(part, "part")
            if (part.markers) {
                part.markers.forEach((marker: any) => {
                    const connection = findConnection(marker.ref, partId, design.connections)
                    //console.log(connection, "connection")
                    if (connection) {
                        marker.connection = connection
                        if (!connection.markerName) {
                            console.warn("No marker name on connection", { connection, })
                        }
                    }
                })
            }
        })



        Object.entries(design.parts).forEach(([partId, part,]: [string, any]) => {
            if (part.markers) {
                const markerNameCount: { [key: string]: number, } = {}

                part.markers.forEach((marker: any) => {
                    if (marker.name) {
                        markerNameCount[marker.name] = (markerNameCount[marker.name] || 0) + 1
                        if (markerNameCount[marker.name] >= 1) {  // Changed from > 1 to >= 1
                            marker.name = `${marker.name} #${markerNameCount[marker.name]}`  // Removed the - 1
                        }
                    }
                })
            }
        })

        // Add names to connections in markers
        Object.entries(design.parts).forEach(([partId, part,]: [string, any]) => {
            if (part.markers) {
                part.markers.forEach((marker: any) => {
                    if (marker.connection) {
                        const connectedPart = design.parts[marker.connection.partId]
                        if (connectedPart && connectedPart.markers) {
                            const connectedMarker = connectedPart.markers.find(
                                (m: any) => m.ref === marker.connection.markerName
                            )
                            //console.log(connectedMarker, "connectedMarker")
                            if (connectedMarker) {
                                if (!connectedMarker.name) {
                                    console.warn("No marker name on connected marker", { connectedMarker, })
                                }
                                //console.log(connectedMarker.name, "connectedMarker.name")
                                marker.connection.name = connectedMarker.name
                            }
                        }
                        delete marker.connection.markerName //remove if you want to debug
                    }
                })
                part.connectors = part.markers //to make it more readable
            }
        })



        const replaceQuotesWithIn = (obj: any): any => {
            if (typeof obj === "string") {
                // Replace quotes with 'in'
                return obj.replace(/['"]/g, "in")
            }

            if (Array.isArray(obj)) {
                return obj.map(item => replaceQuotesWithIn(item))
            }

            if (typeof obj === "object" && obj !== null) {
                const newObj: any = {}
                for (const key in obj) {
                    if (Object.prototype.hasOwnProperty.call(obj, key)) {
                        newObj[key] = replaceQuotesWithIn(obj[key])
                    }
                }
                return newObj
            }

            return obj
        }

        //delete part.markers
        Object.entries(design.parts).forEach(([partId, part,]: [string, any]) => {
            delete part.markers
        })

        delete design.connections

        const unconnectedConnectors: { partId: string, connector: any, }[] = []

        // Add these functions before the final design cleanup
        const removeReciprocalConnections = (design: any) => {
            // Create a set to track processed connections
            const processedConnections = new Set()
            // Track connectors without any connections

            Object.entries(design.parts).forEach(([partId, part,]: [string, any]) => {
                if (part.connectors) {
                    part.connectors.forEach((connector: any) => {
                        if (connector.connection) {
                            const connectionKey = [partId, connector.connection.partId,].sort().join("-")

                            // If we've already processed this connection, remove it
                            if (processedConnections.has(connectionKey)) {
                                delete connector.connection
                                // Don't add to unconnected since it has a reciprocal connection
                            } else {
                                processedConnections.add(connectionKey)
                            }
                        } else {
                            // This connector has no connection at all
                            unconnectedConnectors.push({ partId, connector, })
                        }
                    })
                }
            })

            return design
        }

        const removeEmptyConnectors = (design: any) => {
            Object.entries(design.parts).forEach(([partId, part,]: [string, any]) => {
                if (part.connectors) {
                    // Keep connectors that either have a connection or are in unconnectedConnectors
                    part.connectors = part.connectors.filter((connector: any) => {
                        const isInUnconnectedList = unconnectedConnectors.some(
                            uc => uc.partId === partId && uc.connector.name === connector.name
                        )
                        return connector.connection || isInUnconnectedList
                    })

                    // If no connectors left, remove the connectors array
                    if (part.connectors.length === 0) {
                        delete part.connectors
                    }
                }
            })

            return design
        }

        // In your existing code, before the final cleanup and stringification:
        Object.entries(design.parts).forEach(([partId, part,]: [string, any]) => {
            delete part.markers
        })

        const removeRefKeysFromConnectors = (design: any) => {
            //console.log(design, "design")
            Object.entries(design.parts).forEach(([partId, part,]: [string, any]) => {
                if (part.connectors) {
                    part.connectors = part.connectors.map((connector: any) => {
                        const { ref, ...connectorWithoutRef } = connector
                        return connectorWithoutRef
                    })
                }
            })
            return design
        }

        console.log(design, "design being passed to removeReciprocalConnections")




        //const designWithReciprocalConnections = removeReciprocalConnections(design)
        //const designWithEmptyConnectors = removeEmptyConnectors(designWithReciprocalConnections)
        //const designWithRefKeysRemovedFromConnectors = removeRefKeysFromConnectors(designWithEmptyConnectors)
        //console.log(designWithRefKeysRemovedFromConnectors, "designWithRefKeysRemovedFromConnectors")
        //const designWithInUnits = replaceQuotesWithIn(designWithRefKeysRemovedFromConnectors)


        interface Connector {
            name: string;
            facing: string;
            connection?: {
                partId: string,
                name: string,
            };
        }

        interface Part {
            name: string;
            length?: string;
            connectors?: Connector[];
        }

        interface PartsData {
            parts: {
                [key: string]: Part,
            };
            name: string;
        }

        /*const cleanSizeInName = (name: string): string => {
            const match = name.match(/^([\d\/\.\-]+)in\s+(.+)$/)
            if (!match) { return name }
            return `${match[1]}-inch ${match[2]}`
        }*/

        const cleanName = (name: string): string => {
            console.log(name, "name")
            return name.replace(/Male|MALE/g, "M").replace(/Female/g, "F")
        }

        const convertFractionalToDecimal = (input: string): string => {
            // Match patterns for mixed numbers, fractions, and decimals with various unit notations
            const pattern = /(\d+)?[-\s]?(?:(\d+)\/(\d+)|½)(?:\s*(?:in(?:ch(?:es)?)?|"|″))?|\d+\.?\d*\s*(?:in(?:ch(?:es)?)?|"|″)/i

            return input.replace(pattern, (match) => {
                // Remove units and extra spaces
                const numberPart = match.replace(/\s*(?:in(?:ch(?:es)?)?|"|″)\s*/i, "")

                // Handle special case for ½
                if (numberPart.includes("½")) {
                    const wholeNumber = numberPart.replace("½", "")
                    const value = wholeNumber ? parseFloat(wholeNumber) + 0.5 : 0.5
                    return `${value}"`
                }

                // Handle fractions like "1-1/2" or "1 1/2"
                if (numberPart.includes("/")) {
                    const parts = numberPart.split(/[-\s]/)
                    if (parts.length === 2) {
                        // Mixed number
                        const [whole, fraction,] = parts
                        const [numerator, denominator,] = fraction.split("/")
                        const value = parseFloat(whole) + parseFloat(numerator) / parseFloat(denominator)
                        return `${value}"`
                    } else {
                        // Simple fraction
                        const [numerator, denominator,] = numberPart.split("/")
                        const value = parseFloat(numerator) / parseFloat(denominator)
                        return `${value}"`
                    }
                }

                // Handle decimal numbers
                const value = parseFloat(numberPart)
                return `${value}"`
            })
        }

        const convertToText = (data: PartsData): string[] => {
            return Object.entries(data.parts).map(([id, part,]) => {
                const desc = [`P${id} (${cleanName(convertFractionalToDecimal(part.name))}${part.length ? ` ${part.length} length` : ""})`,]

                if (part.connectors && part.connectors.length > 0) {
                    const connectionsWithTarget = part.connectors
                        .filter(conn => conn.connection)
                        .map(conn => `connect its ${cleanName(conn.name)} facing ${conn.facing} connects to part ${conn.connection!.partId}'s ${cleanName(conn.connection!.name)}`)
                        .filter(Boolean)

                    const connectionsWithoutTarget = part.connectors
                        .filter(conn => !conn.connection)
                        .map(conn => `its ${cleanName(conn.name)} should face ${conn.facing}`)
                        .filter(Boolean)

                    if (connectionsWithTarget.length > 0) {
                        desc.push(`- ${connectionsWithTarget.join(", and ")}`)
                    }

                    if (connectionsWithoutTarget.length > 0) {
                        const prefix = connectionsWithTarget.length > 0 ? ", and " : "- "
                        desc.push(`${prefix}${connectionsWithoutTarget.join(", and ")}`)
                    }
                }

                return desc.join(" ")
            })
        }


        const simplifiedDesignStateToUse = simplifiedDesignState ?? design

        console.log(simplifiedDesignStateToUse, "simplifiedDesignStateToUse")

        const addGroupsAsKey = (design: any, idMapping: { [key: string]: string, }) => {
            if (!design.groups || !design.parts) { return design }

            // Create a mapping of part IDs to their group names
            const partToGroupMap: { [key: string]: string, } = {}

            design.groups.forEach((group: any) => {
                group.partIds.forEach((originalPartId: string) => {
                    const newId = Object.entries(idMapping).find(([origId,]) => origId === originalPartId)?.[1]
                    if (newId) {
                        partToGroupMap[newId] = group.name
                    }
                })
            })

            // Define a type for the design object
            interface DesignWithGroups {
                name: string;
                parts: Record<string, any>;
                [key: string]: any;  // Allow additional properties
            }

            // Create new design object with explicit typing
            const designWithGroups: DesignWithGroups = {
                name: design.name,
                parts: Object.entries(design.parts).reduce((acc, [partId, part,]) => ({
                    ...acc,
                    [partId]: {
                        ...(part as object),
                        ...(partToGroupMap[partId] ? { group: partToGroupMap[partId], } : {}),
                    },
                }), {}),
            }

            // Copy remaining properties
            Object.keys(design).forEach(key => {
                if (key !== "parts" && key !== "groups") {
                    designWithGroups[key] = design[key]
                }
            })

            delete designWithGroups.groups

            return designWithGroups
        }

        // Then replace the groupedVersion assignment with:
        const groupedVersionWithKeys = addGroupsAsKey(design, idMapping)
        console.log(groupedVersionWithKeys, "groupedVersionWithKeys")

        //const groupedVersion = createGroupedVersion(design, idMapping)
        //console.log(groupedVersion, "groupedVersion")

        const simplifiedInstructions = convertToText(simplifiedDesignStateToUse)
        //const joinedInstructions = simplifiedInstructions.join("\n")
        //console.log(joinedInstructions, "joinedInstructions")

        console.log("Cleaned design state:", JSON.stringify(design, null, 2))

        const simplifiedDesign = JSON.stringify(design)

        const reversedSanityCheck = parseTextToJson(simplifiedInstructions)
        console.log(reversedSanityCheck, "reversedSanityCheck")

        return { simplifiedDesign, simplifiedInstructions, groupedVersion: JSON.stringify(groupedVersionWithKeys), }
    } catch (error) {
        console.error("Error cleaning design state:", error)
        return null
    }
}

// const compressDesign = (designAsString: string, shouldLog = true) => {
//     if (shouldLog) {
//         console.time("compressDesign")
//     }
//     breadcrumb({
//         level: "info",
//         message: "compressDesign",
//     })
//     try {
//         const encoder = new TextEncoder()
//         const data = encoder.encode(designAsString)
//         const compressed = pako.deflate(data)
//         // Convert compressed bytes to base64 string
//         // const base64Encoded = btoa(String.fromCharCode(...compressed))
//         const base64Encoded = btoa(String.fromCharCode.apply(null, Array.from(compressed)))
//         return base64Encoded
//     } catch (error) {
//         console.error("Error compressing design:", error)
//         captureException(error, {
//             extra: { message: "Error compressing design", },
//         })
//         return designAsString
//     } finally {
//         if (shouldLog) {
//             console.timeEnd("compressDesign")
//         }
//     }
// }

// const decompressDesign = (encodedDesign: string, shouldLog = true) => {
//     breadcrumb({
//         level: "info",
//         message: "decompressDesign",
//     })
//     if (shouldLog) {
//         console.time("decompressDesign")
//     }
//     try {
//         const base64Decoded = atob(encodedDesign)
//         const charData = Uint8Array.from(base64Decoded.split("").map(c => c.charCodeAt(0)))
//         const decompressedData = pako.inflate(charData)
//         const decoder = new TextDecoder()
//         return decoder.decode(decompressedData)
//     } catch (error) {
//         console.error("Error decompressing design:", error)
//         captureException(error, {
//             extra: { message: "Error decompressing design", },
//         })
//         return encodedDesign
//     } finally {
//         if (shouldLog) {
//             console.timeEnd("decompressDesign")
//         }
//     }
// }

// const compressionLogs = (designAsString: string, compressedDesign: string) => {
//     const decompressedDesign = decompressDesign(compressedDesign)
//     const equal = designAsString === decompressedDesign
//     // console.log("Desings are equal?", equal)
//     if (!equal) {
//         captureException("Compressed design is not equal to original design at compressionLogs")
//     }
//     const originalSize = new TextEncoder().encode(designAsString).length
//     console.log("Original design size (bytes):", originalSize)

//     // Compressed design (base64 encoded)
//     const compressedSize = new TextEncoder().encode(compressedDesign).length
//     console.log("Compressed design size (bytes):", compressedSize)

//     // Optional: Compare compression ratio
//     const ratio = ((compressedSize / originalSize) * 100).toFixed(2)
//     console.log(`Compression ratio: ${ratio}%`)
// }

// const compressedCheck = (designAsString: string) => {
//     breadcrumb({
//         level: "info",
//         message: "compressedCheck",
//     })

//     console.log("designAsString", designAsString)

//     const compressedDesign = compressDesign(designAsString, false)
//     const decompressedDesign = decompressDesign(compressedDesign, false)
//     const equal = designAsString === decompressedDesign
//     // console.log("Desings are equal?", equal)
//     if (!equal) {
//         captureException("Compressed design is not equal to original design at check")
//     }
//     return compressedDesign
// }


const saveDesign = async (
    designAsString: string, screenshot: string, name?: string, id?: string, unit?: "cm" | "in", componentFunction?: () => void, connectionTypes?: ObjDictionary<ConnectionTypeAPI>, sizes?: ObjDictionary<SizeAPI>, shouldCompress?: boolean,
) => {
    // if (shouldCompress) {
    //     console.log("saving compressed design")
        return await saveDesignCompressed(designAsString, screenshot, name, id, unit, componentFunction, connectionTypes, sizes)
    // } else {
        // return await saveDesignOld(designAsString, screenshot, name, id, unit, componentFunction, connectionTypes, sizes)
    // }
    // return await saveDesignOld(designAsString, screenshot, name, id, unit, componentFunction, connectionTypes, sizes)

}

const saveDesignOld = async (
    designAsString: string, screenshot: string, name?: string, id?: string, unit?: "cm" | "in", componentFunction?: () => void, connectionTypes?: ObjDictionary<ConnectionTypeAPI>, sizes?: ObjDictionary<SizeAPI>,
) => {
    const user = getUser()!
    const actualTimestamp = Timestamp.now()
    const designParsed = JSON.parse(designAsString)
    const numberOfParts = Object.keys(designParsed.parts).length
    //const result = simplifiedDesignState(designAsString, componentFunction, connectionTypes, sizes)
    //if (!result) {
    //    throw new Error("Error cleaning design state")
    //}
    //const { simplifiedDesign, simplifiedInstructions, groupedVersion, } = result

    //console.log(simplifiedInstructions, "simplifiedInstructions early")
    //const joinedInstructions = simplifiedInstructions.join("\n")
    //console.log(joinedInstructions, "simplifiedInstructions formatted")

    const data: Omit<DesignTypeAPI,
        "id" | "creationDate" | "creationBuildId" | "name" | "currentSessionId"
    > = {
        state: designAsString,
        imageBase64: screenshot,
        modificationDate: actualTimestamp,
        modificationBuildId: EnvHelper.buildName,
        userId: user.uid,
        deleted: false,
        unit: unit || "in",
        email: user.email || undefined,
        isCompressed: false,
        numberOfParts: numberOfParts,
    }

    /*const groupedDesignData = {
        ...data,
        state: groupedVersion,
        instructions: joinedInstructions,
    }*/

    if (id) {

        //console.log(getDesignPath(user.uid), "getDesignPath")
        //console.log(getSimplifiedDesignPath(user.uid), "getSimplifiedDesignPath")
        //console.log(groupedDesignData, "groupedDesignData")

        await updateInFirebase(getDesignPath(user.uid), id, data,)
        //await updateInFirebase(getSimplifiedDesignPath(user.uid), id, groupedDesignData,)
        setTimeout(() => {
            // compressedCheck(designAsString)
        }, 0)
        return id
    } else {
        //console.log(data, "data")
        //console.log(groupedDesignData, "groupedDesignData")
        const savedData = await saveDataToFirebase(
            getDesignPath(user.uid),
            {
                ...data,
                creationDate: actualTimestamp, creationBuildId: EnvHelper.buildName, name: name,
            }
        )
        //console.log(savedData.id, "savedData.id")
        //await setDocInFirebase(
        //    getSimplifiedDesignPath(user.uid), savedData.id,
        //    {
        //        ...groupedDesignData,
        //        creationDate: actualTimestamp, creationBuildId: EnvHelper.buildName, name: name,
        //    }
        //)

        setTimeout(() => {
            // compressedCheck(designAsString)
        }, 0)

        return savedData.id
    }
}


const saveDesignCompressed = async (
    designAsString: string, screenshot: string, name?: string, id?: string, unit?: "cm" | "in", componentFunction?: () => void, connectionTypes?: ObjDictionary<ConnectionTypeAPI>, sizes?: ObjDictionary<SizeAPI>,
) => {
    // console.log("designAsString", designAsString)
    const user = getUser()!
    const actualTimestamp = Timestamp.now()
    const designParsed = JSON.parse(designAsString)
    const numberOfParts = Object.keys(designParsed.parts).length
    const compressedDesign = await compressDesign(designAsString)
    // compressionLogs(designAsString, compressedDesign)
    const data: Omit<DesignTypeAPI,
        "id" | "creationDate" | "creationBuildId" | "name" | "currentSessionId"
    > = {
        state: compressedDesign,
        isCompressed: true,
        imageBase64: screenshot,
        modificationDate: actualTimestamp,
        modificationBuildId: EnvHelper.buildName,
        userId: user.uid,
        deleted: false,
        unit: unit || "in",
        numberOfParts: numberOfParts,
        email: user.email || undefined,
    }

    if (id) {
        await updateInFirebase(getDesignPath(user.uid), id, data,)
        //await updateInFirebase(getSimplifiedDesignPath(user.uid), id, groupedDesignData,)

        return id
    } else {
        //console.log(data, "data")
        //console.log(groupedDesignData, "groupedDesignData")
        const savedData = await saveDataToFirebase(
            getDesignPath(user.uid),
            {
                ...data,
                creationDate: actualTimestamp, creationBuildId: EnvHelper.buildName, name: name,
            }
        )
        return savedData.id
    }
}

const fetchUserDesigns = async (limitCount?: number, afterTimestamp?: Timestamp, maxRetries = 3) => {
    const user = getUser()!
    let attempt = 0

    while (attempt < maxRetries) {
        try {
            let query_ = query(
                getCollection(getDesignPath(user.uid)),
                where("deleted", "==", false),
                orderBy("modificationDate", "desc")
            )

            if (afterTimestamp) {
                query_ = query(query_, where("modificationDate", "<", afterTimestamp))
            }

            if (limitCount !== undefined) {
                query_ = query(query_, limit(limitCount))
            }

            const designs = (await GetDataFromFirebase<DesignTypeAPI>(getDesignPath(user.uid), query_))
            return designs

        } catch (error) {
            attempt++
            if (attempt === maxRetries) {
                throw error // If we've used all retries, throw the error
            }

            // Exponential backoff: wait longer after each failure
            const delay = Math.min(1000 * Math.pow(2, attempt), 10000) // Cap at 10 seconds
            await new Promise(resolve => setTimeout(resolve, delay))

            console.warn(`Retry attempt ${attempt} for fetchUserDesigns after ${delay}ms delay`)
        }
    }
}

const getDesignById = async (userId: string, designId: string,) => {
    const design = await getDatumFromFirebase(getDesignPath(userId),
        designId)
    return design.data() as DesignTypeAPI
}

const renameDesign = async (id: string, newName: string) => {
    const user = getUser()!
    const data = {
        name: newName,
    }
    await updateInFirebase(getDesignPath(user.uid), id, data,)
    await updateInFirebase(getSimplifiedDesignPath(user.uid), id, data,)
    return newName
}

const duplicateDesign = async (id: string) => {
    const user = getUser()!
    const design = (await getDatumFromFirebase(getDesignPath(user.uid), id)).data() as DesignTypeAPI
    const newName = `${design.name} - copy`
    let state = design.state
    if (design.isCompressed) {
        state = await decompressDesign(design.state)
    }
    const copyId = await saveDesign(state, design.imageBase64, newName, undefined, undefined, undefined, undefined, undefined, design.isCompressed)
    return { ...design, id: copyId, name: newName, }
}

const deleteDesign = async (id: string) => {
    const user = getUser()!
    const data = {
        deleted: true,
    }
    await updateInFirebase(getDesignPath(user.uid), id, data,)
    await updateInFirebase(getSimplifiedDesignPath(user.uid), id, data,)
}

export const DesignsApi = {
    getDesignPath,
    getSimplifiedDesignPath,
    saveDesign,
    fetchUserDesigns,
    getDesignById,
    renameDesign,
    duplicateDesign,
    deleteDesign,
    simplifiedDesignState,
    parseTextToJson,
}