/* eslint-disable no-console */
import React, { useEffect, useMemo, useRef } from "react"
import { produce } from "immer"
import { useRecoilState, useRecoilValue } from "recoil"
import { layoutState } from "../../../../../../state/layoutState"
import ReactDOM from "react-dom"
import { Container, Overlay } from "../../../../../../../common/components/DrawerShared"
import { Header } from "./Header"
import { useTabs } from "../../Tabs/Tabs"
import { PartsToBuyList } from "./partLists/PartsToBuyList"
import { PartsUsedList } from "./partLists/PartsUsedList"
import { allPartsSelector, shopifyIdsSelector } from "../../../../../../state/initialDataSelectors"
import { events, useEventsData } from "../../../../../../../common/utils/rudderAnalyticsUtils"
import { scenePartsSelector } from "../../../../../../state/scene/atoms"
import { useGroupVisibility } from "../../../../../../hooks/useGroupVisibility"
import { SceneRef } from "../../../../../../state/types"

enum PartsListModalTabsMenu {
  partsList = "partsList",
  partsToBuy = "partsToBuy"
}

type PartListProps = {
  sceneRefs: SceneRef,
  filteredPartIds?: string[],
}

const PartList = ({ sceneRefs, }: PartListProps) => {
  const [layoutValue, setLayout,] = useRecoilState(layoutState)
  const shopifyIds = useRecoilValue(shopifyIdsSelector)
  const allParts = useRecoilValue(allPartsSelector)
  const sceneAtomList = useRecoilValue(scenePartsSelector)
  const { clearOnlyView, } = useGroupVisibility()

  // Filter scene parts if filteredPartIds is provided
  const filteredSceneAtomList = useMemo(() => {
    if (!layoutValue || !layoutValue.filteredPartIds) {
      return sceneAtomList
    }
    return Object.fromEntries(
      Object.entries(sceneAtomList).filter(([_, part,]) =>
        layoutValue.filteredPartIds!.includes(part.id)
      )
    )
  }, [sceneAtomList, layoutValue.filteredPartIds,])

  const overlay = document.getElementById("drawer-overlay") as HTMLElement | null
  const listRef = useRef<HTMLDivElement | null>(null)

  const eventsData = useEventsData()

  const defaultTabs = {
    [PartsListModalTabsMenu.partsList]: {
      name: "Parts List",
      render: () => <PartsUsedList
        parts={allParts}
        shopifyIds={shopifyIds}
        sceneRefs={sceneRefs}
        listRef={listRef}
        showPrintButton={content.current.currentOrDefault === PartsListModalTabsMenu.partsList}
        sceneAtomList={sceneAtomList}
        filteredSceneAtomList={filteredSceneAtomList}
      />,
    },
    [PartsListModalTabsMenu.partsToBuy]: {
      name: "Buy Parts",
      render: () => <PartsToBuyList
        parts={allParts}
        shopifyIds={shopifyIds}
      />,
    },
  }

  const tabs = useTabs({
    tabs: defaultTabs,
    defaultTab: PartsListModalTabsMenu.partsList,
  })

  const { headers, content, } = tabs

  const handleClose = () => {
    clearOnlyView()
    setLayout(produce((draft) => {
      draft.isDrawerOpen = false
      draft.showPartList = false
      draft.filteredPartIds = undefined
      draft.from = undefined
    }))
  }

  useEffect(() => {
    if (content.current.currentOrDefault === PartsListModalTabsMenu.partsList) {
      events.openPartsList(eventsData())
    } else {
      events.openBuyPartsTab(eventsData())
    }
  }, [content.current.currentOrDefault,])

  if (!layoutValue.showPartList) {
    return null
  }

  return <Container>
    <Header onClose={handleClose} />
    <div>{headers}</div>
    {content.tabs[content.current.currentOrDefault].render()}
    {overlay && (
      <>{ReactDOM.createPortal(<Overlay onClick={handleClose} />, overlay)}</>
    )}
  </Container>
}

export default PartList