/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import React, { useEffect, useState } from "react"
import { roundLength } from "../../../../../../../../utils/utils"
import { LengthSliderConfig, SEGMENTED_TUBE_UI } from "../types/types"
import SegmentedTubeLengthSlider from "./SegmentedTubeLengthSlider"
import { SegmentedTubeSectionType } from "../../../../../../../../../common/api/Types"

interface Props {
    lengthSliderConfig: LengthSliderConfig & { onValueChange: (value: number) => void, };
    setTubeUI: (ui: SEGMENTED_TUBE_UI) => void;
    heightScaling: boolean | undefined;
    widthScaling: boolean | undefined;
    realWidth: number | undefined;
    realHeight: number | undefined;
    scalingDimension: "length" | "width" | "height";
    setScalingDimension: (dimension: "length" | "width" | "height") => void;
}

const LengthUI = (props: Props) => {

    const [conversionMultiplier, setConversionMultiplier,] = useState(1)
    const handleFinishEditing = () => {
        props.setTubeUI(SEGMENTED_TUBE_UI.CLOSE)
    }

    const getEdgesLength = () => {
        return props.lengthSliderConfig.startSegmentLength
            + props.lengthSliderConfig.endSegmentLength
    }

    //value that shows in the display
    const getValue = () => {
        // Width case
        if (props.scalingDimension === "width" && props.lengthSliderConfig.modifiedWidth) {
            if (props.lengthSliderConfig.unit === props.lengthSliderConfig.modifiedWidthUnits) {
                return props.lengthSliderConfig.modifiedWidth
            }
            // Different units - need to convert
            const fromUnit = props.lengthSliderConfig.modifiedWidthUnits
            const toUnit = props.lengthSliderConfig.unit
            return fromUnit === "in" && toUnit === "cm"
                ? props.lengthSliderConfig.modifiedWidth * 2.54
                : props.lengthSliderConfig.modifiedWidth / 2.54
        }

        // Height case
        if (props.scalingDimension === "height" && props.lengthSliderConfig.modifiedHeight) {
            if (props.lengthSliderConfig.unit === props.lengthSliderConfig.modifiedHeightUnits) {
                return props.lengthSliderConfig.modifiedHeight
            }
            // Different units - need to convert
            const fromUnit = props.lengthSliderConfig.modifiedHeightUnits
            const toUnit = props.lengthSliderConfig.unit
            return fromUnit === "in" && toUnit === "cm"
                ? props.lengthSliderConfig.modifiedHeight * 2.54
                : props.lengthSliderConfig.modifiedHeight / 2.54
        }

        // Real value case
        if (props.scalingDimension === "length" && props.lengthSliderConfig.unitRealValue) {
            if (props.lengthSliderConfig.unit === props.lengthSliderConfig.partUnits) {
                return props.lengthSliderConfig.unitRealValue
            }
            // Different units - need to convert
            const fromUnit = props.lengthSliderConfig.partUnits
            const toUnit = props.lengthSliderConfig.unit
            return fromUnit === "in" && toUnit === "cm"
                ? props.lengthSliderConfig.unitRealValue * 2.54
                : props.lengthSliderConfig.unitRealValue / 2.54
        }

        // Fallback case
        return roundLength(props.lengthSliderConfig.value
            * props.lengthSliderConfig.segmentLength
            + getEdgesLength())
    }
    const [value, setValue,] = useState(getValue())

    useEffect(() => {
        setValue(getValue())
    }, [props.scalingDimension,])

    useEffect(() => {
        setConversionMultiplier(props.lengthSliderConfig.unit === "cm" ? 2.54 : 1)
    }, [props.lengthSliderConfig.unit,])

    const getValidMin = () => {
        if (props.scalingDimension !== "length") {
            return Number(0.1)
        }

        const { direction, segmentLength, startSegmentLength, endSegmentLength, tubeLength, tubeNegativeLength, } = props.lengthSliderConfig

        // Calculate base valid min
        const configValidMin = roundLength(
            props.lengthSliderConfig.validMin * segmentLength + getEdgesLength()
        ) * conversionMultiplier

        // Calculate minimum based on maintaining at least one segment on the opposite side
        const minSegmentsRequired = direction === SegmentedTubeSectionType.END
            ? Math.max(1, tubeNegativeLength + 1)  // When moving END, you're growing POSTIIVE length. So your min ensure negative length + 1 or a min of 1 because POSITIVE length has to be 1 at  min
            : Math.max(0, tubeLength)          // When moving START, you're growing NEGATIVE length. You can just use the regular positive length because tht should always be more than 0


        const segmentBasedMin = (minSegmentsRequired * segmentLength + getEdgesLength()) * conversionMultiplier

        // Return the larger of the two minimums
        return Math.max(configValidMin, segmentBasedMin)
    }

    const getValidMax = () => {
        if (props.scalingDimension !== "length") {
            return Number(100)
        }
        return roundLength(props.lengthSliderConfig.maxMiddles
            * props.lengthSliderConfig.segmentLength
            + getEdgesLength()) * conversionMultiplier
    }

    const getMin = () => {
        if (props.scalingDimension !== "length") {
            return Number(0.1)
        }

        const { direction, segmentLength, startSegmentLength, endSegmentLength, tubeLength, tubeNegativeLength, } = props.lengthSliderConfig

        // Calculate base min from current config
        const configMin = ((props.lengthSliderConfig.minMiddles || 1)
            * segmentLength + getEdgesLength()) * conversionMultiplier

        // Calculate minimum based on maintaining at least one segment on the opposite side
        const minSegmentsRequired = direction === SegmentedTubeSectionType.END
            ? Math.max(1, tubeNegativeLength + 1)  // When moving END, you're growing POSTIIVE length. So your min ensure negative length + 1 or a min of 1 because POSITIVE length has to be 1 at  min
            : Math.max(0, tubeLength)          // When moving START, you're growing NEGATIVE length. You can just use the regular positive length because tht should always be more than 0

        const segmentBasedMin = (minSegmentsRequired * segmentLength + getEdgesLength()) * conversionMultiplier

        // Return the larger of the two minimums to prevent negative segment lengths
        return Math.max(configMin, segmentBasedMin)
    }
    const getMax = () => {
        if (props.scalingDimension !== "length") {
            return Number(100 * conversionMultiplier)
        }
        return ((props.lengthSliderConfig.maxMiddles || 80)
            * props.lengthSliderConfig.segmentLength + getEdgesLength()) * conversionMultiplier
    }

    const getStep = () => {
        return props.lengthSliderConfig.segmentLength
    }

    const handleMouseUp = (value: number) => {
        props.lengthSliderConfig.setHideAddPartButtons(false)
        if (props.lengthSliderConfig.onMouseUp) {
            props.lengthSliderConfig.onMouseUp(value)
        }

    }

    const handleMouseDown = () => {
        if (props.lengthSliderConfig.onMouseDown) {
            props.lengthSliderConfig.onMouseDown()
        }
    }

    const handleChange = (value: number) => {
        props.lengthSliderConfig.onchange(value)
        props.lengthSliderConfig.onValueChange(value)
    }

    const isProcessing = React.useRef(false)

    const handleChangeFromInput = (value: number) => {
        if (isProcessing.current) {
            return
        }
        isProcessing.current = true
        handleMouseDown()

        setTimeout(() => {
            handleChange(value)
            setTimeout(() => {
                handleMouseUp(value)
                setTimeout(() => {
                    isProcessing.current = false
                }, 25)
            }, 25)
        }, 25)
    }


    return (
        <SegmentedTubeLengthSlider
            value={getValue()}
            valueDirect={value}
            min={getMin()}
            max={getMax()}
            validMin={getValidMin()}
            validMax={getValidMax()}
            tubeLength={props.lengthSliderConfig.tubeLength}
            tubeNegativeLength={props.lengthSliderConfig.tubeNegativeLength}
            step={props.lengthSliderConfig.segmentLength}
            unit={props.lengthSliderConfig.unit}
            updateUnit={props.lengthSliderConfig.updateUnit}
            onMouseUp={handleMouseUp}
            onChange={handleChange}
            onInputChange={handleChangeFromInput}
            onMouseDown={handleMouseDown}
            onFinishEditing={handleFinishEditing}
            setDirection={props.lengthSliderConfig.setDirection}
            direction={props.lengthSliderConfig.direction}
            scalingDimension={props.scalingDimension}
            setScalingDimension={props.setScalingDimension}
            realWidth={props.realWidth}
            realHeight={props.realHeight}
            heightScaling={props.heightScaling}
            widthScaling={props.widthScaling}
        />
    )
}

export default LengthUI