/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable no-lonely-if */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { useThree } from "@react-three/fiber"
import React, { createContext, useCallback, useContext, useRef, useEffect } from "react"
import { Object3D, Raycaster, Vector2 } from "three"
import { MarkerType } from "../../utils/Types"
import { instancedMeshContext } from "../instancedMesh/InstancedMeshProvider"
import { selectedItemID, shiftSelectedItemID } from "../../state/atoms"
import { useRecoilCallback, useResetRecoilState, useSetRecoilState } from "recoil"
import isMobile from "ismobilejs"
import useInstancedMeshSegmentedTubes from "../instancedMeshSegmentedTubesProvider/useInstancedMeshSegmentedTubes"
import { partVisibilityAtom } from "../../state/scene/atoms"
interface Props {
    children: React.ReactNode;
}

type SelectablesType = {
    id: string,
    meshCallback: (() => void) | undefined,
}

export type InstancedMeshContextType = {
    registerSelectableMesh: (id: string, meshCallback: (() => void) | undefined) => void,
} | undefined

export const selectorContext = createContext<InstancedMeshContextType>(undefined)

function isObjectVisible(object: Object3D): boolean {
    let currentObject: Object3D | null = object

    while (currentObject !== null) {
        if (!currentObject.visible) {
            return false
        }
        currentObject = currentObject.parent || null
    }

    return true
}

const SelectorProvider: React.FC<Props> = ({ children, }) => {
    const raycaster = useRef<Raycaster>()
    const selectables = useRef<SelectablesType[]>([])
    const { scene, camera, gl, } = useThree()
    const instanceContext = useContext(instancedMeshContext)
    const resetSelectedItem = useResetRecoilState(selectedItemID)
    const mouseClick = useRef<{ x: number, y: number, } | undefined>(undefined)
    const isMultitouch = useRef(false)
    const setSelectedItemID = useSetRecoilState(selectedItemID)
    const setShiftSelectedItemID = useSetRecoilState(shiftSelectedItemID)
    const lasMousePosition = useRef({ x: 0, y: 0, })
    const { handleTubeSelection, } = useInstancedMeshSegmentedTubes()
    const getInvisibleParts = useRecoilCallback(
        ({ snapshot, }) =>
            () => {
                return snapshot.getLoadable(partVisibilityAtom).contents
            },
        [],
    )

    // Create a ref to track the current modifier key state
    const modifierKeyRef = useRef(false)

    const isPartVisible = (partId: string) => {
        const invisibleParts = getInvisibleParts()
        return !invisibleParts.includes(partId)
    }


    // Add useEffect for hotkeys setup
    useEffect(() => {
        //console.log("useEffect for keyboard setup")

        const handleKeyDown = (event: KeyboardEvent) => {
            //console.log("key down:", event.key)
            if (event.key === "Shift" || event.key === "Control") {
                modifierKeyRef.current = true  // Update ref
            }
        }

        const handleKeyUp = (event: KeyboardEvent) => {
            //console.log("key up:", event.key)
            if (event.key === "Shift" || event.key === "Control") {
                modifierKeyRef.current = false  // Update ref
            }
        }

        window.addEventListener("keydown", handleKeyDown)
        window.addEventListener("keyup", handleKeyUp)

    }, [])

    const setMultitouch = (event: MouseEvent | TouchEvent) => {
        if (event.type.includes("touch")) {
            if ((event as TouchEvent).touches.length > 1) {
                isMultitouch.current = true
            }
            if ((event as TouchEvent).touches.length === 1) {
                isMultitouch.current = false
            }
        }
    }

    const getTouchCoordinates = (event: MouseEvent | TouchEvent) => {
        if ((event as any).touches
            && (event as any).touches[0]) {
            return {
                x: (event as any).touches[0].pageX,
                y: (event as any).touches[0].pageY,
            }
        }
        return { x: (event as any).pageX, y: (event as any).pageY, }
    }

    const onMouseMove = (event: MouseEvent | TouchEvent) => {
        lasMousePosition.current = getTouchCoordinates(event)
    }

    const onMouseDown = (event: MouseEvent | TouchEvent) => {
        const coordinates = getTouchCoordinates(event)
        lasMousePosition.current = coordinates
        setMultitouch(event)
        mouseClick.current = coordinates
    }

    // eslint-disable-next-line max-statements
    const onMouseUp = useCallback((event: MouseEvent | TouchEvent) => {
        if (isMultitouch.current) {
            return
        }

        if (mouseClick.current
            && Math.floor(lasMousePosition.current.x) === Math.floor(mouseClick.current.x)
            && Math.floor(lasMousePosition.current.y) === Math.floor(mouseClick.current.y)
        ) {
            if ((event.target as HTMLCanvasElement).nodeName === "CANVAS") {
                const mouse = lasMousePosition.current
                const clientX = mouse.x
                const clientY = mouse.y

                const rect = gl.domElement.getBoundingClientRect()

                mouse.x = ((clientX - rect.left) / rect.width) * 2 - 1
                mouse.y = - ((clientY - rect.top) / rect.height) * 2 + 1

                if (raycaster.current) {
                    const mouseVector = new Vector2(mouse.x, mouse.y)
                    raycaster.current.setFromCamera(mouseVector, camera)
                    const objectsToIntersect = scene.children.filter(object => !object.userData.ignoreRaycast || !object.name.includes("aligmentPlane"))
                    const intersections = raycaster.current.intersectObjects(objectsToIntersect)
                    const filteredIntersections = intersections.filter(intersection => !intersection.object.name.includes("aligmentPlane"))
                    let instanceId = -1
                    let meshName = ""
                    let objectSelected = false
                    const intersectionDifferentPlusButton = !filteredIntersections.some(
                        i => i.object.userData.type === MarkerType.PLUS_BUTTON
                    )

                    const intersectionScalerButton = filteredIntersections.find(
                        i => i.object.userData.scalerButton)
                    const intersectionRulerPointSphere = intersections.find(
                        i => i.object.userData.rulerPoint)

                    //console.log(filteredIntersections)


                    if (intersectionDifferentPlusButton && !intersectionScalerButton && !intersectionRulerPointSphere) {
                        if (filteredIntersections.length !== 0) {
                            filteredIntersections.forEach((intersection) => {
                                if (intersection.object
                                    && (intersection.object.type === "Mesh"
                                        || intersection.object.type === "InstancedMesh"
                                    )
                                ) {

                                    if (intersection.object.userData.type === "InstancedMesh"
                                        && instanceId === -1 && !objectSelected) {
                                        instanceId = intersection.instanceId!
                                        meshName = intersection.object.name

                                        // Get the batchIndex from the object userData if available for batched instancedMesh objects
                                        const batchIndex = intersection.object.userData.batchIndex || 0

                                        if (intersection.object.userData.partType && intersection.object.userData.partType === "SECTIONED_TUBE") {
                                            if (!isPartVisible(intersection.object.userData.partId)) {
                                                return
                                            }
                                            // It is a segmented tube (old and not used anymore)
                                            if (modifierKeyRef.current) {
                                                //console.log("segmentedTubeCase")
                                                setShiftSelectedItemID(intersection.object.userData.partId)
                                            } else {
                                                //console.log("segmentedTubeCase else")
                                                handleTubeSelection(meshName, instanceId)
                                            }
                                        } else {
                                            // It is a connector
                                            const partId = instanceContext?.getPartId(meshName, instanceId, batchIndex)
                                            if (partId && !isPartVisible(partId)) {
                                                return
                                            }
                                            if (modifierKeyRef.current) {

                                                //console.log("connectorCase", partId)
                                                if (partId) {
                                                    setShiftSelectedItemID(partId)
                                                }
                                            } else {
                                                //console.log("connectorCase else", modifierKeyRef.current)
                                                instanceContext?.clickCallback(meshName, instanceId, batchIndex)

                                            }
                                            objectSelected = true
                                        }

                                    }
                                    if (intersection.object.type === "Mesh" && !objectSelected) {
                                        const s = selectables.current.find((s) =>
                                            s.id === intersection.object.name)
                                        if (s) {
                                            const partId = intersection.object.userData.partId
                                            if (partId && !isPartVisible(partId)) {
                                                return
                                            }
                                            if (modifierKeyRef.current) {
                                                //console.log("meshCase")
                                                setShiftSelectedItemID(partId)
                                            } else {
                                                //console.log("meshCase else", modifierKeyRef.current)
                                                s.meshCallback!()

                                            }
                                            objectSelected = true
                                        }

                                    }
                                }
                                if (intersection.object.userData.type === "MergedMesh" && !objectSelected && intersection.object.userData.partId) {
                                    const partId = intersection.object.userData.partId
                                    if (partId && !isPartVisible(partId)) {
                                        return
                                    }
                                    if (modifierKeyRef.current) {
                                        setShiftSelectedItemID(partId)
                                    } else {
                                        //console.log("mergedMeshCase")
                                        setSelectedItemID(partId)

                                    }
                                    objectSelected = true

                                }

                            })
                        }

                        if (!objectSelected) {
                            resetSelectedItem()
                        }
                    }
                }
            }
        }
        mouseClick.current = undefined
    }, [])

    useEffect(() => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const viewParam = urlParams.get("viewOnly")
        if (viewParam !== "true") {
            raycaster.current = new Raycaster()
            if (isMobile(window.navigator).phone) {
                document.removeEventListener("touchstart", onMouseDown)
                document.addEventListener("touchstart", onMouseDown)
                document.removeEventListener("touchend", onMouseUp)
                document.addEventListener("touchend", onMouseUp)
                document.removeEventListener("touchmove", onMouseMove)
                document.addEventListener("touchmove", onMouseMove)
            } else {
                document.removeEventListener("mousedown", onMouseDown)
                document.addEventListener("mousedown", onMouseDown)
                document.removeEventListener("mouseup", onMouseUp)
                document.addEventListener("mouseup", onMouseUp)
                document.removeEventListener("mousemove", onMouseMove)
                document.addEventListener("mousemove", onMouseMove)
            }
        }
    }, [camera,])


    const registerSelectableMesh = (id: string, meshCallback: (() => void) | undefined) => {
        selectables.current.push({
            id,
            meshCallback,
        })
    }

    const init = () => {
        return {
            registerSelectableMesh,
        }
    }

    return <selectorContext.Provider value={init()} children={children} />
}

export default SelectorProvider