/* eslint-disable max-len */
/* eslint-disable no-process-env */
import { initializeApp } from "firebase/app"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { disableNetwork, enableNetwork, getFirestore, initializeFirestore, persistentLocalCache, persistentMultipleTabManager } from "firebase/firestore"
import { EnvHelper } from "../../utils/EnvHelper"
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check"

const app = initializeApp(EnvHelper.firebaseConfig)

initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(EnvHelper.appCheckVariable!),
  isTokenAutoRefreshEnabled: true,
})

//
export const db = initializeFirestore(app, {

})

export const refreshCache = (...loadingSetters: ((value: boolean) => void)[]) => {
  try {
    return new Promise<void>((resolve) => {
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          await enableNetwork(db)
          await disableNetwork(db)
          await enableNetwork(db)
        }
        unsubscribe()
        resolve()
      })
    })
  } catch (error) {
    console.warn("Cache refresh failed:", error)
  }
}

export const auth = getAuth()

export default app