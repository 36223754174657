// a custom tooltip component that uses the tooltip component from antd
// handles mobile tooltip trigger issues where tooltips persist after click

import { Tooltip as AntdTooltip, TooltipProps as AntdTooltipProps } from "antd"
import isMobile from "ismobilejs"
import React, { useState, useEffect, useRef } from "react"

interface CustomTooltipProps extends Omit<AntdTooltipProps, "children"> {
  children: React.ReactNode;
}

// Shared listener for all tooltip instances
let documentTouchListener: ((e: TouchEvent) => void) | null = null
let activeTooltipRef: React.RefObject<HTMLElement> | null = null

export const CustomTooltip: React.FC<CustomTooltipProps> = ({
  children,
  trigger = ["hover",],
  ...props
}) => {
  const [isOpen, setIsOpen,] = useState(false)
  const isMobileDevice = isMobile(window.navigator).any
  const wrapperRef = useRef<HTMLDivElement>(null)
  const timeoutRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (!isMobileDevice) {
      return
    }

    // Only set up the document listener once
    if (!documentTouchListener) {
      documentTouchListener = (e: TouchEvent) => {
        // Only close if we have an active tooltip and the touch
        // wasn't inside that tooltip's content
        if (activeTooltipRef?.current && !activeTooltipRef.current.contains(e.target as Node)) {
          setIsOpen(false)
          activeTooltipRef = null
        }
      }
      document.addEventListener("touchstart", documentTouchListener)
    }

    return () => {
      // Clean up both the listener and any pending timeout
      if (documentTouchListener) {
        document.removeEventListener("touchstart", documentTouchListener)
        documentTouchListener = null
        activeTooltipRef = null
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [isMobileDevice,])

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open)

    if (open && isMobileDevice) {
      // Clear any existing timeout
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      // Set new timeout to close after 1000ms
      timeoutRef.current = setTimeout(() => {
        setIsOpen(false)
        activeTooltipRef = null
      }, 1250)

      activeTooltipRef = wrapperRef
    }

    props.onOpenChange?.(open)
  }

  return (
    <AntdTooltip
      {...props}
      open={isOpen}
      onOpenChange={handleOpenChange}
    >
      <div ref={wrapperRef}>
        {children}
      </div>
    </AntdTooltip>
  )
}