/* eslint-disable no-param-reassign */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import styled from "styled-components"
import { PartTypeAPI, ShopifyIdAPI } from "../../../../../../../../common/api/Types"
import { groupsSelector, sceneAtom, showGroupingAtom } from "../../../../../../../state/scene/atoms"
import { ObjDictionary } from "../../../../../../../../common/utils/utils"
import { partsListStyles } from "./partsListHelpers"
import { usePartsList } from "../usePartsList"
import { NoPartsInScene } from "./NoPartsInScene"
import UnitToggle from "../../../../scene/UnitToggle"
import PrintButton from "./Print/PrintButton"
import { useComponentRegistry } from "../../../../../../../providers/multiselectProvider/useComponentMethods"
import NameModal from "../../../../../../../../common/components/NameModal"
import { SceneType } from "../../../../../../../state/scene/types"
import ConfirmModal from "../../../../../../../../manager/components/Designs/ConfirmModal"
import { GroupPanel } from "./GroupPanel"
import { SamePartsPanel } from "./SamePartsPanel"
import GroupingToggle from "../../../../scene/GroupingToggle"
import { swapJobsAtom } from "../../../../../../../state/scene/atoms"
import { layoutState } from "../../../../../../../state/layoutState"
import PartsViewToggle from "../../../../scene/PartsViewToggle"
import { multiSelectionSelector } from "../../../../../../../state/scene/selectors"
import { SceneRef } from "../../../../../../../state/types"

interface PartsUsedListProps {
  parts: PartTypeAPI[];
  shopifyIds: ObjDictionary<ShopifyIdAPI>;
  sceneRefs: SceneRef;
  listRef: React.MutableRefObject<HTMLDivElement | null>;
  showPrintButton: boolean;
  sceneAtomList: Record<string, any>;
  filteredSceneAtomList?: Record<string, any>;
}

export const PartsUsedList: React.FC<PartsUsedListProps> = ({
  parts,
  shopifyIds,
  sceneRefs,
  listRef,
  showPrintButton,
  sceneAtomList,
  filteredSceneAtomList,
}) => {
  const groupsAtomList = useRecoilValue(groupsSelector)
  const { getComponent, } = useComponentRegistry()
  const [groupToRename, setGroupToRename,] = useState<string | null>(null)
  const setSceneAtom = useSetRecoilState(sceneAtom)
  const currentGroupIDRef = useRef<string | null>(null)
  const [showDeleteModal, setShowDeleteModal,] = useState<boolean>(false)
  const [groupToDelete, setGroupToDelete,] = useState<string | null>(null)
  const [activeGroups, setActiveGroups,] = useState<string[]>([])
  const showGrouping = useRecoilValue(showGroupingAtom)
  const [showGroupToggleOption, setShowGroupToggleOption,] = useState<boolean>(false)
  const swapJobs = useRecoilValue(swapJobsAtom)
  const hasNewCompletedSwapRef = useRef(false)
  const initialSwapJobsRef = useRef<typeof swapJobs | null>(null)
  const [layoutValue, setLayout,] = useRecoilState(layoutState)
  const multiSelection = useRecoilValue(multiSelectionSelector)
  const [showSelectedOnly, setShowSelectedOnly,] = useState(() => {
    // Initialize based on either multiSelection or filteredPartIds
    const isMultiSelection = multiSelection.length > 0 && layoutValue.from === "multiSelectionInfo"
    return isMultiSelection || (layoutValue.filteredPartIds?.length ?? 0) > 0
  })

  const showPartsViewToggle = multiSelection.length > 0 || (layoutValue.filteredPartIds?.length ?? 0) > 0

  const { getRows, } = usePartsList({ parts, shopifyIds, allowCm: true, })


  useEffect(() => {
    if (Object.keys(groupsAtomList).length > 0) {
      setShowGroupToggleOption(true)
    } else {
      setShowGroupToggleOption(false)
    }
  }, [groupsAtomList,])

  // Add useEffect to apply colors on initial load
  useEffect(() => {
    // Apply colors to all parts in groups that are initially expanded
    Object.entries(groupsAtomList).forEach(([_, group,]) => {
      if (group.partIds && group.color) {
        group.partIds.forEach(partId => {
          const component = getComponent(partId)
          if (component) {
            component.updateColor(group.color)
          }
        })
      }
    })

    return () => {
      // Clean up colors when component unmounts
      Object.values(groupsAtomList).forEach(group => {
        group.partIds.forEach(partId => {
          const component = getComponent(partId)
          if (component) { component.originalColor() }
        })
      })
      if (sceneRefs.current?.updateColorsOfHighlightedParts) {
        sceneRefs.current.updateColorsOfHighlightedParts()
      }
    }
  }, [groupsAtomList, getComponent,])

  useEffect(() => {
    // On first mount, store initial swap jobs
    if (initialSwapJobsRef.current === null) {
      initialSwapJobsRef.current = swapJobs
      return
    }

    const newJobs = swapJobs.slice(initialSwapJobsRef.current.length)

    const hasNewPendingJob = newJobs.some(job => job.status === "pending")
    if (hasNewPendingJob) {
      hasNewCompletedSwapRef.current = false
      return
    }

    const hasNewCompletedJob = newJobs.some(job => job.status === "completed")
    if (hasNewCompletedJob) {
      hasNewCompletedSwapRef.current = true
      const lastCompletedJob = getLastCompletedSwapJob()
      if (lastCompletedJob) {
        const newPartIds = lastCompletedJob.targetPartIds || []
        if (layoutValue.filteredPartIds) {
          const newFilteredPartIds = [...layoutValue.filteredPartIds, ...newPartIds,]
          // after swap, update the filteredPartIds so the new parts show
          setLayout({ ...layoutValue, filteredPartIds: newFilteredPartIds, })
        }
        if (multiSelection.length > 0 && sceneRefs.current?.updateSelection) {
          // after swap, update the selection so the new parts are highlighted
          sceneRefs.current.updateSelection(undefined, newPartIds, false, true, lastCompletedJob.sourcePartIds)
        }
      }
    }
  }, [swapJobs,])

  useEffect(() => {
    // switch to pan mode
    if (multiSelection.length > 0 && sceneRefs.current?.setSelectionMode && sceneRefs.current?.setTransformMode) {
      sceneRefs.current.setSelectionMode(false)
      sceneRefs.current.setTransformMode("off")
    }
  }, [])


  const handleItemHover = (itemIds: string[]) => {
    itemIds.forEach(id => {
      const component = getComponent(id)
      if (component) {
        component.updateColor(0x00008B)
      }
    })
  }

  const handleItemLeave = (itemIds: string[]) => {
    itemIds.forEach(id => {
      const component = getComponent(id)
      if (component) {
        component.originalColor()
        if (sceneRefs.current.updateColorsOfHighlightedParts) {
          sceneRefs.current.updateColorsOfHighlightedParts()
        }
      }
    })
  }

  // Group action handlers
  const handleRenameGroup = (groupId: string) => {
    currentGroupIDRef.current = groupId
    setGroupToRename(groupId)
  }

  const handleRenameSubmit = (newName: string) => {
    const groupId = currentGroupIDRef.current
    if (!groupId) { return }

    setSceneAtom((prevScene: SceneType) => ({
      ...prevScene,
      groups: prevScene.groups?.map(group =>
        (group.id === groupId ? { ...group, name: newName, } : group)
      ),
    }))
    setGroupToRename(null)
    currentGroupIDRef.current = null
  }

  const handleUngroup = (groupId: string) => {
    setSceneAtom((prevScene: SceneType) => ({
      ...prevScene,
      groups: prevScene.groups?.filter(group => group.id !== groupId),
    }))
    if (sceneRefs.current.resetSelection) {
      sceneRefs.current.resetSelection()
    } else {
      console.warn("sceneRefs or resetSelection is undefined")
    }
  }

  const handleDeleteGroup = (groupId: string) => {
    setGroupToDelete(groupId)
    setShowDeleteModal(true)
  }

  const handleConfirmDelete = () => {
    if (!groupToDelete) { return }

    // Get all part IDs from the group
    const group = Object.values(groupsAtomList).find(g => g.id === groupToDelete)
    if (!group) { return }

    // Delete all parts in the group
    group.partIds.forEach(id => {
      const component = getComponent(id)
      if (component) {
        component.deletePart()
      }
    })

    // Close modal and reset state
    setShowDeleteModal(false)
    setGroupToDelete(null)
  }

  const handlePanelChange = (activeKeys: string | string[]) => {
    const newActiveKeys = Array.isArray(activeKeys) ? activeKeys : [activeKeys,]
    setActiveGroups(newActiveKeys)

    // Reset all parts to original color first
    Object.values(sceneAtomList).forEach(part => {
      const component = getComponent(part.id)
      if (component) {
        component.originalColor()
      }
    })

    // Update colors for active panels using existing group colors
    newActiveKeys.forEach(groupId => {
      const group = Object.values(groupsAtomList).find(g => g.id === groupId)
      if (group?.color && group.partIds) {
        group.partIds.forEach(partId => {
          const component = getComponent(partId)
          if (component) {
            component.updateColor(group.color)
          }
        })
      }
    })
  }

  const getLastCompletedSwapJob = () => {
    if (!hasNewCompletedSwapRef.current || !swapJobs.length) {
      return null
    }

    // Find the last completed swap job
    const lastCompletedJob = [...swapJobs,]
      .reverse()
      .find(job => job.status === "completed")

    if (!lastCompletedJob?.targetPart) {
      return null
    }

    return lastCompletedJob
  }

  const getLastCompletedSwapTypeId = () => {
    const lastCompletedJob = getLastCompletedSwapJob()
    if (!lastCompletedJob) {
      return null
    }

    return lastCompletedJob.targetPart.part.id
  }

  const handlePartsViewToggle = (showSelected: boolean) => {
    setShowSelectedOnly(showSelected)
  }

  const handleUndoSwap = () => {
    const lastCompletedJob = getLastCompletedSwapJob()
    if (!lastCompletedJob || !multiSelection.length) {
      return
    }
    setTimeout(() => {
      if (lastCompletedJob.sourcePartIds && sceneRefs.current?.updateSelection) {
        sceneRefs.current.updateSelection(undefined, lastCompletedJob.sourcePartIds, false, true)
      }
    }, 500)
  }

  const renderPartsList = () => {
    // Filter parts based on multiple conditions
    let filteredSceneAtomList = sceneAtomList

    if (showSelectedOnly) {
      if (multiSelection.length > 0) {
        // Filter by multiSelection
        filteredSceneAtomList = Object.fromEntries(
          Object.entries(sceneAtomList).filter(([_, part,]) => {
            return multiSelection.includes(part.id)
          })
        )
      } else if (layoutValue.filteredPartIds && layoutValue.filteredPartIds.length > 0) {
        // Filter by layoutState.filteredPartIds
        filteredSceneAtomList = Object.fromEntries(
          Object.entries(filteredSceneAtomList).filter(([_, part,]) => {
            return layoutValue.filteredPartIds?.includes(part.id)
          })
        )
      }
    }

    if (!showGrouping) {
      const allPartsByType: { [key: string]: any[], } = {}
      Object.values(filteredSceneAtomList).forEach(part => {
        const typeKey = part.apiTypeId
        if (!allPartsByType[typeKey]) {
          allPartsByType[typeKey] = []
        }
        allPartsByType[typeKey].push(part)
      })

      const sortedTypes = Object.entries(allPartsByType).sort((a, b) => {
        return b[1].length - a[1].length
      })

      return (
        <>
          {sortedTypes.map(([typeKey, sameParts,]) => (
            <SamePartsPanel
              key={typeKey}
              typeKey={typeKey}
              sameParts={sameParts}
              handleItemHover={handleItemHover}
              handleItemLeave={handleItemLeave}
              sceneRefs={sceneRefs}
              getRows={getRows}
              isLastSwappedType={typeKey === getLastCompletedSwapTypeId()}
              handleUndoCallback={handleUndoSwap}
            />
          ))}
        </>
      )
    }

    // Separate grouped and ungrouped parts
    const groupedParts: { [key: string]: any[], } = {}
    const ungroupedParts: any[] = []

    Object.values(filteredSceneAtomList).forEach(part => {
      const groupEntry = Object.entries(groupsAtomList)
        .find(([_, group,]) => group.partIds.includes(part.id))

      if (groupEntry) {
        const [_, group,] = groupEntry
        if (!groupedParts[group.id]) { groupedParts[group.id] = [] }
        groupedParts[group.id].push(part)
      } else {
        ungroupedParts.push(part)
      }
    })

    // Organize ungrouped parts by their type/name
    const ungroupedByType: { [key: string]: any[], } = {}
    ungroupedParts.forEach(part => {
      const typeKey = part.apiTypeId
      if (!ungroupedByType[typeKey]) {
        ungroupedByType[typeKey] = []
      }
      ungroupedByType[typeKey].push(part)
    })

    const sortedTypes = Object.entries(ungroupedByType).sort((a, b) => {
      return b[1].length - a[1].length
    })

    return (
      <>
        {/* Render grouped parts */}
        {Object.entries(groupedParts).map(([groupId, groupParts,]) => (
          <GroupPanel
            key={groupId}
            groupId={groupId}
            parts={groupParts}
            allParts={parts}
            groupsAtomList={groupsAtomList}
            handleRenameGroup={handleRenameGroup}
            handleUngroup={handleUngroup}
            handleDeleteGroup={handleDeleteGroup}
            handleItemHover={handleItemHover}
            handleItemLeave={handleItemLeave}
            sceneRefs={sceneRefs}
            isActive={activeGroups.includes(groupId)}
            onPanelChange={(isActive) => {
              handlePanelChange(
                isActive
                  ? [...activeGroups, groupId,]
                  : activeGroups.filter(id => id !== groupId)
              )
            }}
            shopifyIds={shopifyIds}
          />
        ))}

        {/* Render ungrouped parts using SamePartsPanel */}
        {sortedTypes.map(([typeKey, sameParts,]) => (
          <SamePartsPanel
            key={typeKey}
            typeKey={typeKey}
            sameParts={sameParts}
            handleItemHover={handleItemHover}
            handleItemLeave={handleItemLeave}
            sceneRefs={sceneRefs}
            getRows={getRows}
            isLastSwappedType={typeKey === getLastCompletedSwapTypeId()}
            handleUndoCallback={handleUndoSwap}
          />
        ))}
      </>
    )
  }

  if (Object.keys(sceneAtomList).length === 0) {
    return <NoPartsInScene />
  }

  return (
    <Wrapper ref={listRef}>
      {groupToRename && (
        <NameModal
          onCancel={() => {
            setGroupToRename(null)
            currentGroupIDRef.current = null
          }}
          onRename={handleRenameSubmit}
          submitButtonText="Save"
          title="Rename Group"
          loading={false}
        />
      )}
      {showDeleteModal && (
        <ConfirmModal
          title="Delete Group"
          message="Are you sure you want to delete this group and all its parts?"
          confirmButtonText="Delete"
          confirmButtonColor="#FF0000"
          onConfirm={handleConfirmDelete}
          onCancel={() => {
            setShowDeleteModal(false)
            setGroupToDelete(null)
          }}
          loading={false}
        />
      )}
      <partsListStyles.Header>
        {showPrintButton && <PrintButton listRef={listRef} />}
        <div className="no-print" style={{ display: "flex", gap: "8px", }}>
          {showGroupToggleOption && <GroupingToggle />}
          {showPartsViewToggle && (
            <PartsViewToggle
              showSelectedOnly={showSelectedOnly}
              onChange={handlePartsViewToggle}
            />
          )}
          <UnitToggle />
        </div>
      </partsListStyles.Header>
      {renderPartsList()}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 0 2px 0 16px;
  scrollbar-gutter: stable;
`
// padding is uneven because of the scrollbar gutter behavior