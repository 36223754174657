/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import { Icosahedron, Text } from "@react-three/drei"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useFrame, useThree } from "@react-three/fiber"
import { useRecoilValue, useSetRecoilState } from "recoil"
import {
  BackSide,
  Box3,
  Color,
  Mesh,
  Object3D,
  Raycaster,
  Vector3
} from "three"
import { breadcrumb } from "../../../../../../../../common/utils/sentrySetup"
import { addPartModal } from "../../../../../../../state/atoms"
import { connectionTypesSelector } from "../../../../../../../state/initialDataSelectors"
import { allConnections } from "../../../../../../../state/scene/selectors"
import { MeshUtils } from "../../../../../../../utils/MeshUtils"
import {
  MarkerType,
  PartTypeEnum,
  TubeMarkerEnum,
  TubeValues
} from "../../../../../../../utils/Types"
import { getInitialRotationTube } from "../utils/getInitialRotation"
import { TubeInternalsType, TUBE_UI } from "./types/types"
import { isExpandReduceApplicable } from "./utils/ReduceExpandToFit"
import { getDistancePercentage } from "./utils/TubeUtils"
import { debounce as debounceLodash } from "lodash"
import { SceneRef } from "../../../../../../../state/types"
type NewPartButtonProps = {
  trackType: TubeMarkerEnum,
  tubeInternalsRef: React.MutableRefObject<TubeInternalsType>,
  clearTubeUI: (value: React.SetStateAction<TUBE_UI>) => void,
  setIsSelected: (isSelected: boolean) => void,
  tube: TubeValues,
  sceneRefs: SceneRef,
}

const NewPartButtonTube = (props: NewPartButtonProps) => {

  const { scene, camera, } = useThree()
  const [hovered, hover,] = useState(false)

  const connections = useRecoilValue(allConnections)
  const setNewConnectionData = useSetRecoilState(addPartModal)
  const connectionTypes = useRecoilValue(connectionTypesSelector)

  // let buttonSize = roundNumber(props.tube.diameter.inner / metersToInch, 3)
  // buttonSize = buttonSize < 0.02 ? buttonSize = 0.022 : buttonSize
  // buttonSize = buttonSize > 0.05 ? buttonSize = 0.04 : buttonSize
  const mesh = props.trackType === TubeMarkerEnum.TOP
    ? props.tubeInternalsRef.current.markerTopMesh
    : props.tubeInternalsRef.current.markerBottomMesh

  const tubeBox = useMemo(() => {
    const box = new Box3()

    if (mesh) {
      // Get world position from the mesh
      const worldPosition = new Vector3()
      mesh.getWorldPosition(worldPosition)

      // Create a small box around that position
      const size = 0.02 // Small default size
      box.min.set(
        worldPosition.x - size,
        worldPosition.y - size,
        worldPosition.z - size
      )
      box.max.set(
        worldPosition.x + size,
        worldPosition.y + size,
        worldPosition.z + size
      )
    }
    return box
  }, [mesh,])


  useEffect(() => {
    if (!props.sceneRefs?.current?.cameraControls || !props.sceneRefs?.current?.calculateSizeOfBoxFactor) {
      return
    }
    const cam = props.sceneRefs.current.cameraControls.current
    if (!cam) {
      return
    }

    //updating of 2d position of markers on the screen for snapping logic
    //you need a slight timeout bc the camera still moved for a little right after it says
    //controlend

    const debouncedUpdate = debounceLodash(() => {
      if (tubeBox && props.sceneRefs.current.calculateSizeOfBoxFactor) {
        const size = props.sceneRefs.current.calculateSizeOfBoxFactor(tubeBox, false)
        if (size && size.result !== undefined) {
          if (size.result === buttonSize) {
            // No change needed
          } else {
            setButtonSize(size.result)
          }
        }
      }
    }, 50)


    const update = (() => {
      //console.log("update")
      debouncedUpdate()
    })

    update()


    // we need both
    cam.addEventListener("update", update)
    cam.addEventListener("transitionstart", update)

    // Clean up
    return () => {
      cam.removeEventListener("update", update)
      cam.removeEventListener("transitionstart", update)
    }
  }, [tubeBox,])

  const icoRef = useRef<Mesh | null>(null)
  const [buttonSize, setButtonSize,] = useState(0.02)
  const cameraVector = new Vector3()
  useFrame((state) => {
    if (icoRef.current) {
      camera.getWorldPosition(cameraVector)
      icoRef.current.lookAt(cameraVector)
    }
  })


  const onHover = () => {
    hover(true)
  }

  const onHoverOut = () => {
    hover(false)
  }

  useEffect(() => {
    if (hovered) {
      document.body.style.cursor = "pointer"
    } else {
      document.body.style.cursor = "default"
    }

    return () => {
      document.body.style.cursor = "default"
    }
  }, [hovered,])

  // eslint-disable-next-line max-statements
  const handleNewConector = (tracker: Mesh | null) => {
    if (tracker) {
      breadcrumb({
        message: "Click on new Part button",
        level: "info",
        data: {
          partToConnectId: props.tube.apiTypeId,
          partToConnectName: props.tube.name,
        },
      })
      document.body.style.cursor = "default"
      const ray = new Raycaster()
      const isExpand = isExpandReduceApplicable(
        tracker,
        ray,
        scene,
        connections,
        connectionTypes
      )

      breadcrumb({
        message: `is expand or reduce: ${isExpand.isApplicable}`,
        level: "info",
      })

      props.clearTubeUI(TUBE_UI.NONE)

      const trackerPos = MeshUtils.copyWorldPosition(tracker)

      const trackerRot = getInitialRotationTube(tracker, scene)

      // const trackerRot = new Quaternion()
      // tracker.getWorldQuaternion(trackerRot)

      const otherTracker = tracker.name === TubeMarkerEnum.TOP
        ? props.tubeInternalsRef.current.markerBottomMesh
        : props.tubeInternalsRef.current.markerTopMesh

      const otherTrackerPos = MeshUtils.copyWorldPosition(otherTracker!)

      let innerPos = undefined

      const auxTracker = new Vector3(trackerPos.x, trackerPos.y, trackerPos.z)
      innerPos = auxTracker.lerp(
        otherTrackerPos,
        getDistancePercentage(auxTracker, otherTrackerPos, tracker.userData.iELength)
      )

      props.setIsSelected(false)

      const source = {
        partId: props.tube.id,
        markerId: tracker.userData.id,
        markerName: tracker.name,
        posAndRot: {
          inner: {
            pos: innerPos,
            rot: trackerRot,
          },
          outer: {
            pos: trackerPos,
            rot: trackerRot,
          },
        },
        connectionLength: tracker.userData.iELength,
        sizeId: tracker.userData.sizeId,
        expandReduceToFitInfo: isExpand,
        type: PartTypeEnum.tube,
      }

      breadcrumb({
        message: "Set data to show parts modal",
        level: "info",
        data: {
          partId: source.partId,
        },
      })

      setNewConnectionData({ step1: { source, }, })
    }
  }



  const materialColor = useMemo(() => {
    return new Color(hovered ? "#43a7ff" : "#0088ff").convertSRGBToLinear()
  }, [hovered,])

  return (
    <Icosahedron
      userData={{
        type: MarkerType.PLUS_BUTTON,
      }}
      ref={icoRef}
      args={[buttonSize / 2, 3,]}
      position={[0, 0, buttonSize,]}
      //use PointerUp instead of onClick bc on mobile, sometimes it does not get recognized
      onPointerDown={(e) => {
        e.stopPropagation()
        //handleNewConector(mesh)
      }}
      onPointerUp={(e) => {
        e.stopPropagation()
        handleNewConector(mesh)
      }}
      onClick={(e) => {
        e.stopPropagation()
      }}
      onPointerLeave={() => onHoverOut()}
      onPointerEnter={() => onHover()}>
      <meshBasicMaterial color={materialColor} side={BackSide} />
      <Text
        fontSize={buttonSize}
        color={"#fff"}>
        +
      </Text>
    </Icosahedron>
  )
}

export default NewPartButtonTube