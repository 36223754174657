import { AxesHelper, Matrix4, Object3D, Quaternion, Vector3 } from "three"
import { getDragMeshForEnds, getPointsArrayForEnds } from "./meshBuilderEnds"
import { getBoundingBoxPointsForMiddle, getDragMeshForMiddle, getPointsArrayForMiddle }
    from "./meshBuilderMiddle"
import { convertInToCm } from "../../components/main/DesignScreen/utils/UnitUtils"

enum MeshStrategy {
    Mesh = "mesh",
    Markers = "markers",
}

function checkIfMiddleWithoutBoundary(sides: any) {
    if (!sides) {
        console.warn("No sides found")
        return false
    }
    const firstAndLastSides = getFirstAndLastSides(sides)
    const isMiddle = sides[firstAndLastSides[0]].outer.userData.middleSection
    const boundary = sides[firstAndLastSides[0]].outer.userData.boundary
    return isMiddle && !boundary
}

function getMeshStrategy(sides: any): MeshStrategy {
    const firstAndLastSides = getFirstAndLastSides(sides)
    const hasMesh = sides[firstAndLastSides[0]].hasOwnProperty("mesh")
    if (hasMesh) {
        return MeshStrategy.Mesh
    }
    return MeshStrategy.Markers
}

function getBoundary(sides: any) {
    const firstAndLastSides = getFirstAndLastSides(sides)
    const marker = sides[firstAndLastSides[0]].outer
    if (marker.userData.boundary) {
        return marker.userData.boundary
    }
    return null
}

function getFirstAndLastSides(sides: any) {
    if (!sides) {
        console.warn("No sides found")
        return [0, 0,]
    }
    const keys = Object.keys(sides).map(Number)
        .sort((a, b) => a - b)
    const firstKey = keys[0]
    const lastKey = keys[keys.length - 1]

    return [firstKey, lastKey,]
}

function getMarkerQuaternion(sides: any) {
    const firstAndLastSides = getFirstAndLastSides(sides)
    const marker = firstAndLastSides.map(key => sides[key].outer)[0]
    return marker.getWorldQuaternion(new Quaternion())
}

function getPointsArray(
    sides: any,
    segmentLength = 0.25,
    extend = false,
    stepDensity = 1,
    pieceLength?: number
) {
    const strategy = getMeshStrategy(sides)
    const isMiddleWithoutBoundary = checkIfMiddleWithoutBoundary(sides)
    const getPointsArrayFunction
        = strategy === MeshStrategy.Mesh ? getPointsArrayForEnds : getPointsArrayForMiddle

    const segmentLengthToUse = segmentLength ? segmentLength : 0.25

    const markers = getMarkersFromSides(sides)
    return getPointsArrayFunction(
        markers,
        segmentLengthToUse,
        extend,
        stepDensity,
        pieceLength,
        isMiddleWithoutBoundary
    )
}

function getMarkersFromSides(sides: any) {
    const markers = Object.keys(sides).map(key => {
        const marker = sides[key].outer
        if (sides[key].mesh) {
            // if the marker is a mesh, we don't use outer markers
            sides[key].mesh.userData.pointsMarkerName = marker.name
            return sides[key].mesh
        }
        return marker
    })
    return markers
}

function getDragMesh(sides: any, viewSurfaceMesh = false, viewDragMesh = false) {
    if (!sides) {
        return null
    }
    const strategy = getMeshStrategy(sides)
    // if (strategy === MeshStrategy.Mesh) {
    return { strategy, ...getDragMeshForEnds(sides, viewSurfaceMesh, viewDragMesh), }
    // }
    return {
        strategy, ...getDragMeshForMiddle(sides, viewSurfaceMesh, viewDragMesh),
    }
}

function getBoundingBoxPoints(sides: any, segmentLength = 0.25, stepDensity = 1) {
    const strategy = getMeshStrategy(sides)
    const markers = getMarkersFromSides(sides)
    if (strategy === MeshStrategy.Mesh) {
        const points = getPointsArrayForEnds(markers, 0.025)
        return points.boundingBoxPoints
    }
    return getBoundingBoxPointsForMiddle(markers, segmentLength, stepDensity)
}

const findClosestPointInPointsArray = (value: Vector3, pointsArray: any) => {
    const newPosition = value
    let closestPoint = pointsArray.freePositions[0]
    let minDistanceSquared = newPosition.distanceToSquared(closestPoint.position)

    for (let i = 1; i < pointsArray.freePositions.length; i++) {
        const currentPoint = pointsArray.freePositions[i]
        const distanceSquared = newPosition.distanceToSquared(currentPoint.position)

        if (distanceSquared < minDistanceSquared) {
            minDistanceSquared = distanceSquared
            closestPoint = currentPoint

            // Early termination: If we're very close to a point, we can stop searching
            if (minDistanceSquared < 0.00000025) {
                break
            }
        }
    }
    return closestPoint
}

const getClosestPoint = (
    pointToCheck: Vector3,
    pointsArray: any,
    segmentLength: number,
    stepDensity: number,
    markerMesh: any
) => {
    if (pointsArray) {
        return findClosestPointInPointsArray(pointToCheck, pointsArray)
    }
    return getSnappedPosition(pointToCheck, segmentLength, stepDensity, markerMesh)
}

function getSnappedPosition(
    cursorPosition: Vector3,
    segmentLength: number,
    stepDensity: number,
    markerMesh: any
) {
    const firstAndLastSides = getFirstAndLastSides(markerMesh)
    const marker = firstAndLastSides.map(key => markerMesh[key].outer)[0]
    const gridSize = getStep(segmentLength, stepDensity)
    // drawVector3Point(cursorPosition, 0xff0000, 0.001)
    // Inverse transform to get local coordinates
    const inverseMatrix = new Matrix4().copy(marker.matrixWorld)
        .invert()
    const localPos = cursorPosition.clone().applyMatrix4(inverseMatrix)

    // Snap to grid
    localPos.x = Math.round(localPos.x / gridSize) * gridSize
    localPos.y = Math.round(localPos.y / gridSize) * gridSize
    // Optionally snap Z if needed
    // localPos.z = Math.round(localPos.z / gridSize) * gridSize;

    // Transform back to world coordinates
    const snappedPosition = localPos.applyMatrix4(marker.matrixWorld)
    // draw(snappedPosition, 0x00ff00, 0.0005)

    const closestPoint = {
        position: snappedPosition,
        // TODO: change this to the actual mesh name
        meshName: "outer_0",
    }
    return closestPoint
}

const getStep = (segmentLength: number, stepDensity: number) => {
    return convertInToCm(segmentLength) / 100 / stepDensity
}

export {
    getDragMesh,
    getPointsArray,
    getFirstAndLastSides,
    MeshStrategy,
    getMarkerQuaternion,
    getBoundary,
    getBoundingBoxPoints,
    getSnappedPosition,
    getClosestPoint,
    checkIfMiddleWithoutBoundary,
}