/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
/* eslint-disable max-len */
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useRecoilValue, useRecoilState } from "recoil"
import { finishedLoadingAtom, rulerAtom, rulerRelationshipsAtom, sceneAtom } from "../../state/scene/atoms"
import RulerPoints from "./RulerPoints"
import MeasurementLine from "./MeasurementLine"
import tinycolor from "tinycolor2"
import {
    PointInfo,
    RulerProviderProps,
    Faces,
    PartWithBoundingBox,
    RulerPointInfo,
} from "./types"
import { Vector3 } from "three"
import { useParams } from "react-router-dom"
import {
    getRulerRelationshipsByDesign,
    setRulerRelationshipsByDesign,
} from "../../../common/utils/LocalPersistence"
import { messageUtils } from "../../components/main/DesignScreen/scene/LowerRightMessages"
import { selectedItemID } from "../../exportables"
import { multiSelectionSelector } from "../../state/scene/selectors"

const generateRandomColor = () => {
    return tinycolor.random()
        .darken(5)
        .saturate(20)
        .setAlpha(1)
        .toHexString()
}

const RulerProvider: React.FC<RulerProviderProps> = ({ partDataRef, sceneRefs, }) => {
    const selectedPart = useRecoilValue(selectedItemID)
    const finishedLoading = useRecoilValue(finishedLoadingAtom)
    const ruler = useRecoilValue(rulerAtom)
    const [relationships, setRelationships,] = useRecoilState(rulerRelationshipsAtom)
    const sceneData = useRecoilValue(sceneAtom)
    const [partsWithFaces, setPartsWithFaces,] = useState<{ [key: string]: Faces, } | null>(null)
    const { designId, } = useParams()
    const multiSelection = useRecoilValue(multiSelectionSelector)

    // const [cameraDistance, setCameraDistance,] = useState(cameraControls.current?.distance ?? 0),

    useEffect(() => {
        if (!ruler.show) {
            return
        }
        // document.body.style.cursor = "wait"
        messageUtils.custom("Loading ruler points...", {
            duration: 1, showSpinner: true, forceShow: true,
        })
    }, [ruler.show,])

    // Track if we've loaded relationships from localStorage
    const initialLoadCompletedRef = useRef(false)
    const hasFinishedLoading = finishedLoading === "done" || finishedLoading === "camera-adjusted"

    // Load relationships from local storage when component mounts or designId changes
    useEffect(() => {
        if (designId && hasFinishedLoading) {
            const storedRelationships = getRulerRelationshipsByDesign(designId)
            if (storedRelationships && storedRelationships.length > 0) {
                setRelationships(storedRelationships)
                // Mark that we've completed the initial load
                initialLoadCompletedRef.current = true
            } else {
                // Even if no relationships are found, mark as loaded
                initialLoadCompletedRef.current = true
            }
        }
    }, [designId, setRelationships, ruler.show, hasFinishedLoading,])

    // Show initial message when ruler mode is turned on
    useEffect(() => {
        if (ruler.show) {
            messageUtils.custom("Select two points to create a measurement line between them", {
                duration: 20,
                showUpTo: 3,
                minTimeBetweenShows: 30,
                showCloseIcon: true,
            })
        }
    }, [ruler.show,])

    // Save relationships to local storage whenever they change
    useEffect(() => {
        if (designId && ruler.show) {
            setRulerRelationshipsByDesign(designId, relationships)
        }
    }, [relationships, designId,])

    const getPartFaces = (partId: string): Faces | null => {
        const part = partDataRef.current[partId] as PartWithBoundingBox
        if (!part?.boundingBoxMesh?.userData?.getAllFaces) {
            return null
        }
        return part.boundingBoxMesh.userData.getAllFaces() as Faces
    }

    const getPartsWithFaces = () => {
        if (!hasFinishedLoading) {
            return null
        }
        const faces: { [key: string]: Faces, } = {}
        Object.keys(partDataRef.current).forEach((partId) => {
            const partFaces = getPartFaces(partId)
            if (partFaces) {
                faces[partId] = partFaces
            }
        })
        return faces
    }

    useEffect(() => {
        setTimeout(() => {
            const partsWithFaces = getPartsWithFaces()
            setPartsWithFaces(partsWithFaces)
            // document.body.style.cursor = "pointer"
            const cameraDistance = sceneRefs.current.cameraControls?.current?.distance
            if (cameraDistance && cameraDistance > 3) {
                messageUtils.custom("Zoom in to see the measurement points available", {
                    duration: 20,
                    forceShow: true,
                    minTimeBetweenShows: 30,
                    showCloseIcon: true,
                })
            }
        }, 250)
    }, [partDataRef.current, ruler.show, sceneData, hasFinishedLoading,])

    const handleRelationshipCreated = (point1: PointInfo, point2: PointInfo) => {
        // Prevent creating relationship between the same point
        if (point1.partId === point2.partId && point1.pointId === point2.pointId) {
            return
        }

        const color = generateRandomColor()
        setRelationships(prev => [...prev, {
            point1,
            point2,
            color, // Add color to the relationship
        },])

        // Show message after relationship is created
        messageUtils.custom("You can have any number of measurements! Click on one to remove it", {
            duration: 20,
            showUpTo: 3,
            minTimeBetweenShows: 30,
            showCloseIcon: true,
        })
    }

    // Update relationships when bounding boxes change, but only after initial load
    useEffect(() => {
        if (finishedLoading !== "done") {
            return
        }
        const updatedRelationships = relationships.filter(rel => {
            const part1Faces = getPartFaces(rel.point1.partId)
            const part2Faces = getPartFaces(rel.point2.partId)
            return part1Faces && part2Faces
        })

        if (updatedRelationships.length !== relationships.length) {
            setRelationships(updatedRelationships)
        }
    }, [partsWithFaces, relationships, setRelationships, finishedLoading,])

    const getPointPosition = (pointInfo: RulerPointInfo): Vector3 | null => {
        const faces = getPartFaces(pointInfo.partId)
        if (!faces) { return null }

        // Search through all faces to find the point
        for (const face of Object.values(faces)) {
            const point = face.points.find((p: any) => p.id === pointInfo.pointId)
            if (point) {
                return point.position instanceof Vector3
                    ? point.position
                    : new Vector3(...point.position)
            }
        }
        return null
    }

    if (!ruler.show || selectedPart || !hasFinishedLoading || !partsWithFaces || multiSelection.length > 0) {
        return null
    }

    return (
        <>
            <RulerPoints
                partDataRef={partDataRef}
                show={ruler.show}
                onRelationshipCreated={handleRelationshipCreated}
                partsWithFaces={partsWithFaces}
                sceneRefs={sceneRefs}
            />

            {relationships.map((relationship, index) => {
                const point1Position = getPointPosition(relationship.point1)
                const point2Position = getPointPosition(relationship.point2)

                if (!point1Position || !point2Position) { return null }

                return (
                    <MeasurementLine
                        key={index}
                        point1={{
                            ...relationship.point1,
                            position: point1Position,
                        }}
                        point2={{
                            ...relationship.point2,
                            position: point2Position,
                        }}
                        color={relationship.color} // Pass color to MeasurementLine
                        onRemove={() => {
                            setRelationships(prev => prev.filter((_, i) => i !== index))
                        }}
                    />
                )
            })}
        </>
    )
}

export default RulerProvider