import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import {
  groupVisibilityAtom,
  groupOnlyViewAtom,
  partVisibilityAtom,
  groupsSelector,
  partsIdsSelector,
} from "../state/scene/atoms"
import { useComponentRegistry } from "../providers/multiselectProvider/useComponentMethods"
import { GroupType } from "../state/scene/types"

export const useGroupVisibility = () => {
  const groupsAtomList = useRecoilValue(groupsSelector)
  const [groupVisibility, setGroupVisibility,] = useRecoilState(groupVisibilityAtom)
  const [groupOnlyView, setGroupOnlyView,] = useRecoilState(groupOnlyViewAtom)
  const setPartVisibility = useSetRecoilState(partVisibilityAtom)
  const { getComponent, } = useComponentRegistry()
  const partIds = useRecoilValue(partsIdsSelector)

  const updateComponentsVisibility = (partIds: string[], visible: boolean) => {
    partIds.forEach(partId => {
      const component = getComponent(partId)
      if (component) {
        component.setVisibility(visible)
      }
    })
  }

  const showGroup = (groupId: string) => {
    const group = groupsAtomList.find(g => g.id === groupId)
    if (!group) {return}

    setGroupVisibility(prev => ({
      ...prev,
      [groupId]: true,
    }))

    updateComponentsVisibility(group.partIds, true)
    setPartVisibility(prev =>
      prev.filter(id => !group.partIds.includes(id))
    )
  }

  const hideGroup = (groupId: string) => {
    const group = groupsAtomList.find(g => g.id === groupId)
    if (!group) {return}

    setGroupVisibility(prev => ({
      ...prev,
      [groupId]: false,
    }))

    updateComponentsVisibility(group.partIds, false)
    setPartVisibility(prev =>
      Array.from(new Set([...prev, ...group.partIds,]))
    )
  }

  const getAllPartIds = () => {
    return partIds.map(part => part.id)
  }

  const setOnlyViewGroup = (groupId: string) => {
    const group = groupsAtomList.find(g => g.id === groupId)
    if (!group) {return}

    // Hide all groups first
    updateComponentsVisibility(getAllPartIds(), false)

    // Show only the selected group
    updateComponentsVisibility(group.partIds, true)

    setGroupOnlyView({ [groupId]: true, })

    const newVisibility: { [key: string]: boolean, } = {}
    groupsAtomList.forEach(g => {
      newVisibility[g.id] = (g.id === groupId)
    })
    setGroupVisibility(newVisibility)

    // Update part visibility state
    const allPartIds = groupsAtomList.flatMap(g => g.partIds)
    setPartVisibility(allPartIds.filter(partId => !group.partIds.includes(partId)))
  }

  const clearOnlyView = () => {
    setGroupOnlyView({})

    updateComponentsVisibility(getAllPartIds(), true)

    setGroupVisibility({})
    setPartVisibility([])
  }

  const isGroupVisible = (groupId: string) => groupVisibility[groupId] !== false
  const isGroupOnlyView = (groupId: string) => groupOnlyView[groupId] === true
  const hasAnyGroupOnlyView = () => Object.keys(groupOnlyView).length > 0

  return {
    showGroup,
    hideGroup,
    setOnlyViewGroup,
    clearOnlyView,
    isGroupVisible,
    isGroupOnlyView,
    hasAnyGroupOnlyView,
  }
}