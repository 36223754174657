/* eslint-disable max-lines-per-function */
/* eslint-disable react/jsx-max-depth */
/* eslint-disable max-len */
import React, { useMemo, useRef, useState, useEffect, useCallback } from "react"
import Stage from "./stage/Stage"
import SceneCanvas from "./PaperCanvas"
import { useRecoilValue, useRecoilCallback } from "recoil"
import { StatsGl } from "@react-three/drei"
import InstancedMeshProvider from "../../../../providers/instancedMesh/InstancedMeshProvider"
import ConnectionsHandler from "../ConnectionModal/ConnectionsHandler"
import CollisionsProvider from "../../../../providers/collisionProvider/CollisionsProvider"
// import ModelTest from "../ModelTest"
import SelectorProvider from "../../../../providers/mouseProvider/SelectorProvider"
import { v4 as uuidv4 } from "uuid"
import { addPartModal, renderConnectionsData } from "../../../../state/atoms"
import { useDeletePart } from "../../../../state/scene/setters"
import { partsIdsSelector, rulerAtom } from "../../../../state/scene/atoms"
import Part from "./part/Part"
import { PartDataStore, PartIdType, PartTypeEnum } from "../../../../utils/Types"
import ModelTest from "../debug/ModelTest"
import useGetDebugVariables from "../utils/useGetDebugVariables"
import Footer from "./Footer"
import { CameraControls } from "../../../../providers/cameraProvider/CameraControls"
import CameraProvider from "../../../../providers/cameraProvider/CameraProvider"
import SaveHandler from "./SaveHandler"
import { useParams } from "react-router-dom"
import { useRouterContexts } from "../../../../../common/utils/RouterBridge"
import ModalProvider from "../../../../../common/providers/modalProvider/modalProvider"
import CloseingTabLogic from "./ClosingTab"
import MultipleMovementProvider from "../../../../providers/multipleMovementProvider/MultipleMovementProvider"
import GLTFExportManager from "./GLTFExportManager"

import GLTFLoaderProvider from "../../../../providers/GLTFLoaderProvider/GLTFLoaderProvider"
import InstancedMeshSegmentedTubesProvider from "../../../../providers/instancedMeshSegmentedTubesProvider/InstancedMeshSegmentedTubesProvider"
import SlideProvider from "../../../../providers/slideProvider/SlideProvider"
import CloseMarkersProvider from "../../../../providers/closeMarkersProvider/CloseMarkersProvider"
import MultiSelectProvider from "../../../../providers/multiselectProvider/MultiSelectProvider"
import { useLevaControls } from "../../../../providers/debugProvider/useLevaControls"
import { GlobalAnimationProvider } from "./part/parts/utils/animations/GlobalAnimationProvider"
import LocalStorageProvider from "../../../../providers/localStorageBackupProvider/LocalStorageBackupProvider"
import { MiniTutorialsProvider } from "../../../../providers/miniTutorialsProvider/MiniTutorialsProvider"
import { AIProvider } from "../../../../providers/aiProvider/AIProvider"
import { MultiSwapProvider } from "../../../../providers/multiSwapProvider/MultiSwapProvider"
import SceneRaycaster from "../../../../providers/raycastProvider/SceneRaycaster"
import { BoxMeshWithUserData } from "../../../../utils/PartUtils"
import { Mesh } from "three"
import RulerProvider from "../../../../providers/rulerProvider/RulerProvider"
import { useThree } from "@react-three/fiber"
import { SceneRef } from "../../../../state/types"
const Scene = (props: {
    sceneRefs: SceneRef,
}) => {
    const partIdsList = useRecoilValue(partsIdsSelector)
    const renderConnections = useRecoilValue(renderConnectionsData)
    const partModal = useRecoilValue(addPartModal)
    const ruler = useRecoilValue(rulerAtom)
    const deletePart = useDeletePart()
    const { getVariables, } = useGetDebugVariables()
    const cameraControls = useRef<CameraControls | null>(null)
    const [autofocusMode, setAutofocusMode,] = useState(true)
    const userSelectionAutoFocusMode = useRef(null)
    const { userId, designId, } = useParams()
    const { showStats, raycastLayers, } = useLevaControls()

    const routerContext = useRouterContexts()



    // Store multiple types of data per part ID
    const partDataRef = useRef<Record<string, PartDataStore>>({})
    const combinedBoxRef = useRef<BoxMeshWithUserData<Mesh> | null>(null)

    const handleDeletePart = useCallback((id: string) => {
        deletePart(id)
    }, [deletePart,])

    const [parts, setParts,] = useState<PartIdType[]>([])


    useEffect(() => {
        const updateParts = async () => {
            const latestParts = await getLatestParts()
            setParts(latestParts)
        }
        Object.keys(partDataRef.current).forEach(id => {
            //here we are removing any parts that are not in the partIdsList. this should
            //only happen when you create a new design but also a safe guard against any other cases
            //where unmounting is happening
            if (!partIdsList.some(part => part.id === id)) {
                delete partDataRef.current[id]
            }
        })
        updateParts()
    }, [partIdsList,]) // Re-run when partIdsList changes

    const getLatestParts = useRecoilCallback(({ snapshot, }) => async () => {
        const latestPartsList = await snapshot.getPromise(partsIdsSelector)

        // Create a Map to track duplicates
        const partsMap = new Map()
        latestPartsList.forEach(part => {
            if (partsMap.has(part.id)) {
                console.warn("Duplicate part found:", {
                    id: part.id,
                    type: part.type,
                    existingPart: partsMap.get(part.id),
                    duplicatePart: part,
                })
                return // Skip duplicates to keep the first occurrence
            }
            partsMap.set(part.id, part)
        })

        // Filter out duplicates
        const uniqueParts = Array.from(partsMap.values())

        if (uniqueParts.length !== latestPartsList.length) {
            console.warn(`Filtered out ${latestPartsList.length - uniqueParts.length} duplicate parts`)
        }

        return uniqueParts
    })

    const renderParts = () => {
        return parts.map(part => {
            return <Part
                id={part.id}
                type={part.type}
                handleDeletePart={handleDeletePart}
                key={part.id}
                sceneRefs={props.sceneRefs}
                partDataRef={partDataRef}
            />
        })
    }

    // Funcion to test performance
    const stressarray = useMemo(() => {
        const count = getVariables().partCount
            && Number(getVariables().partCount)
            && Number(getVariables().partCount) < 200
            ? Number(getVariables().partCount) : 200

        const array = []

        for (let i = 0; i < count; i++) {
            array.push({
                id: uuidv4(),
                type: PartTypeEnum.connector,
            })
        }

        return array
    }, [])


    const stressTest = () => {
        return stressarray.map((s) => {
            return <ModelTest key={s.id} id={s.id} />
        })
    }

    return (
        <React.Fragment>
            <SceneCanvas />
            <Stage>
                <LocalStorageProvider sceneRefs={props.sceneRefs}>
                    <MiniTutorialsProvider>
                        <CloseMarkersProvider>
                            <MultipleMovementProvider>
                                <SlideProvider>
                                    <CollisionsProvider
                                        debug={
                                            !!(getVariables().colliderDisplay
                                                && (getVariables().colliderDisplay === "TRUE"))
                                        }>
                                        <InstancedMeshProvider>
                                            <ModalProvider>
                                                {raycastLayers && <SceneRaycaster />}
                                                <GlobalAnimationProvider>
                                                    <MultiSelectProvider cameraControls={cameraControls} sceneRefs={props.sceneRefs} designId={designId}
                                                        setAutofocusMode={setAutofocusMode} partDataRef={partDataRef}
                                                        autofocusMode={autofocusMode}>
                                                        <AIProvider>
                                                            <MultiSwapProvider>
                                                                <CameraProvider
                                                                    cameraControls={cameraControls}
                                                                    partCount={partIdsList.length}
                                                                    sceneRefs={props.sceneRefs}
                                                                    designId={designId}
                                                                    autofocusMode={autofocusMode}
                                                                    setAutofocusMode={setAutofocusMode}
                                                                    userSelectionAutoFocusMode={userSelectionAutoFocusMode}
                                                                    partDataRef={partDataRef}
                                                                    combinedBoxRef={combinedBoxRef}
                                                                >
                                                                    <CameraControls ref={cameraControls} />
                                                                    <GLTFLoaderProvider>
                                                                        <InstancedMeshSegmentedTubesProvider>
                                                                            <SelectorProvider>
                                                                                {
                                                                                    getVariables().partCount
                                                                                    && Number(getVariables().partCount) > 0 && stressTest()
                                                                                }
                                                                                {renderParts()}
                                                                                <GLTFExportManager />
                                                                            </SelectorProvider>
                                                                            <>
                                                                                {renderConnections && <ConnectionsHandler
                                                                                    partToAdd={renderConnections.partToAdd!}
                                                                                    compatibleConnectionsIds={renderConnections.compatibleConnectionsIds}
                                                                                    sizeId={renderConnections.sizeId}
                                                                                    menuRef={renderConnections.menuRef}
                                                                                    ignoreSizeCompatibility={renderConnections.ignoreSizeCompatibility}
                                                                                    sceneRefs={props.sceneRefs}
                                                                                    connectionData={{
                                                                                        posAndRot: renderConnections.connectionData.posAndRot,
                                                                                        partId: renderConnections.connectionData.partId,
                                                                                        markerName: renderConnections.connectionData.markerName,
                                                                                        length: partModal?.step1.source?.connectionLength,
                                                                                        swap: renderConnections.connectionData.swap,
                                                                                        type: renderConnections.connectionData.type,
                                                                                        canSlide: renderConnections.connectionData.canSlide,
                                                                                    }}
                                                                                />}
                                                                            </>
                                                                        </InstancedMeshSegmentedTubesProvider>
                                                                        {ruler.show && <RulerProvider partDataRef={partDataRef} sceneRefs={props.sceneRefs} />}
                                                                    </GLTFLoaderProvider>
                                                                </CameraProvider>
                                                            </MultiSwapProvider>
                                                        </AIProvider>
                                                    </MultiSelectProvider>
                                                </GlobalAnimationProvider>
                                            </ModalProvider>
                                        </InstancedMeshProvider>
                                    </CollisionsProvider>
                                </SlideProvider>
                            </MultipleMovementProvider>
                        </CloseMarkersProvider>
                        <CloseingTabLogic />

                        <SaveHandler router={routerContext} userId={userId} designId={designId} />
                        {
                            showStats
                            && <>
                                <StatsGl />
                            </>
                        }
                    </MiniTutorialsProvider>
                </LocalStorageProvider>

            </Stage>
            {/* FPS / MS / MB */}
            <Footer cameraControls={cameraControls} autofocusMode={autofocusMode} setAutofocusMode={setAutofocusMode} userSelectionAutoFocusMode={userSelectionAutoFocusMode}
            />
        </React.Fragment >
    )
}

export default Scene