/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
/* eslint-disable max-len */
import React, { createContext, useContext, useEffect } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { swapJobsAtom, type SwapJob } from "../../state/scene/atoms"
import { PartTypeAPI } from "../../../common/api/Types"
import { useAIProvider } from "../../hooks/useAIProvider"
import { ComponentMethods, useComponentRegistry } from "../multiselectProvider/useComponentMethods"
import { useBlockUI } from "../../hooks/useBlockUI"
import { useNewPart } from "../../state/scene/setters"
import { DoubleSide, Mesh, MeshBasicMaterial, Quaternion, Vector3 } from "three"
import { CompatiblePart } from "../../components/main/DesignScreen/mainLayout/PartsModal/partListModal/partLists/SamePartsPanel"
import { Marker, PartTypeEnum, TubeMarkerEnum } from "../../utils/Types"
import { getMarkerNumber, isInner } from "../../utils/MarkerUtil"
import { PosAndRotType } from "../../state/types"
import { useLevaControls } from "../debugProvider/useLevaControls"
import { messageUtils } from "../../components/main/DesignScreen/scene/LowerRightMessages"
import { SceneType } from "../../state/scene/types"
import { breadcrumb } from "../../../common/utils/sentrySetup"

interface MultiSwapContextType {
    handlePartSwap: (sourcePartIds: string[], newPart: CompatiblePart) => void;
    swapJobs: SwapJob[];
}

type NewPart = {
    instance: {
        updatedAtom: SceneType,
        newPartId: string,
    },
    markerNameMap: {
        [key: string]: string,
    },
}


const MultiSwapContext = createContext<MultiSwapContextType | null>(null)

export const MultiSwapProvider: React.FC<{ children: React.ReactNode, }> = ({ children, }) => {
    const [swapJobs, setSwapJobs,] = useRecoilState(swapJobsAtom)
    const { getComponent, } = useComponentRegistry()
    const { block, unblock, } = useBlockUI()
    const createPart = useNewPart()

    useEffect(() => {
        // Find the first pending job
        const pendingJobIndex = swapJobs.findIndex(job => job.status === "pending")

        if (pendingJobIndex >= 0) {
            const currentJob = swapJobs[pendingJobIndex]

            breadcrumb({
                message: "Starting part swap job",
                level: "info",
                data: {
                    sourcePartIds: currentJob.sourcePartIds,
                    targetPartType: currentJob.targetPart.part.apiTypeId,
                    jobIndex: pendingJobIndex,
                },
            })

            handlePartSwap(currentJob.sourcePartIds, currentJob.targetPart)
                .then(async (newParts: NewPart[]) => {
                    // Mark the job as completed
                    const newPartIds = newParts.map(part => part.instance.newPartId)

                    breadcrumb({
                        message: "Part swap completed successfully",
                        level: "info",
                        data: {
                            newPartIds,
                            sourcePartIds: currentJob.sourcePartIds,
                        },
                    })

                    setSwapJobs(prev => prev.map((job, index) =>
                    (index === pendingJobIndex
                        ? { ...job, status: "completed", targetPartIds: newPartIds, }
                        : job)
                    ))

                    await wait(500)
                    markUpdatedPartsWithColors(newParts)
                    await wait(2000)
                    resetColors(newParts)
                })
                .catch((error) => {
                    console.error("Failed to process swap job:", error)
                    // Mark the job as failed
                    setSwapJobs(prev => prev.map((job, index) =>
                    (index === pendingJobIndex
                        ? { ...job, status: "failed", }
                        : job)
                    ))
                })
        }
    }, [swapJobs,])

    const getInitialMarkerInfoSegTubesConnectors = (partInfo: any, markerMap: { [key: string]: string, }) => {
        const initialMarkerName = partInfo.initialMarkerName
        const initialMarkerNameHasSuffix = initialMarkerName?.includes("_0")
        const initialMarkerNameWithoutSuffix = initialMarkerNameHasSuffix ? initialMarkerName.split("_0")[0] : initialMarkerName

        let newInitialMarkerName
        for (const [key, value,] of Object.entries(markerMap)) {
            if (value.includes(initialMarkerNameWithoutSuffix)) {
                newInitialMarkerName = key
            }
        }

        if (initialMarkerNameHasSuffix) {
            newInitialMarkerName = `${newInitialMarkerName}_0`
        }

        const position = partInfo.position
        const rotation = partInfo.rotation

        const posAndRot: PosAndRotType = {
            inner: {
                pos: position,
                rot: rotation,
            },
            outer: {
                pos: position,
                rot: rotation,
            },
        }

        return {
            posAndRot,
            initialMarkerName: newInitialMarkerName,
            length: partInfo.length,
            lengthNegativeSide: partInfo.lengthNegativeSide,
            markerOffset: partInfo.markerOffset,
        }
    }

    const getInitialMarkerInfoTubes = (partInfo: any, markerMap: { [key: string]: string, }) => {
        return {
            length: partInfo.length,
            originMarkerName: partInfo.originMarkerName,
            posAndRot: {
                inner: {
                    pos: partInfo.position,
                    rot: partInfo.rotation,
                },
                outer: {
                    pos: partInfo.position,
                    rot: partInfo.rotation,
                },
            },
        }
    }

    const getInitialMarkerInfo = (part: ComponentMethods, markerMap: { [key: string]: string, }) => {
        const partInfo = part.getPartInfo()
        if (partInfo.type === PartTypeEnum.tube) {
            return getInitialMarkerInfoTubes(partInfo, markerMap)
        }
        return getInitialMarkerInfoSegTubesConnectors(partInfo, markerMap)
    }

    const handlePartSwap = async (sourcePartIds: string[], newPart: CompatiblePart) => {
        block()
        const components = []
        const newParts = []
        try {
            for (const sourcePartId of sourcePartIds) {
                const part = getComponent(sourcePartId)
                if (part && newPart.markerMap) {
                    breadcrumb({
                        message: "Processing individual part swap",
                        level: "info",
                        data: {
                            sourcePartId,
                            targetPartType: newPart.part.apiTypeId,
                        },
                    })

                    components.push(part)
                    const markerInfo = getInitialMarkerInfo(part, newPart.markerMap)
                    const partParams = {
                        duplicatedFrom: sourcePartId,
                        part: newPart.part,
                        ...markerInfo,
                    }
                    const newPartInstance = await createPart(partParams, "multiSwap")
                    newParts.push({
                        instance: newPartInstance,
                        markerNameMap: newPart.markerMap,
                    })
                    part.deletePart()
                } else {
                    breadcrumb({
                        message: "Part swap skipped",
                        level: "warning",
                        data: {
                            sourcePartId,
                            reason: part ? "Missing marker map" : "Part not found",
                        },
                    })

                    if (!part) {
                        console.warn(`Part ${sourcePartId} not found, skipping...`)
                    } else if (!newPart.markerMap) {
                        console.warn(`Marker map not found for part ${sourcePartId}, skipping...`)
                    }
                }
            }
            return newParts
        } catch (error) {
            breadcrumb({
                message: "Part swap failed",
                level: "error",
                data: {
                    sourcePartIds,
                    error: error instanceof Error ? error.message : String(error),
                },
            })

            console.error("Error in handlePartSwap", error)
            throw error
        } finally {
            unblock()
            messageUtils.custom(`Finished swapping ${sourcePartIds.length} parts`, {
                duration: 5,
                forceShow: true,
            })
            return newParts
        }
    }

    const markUpdatedPartsWithColors = (newParts: NewPart[]) => {
        for (const newPart of newParts) {
            const newPartComponent = getComponent(newPart.instance.newPartId)
            if (newPartComponent && newPart.markerNameMap) {
                // visualDebugMarkerMap(newPartComponent, components[0], newPart.markerNameMap)
                newPartComponent.updateColor(0x0089ee)
            }
        }
    }

    const resetColors = (newParts: NewPart[]) => {
        for (const newPart of newParts) {
            const newPartComponent = getComponent(newPart.instance.newPartId)
            if (newPartComponent) {
                newPartComponent.originalColor()
            }
        }
    }

    const visualDebugMarkerMap = (newPart: ComponentMethods, oldPart: ComponentMethods, markerNameMap: { [key: string]: string, }) => {
        const newPartMarkers = newPart.getAllMarkers(true)
        const oldPartMarkers = oldPart.getAllMarkers(true)
        const newPartMesh = newPart.getMesh()
        const oldPartMesh = oldPart.getMesh()
        newPartMesh.material.visible = false
        oldPartMesh.material.visible = false

        const colors = [
            "#FF0000",
            "#00FF00",
            "#0000FF",
            "#FFFF00",
            "#00FFFF",
            "#FF00FF",
        ]


        for (const [index, key,] of Object.entries(markerNameMap)) {
            if (markerNameMap.hasOwnProperty(key) && key.includes("outer")) {
                const newMarker = newPartMarkers.find((m: Marker) => m.name.includes(markerNameMap[key]))
                const oldMarker = oldPartMarkers.find((m: Marker) => m.name.includes(key))

                if (!oldMarker) {
                    console.log("oldMarker not found", key)
                }
                if (!newMarker) {
                    console.log("newMarker not found", markerNameMap[key])
                }

                const setMarkerVisibility = (marker: Mesh, color: string) => {
                    marker.visible = true
                    const materials = Array.isArray(marker.material) ? marker.material : [marker.material,]
                    materials.forEach(mat => {
                        const material = mat as MeshBasicMaterial
                        material.visible = true
                        material.opacity = 1
                        material.transparent = false
                        material.color.set(color)
                        material.wireframe = false
                        material.side = DoubleSide
                    })
                }
                if (newMarker && oldMarker) {
                    const color = colors.pop()
                    setMarkerVisibility(newMarker, color!)
                    setMarkerVisibility(oldMarker, color!)
                }
            }
        }
    }

    const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

    const value = {
        handlePartSwap,
        swapJobs,
    }

    return (
        <MultiSwapContext.Provider value={value}>
            {children}
        </MultiSwapContext.Provider>
    )
}

export const useMultiSwap = () => {
    const context = useContext(MultiSwapContext)
    if (!context) {
        throw new Error("useMultiSwap must be used within a MultiSwapProvider")
    }
    return context
}