/* eslint-disable max-len */
import React, { useCallback, MutableRefObject, useEffect, useRef } from "react"
import { Mesh, Vector3 } from "three"
import useUnitConversion, { convertCmToIn, convertInToCm } from "../../../../../../utils/UnitUtils"
import TShapedLine from "./TShapedLine"
import TextLabel from "./TextLabel"
import { CameraControls } from "camera-controls/dist/CameraControls"
import InputLabel from "./InputLabel"
import { useDebugTools } from "../../../../../../../../../providers/debugProvider/useDebugTools"

interface MeasurementProps {
    pointA: Vector3;
    pointB: Vector3;
    cameraControls?: MutableRefObject<CameraControls | undefined>;
    onNewPoint: (point: Vector3) => void;
    onFocusChange: (isFocused: boolean) => void;
}

export const distanceToUnit = (distance: number) => {
    const distanceCm = distance * 100
    const distanceIn = convertCmToIn(distanceCm)
    return { distanceCm, distanceIn, }
}

// Converts from cm/in to three.js units (reverse of distanceToUnit)
export const unitToDistance = (value: number, unit: string): number => {
    if (unit === "in") {
        // Convert inches to cm first, then to three.js units
        const valueCm = convertInToCm(value)
        return valueCm / 100
    } else {
        // Convert cm directly to three.js units
        return value / 100
    }
}

const Measurement = ({ pointA, pointB, onNewPoint, onFocusChange, cameraControls, }: MeasurementProps) => {
    const distance = pointA.distanceTo(pointB)
    const { distanceCm, distanceIn, } = distanceToUnit(distance)
    const { unit, } = useUnitConversion()
    const textRef = useRef<Mesh>(null)
    const { drawVector3Point, } = useDebugTools()

    pointA.z -= 0.0005
    pointB.z -= 0.0005

    // Calculate label position with dynamic scaling based on camera distance
    const getLabelPosition = () => {
        const cameraDistance = cameraControls?.current?.distance ?? 2
        const labelScale = 0.4
        const scaleFactor = cameraDistance * (labelScale / 10)

        return new Vector3().addVectors(
            pointB,
            pointB.clone()
                .sub(pointA)
                .normalize()
                .multiplyScalar(scaleFactor)
        )
    }

    const labelPosition = getLabelPosition()

    useEffect(() => {
        if (textRef.current) {
            const lookAtPointLocal = new Vector3(0, 0, -1)
            const lookAtPointWorld = textRef.current.localToWorld(lookAtPointLocal)
            textRef.current.lookAt(lookAtPointWorld)
        }
    }, [])

    const measurementNumber = unit === "in"
        ? parseFloat(distanceIn.toFixed(2))
        : parseFloat(distanceCm.toFixed(2))
    const measurementUnit = unit === "in" ? "\"" : " cm"

    const onTextChange = useCallback((newValue: number) => {
        const threeJSUnits = unitToDistance(newValue, unit)
        const pointACorrected = new Vector3(pointA.x, pointA.y, pointA.z + 0.0005)
        const pointBCorrected = new Vector3(pointB.x, pointB.y, pointB.z + 0.0005)
        const direction = pointACorrected.clone().sub(pointBCorrected)
            .normalize()
        const newPoint = pointBCorrected.clone().add(direction.multiplyScalar(threeJSUnits))
        onNewPoint(newPoint)
    }, [pointA, pointB, unit, drawVector3Point, measurementUnit, onNewPoint,])

    return (
        <>
            <TShapedLine pointA={pointA} pointB={pointB} />
            <InputLabel
                position={labelPosition}
                number={measurementNumber}
                unit={measurementUnit}
                onTextChange={onTextChange}
                onFocusChange={onFocusChange}
            />
        </>
    )
}

export default Measurement