/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
import React, { useCallback, useMemo } from "react"
import { Fragment, MutableRefObject, useEffect, useRef, useState } from "react"
import {
  Box3,
  Box3Helper,
  Color,
  Mesh,
  Object3D,
  PerspectiveCamera,
  Quaternion,
  Vector3,
} from "three"
import {
  ConnectorValues,
  GenericPartState,
  PartTypeEnum,
  SegmentedTubeValues
} from "../../../../utils/Types"
import { Html } from "@react-three/drei"
import ReactDOM from "react-dom"
import { useThree } from "@react-three/fiber"
import { v4 as uuidv4 } from "uuid"
import ConnectorRender from "../scene/part/parts/connector/Render/ConnectorRender"
import { PartTypeAPI } from "../../../../../common/api/Types"
import { useRecoilValue } from "recoil"
import useCamera from "../../../../providers/cameraProvider/useCamera"
import isMobile from "ismobilejs"
import { getNewPartOrigin } from "../../../../state/scene/util"
import { PosAndRotType, SceneRef } from "../../../../state/types"
import { ApiClient } from "../../../../../common/api/ApiClient"
import { colorsSelector } from "../../../../state/initialDataSelectors"
import SegmentedTubeRender from "../scene/part/parts/segmentedTube/render/SegmentedTubeRender"
import { getPlaceholderIdWithoutPosition, getSideAndPosition } from "../../../../utils/MarkerUtil"
import * as Sentry from "@sentry/react"
import Connection from "./Connection"
import useClonedScene from "./useClonedScene"
import { ConnectionType } from "./Connection"
import { useLevaControls } from "../../../../providers/debugProvider/useLevaControls"
import { breadcrumb } from "../../../../../common/utils/sentrySetup"

type Props = {
  partToAdd: PartTypeAPI,
  partsToRender: {
    placeholderId: string,
    connectorId: string,
  }[],
  menuRef: MutableRefObject<HTMLDivElement | null>,
  connectionPosition: Vector3,
  connectionRotation: Quaternion,
  connectionLength: number,
  posAndRot: PosAndRotType,
  actualPartType?: PartTypeEnum,
  newPartHandler: (placeholderId: string, degree: number) => void,
  makeVisible?: () => void,
  canSlide?: boolean,
  sceneRefs: SceneRef,
}

const getRotationCombinations = (steps: number) => {
  const rotationCombinations = []
  let rotationSteps = steps

  if (steps < 90) {
    rotationSteps = 90
  }

  for (let i = 0; i < 360; i += rotationSteps || 0) {
    // for (let i = 0; i < 360; i += 90 || 0) {
    rotationCombinations.push(i)
  }
  return rotationCombinations
}



const RenderConnections = (props: Props) => {
  const { gl, scene, camera, } = useThree()
  const [partToRender, setPartToRender,] = useState<GenericPartState | null>(null)
  const [connections, setConnections,] = useState<ConnectionType[]>([])
  const [generationPhase, setGenerationPhase,] = useState("initial")
  const [index, setIndex,] = useState(0)
  const [degrees, setDegrees,] = useState(0)
  const colors = useRecoilValue(colorsSelector)
  const { cameraPadding, } = useLevaControls()

  //TODO: Put back error text
  const [errorText, setErrorText,] = useState<string | undefined>(undefined)
  const steps = props.partToAdd.rotationSteps || 0
  const rotationCombinations = getRotationCombinations(steps)
  const { fitCameraToPart, fitCameraToBox, setShowCameraControls, } = useCamera()
  const clonedSceneRef = useClonedScene(scene)
  const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

  useEffect(() => {
    setShowCameraControls(false)
    return () => {
      setShowCameraControls(true)
    }
  }, [])

  const isConnector = partToRender && partToRender.type === PartTypeEnum.connector
  const sceneToUse = isConnector ? scene : clonedSceneRef.current

  if (isConnector) {
    breadcrumb({
      message: "RenderConnections: Is connector",
      level: "info",
    })
  } else {
    breadcrumb({
      message: "RenderConnections: Is not connector",
      level: "info",
    })
  }

  const renderImage = (mesh: Mesh, cleanup?: () => void, box?: Box3) => {
    if (!sceneToUse) {
      return ""
    }

    // causes issues with the transform controls so we turn them off
    if (props.sceneRefs.current && props.sceneRefs.current.setTransformMode) {
      props.sceneRefs.current.setTransformMode("off")
    }
    sceneToUse.children.forEach((child) => {
      if ((child as any).isTransformControls) {
        sceneToUse.remove(child)
      }
    })

    mesh.updateWorldMatrix(true, true)
    const newCam = camera.clone(true) as PerspectiveCamera
    if (isMobile(window.navigator).phone) {
      newCam.aspect = window.innerWidth / (window.innerHeight / 3)
    }
    if (newCam) {
      if (box) {
        // const boxHelper = new Box3Helper(box, new Color("lime"))
        // sceneToUse.add(boxHelper)
        breadcrumb({
          message: "RenderConnections: Box is not null",
          level: "info",
        })
        sceneToUse.attach(newCam)
        fitCameraToBox(newCam as PerspectiveCamera, box, sceneToUse, cameraPadding)
      } else {
        breadcrumb({
          message: "RenderConnections: Box is null",
          level: "info",
        })
        sceneToUse.add(newCam)
        fitCameraToPart(newCam, mesh, sceneToUse)
      }

      gl.setClearColor(new Color("white"))
      gl.autoClear = true
      gl.render(sceneToUse, newCam)

      const imageContainer = document.createElement("div")
      imageContainer.style.position = "relative"
      const imgData = gl.domElement.toDataURL("image/jpeg")

      const newImage = document.createElement("img")
      newImage.src = imgData
      newImage.className = "renderImage"
      if (isMobile(window.navigator).phone) {
        newImage.style.height = `${window.innerHeight / 3}px`
      }
      sceneToUse.remove(newCam)
      return imgData
    }
  }

  const preparePartsToRender = () => {
    const parts: GenericPartState[] = []
    props.partsToRender.forEach(part => {
      const newId = uuidv4()
      const color = colors[props.partToAdd.colorId].hex
        ? colors[props.partToAdd.colorId].hex
        : "#fff"

      const { markerPosition, } = getSideAndPosition(part.placeholderId)

      const marker
        = props.partToAdd.connections.find(
          m => m.placeholderId
            === getPlaceholderIdWithoutPosition(part.placeholderId))!

      const { initialMarkerName, position, rotation, }
        = getNewPartOrigin(
          marker.iELength,
          props.connectionLength,
          marker.placeholderId,
          props.posAndRot,
          props.actualPartType)

      const onDegreeChange = (degree: number, connection: ConnectionType) => {
        setDegrees(degree)
        setIndex(connection.index)
        setPartToRender(partsToRender[connection.index - 1])
      }

      const newPart = ApiClient.translateToPartValue(
        props.partToAdd,
        newId,
        position,
        rotation,
        color,
        (markerPosition !== undefined && !isNaN(markerPosition as number))
          ? `${initialMarkerName}_${markerPosition}`
          : initialMarkerName
      )
      if (newPart.type === PartTypeEnum.segmentedTube) {
        newPart.defaultLength = 3
      }
      const newConnection: ConnectionType = {
        images: [],
        canSlide: false,
        onClick: () => { },
        box: undefined,
        onDegreeChange,
        index: 0,
      }
      connections.push(newConnection)
      parts.push(newPart)
    })
    return parts
  }

  const partsToRender = useMemo(() => {
    return preparePartsToRender()
  }, [])


  const generateCombinations = (mesh: Mesh, cleanup?: () => void, box?: Box3) => {
    const connectionToUpdate = connections[index - 1]

    const img = renderImage(mesh, cleanup, box)
    connectionToUpdate.images = [img,]
    if (generationPhase === "initial") {
      cleanup && cleanup()
      changeRender()
    }

    setConnections([...connections,])
  }

  const onMeshInstantiated = useCallback((mesh: Mesh, cleanup?: () => void, box?: Box3) => {
    let markerPosition
    if (partToRender?.type !== PartTypeEnum.tube) {
      markerPosition = getSideAndPosition(partToRender!.initialMarkerName).markerPosition
    }
    const canSlide = Boolean(props.canSlide || markerPosition)
    const onClick = (degree: number) => {
      if (partToRender?.type !== PartTypeEnum.tube) {
        props.newPartHandler(partToRender!.initialMarkerName, degree)
      }
    }
    const connectionToUpdate = connections[index - 1]
    connectionToUpdate.canSlide = canSlide
    connectionToUpdate.onClick = onClick
    connectionToUpdate.index = index
    setConnections([...connections,])
    generateCombinations(mesh, cleanup, box)
  }, [index, partToRender,])

  const changeRender = () => {
    if (partsToRender[index]) {
      setIndex(index + 1)
      setPartToRender(partsToRender[index])

    } else if (generationPhase === "initial") {
      setIndex(1)
      setGenerationPhase("combinations")
      setPartToRender(partsToRender[0])
    } else {
      setPartToRender(null)
    }
  }

  useEffect(() => {
    setTimeout(changeRender, 0)
  }, [])

  return (
    <Fragment>
      <Html>
        {props.menuRef?.current && ReactDOM.createPortal(
          <Connection
            errorText={errorText}
            connections={connections}
            steps={steps}
            isMobile={isMobile(window.navigator).phone}
          />,
          props.menuRef.current
        ) as React.ReactPortal}
      </Html>
      {(partToRender && partToRender.type === PartTypeEnum.connector) && <ConnectorRender
        key={partToRender.id}
        connector={partToRender as ConnectorValues}
        onMeshInstantiated={onMeshInstantiated}
        onRotationChange={generateCombinations}
        degrees={degrees}
        color={"yellow"} />
      }
      {(partToRender && partToRender.type === PartTypeEnum.segmentedTube && sceneToUse)
        && <SegmentedTubeRender
          key={partToRender.id}
          tube={partToRender as SegmentedTubeValues}
          onMeshInstantiated={onMeshInstantiated}
          onRotationChange={generateCombinations}
          degrees={degrees}
          scene={sceneToUse}
          color={"yellow"} />
      }
    </Fragment>
  )
}

export default RenderConnections