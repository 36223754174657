import React from "react"
import { Collapse } from "antd"
import styled from "styled-components"
import { CompatiblePart } from "./SamePartsPanel"

const PartCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border: 1px solid #f0f0f0;
    margin: 4px 0;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
        background-color: #d8e7ff;
    }
`

const PartImage = styled.img`
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin-right: 12px;
`

const PartInfo = styled.div`
    display: flex;
    flex-direction: column;
`

const PartName = styled.span`
    font-weight: 700;
`

const EmptyState = styled.div`
    text-align: center;
    color: #666;
`

const LeftContent = styled.div`
    display: flex;
    align-items: center;
`

interface SwappablePartsListProps {
    compatibleParts: CompatiblePart[];
    onPartSelect: (part: CompatiblePart) => void;
    onCollapse: () => void;
    showEmptyState?: boolean;
}

export const SwappablePartsList: React.FC<SwappablePartsListProps> = ({
    compatibleParts,
    onPartSelect,
    onCollapse,
    showEmptyState,
}) => {
    const [hoveredPartId, setHoveredPartId,] = React.useState<string | null>(null)

    const panelHeader = hoveredPartId
        ? "Click to swap"
        : `Compatible parts (${compatibleParts.length})`

    return (
        <Collapse
            defaultActiveKey={["compatible-parts",]}
            onChange={(keys) => {
                if (keys.length === 0) {
                    onCollapse()
                }
            }}
        >
            <Collapse.Panel
                header={panelHeader}
                key="compatible-parts">
                {showEmptyState ? (
                    <EmptyState>No compatible parts found</EmptyState>
                ) : (
                    compatibleParts.map((part) => (
                        <PartCard
                            key={part.part.id}
                            onClick={() => onPartSelect(part)}
                            onMouseEnter={() => setHoveredPartId(part.part.id)}
                            onMouseLeave={() => setHoveredPartId(null)}
                        >
                            <LeftContent>
                                <PartImage
                                    crossOrigin="anonymous"
                                    src={part.part.modelIcon}
                                    alt={part.part.name}
                                />
                                <PartInfo>
                                    <PartName>{part.part.name}</PartName>
                                </PartInfo>
                            </LeftContent>
                        </PartCard>
                    ))
                )}
            </Collapse.Panel>
        </Collapse>
    )
}