/* eslint-disable max-len */
import React from "react"
import { useRecoilValue } from "recoil"
import { unitSelector } from "../../../../state/scene/atoms"
import { useUpdateUnit } from "../../../../state/scene/setters"
import { UnitType } from "../../../../state/scene/types"
import PillToggle from "../../../../../common/components/PillToggle"

interface UnitToggleProps {
    dropdown?: boolean;
    compact?: boolean;
}

const UnitToggle = ({ dropdown = false, compact = false, }: UnitToggleProps) => {
    const unit = useRecoilValue(unitSelector)
    const updateUnit = useUpdateUnit()

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        updateUnit(event.target.value as UnitType)
    }

    const handlePillChange = (newUnit: string) => {

        updateUnit(newUnit as UnitType)
    }

    if (dropdown) {
        return (
            <select
                value={unit}
                onChange={handleChange}
                style={{
                    padding: "2px 6px",
                    textDecoration: "none",
                    height: "24px",
                    borderRadius: "4px",
                    border: "1px solid #e0e0e0",
                    fontSize: "12px",
                    backgroundColor: "white",
                    cursor: "pointer",
                    color: "grey",
                    appearance: "none",
                    WebkitAppearance: "none",
                    MozAppearance: "none",
                    backgroundImage: "url(\"data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23999999' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e\")",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right 4px center",
                    backgroundSize: "12px",
                    paddingRight: compact ? "16px" : "24px",
                    margin: "0 4px",
                    minWidth: compact ? "40px" : "50px",
                    outline: "none",
                    marginLeft: compact ? "4px" : "10px",
                }}
            >
                <option value="in">in</option>
                <option value="cm">cm</option>
            </select>
        )
    }

    return (
        <PillToggle
            options={["in", "cm",]}
            activeOption={unit}
            onChange={handlePillChange}
        />
    )
}

export default UnitToggle
