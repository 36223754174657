/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
/* eslint-disable max-len */
import React, { useState, useEffect, ReactNode, useRef } from "react"
import { useControls, Leva, button } from "leva"
import { atom, useRecoilState, useSetRecoilState, useRecoilValue, useRecoilCallback } from "recoil"
import { sceneAtom } from "../../state/scene/atoms"
import { useComponentRegistry } from "../multiselectProvider/useComponentMethods"
import { PartTypeEnum } from "../../utils/Types"

// Define atoms
const adminModeState = atom({
    key: "adminModeState", // unique ID (with respect to other atoms/selectors)
    default: false, // default value (aka initial value)
})

const controlsState = atom({
    key: "controlsState",
    default: {}, // default empty object, adjust as needed
})

type MarkerControlsConfig = {
    viewHelpers: boolean,
    viewTextOverlaysForSegmentedTube: boolean,
    viewInnerMarkers: boolean,
    viewOuterMarkers: boolean,
    viewAttachmentPoint: boolean,
    viewSetupAttachmentFunctionDebug: boolean,
    viewMarkerPositionsWithLabels: boolean,
    viewOriginPoint: boolean,
    hideMergedMeshes: boolean,
    realTimeOffsetAndPositionDrawing: boolean,
    viewLocalAxis: boolean,
    viewNewPositionWhenGrowingLength: boolean,
    viewEndsStarts: boolean,
};

type CameraControlsConfig = {
    useCameraUp: boolean,
    useFit: boolean,
    yOffset: number,
    xOffset: number,
    zOffset: number,
    removeCameraLock: boolean,
    polarRotateSpeed: number,
    azimuthRotateSpeed: number,
    cameraPadding: number,
    debugCameraCentering: boolean,
}

type MoveControlsConfig = {
    viewDragMesh: boolean,
    viewSurfaceMesh: boolean,
    viewSurfacePoints: boolean,
    viewIntersectionPoints: boolean,
    stepDensity: {
        value: number,
        min: number,
        max: number,
        step: number,
    } | number,
    debugAlignment: boolean,
    debugMeasurements: boolean,
};

type twoDdebuggingConfig = {
    view2DPolygon: boolean,
    view2DPoints: boolean,
};

type NXerConfig = {
    showDimensionAxisArrows: boolean,
    showGrowthDirectionArrows: boolean,
    drawOtherLines: boolean,
    drawCombinedBoxCenter: boolean,
    drawStepPoints: boolean,
    posOperationDrawOriginalCenters: boolean,
    posOperationDrawNewCenters: boolean,
    posOperationDrawNewPositions: boolean,
    multiplierDirectionDebug: boolean,
    canvasSplitDebug: boolean,
    nxLogs: boolean,
};

type snapDebugConfig = {
    seeSnapLogs: boolean,
    visualizeSnapChecks: boolean,
};

type historyUndoRedoConfig = {
    includeHistoryLogger: boolean,
    seeHistoryLogs: boolean,
    seeSceneAtomLogs: boolean,
    seePendingChangesLogs: boolean,
};

type multiMoveDebugConfig = {
    showCollidingBoundingBoxes: boolean,
    showClonedModels: boolean,
    keepClonedModels: boolean,
    boundingBoxFromHighlightedParts: boolean,
    intersectingPartsDebug: boolean,
    viewStoreBoundingBoxesOfNotHighlightedParts: boolean,
};

type alignmentDebugConfig = {
    drawAllLines: boolean,
    debugLogsWhileDoingIntervalCheck: boolean,
};

type scalerDebuggingConfig = {
    storeAndIncrementMiddles: boolean,
    offsetBreadcrumbs: boolean,
    showMarkerNormals: boolean,
    showScalerNormals: boolean,
    showOrientedBoundingBox: boolean,
    seeRaycastsForEnds: boolean,
    logNormalLineSequences: boolean,
    viewPercentageLabels: boolean,
    showMarkerPositionForBaseParts: boolean,
    segmentedTubePositionWithMarker: boolean,
    createViewsDebug: boolean,
    middlePositionLogs: boolean,
    scaleEndLogs: boolean,
    spatialMapDebug: boolean,
};

type AppControlsConfig = {
    printJson: any,
    runSegmentedTubeSnapChecks: any,
    createInstancedMeshOriginsDebug: boolean,
    vertexGroupDebug: boolean,
    seeOBBdebugOnSelect: boolean,
    visualizeFacesForOBB: boolean,
    viewTubeNames: boolean,
    showStats: boolean,
    showGlobalAxes: boolean,
    useAiPanel: boolean,
    showDebugText: boolean,
    multiswapDebug: boolean,
    viewColliders: boolean,
    showAllPartsIds: boolean,
    logRenders: boolean,
    segmentedPlusButtonPosDebug: boolean,
    drawBoundingBoxMeshesOfUserSelection: boolean,
    drawCombinedBoxOfUserSelection: boolean,
    debugBoundingBoxesForAllParts: boolean,
    rulerDebug: boolean,
};

type SceneRaycasterControlsConfig = {
    firstHitOnly: boolean,
    raycastLayers: boolean,
    include: any,
    exclude: any,
}

type AdminConfig = {
    admin: boolean,
}

// Updated getBooleanFromUrl function to assume true when the param exists without an explicit value
const getBooleanFromUrl = (paramName: string, defaultValue: boolean): boolean => {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has(paramName)) {
        const paramValue = urlParams.get(paramName)
        // If the parameter exists with no value or explicitly set as "true", return true
        if (paramValue === "" || paramValue === "true") {
            return true
        }
        // If the parameter explicitly equals "false", return false
        if (paramValue === "false") {
            return false
        }
        // For any other value you might choose to return true (or adjust based on your needs)
        return true
    }
    return defaultValue
}

export const DebugState = () => {
    const [adminMode, setAdminMode,] = useRecoilState(adminModeState)

    useEffect(() => {
        setAdminMode(getBooleanFromUrl("admin", false))
    }, [setAdminMode,])

    const { getComponent, } = useComponentRegistry()

    const printJsonCallback = useRecoilCallback(({ snapshot, }) => async () => {
        const latestSceneData = await snapshot.getPromise(sceneAtom)
        const sceneDataJSON = JSON.stringify(latestSceneData, null, 2)
        const blob = new Blob([sceneDataJSON,], { type: "application/json", })
        const url = URL.createObjectURL(blob)
        window.open(url, "_blank")
    }, [])

    const segmentedPartsMigrationConnectionsCallback = useRecoilCallback(({ snapshot, }) => async () => {
        const latestSceneData = await snapshot.getPromise(sceneAtom)

        const processSegmentedParts = async () => {
            for (const partId of latestSceneData.partsIds) {
                const partComponent = getComponent(partId.id)
                if (partId.type === PartTypeEnum.segmentedTube) {
                    if (typeof partComponent?.seeWhatToDisconnectedAndConnect === "function") {
                        console.log(`Processing segmented part: ${partId}`)
                        await partComponent.seeWhatToDisconnectedAndConnect()
                        // Wait for 1 second before processing next part
                        await new Promise(resolve => setTimeout(resolve, 1000))
                    }
                }
            }
            console.log("Finished processing segmented parts")
        }

        processSegmentedParts()
    }, [])



    // Initialize configs with URL parameters if present
    const markerControlsConfig: MarkerControlsConfig = {
        viewHelpers: getBooleanFromUrl("viewHelpers", false),
        viewTextOverlaysForSegmentedTube: getBooleanFromUrl("viewTextOverlaysForSegmentedTube", false),
        viewInnerMarkers: getBooleanFromUrl("viewInnerMarkers", false),
        realTimeOffsetAndPositionDrawing: getBooleanFromUrl("realTimeOffsetAndPositionDrawing", false),
        viewOuterMarkers: getBooleanFromUrl("viewOuterMarkers", false),
        viewAttachmentPoint: getBooleanFromUrl("viewAttachmentPoint", false),
        viewSetupAttachmentFunctionDebug: getBooleanFromUrl("viewSetupAttachmentFunctionDebug", false),
        viewMarkerPositionsWithLabels: getBooleanFromUrl("viewMarkerPositionsWithLabels", false),
        viewNewPositionWhenGrowingLength: getBooleanFromUrl("viewNewPositionWhenGrowingLength", false),
        viewOriginPoint: getBooleanFromUrl("viewOriginPoint", false),
        hideMergedMeshes: getBooleanFromUrl("hideMergedMeshes", false),
        viewLocalAxis: getBooleanFromUrl("viewLocalAxis", false),
        viewEndsStarts: getBooleanFromUrl("viewEndsStarts", false),
    }

    const cameraControlsConfig: CameraControlsConfig = {
        useCameraUp: getBooleanFromUrl("useCameraUp", false),
        useFit: getBooleanFromUrl("useFit", false),
        yOffset: 1.5,
        xOffset: 1.5,
        zOffset: 1.5,
        removeCameraLock: getBooleanFromUrl("removeCameraLock", true),
        polarRotateSpeed: 5,
        azimuthRotateSpeed: 5,
        cameraPadding: 2,
        debugCameraCentering: getBooleanFromUrl("debugCameraCentering", false),
    }

    const twoDdebuggingConfig: twoDdebuggingConfig = {
        view2DPolygon: getBooleanFromUrl("view2DPolygon", false),
        view2DPoints: getBooleanFromUrl("view2DPoints", false),
    }

    const nxerConfig: NXerConfig = {
        showDimensionAxisArrows: getBooleanFromUrl("showDimensionAxisArrows", false),
        showGrowthDirectionArrows: getBooleanFromUrl("showGrowthDirectionArrows", false),
        drawOtherLines: getBooleanFromUrl("drawOtherLines", false),
        drawCombinedBoxCenter: getBooleanFromUrl("drawCombinedBoxCenter", false),
        drawStepPoints: getBooleanFromUrl("drawStepPoints", false),
        posOperationDrawOriginalCenters: getBooleanFromUrl("posOperationDrawOriginalCenters", false),
        posOperationDrawNewCenters: getBooleanFromUrl("posOperationDrawNewCenters", false),
        posOperationDrawNewPositions: getBooleanFromUrl("posOperationDrawNewPositions", false),
        multiplierDirectionDebug: getBooleanFromUrl("multiplierDirectionDebug", false),
        canvasSplitDebug: getBooleanFromUrl("canvasSplitDebug", false),
        nxLogs: getBooleanFromUrl("nxLogs", false),
    }

    const snapDebugConfig: snapDebugConfig = {
        seeSnapLogs: getBooleanFromUrl("seeSnapLogs", false),
        visualizeSnapChecks: getBooleanFromUrl("visualizeSnapChecks", false),
    }

    const historyUndoRedoConfig: historyUndoRedoConfig = {
        includeHistoryLogger: getBooleanFromUrl("includeHistoryLogger", false),
        seeHistoryLogs: getBooleanFromUrl("seeHistoryLogs", false),
        seeSceneAtomLogs: getBooleanFromUrl("seeSceneAtomLogs", false),
        seePendingChangesLogs: getBooleanFromUrl("seePendingChangesLogs", false),
    }

    const alignmentDebugConfig: alignmentDebugConfig = {
        drawAllLines: getBooleanFromUrl("drawAllLines", false),
        debugLogsWhileDoingIntervalCheck: getBooleanFromUrl("debugLogsWhileDoingIntervalCheck", false),
    }

    const scalerDebuggingConfig: scalerDebuggingConfig = {
        storeAndIncrementMiddles: getBooleanFromUrl("storeAndIncrementMiddles", true),
        offsetBreadcrumbs: getBooleanFromUrl("offsetBreadcrumbs", false),
        showScalerNormals: getBooleanFromUrl("showScalerNormals", false),
        showOrientedBoundingBox: getBooleanFromUrl("showOrientedBoundingBox", false),
        viewPercentageLabels: getBooleanFromUrl("viewPercentageLabels", false),
        seeRaycastsForEnds: getBooleanFromUrl("seeRaycastsForEnds", false),
        logNormalLineSequences: getBooleanFromUrl("logNormalLineSequences", false),
        showMarkerNormals: getBooleanFromUrl("showMarkerNormals", false),
        showMarkerPositionForBaseParts: getBooleanFromUrl("showMarkerPositionForBaseParts", false),
        segmentedTubePositionWithMarker: getBooleanFromUrl("segmentedTubePositionWithMarker", false),
        createViewsDebug: getBooleanFromUrl("createViewsDebug", false),
        middlePositionLogs: getBooleanFromUrl("middlePositionLogs", false),
        scaleEndLogs: getBooleanFromUrl("scaleEndLogs", false),
        spatialMapDebug: getBooleanFromUrl("spatialMapDebug", false),
    }

    const multiMoveDebugConfig: multiMoveDebugConfig = {
        showCollidingBoundingBoxes: getBooleanFromUrl("showCollidingBoundingBoxes", false),
        showClonedModels: getBooleanFromUrl("showClonedModels", false),
        keepClonedModels: getBooleanFromUrl("keepClonedModels", false),
        boundingBoxFromHighlightedParts: getBooleanFromUrl("boundingBoxFromHighlightedParts", false),
        intersectingPartsDebug: getBooleanFromUrl("intersectingPartsDebug", false),
        viewStoreBoundingBoxesOfNotHighlightedParts: getBooleanFromUrl("storeBoundingBoxesOfNotHighlightedParts", false),
    }

    const moveControlsConfig: MoveControlsConfig = {
        viewDragMesh: getBooleanFromUrl("viewDragMesh", false),
        viewSurfaceMesh: getBooleanFromUrl("viewSurfaceMesh", false),
        viewSurfacePoints: getBooleanFromUrl("viewSurfacePoints", false),
        viewIntersectionPoints: getBooleanFromUrl("viewIntersectionPoints", false),
        stepDensity: {
            value: 8,
            min: 1,
            max: 10,
            step: 1,
        },
        debugAlignment: getBooleanFromUrl("debugAlignment", false),
        debugMeasurements: getBooleanFromUrl("debugMeasurements", false),
    }

    const AppControlsConfig: AppControlsConfig = {
        printJson: button(printJsonCallback),
        runSegmentedTubeSnapChecks: button(segmentedPartsMigrationConnectionsCallback),
        createInstancedMeshOriginsDebug: getBooleanFromUrl("createInstancedMeshOriginsDebug", false),
        vertexGroupDebug: getBooleanFromUrl("vertexGroupDebug", false),
        seeOBBdebugOnSelect: getBooleanFromUrl("seeOBBdebugOnSelect", false),
        visualizeFacesForOBB: getBooleanFromUrl("visualizeFacesForOBB", false),
        viewTubeNames: getBooleanFromUrl("viewTubeNames", false),
        showStats: getBooleanFromUrl("showStats", false),
        showGlobalAxes: getBooleanFromUrl("showGlobalAxes", false),
        useAiPanel: getBooleanFromUrl("useAiPanel", true),
        multiswapDebug: getBooleanFromUrl("multiswapDebug", false),
        showDebugText: getBooleanFromUrl("showDebugText", false),
        viewColliders: getBooleanFromUrl("viewColliders", false),
        showAllPartsIds: getBooleanFromUrl("showAllPartsIds", false),
        logRenders: getBooleanFromUrl("logRenders", false),
        segmentedPlusButtonPosDebug: getBooleanFromUrl("segmentedPlusButtonPosDebug", false),
        drawBoundingBoxMeshesOfUserSelection: getBooleanFromUrl("drawBoundingBoxMeshesOfUserSelection", false),
        drawCombinedBoxOfUserSelection: getBooleanFromUrl("drawCombinedBoxOfUserSelection", false),
        debugBoundingBoxesForAllParts: getBooleanFromUrl("debugBoundingBoxesForAllParts", false),
        rulerDebug: getBooleanFromUrl("rulerDebug", false),
    }

    const SceneRaycasterControlsConfig: SceneRaycasterControlsConfig = {
        raycastLayers: getBooleanFromUrl("raycastLayers", false),
        firstHitOnly: getBooleanFromUrl("firstHitOnly", false),
        include: {
            value: "outer,inner",
        },
        exclude: {
            value: "aligmentPlane",
        },
    }

    const markerControls = useControls("Marker Options", markerControlsConfig, {
        collapsed: true,
    })
    const cameraControls = useControls("Camera Options", cameraControlsConfig, {
        collapsed: true,
    })
    const moveControls = useControls("Move Options", moveControlsConfig, {
        collapsed: true,
    })

    const twoDdebugging = useControls("2D Debugging", twoDdebuggingConfig, {
        collapsed: true,
    })

    const snapDebugging = useControls("Snap Debugging", snapDebugConfig, {
        collapsed: true,
    })

    const historyUndoRedo = useControls("History Undo Redo", historyUndoRedoConfig, {
        collapsed: true,
    })

    const scalerDebugging = useControls("Scaler Debugging", scalerDebuggingConfig, {
        collapsed: true,
    })

    const multiMoveDebugging = useControls("Multi Move Debugging", multiMoveDebugConfig, {
        collapsed: true,
    })

    const appControls = useControls("App Options", AppControlsConfig, {
        collapsed: true,
    })

    const sceneRaycasterControls = useControls("Scene Raycaster Options", SceneRaycasterControlsConfig, {
        collapsed: true,
    })

    const nxerControls = useControls("NXer Options", nxerConfig, {
        collapsed: true,
    })

    const alignmentDebugging = useControls("Alignment Debugging", alignmentDebugConfig, {
        collapsed: true,
    })

    const setControls = useSetRecoilState(controlsState)

    useEffect(() => {
        setControls({
            ...markerControls,
            ...cameraControls,
            ...moveControls,
            ...twoDdebugging,
            ...snapDebugging,
            ...historyUndoRedo,
            ...scalerDebugging,
            ...appControls,
            ...sceneRaycasterControls,
            ...multiMoveDebugging,
            ...nxerControls,
            ...alignmentDebugging,
            admin: adminMode,
        })
    }, [markerControls, cameraControls, moveControls, twoDdebugging, snapDebugging, historyUndoRedo, scalerDebugging, appControls, setControls, sceneRaycasterControls, multiMoveDebugging, nxerControls, alignmentDebugging, adminMode,])

    return (
        <div style={{ position: "absolute", left: 10, top: 50, width: "300px", zIndex: 100, }}>
            <Leva fill hidden={!adminMode} collapsed={true} />
        </div>
    )
}

export const useLevaControls = () => {
    const [adminMode, setAdminMode,] = useRecoilState(adminModeState)
    // Track previous values to detect changes
    const previousValues = useRef<Record<string, boolean>>({})

    // Subscribe to control changes through useControls
    const controls = useRecoilValue(controlsState)

    useEffect(() => {
        if (!adminMode) { return }
        // Check each value in controls
        Object.entries(controls).forEach(([key, value,]) => {
            // Only handle boolean values
            if (typeof value === "boolean") {
                const prevValue = previousValues.current[key]

                // If value changed
                if (prevValue !== undefined && prevValue !== value) {
                    // Update URL
                    const url = new URL(window.location.href)
                    url.searchParams.set(key, value.toString())
                    window.history.replaceState({}, "", url.toString())
                }

                // Update previous value
                previousValues.current[key] = value
            }
        })
    }, [controls,])

    return controls as MarkerControlsConfig
        & CameraControlsConfig
        & MoveControlsConfig
        & AppControlsConfig
        & twoDdebuggingConfig
        & snapDebugConfig
        & historyUndoRedoConfig
        & scalerDebuggingConfig
        & alignmentDebugConfig
        & SceneRaycasterControlsConfig
        & multiMoveDebugConfig
        & AdminConfig
        & NXerConfig
}
